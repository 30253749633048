import { Media } from '@klickdata/core/media/src/media.model';
import { Utils } from '@klickdata/core/util';
import { ObjectValidator } from 'apps/klickdata/src/app/shared/validator/object.validator';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export interface ModelSync {
    id?: number;
    sync_all: boolean;
    attach_ids: [];
    detach_ids: [];
    filters?: {};
}

// MethodType decorator Types
export enum MethodType {
    ALL = 1,
    POST = 2,
    PUT = 3,
}

// MethodType decorator Types
export enum CastType {
    PRIMITIVE = 0,
    MOMENT = 1,
    OBJECT = 2,
    CLOSURE = 5,
}
export interface IModel {
    id: string | number;
    getData: () => IDataModel;

    // Handle selection model interface.
    checked?: boolean;
    disabled?: boolean;

    /** Handle media links */
    media?: Media;
}

export interface AfterModelInit {
    afterModelInit(data: IDataModel): void;
}

export interface OnExport {
    onExport(data: IDataModel): void;
}

export interface IDataModel {
    id?: string | number;
}

export abstract class Model<T extends IDataModel> implements IModel {
    public abstract id: string | number;
    media?: Media;

    public constructor(data?: T) {
        if (data) {
            Object.entries(data)
                // .filter(([key, value]) => !!value) // fix init model with data including none model fields
                .forEach(([key, value]) => {
                    const castValue = value && getCast(this, key);
                    this[key] = !castValue ? value : this.castValueFromat(value, castValue);
                });
        }
        // Handle initial values
        Object.entries(this).forEach(([key, value]) => {
            if (!value) {
                const intialValue = getInitialValue(this, key);
                if (intialValue || typeof intialValue === 'number') {
                    this[key] = intialValue;
                }
            }
        });
        if (this['afterModelInit']) {
            this['afterModelInit'](data ?? this);
        }
    }

    /**
     * Cast a property input to a specified type.
     *
     * @param value
     * @param type
     */
    private castValueFromat(value: any, castMetaValue: { type: number; format: any }): any {
        switch (castMetaValue.type) {
            case CastType.MOMENT:
                return moment.utc(value, castMetaValue.format);
            default:
                return value;
        }
    }

    /**
     * Get POST/PUT payload.
     * Ignore null values.
     * fromat date object to string date format.
     */
    public getPayload(updates?: T, methodType?: MethodType): T {
        return this.getData(updates, methodType);
    }

    public getData(updates?: T, methodType?: MethodType): T {
        const data = {} as T;
        if (updates && this.id) {
            data.id = this.id;
        }

        Object.entries(updates || this).forEach(([key, value]) => {
            const sourceValue = updates ? this[key] : undefined;
            const payloadValue = this.getPayloadValue(key, value, sourceValue, methodType);
            if (payloadValue !== undefined) {
                data[key] = this.formatPayloadValue(key, payloadValue);
            }
        });

        // Handle export
        if (this['onExport']) {
            this['onExport'](data);
        }
        return data;
    }

    /**
     * Validate payload: property will be valid  for payload serialize when.
     * 1. Explicitly provided on payload update && value updated even if null or empty array for reset.
     * 2. Value not null
     * 3. Not empty array.
     * 4. Not ignore on model using @Ignore annotation.
     */
    protected getPayloadValue(key: string, value: any, sourceValue: any, methodType?: MethodType): any {
        /**
         * remove Observable from payload.
         */
        if (value instanceof Observable) {
            return undefined;
        }

        /**
         * remove ignored keys from payload.
         */
        methodType = methodType || (!!this.id ? MethodType.PUT : MethodType.POST);
        const ignore = getIgnore(this, key);
        if (ignore && (ignore === MethodType.ALL || ignore === methodType)) {
            return undefined;
        }

        const castType = getCast(this, key);
        if (value && castType) {
            /**
             * Handle json field object payload for translatable models.
             */
            if (castType.type === CastType.OBJECT) {
                if (!ObjectValidator.isValid(value) && !this.isNullable(key)) {
                    return undefined;
                }

                /**
                 * trim when no changes
                 */
                return !Utils.objEqual(value, sourceValue) ? value : undefined;
            }

            /**
             * Handle Clouser || callback type
             */
            if (castType.type === CastType.CLOSURE) {
                return castType.format(value);
            }

            /** Validate type safe.*/
            if (castType.validateType && typeof value !== castType.format) {
                return undefined;
            }
        }

        if (sourceValue !== undefined) {
            // ignore override id value
            if (key === 'id') {
                return undefined;
            }

            // When same date ignore
            if (sourceValue instanceof moment && (<moment.Moment>sourceValue).isSame(value)) {
                return undefined;
            }

            /**
             * When update value is null explicit reset.
             * When update old value and new value not equals
             */

            if ((value === null || typeof value !== 'object' || value instanceof moment) && sourceValue !== value) {
                return value;
            }

            /**
             * When update item is array and old value and new values not equals
             */
            if (Array.isArray(value) && !Utils.arraysEqual(sourceValue, value, !this.isSortedArray(key))) {
                return value;
            }
        } else {
            if ((value || typeof value === 'boolean') && (!Array.isArray(value) || value.length)) {
                return value;
            }
            if (this.isNullable(key)) {
                return value;
            }
        }
    }

    private isNullable(key: string) {
        const nullable = getNullable(this, key);
        return (
            nullable &&
            (nullable === MethodType.ALL ||
                (nullable === MethodType.POST && !this.id) ||
                (nullable === MethodType.PUT && !!this.id))
        );
    }

    private isSortedArray(key: string) {
        const sortedArray = getSortedArray(this, key);
        return (
            sortedArray &&
            (sortedArray === MethodType.ALL ||
                (sortedArray === MethodType.POST && !this.id) ||
                (sortedArray === MethodType.PUT && !!this.id))
        );
    }

    protected formatPayloadValue(key: string, value: any) {
        if (value instanceof moment) {
            const castValue = getCast(this, key);
            return (<moment.Moment>value).format(castValue?.format || 'YYYY-MM-DD HH:mm:ss');
        }

        if (typeof value === 'string' && 0 === value.trim().length) {
            return null; // Return null when empty string.
        }
        return value;
    }
}

// Cast decorator
const castMetadataKey = Symbol('Cast');
export function Cast(type: number, format: any = 'YYYY-MM-DD HH:mm:ss', validateType = false) {
    return Reflect.metadata(castMetadataKey, { type: type, format: format, validateType: validateType });
}
function getCast(target: any, propertyKey: string): { type: number; format: any; validateType: boolean } {
    return Reflect.getMetadata(castMetadataKey, target, propertyKey);
}

// Ignore decorator
const ignoreMetadataKey = Symbol('Ignore');
export function Ignore(type = MethodType.ALL) {
    return Reflect.metadata(ignoreMetadataKey, type);
}
function getIgnore(target: any, propertyKey: string): number {
    return Reflect.getMetadata(ignoreMetadataKey, target, propertyKey);
}

// Ignore decorator
const nullableMetadataKey = Symbol('nullable');
export function Nullable(type = MethodType.ALL) {
    return Reflect.metadata(nullableMetadataKey, type);
}
function getNullable(target: any, propertyKey: string): number {
    return Reflect.getMetadata(nullableMetadataKey, target, propertyKey);
}

// Ignore decorator
const sortedArrayMetadataKey = Symbol('SortedArray');
export function SortedArray(type = MethodType.ALL) {
    return Reflect.metadata(sortedArrayMetadataKey, type);
}
function getSortedArray(target: any, propertyKey: string): any {
    return Reflect.getMetadata(sortedArrayMetadataKey, target, propertyKey);
}

// Default decorator
const initalValueMetadataKey = Symbol('InitialValue');
export function InitialValue(value: any) {
    return Reflect.metadata(initalValueMetadataKey, value);
}
function getInitialValue(target: any, propertyKey: string): any {
    return Reflect.getMetadata(initalValueMetadataKey, target, propertyKey);
}
