import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    Resource,
    ResourceType,
    ResourceTypes,
    AppScope,
    ResourceTypeService,
} from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { ResourceItemData } from '@klickdata/core/resource-item/src/resource-item';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, startWith, takeUntil } from 'rxjs/operators';
import { ResourceBuilderComponent } from '../resource-builder/resource-builder.component';
import { User } from './../../../core/user/src/user.model';
import { CoursePlanAccessComponent } from './course-plan-access/course-plan-access.component';
import { CoursePlanInformationsComponent } from './course-plan-informations/course-plan-informations.component';
import { SettingsForm } from './course-plan-informations/settings.form';
import { CoursePlanPublishComponent } from './course-plan-publish/course-plan-publish.component';
import { CoursePlanRemindersComponent } from './course-plan-reminders/course-plan-reminders.component';
import { CoursePlanForm } from './course-plan.form';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-create-course-plan-simple',
    templateUrl: './create-course-plan-simple.component.html',
    styleUrls: ['./create-course-plan-simple.component.scss'],
    providers: [CoursePlanForm, { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }],
})
export class CreateCoursePlanSimpleComponent implements OnInit, CanComponentDeactivate {
    @Input() resourceTypes: ResourceType[];
    @Input() resource_type_id = ResourceTypes.GeneralCoursePlan;
    @Input() resourceType = ResourceTypes.COURSE;
    @Input() appScope = AppScope.COURSE;
    AppScope = AppScope;
    public selection: number[] = [];
    private isSaving: boolean;
    private onPublishing: boolean;
    public itemsData: ResourceItemData[] = [];
    @ViewChild(ResourceBuilderComponent)
    public set resourceBuilder(resourceBuilder: ResourceBuilderComponent) {
        if (resourceBuilder) {
            this._resourceBuilder = resourceBuilder;
            this.resourceBuilder
                .getResource()
                .pipe(
                    filter(resource => !!resource),
                    takeUntil(this.destroy)
                )
                .subscribe(resource => this.updateForm(resource));

            this.resourceBuilder
                .getItems()
                .pipe(takeUntil(this.destroy))
                .subscribe(items => {
                    this.selection = items.map(item => item.child_resource_id);
                    items.map(item => {
                        if (this.itemsData.map(i => i.id).indexOf(item.id) === -1) {
                            this.itemsData.push(item.getData());
                        }
                    });
                    this.courseplanFrom.courseResourceItems.next(this.itemsData);
                });
            this.courseplanFrom.setLoading(this.resourceBuilder.getService().getLoading());
        }
    }

    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() published: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Input() resource: Resource;
    @ViewChild(CoursePlanInformationsComponent) settingsComponent: CoursePlanInformationsComponent;
    @ViewChild(CoursePlanAccessComponent) accessComponent: CoursePlanAccessComponent;
    @ViewChild(CoursePlanRemindersComponent) reminderComponent: CoursePlanRemindersComponent;
    @ViewChild(CoursePlanPublishComponent) publishComponent: CoursePlanPublishComponent;
    @ViewChild('vrStepper') vrStepper: MatStepper;

    public get resourceBuilder(): ResourceBuilderComponent {
        return this._resourceBuilder;
    }
    private _resourceBuilder: ResourceBuilderComponent;

    public user$: Observable<User>;
    public publishing: Subject<boolean> = new Subject<boolean>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public resourceTypes$: Observable<ResourceType[]>;
    public formats: { [key: string]: string };
    ResourceTypes = ResourceTypes;

    public savingParts: boolean;

    constructor(
        protected auth: AuthService,
        public courseplanFrom: CoursePlanForm,
        protected resourceTypeService: ResourceTypeService,
        protected messageService: MessageService,
        protected dialog: MatDialog
    ) {
        this.resourceTypes$ = !this.resourceTypes
            ? this.resourceTypeService.getResourceTypes(AppScope.RESOURCES)
            : of(this.resourceTypes);
    }

    ngOnInit() {
        this.user$ = this.auth.getUser().pipe(first());
        this.formats = {
            title: $localize`:@@title:Title`,
            start_date: $localize`:@@startDate:Start date`,
            end_date: $localize`:@@endDate:End date`,
        };
    }

    get settingsForm(): SettingsForm {
        return <SettingsForm>this.courseplanFrom.settings.child;
    }

    get language_id(): number {
        return this.courseplanFrom.settings.child?.form.get('language_id').value;
    }

    get form(): FormGroup {
        return this.settingsForm?.form;
    }

    get public$(): Observable<boolean> {
        return this.form?.get('public').valueChanges.pipe(startWith(this.form.value.public));
    }

    updateForm(resource: Resource) {
        this.courseplanFrom.import(resource.getData());
        // this.courseplanFrom.settings.child.import(resource);
    }

    addItem(resource: Resource) {
        const resourceType: ResourceTypes = ResourceTypes.parentType(resource.type_id);
        // Check if resource already add just update
        const itemForm = (<FormArray>this.resourceBuilder
            .getService()
            .getCurrentForm()
            .get('items')).controls.find(item => item.get('child_resource_id').value === resource.id);

        if (!itemForm) {
            // Add resource to selection to handle single item selected from resource libraries.
            this.selection.push(resource.id);
            // code for showing the message of adding to CP
            this.showResourceAddedMsg(resource, resourceType);
            // Add item to resource builder.
            this.resourceBuilder.getService().createItem({
                item_type_value: this.getItemType(resourceType),
                name: resource.title,
                description: resource.description,
                instructions: resource.instructions,
                child_resource_id: resource.id,
                child_resource_published: resource.published,
                child_resource_language_id: resource.language_id,
                collapse: true,
                child_resource_customer_id: resource.customer_id,
                child_resource_author_id: resource.author_id,
            });
        } else {
            itemForm.patchValue({
                item_type_value: this.getItemType(resourceType),
                name: resource.title,
                description: resource.description,
                instructions: resource.instructions,
                child_resource_id: resource.id,
                child_resource_published: resource.published,
                child_resource_language_id: resource.language_id,
            });
        }
        // send submit action.
        // this.saveParts();
        this.resourceBuilder.getService().submit(false);
        const resourceItem: ResourceItemData = {
            child_resource_id: resource.id,
            name: resource.title,
            item_type_value: this.getItemType(resourceType),
            mandatory: true, // Make mandatory true as default when new item added
        };
        this.itemsData.push(resourceItem);
        this.courseplanFrom.courseResourceItems.next(this.itemsData);
    }

    isEmpty(form: FormGroup) {
        return !(<FormArray>form?.get('items'))?.controls.length;
    }

    private showResourceAddedMsg(resource: Resource, resourceType: ResourceTypes) {
        this.messageService.openMessage(
            MessageSavedComponent,
            `${resource.title}` +
                $localize` added as ` +
                `${resource.type_id ? this.getItemType(resourceType) : 'resource'}` +
                $localize` to ` +
                `${ResourceTypes.label(this.resource_type_id, { capitalize: true })}`
        );
    }

    getItemType(resourceType: ResourceTypes): ResourceItemTypes {
        switch (resourceType) {
            case ResourceTypes.TEST:
                return ResourceItemTypes.TEST;
            case ResourceTypes.SURVEY:
                return ResourceItemTypes.SURVEY;
            case ResourceTypes.MATERIAL:
                return ResourceItemTypes.MATERIAL;
            case ResourceTypes.E_COURSE:
                return ResourceItemTypes.E_COURSE;
        }
    }

    public canPublish(user: User): boolean {
        return user.role_value !== 'user';
    }

    public updateResource(resource: Resource) {
        this.resourceBuilder.updateResource(
            new Resource({ ...this.resourceBuilder.getCurrentResource().getData(), ...resource.getData() })
        );
    }

    public onPublish() {
        this.publishing.next(true);
        this.courseplanFrom.publishCoursePlan().subscribe(() => {
            this.messageService.openMessage(
                MessageSavedComponent,
                $localize`:@@CoursePlanPublished:Course plan published successfully`
            );
            this.publishing.next(false);
            this.published.emit(this.courseplanFrom.model);
        });
    }

    public canDeactivate(): boolean {
        return this.courseplanFrom.form.pristine && this.resourceBuilder.getService().getCurrentForm().pristine;
    }

    @HostListener('window:beforeunload', ['$event'])
    handleClose($event) {
        $event.returnValue = this.canDeactivate();
    }

    /**
     * Save/Publish button action.
     */
    public onSubmit(isSaving: boolean, onPublishing?: boolean) {
        this.onPublishing = onPublishing;
        this.isSaving = isSaving;

        const itemsReady = this.resourceBuilder
            .getService()
            .readyForSubmit(this.resourceType === ResourceTypes.PACKAGE ? 2 : 0);

        if (!itemsReady) {
            if (onPublishing) {
                this.messageService.openMessage(
                    MessageErrorComponent,
                    $localize`You can not save a Course without any Material, Test or Survey.`
                );
            } else if (this.resourceType === ResourceTypes.PACKAGE && this.itemsData.length < 2) {
                this.messageService.openMessage(
                    MessageErrorComponent,
                    $localize`Package must contains at least 2 items.`
                );
            } else if (this.settingsForm.dirty) {
                this.submitWithParts();
            }
        } else {
            this.saveParts();
        }
    }

    private saveParts() {
        this.savingParts = true;
        this.resourceBuilder.getService().submit();
    }

    /**
     * Submit test after question saved event emitted from RB!
     */
    public submitWithParts() {
        this.savingParts = false;
        if (this.form.invalid) {
            FormHelper.markForm(this.form);
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.form, this.formats)} is missing`
            );
        } else {
            this.setLoading(true);
            this.settingsForm.submit().subscribe(
                resource => {
                    this.setLoading(false);
                    FormHelper.resetForm(this.form);
                    if (this.onPublishing) {
                        this.dialog
                            .open(AlertDialogComponent, {
                                disableClose: true,
                                data: {
                                    icon: 'save',
                                    iconClass: 'material-icons-outlined',
                                    title: $localize`:@@saved:Saved`,
                                    contentBody: $localize`:@@afterSavingCourseCreation:The Course is now saved. Do you want to publish the Course in the Academy or do you want to assign this Course to a Learner or a Group?`,
                                    positiveBtn: $localize`:@@assign:Assign`,
                                    negativeBtn: $localize`:@@publish:Publish`,
                                    neutralBtn: $localize`:@@cancel:Cancel`,
                                },
                            })
                            .afterClosed()
                            .pipe(takeUntil(this.destroy))
                            .subscribe((result: boolean) => {
                                if (result === true) {
                                    this.updateResource(resource);
                                    this.vrStepper.next();
                                } else if (result === false) {
                                    this.settingsForm.submit(true).subscribe();
                                    this.published.emit(this.courseplanFrom.model);
                                } else {
                                    this.updateResource(resource);
                                }
                            });
                    } else if (this.isSaving) {
                        this.saved.emit(resource);
                    }
                },
                err => {
                    this.setLoading(false);
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
        }
    }

    public updateRemovedItems(removal: number[]) {
        this.selection = this.selection.filter(item => removal.indexOf(item) === -1);
    }

    private setLoading(loading: boolean) {
        this.resourceBuilder.getService().setLoading(loading);
        this.saving.emit(loading);
    }
}
