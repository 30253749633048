<ng-template [ngIf]="type === 'text' || type === 'email' || type === 'password' || type === 'number'">
    <div class="form-field" fxLayout="column">
        <div class="item-info-wrap">
            <ng-content select="[role=input-label]"></ng-content>
            <div *ngIf="tips" class="bullets-info-wrap">
                <mat-icon class="third-colmn-editor-title-icon" [matTooltip]="tips">question_mark</mat-icon>
            </div>
        </div>

        <div class="input-container">
            <div
                class="input-help"
                fxLayoutGap="5px"
                *ngIf="clickToEdit || allowRemove || originalType === 'password' || numbersCounterChanger"
            >
                <mat-icon color="primary" *ngIf="originalType === 'password'" (click)="togglePasswordType()">
                    {{ type === 'password' ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <button color="warn" (click)="clearInputField()" mat-raised-button *ngIf="allowRemove" i18n>
                    Remove
                </button>
                <button color="primary" (click)="enableInputField()" mat-raised-button *ngIf="clickToEdit" i18n>
                    Change
                </button>
                <div class="num-changer" fxLayout="column" *ngIf="numbersCounterChanger && type === 'number'">
                    <mat-icon color="primary" (click)="changeNum(1)"> arrow_drop_up </mat-icon>
                    <mat-icon
                        [ngClass]="{ 'disabled-click': control.value < 2 }"
                        color="primary"
                        (click)="changeNum(-1)"
                    >
                        arrow_drop_down
                    </mat-icon>
                </div>
            </div>
            <input [placeholder]="placeholder" class="input-normal" [type]="type" [formControl]="control | cast" />
        </div>
        <ng-content select="[role=input-content]"></ng-content>
    </div>
</ng-template>

<ng-template [ngIf]="type === 'textarea'">
    <div class="form-field" fxLayout="column">
        <ng-content select="[role=textarea-label]"></ng-content>
        <div class="input-container">
            <textarea
                [rows]="textAreaRows"
                [placeholder]="placeholder"
                class="input-normal"
                [formControl]="control | cast"
            ></textarea>
        </div>
        <ng-content select="[role=textarea-content]"></ng-content>
    </div>
</ng-template>

<ng-template [ngIf]="type === 'switch'">
    <div class="form-field" fxLayout="column">
        <ng-content select="[role=switch-title]"></ng-content>
        <div class="input-switch" fxLayout="row" fxLayoutGap="10px">
            <mat-slide-toggle
                [formControl]="control | cast"
                [disabled]="switchDisabled"
                data-on="on"
                data-off="off"
                [ngClass]="{ 'show-label': showLabel === true }"
            >
                <ng-content select="[role=switch-label]"></ng-content>
            </mat-slide-toggle>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="type === 'checkbox'">
    <div class="form-field" fxLayout="column">
        <ng-content select="[role=checkbox-label]"></ng-content>
        <div class="input-checkbox" fxLayout="row" fxLayoutGap="10px">
            <mat-checkbox>male</mat-checkbox>
            <mat-checkbox>female</mat-checkbox>
            <mat-checkbox>Other not specified (I want to be in both gender scoreboards)</mat-checkbox>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="type === 'select'">
    <div class="form-field select-container" fxLayout="row" fxLayoutGap="15px">
        <div class="select">
            <select matNativeControl>
                <option>4</option>
            </select>
        </div>
        <ng-content select="[role=select-label]"></ng-content>
    </div>
</ng-template>

<ng-template [ngIf]="type === 'chips'">
    <div class="form-field chips-container" fxLayout="row" fxFlex="100%" fxLayoutGap="15px">
        <div fxFlex="grow" fxLayout="column" fxLayoutGap="10px">
            <ng-content select="[role=chips-label]"></ng-content>
            <div class="tags" fxLayout="row wrap" fxLayoutGap="15px">
                <div class="tag">
                    <span>test</span>
                    <mat-icon class="close">close</mat-icon>
                </div>
                <div class="tag">
                    <span>test</span>
                    <mat-icon class="close">close</mat-icon>
                </div>
                <div class="tag">
                    <span>test</span>
                    <mat-icon class="close">close</mat-icon>
                </div>
                <div class="tag">
                    <span>test</span>
                    <mat-icon class="close">close</mat-icon>
                </div>
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="add-chips" fxLayoutGap="10px">
            <span>Add</span>
            <mat-icon class="m-0" class="add">add</mat-icon>
        </div>
    </div>
</ng-template>
