<ng-container *ngIf="resource$ | async; else loading; let resource">
    <app-resource-course-details
        *ngIf="isMob$ | async"
        [resource]="resource"
        [resourceItems$]="resourceItems$"
        [resourceCategories$]="resourceCategories$"
        [resourceTags$]="resourceTags$"
        [resourceType]="ResourceTypes.parentType(resource.type_id)"
    >
    </app-resource-course-details>
    <ng-container *ngIf="!(isMob$ | async)">
        <ng-container [ngSwitch]="ResourceTypes.parentType(resource.type_id)">
            <ng-container *ngSwitchCase="ResourceTypes.COURSE">
                <app-resource-details-course
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-course>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.E_COURSE">
                <app-resource-details-ecourse
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-ecourse>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.EVENT">
                <app-resource-details-event
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-event>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.MATERIAL">
                <app-resource-details-material
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-material>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.TEST">
                <app-resource-details-test
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-test>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.SURVEY">
                <app-resource-details-survey
                    [resource]="resource"
                    [customer]="customer$ | async"
                    [user]="user$ | async"
                    [resourceItems]="resourceItems$ | async"
                    [resourceCategories$]="resourceCategories$"
                    [resourceTags$]="resourceTags$"
                    [resourceType]="ResourceTypes.parentType(resource.type_id)"
                ></app-resource-details-survey>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<app-footer *ngIf="!(isMob$ | async)" fxFlex="1 1 auto"></app-footer>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
