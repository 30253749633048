import { animate, state, style, transition, trigger } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { RecordingOutput } from '@klickdata/core/media/src/media-type';
import { RecorderService } from '@klickdata/core/media/src/recorder.service';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceCreationManagerBase, ResourceTypes } from '@klickdata/core/resource';
import { PromptType, ResourceItemService } from '@klickdata/core/resource-item';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { ActionQueue, ActionQueueImpl } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { MaterialTextManagerCoreComponent } from './material-text-manager-core/material-text-manager-core.component';

@Component({
    selector: 'app-material-manager-core',
    templateUrl: './material-manager-core.component.html',
    styleUrls: ['./material-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '50px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MaterialManagerCoreComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(MaterialTextManagerCoreComponent) textManagerCoreComp: MaterialTextManagerCoreComponent;
    @Input() resource: Resource;
    @Input() resourceForm: FormGroup;
    @Input() public recorderOutput: RecordingOutput;
    @Input() active: boolean;
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onResKeysValidatyChanges: EventEmitter<{ [key: string]: string | boolean }[]> = new EventEmitter<
        { [key: string]: string | boolean }[]
    >();
    public langControl = new FormControl();
    public customer$: Observable<Customer>;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public materialIsDirty: boolean;
    public expandedType: ResourceTypes = ResourceTypes.URLMaterial;
    @ViewChild('compRef') compRef: any;
    ResourceTypes = ResourceTypes;
    @ContentChildren(ResourceCreationManagerBase, { descendants: true })
    filterComps: QueryList<ResourceCreationManagerBase>;
    public resKeysValidatyUpdated: boolean;
    public materialAddedItem: ResourceBuildingItems;
    ResourceBuildingItems = ResourceBuildingItems;
    private isMobile: boolean;
    public actionQueue: ActionQueue<PromptType> = new ActionQueueImpl();

    constructor(
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService,
        protected itemService: ResourceItemService,
        private clipboard: Clipboard,
        protected messageService: MessageService,
        protected recordingService: RecorderService
    ) {}

    ngOnInit(): void {
        this.customer$ = this.auth.getCustomer().pipe(first());
        this.langControl.setValue(this.resource?.language_id);
        if (this.recorderOutput) {
            this.materialAddedItem = ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL;
            this.cdr.markForCheck();
        }

        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((ismob) => (this.isMobile = ismob));
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue) {
            setTimeout(() => {
                if (!this.materialAddedItem && !this.resource.id) {
                    this.addMaterial();
                }
            }, 200);
        }
    }

    public onCancelMaterial() {
        if (!this.materialIsDirty) {
            this.materialAddedItem = null;
            this.cdr.markForCheck();
            return;
        }
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'published_with_changes',
                title: $localize`Do you want to change material?`,
                contentBody: $localize`By clicking 'Change', you will lose your progress.`,
                positiveBtn: $localize`Change`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                if (action) {
                    this.compRef.cleanControl();
                    this.materialIsDirty = false;
                    this.materialAddedItem = null;
                    this.cdr.markForCheck();
                }
            });
    }
    public addMaterial() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_QUESTIONS,
            data: {
                type: AppScope.MATERIAL,
                buildingItems: [
                    ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL,
                    ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL,
                    ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL,
                ],
                context: 'materialCreation',
                icon: 'add_circle_outline',
                title: $localize`Add Material`,
                resource: this.resource ?? {},
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === AppScope.MATERIAL),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data.resource) {
                    this.saved.emit(data.resource);
                } else {
                    if (data.value === ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL) {
                        setTimeout(() => {
                            this.recordingService.clickRecorderOptionsBtn();
                        }, 500);
                    } else {
                        this.materialAddedItem = data.value;
                    }

                    this.cdr.markForCheck();
                }
            });
    }

    public canDeactivate(): boolean {
        return this.compRef ? this.compRef.canDeactivate() : true;
    }
    public submit() {
        this.compRef?.submit();
    }

    public saveAndDeactivate(): boolean {
        return this.compRef ? this.compRef.saveAndDeactivate() : true;
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
