<div fxLayout="column">
    <div fxFlex="0 1 100%" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!resourceForm.value.file">
        <div
            [ngStyle]="dropStyle"
            fxFlex="0 0 50%"
            fxFlex.lt-md="0 0 90%"
            class="media-dropzone"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <input
                class="media-upload-button"
                type="file"
                (change)="prepareFile($event)"
                [disabled]="onSavingFile | async"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf,.xlsx,.xls, image/*, video/3gpp, video/mp4,
                video/webm,audio/*, application/vnd.apple.keynote"
            />
        </div>
        <ng-container *ngIf="!resourceForm.value.file">
            <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
                <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
                <span class="progress-value">{{ progress }}%</span>
            </div>
        </ng-container>
    </div>
    <app-media-view
        *ngIf="resourceForm.value.file"
        fxLayoutAlign="start center"
        fxFlex="0 0 50%"
        fxFlex.lt-md="0 0 100%"
        [media_id]="resourceForm.value.file"
    >
        <button
            class="remove-file"
            color="warn"
            type="button"
            fxFlex="0 1 auto"
            mat-icon-button
            matTooltip="Remove file"
            i18n-matTooltip
            (click)="
                resourceForm.patchValue({ file: '' });
                onControlDirty.emit(false);
                resourceForm.get('file').markAsDirty();
                clearVTQ()
            "
        >
            <mat-icon class="md-24">delete_outline</mat-icon>
        </button>
    </app-media-view>
</div>
