import {
    AfterModelInit,
    Cast,
    CastType,
    IDataModel,
    Ignore,
    MethodType,
    Model,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { User } from '@klickdata/core/user/src/user.model';
import { ObjectBoolean } from 'aws-sdk/clients/kendra';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CustomerCategory } from './customer-category/customer-category.model';
import { CustomerLicense } from './customer-license/customer-license.model';
import { CustomerServiceModel } from './customer-service/customer-service.model';
import { Utils } from '@klickdata/core/util';

export interface FXR {
    from: string;
    to: string;
    fxr: number;
}

export interface SocialMedia {
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
}
export interface CustomerData extends IDataModel {
    id?: number;
    name?: string;
    short_name?: string;
    subdomains?: string[];
    domains?: string[];
    lang?: string;
    pref_lang_ids?: number[];
    published?: string;
    category_value?: string;
    service_values?: string[];
    expiration_date?: string;
    max_users?: number;
    customer_number?: number;
    license_type?: string;
    owner_id?: number;
    owner_contact_id?: number;
    owner_contact?: User;
    owner_technical_contact_id?: number;
    owner_technical_contact?: User;
    technical_contact_id?: number;
    contact_id?: number;
    notes?: string;
    welcome_message?: { [key: string]: string };
    about_us?: { [key: string]: string };
    currency_fxr?: FXR[];
    social_media?: SocialMedia;
    logotype_id?: string;
    landing_background_media_id?: string;
    url?: string;
    logotype_url?: string;
    background_url?: string;
    logotype_padding?: number;
    prompt_delimiters_disabled?: boolean;
    prompt_delimiters_master_disabled?: boolean;
    enable_anonymous_register?: boolean;
    enable_subscription_plans?: boolean;

    created_by?: string;
    created_at?: string;
    updated_at?: string;
    users_count?: number;

    // assign param
    sync_param?: { published?: boolean; public?: boolean; customer_id?: number; scope_id: number };
    cluster_count?: number;
}

export class Customer extends Model<CustomerData> implements AfterModelInit {
    id: number;
    name: string;
    short_name: string;
    subdomains: string[];
    domains: string[];
    lang: string;
    pref_lang_ids: number[];
    @Cast(CastType.MOMENT)
    published: moment.Moment;
    category_value: string;
    service_values: string[];

    @Nullable(MethodType.POST)
    max_users: number;
    customer_number: number;
    license_type: string;
    owner_id: number;
    owner_contact_id: number;
    owner_contact: User;
    owner_technical_contact_id: number;
    owner_technical_contact: User;
    technical_contact_id?: number;
    contact_id?: number;
    notes: string;

    @Cast(CastType.OBJECT)
    welcome_message: { [key: string]: string };
    @Cast(CastType.OBJECT)
    about_us: { [key: string]: string };
    currency_fxr: FXR[];
    social_media: SocialMedia;
    logotype_id: number;
    landing_background_media_id: number;
    logotype_padding: number;

    @Cast(CastType.MOMENT)
    @Ignore()
    created_at: moment.Moment;

    @Cast(CastType.MOMENT)
    @Ignore()
    updated_at: moment.Moment;

    @Cast(CastType.MOMENT, 'YYYY-MM-DD')
    @Nullable()
    expiration_date: moment.Moment;

    readonly created_by: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;

    @Ignore()
    services_attached: boolean;

    @Ignore()
    checked: boolean;
    @Ignore()
    users_count: number;
    enable_anonymous_register: boolean;
    enable_subscription_plans: boolean;
    prompt_delimiters_disabled: boolean;
    prompt_delimiters_master_disabled: boolean;

    services: Observable<CustomerServiceModel[]>;
    owner_contact$: Observable<User>;
    license: Observable<CustomerLicense>;
    category: Observable<CustomerCategory>;
    administrators: Observable<User[]>;
    cluster_count: number;

    public afterModelInit(data: CustomerData) {
        this.owner_contact = new User(<any>data.owner_contact);
        this.owner_technical_contact = new User(<any>data.owner_technical_contact);
    }

    /**
     * Object.assign() is used to create a shallow copy of this object and assign it to a new object obj.
     * const obj = Object.assign(<CustomerData>{}, this);
     *
     * A deep copy means that all nested objects and arrays are also copied, rather than just copying their references.
     * One way to create a deep copy is by using JSON.parse() and JSON.stringify()
     */
    get value(): CustomerData {
        const obj: CustomerData = JSON.parse(JSON.stringify(this));
        if (Utils.isEmpty(obj.domains)) obj.domains = [Utils.getDomain()];
        return obj;
    }

    getFXR(from: string, to: string): FXR | null {
        if (!this.currency_fxr) {
            return null;
        }

        const directFXR = this.currency_fxr.find((fxr) => from === fxr.from && to === fxr.to);
        const reverseFXR = this.currency_fxr.find((fxr) => from === fxr.to && to === fxr.from);

        return directFXR ?? reverseFXR ?? null;
    }
}
export interface CustomerLanding {
    title?: string;
    logotype_padding?: number;
    logotype_url?: string;
    background_url?: string;
    enable_anonymous_register?: boolean;
}
