<div class="builder-header">
    <ng-content select="[appBuilderTitle]"></ng-content>

    <ng-content select="[appBuilderEmpty]"></ng-content>

    <button
        *ngIf="!(isMobile$ | async)"
        class="unFold-all"
        [ngClass]="{ unfold: !foldAll }"
        mat-icon-button
        type="button"
        (click)="$event.stopPropagation(); foldAll = !foldAll; onItemCollapsingChange(foldAll)"
    >
        <mat-icon>last_page</mat-icon>
    </button>

    <app-resource-builder-form
        fxFlex="0 1 1000px"
        fxFlex.lt-lg="80%"
        fxFlex.lt-md="100%"
        [usePoints]="usePoints"
        [form]="form | async"
        (saving)="onSaving($event)"
        (removal)="removal.emit($event)"
        (saved)="onSaved($event)"
    >
    </app-resource-builder-form>

    <!-- <app-resource-builder-tools
        [ngClass]="{ 'hide-items-builder-tools': hideAddItems }"
        *ngIf="!hideTools"
        class="sticky"
        [class.in-card]="inCard"
        fxFlex="0 1 1000px"
        fxFlex.lt-lg="80%"
        fxFlex.lt-md="100%"
        [forceSubmit]="forceSubmit"
        [resourceTypes]="resourceTypes"
        [questionTypes]="questionTypes"
        [saveTitle]="saveTitle"
        [usePoints]="usePoints"
        [active]="active"
        [hideAddItems]="hideAddItems"
    >
    </app-resource-builder-tools> -->
    <div
        *ngIf="
            !hideTools &&
            (resource_type_id === ResourceTypes.GeneralSurvey || resource_type_id === ResourceTypes.GeneralTest)
        "
        fxFlex="grow"
        fxLayoutAlign="start center"
        class="mt-1"
    >
        <button mat-button type="button" class="add-qs" (click)="addQuestions()">
            <mat-icon>add_circle</mat-icon>
            <span i18n>Add Question</span>
        </button>
    </div>
</div>
