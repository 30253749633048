import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InfoDialogComponent } from '@klickdata/core/application/src/info-dialog/info-dialog.component';
import { IDataModel, ModelSync } from '@klickdata/core/application/src/model/model-interface';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { Media } from '@klickdata/core/media/src/media.model';
import { ParentMapper } from '@klickdata/core/resource';
import { Filter, SelectFilterOption } from '@klickdata/core/table';
import { NotePrivacy } from '@klickdata/core/user-notes';
import { ObjectValidator } from 'apps/klickdata/src/app/shared/validator/object.validator';
import * as moment from 'moment';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PromptStatus } from './prompt-util';
import { FormGroup } from '@angular/forms';

export class Utils {
    /**
     * Handle youtube links validators
     * @param url expected youtube link.
     */
    private static getYoutubeURLMatcher(url: string) {
        return url && url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/);
    }

    private static getVimeoURLMatcher(url: string) {
        return (
            url &&
            url.match(
                /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/
            )
        );
    }

    static isYoutubeURL(url: string): boolean {
        return this.isYoutubeId(this.getYoutubeURLMatcher(url));
    }

    private static isYoutubeId(matcher: RegExpMatchArray): boolean {
        return matcher && matcher[2] && matcher[2].length === 11;
    }

    static isVimeoURL(url: string): boolean {
        return this.isVimeoId(this.getVimeoURLMatcher(url));
    }

    private static isVimeoId(matcher: RegExpMatchArray): boolean {
        return matcher && !!matcher[4];
    }

    static getYoutubeMedia(url: string): Media {
        const matcher = this.getYoutubeURLMatcher(url);
        if (this.isYoutubeId(matcher)) {
            return <Media>{ src: matcher[2], provider: 'youtube', mediaType: MediaType.VIDEO };
        }
    }

    private static getVimeoMedia(url: string): Media {
        const matcher = this.getVimeoURLMatcher(url);
        if (this.isVimeoId(matcher)) {
            return <Media>{ src: matcher[4], provider: 'vimeo', mediaType: MediaType.VIDEO };
        }
    }

    private static getWebsiteMedia(url: string): Media {
        if (this.internalLink(url)) {
            return <Media>{ url: url, type: 'website', mediaType: MediaType.WEBSITE };
        }
    }

    static getAssetsImg(path: string): Media {
        if (path.indexOf('assets/images/') !== -1) {
            return <Media>{ src: path, mediaType: MediaType.IMAGE };
        }
    }

    static getValuedKeysFromArrayOfObjects(items: any[]): string[] {
        const valuedDatakeys = [];
        items
            .map((item) => {
                return Object.keys(item).reduce((acc, el) => {
                    if (item[el] !== null && item[el] !== '') {
                        acc[el] = item[el];
                    }
                    return acc;
                }, {});
            })
            .forEach((item) => {
                Object.keys(item).forEach((itemKey) => {
                    if (valuedDatakeys.indexOf(itemKey) === -1) {
                        valuedDatakeys.push(itemKey);
                    }
                });
            });
        return valuedDatakeys;
    }

    static getMatchedItemsFromArrays(first: any[], second: any[]): any[] {
        return first.filter((element) => second.includes(element));
    }

    static removeItemByIdFromArray(id: any, array: any[]): any[] {
        const index = array.findIndex((item) => item.id === id);
        if (index !== -1) {
            array.splice(index, 1);
        }
        return array;
    }

    static functionNotImpl(dialog: MatDialog) {
        dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
                contentBody: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }

    private static getLinkMedia(url: string): Media {
        if (url.match(/(http|https)?:.*/)) {
            return <Media>{ url: url, type: 'url_material', mediaType: MediaType.URL };
        }
    }

    static getAllCurrencies() {
        return [
            { code: 'USD', sign: '$', label: 'US Dollar' },
            { code: 'EUR', sign: '€', label: 'Euro' },
            { code: 'SEK', sign: 'SEK', label: 'Swedish Krona' },
            { code: 'EGP', sign: 'E£', label: 'Egyptian Pound' },
            { code: 'SAR', sign: '﷼', label: 'Saudi Riyal' },
            { code: 'GBP', sign: '£', label: 'British Pound Sterling' },
            { code: 'INR', sign: '₹', label: 'Indian Rupee' },
            { code: 'JPY', sign: '¥', label: 'Japanese Yen' },
            { code: 'KRW', sign: '₩', label: 'South Korean Won' },
            { code: 'NGN', sign: '₦', label: 'Nigerian Naira' },
            { code: 'PHP', sign: '₱', label: 'Philippine Peso' },
            { code: 'PLN', sign: 'zł', label: 'Polish Zloty' },
            { code: 'PYG', sign: '₲', label: 'Paraguayan Guarani' },
            { code: 'THB', sign: '฿', label: 'Thai Baht' },
            { code: 'UAH', sign: '₴', label: 'Ukrainian Hryvnia' },
            { code: 'VND', sign: '₫', label: 'Vietnamese Dong' },
        ];
    }

    static getUserStatusOptions(): SelectFilterOption[] {
        return [
            { title: $localize`:@@active:Active`, value: 'active', icon: 'notifications_active' },
            { title: $localize`Inactive (30d)`, value: 'inactive', icon: 'verified_user' },
            { title: $localize`Unactivated`, value: 'unactivated', icon: 'sync_disabled' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
            { title: $localize`Imported`, value: 'imported', icon: 'label_important' },
        ];
    }
    static getGeneralModelStatusSelectOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
        ];
    }

    static getResourceTypeOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Course`, value: 3, icon: 'kd-icon-task', isMainResource: true },
            { title: $localize`Test`, value: 5, icon: 'kd-icon-test', isMainResource: true },
            { title: $localize`Material`, value: 7, icon: 'kd-icon-document', isMainResource: true },
            { title: $localize`Survey`, value: 4, icon: 'kd-icon-survey', isMainResource: true },
            { title: $localize`Ecourse`, value: 6, icon: 'kd-icon-player', isMainResource: true },
        ];
    }

    /**
     * Action event source from ActivityEventEnum
     * https://git.klickportalen.se/klickdata2016/nk3-datastore/blob/develop/app/Klickdata/ActionLog/ActivityEventEnum.php
     * @returns
     */
    static getActionLogEventOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Assigned`, value: 'assigned', icon: 'assignment_ind', data: { isResRelated: true } },
            {
                title: $localize`Unassigned`,
                value: 'unassigned',
                icon: 'assignment_returned',
                data: { isResRelated: true },
            },
            { title: $localize`Cancelled`, value: 'cancelled', icon: 'cancel', data: { isResRelated: true } },
            { title: $localize`Completed`, value: 'completed', icon: 'task_alt', data: { isResRelated: true } },
            {
                title: $localize`Created`,
                value: 'created',
                icon: 'drive_file_rename_outline',
                data: { isResRelated: true },
            },
            { title: $localize`Deleted`, value: 'deleted', icon: 'delete', data: { isResRelated: true } },
            { title: $localize`Login`, value: 'login', icon: 'login', data: { isResRelated: false } },
            { title: $localize`Activate`, value: 'activate', icon: 'contact_mail', data: { isResRelated: false } },
            { title: $localize`Activated`, value: 'activated', icon: 'mark_email_read', data: { isResRelated: false } },
            { title: $localize`Deactivated`, value: 'deactivated', icon: 'person_off', data: { isResRelated: false } },
            {
                title: $localize`Reminder`,
                value: 'reminder',
                icon: 'notifications_paused',
                data: { isResRelated: false },
            },
            { title: $localize`Invite`, value: 'invite', icon: 'contact_phone', data: { isResRelated: false } },
            { title: $localize`Logout`, value: 'logOut', icon: 'logout', data: { isResRelated: false } },
            { title: $localize`Ongoing`, value: 'ongoing', icon: 'cached', data: { isResRelated: true } },
            { title: $localize`Overdue`, value: 'overdue', icon: 'event_busy', data: { isResRelated: true } },
            { title: $localize`Restored`, value: 'restored', icon: 'restore_page', data: { isResRelated: true } },
            { title: $localize`Started`, value: 'started', icon: 'start', data: { isResRelated: true } },
            { title: $localize`Updated`, value: 'updated', icon: 'restart_alt', data: { isResRelated: true } },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                data: { isResRelated: true },
            },
            { title: $localize`Unpublished`, value: 'unpublished', icon: 'unpublished', data: { isResRelated: true } },
            { title: $localize`Public`, value: 'public', icon: 'public', data: { isResRelated: true } },
            { title: $localize`Unpublic`, value: 'unpublic', icon: 'public_off', data: { isResRelated: true } },
            { title: $localize`Duration`, value: 'durationtime', icon: 'timer', data: { isResRelated: true } },
            { title: $localize`Prompt`, value: 'user_prompt', icon: 'smart_toy', data: { isResRelated: false } },
        ];
    }
    static getKDColors(): string[] {
        return ['#e44a66', '#93cbd1', '#3e5365', '#ff9961', '#bfd8d0'];
    }
    static getNotesPrivacyOptions(): NotePrivacy[] {
        return [
            { value: 'private', label: $localize`Private` },
            { value: 'user', label: $localize`Learners` },
            { value: 'group', label: $localize`Groups` },
            { value: 'academy', label: $localize`Academy` },
            { value: 'public', label: $localize`Public` },
        ];
    }
    static getPredefinedTimeSpentOptions(): SelectFilterOption[] {
        return [
            {
                title: $localize`24H`,
                value: moment().subtract(1, 'day').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '24h',
                data: {
                    tooltipValue: $localize`Last 24 hours`,
                },
            },
            {
                title: $localize`7D`,
                value: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '7d',
                data: {
                    tooltipValue: $localize`Last 7 Days`,
                },
            },
            {
                title: $localize`30D`,
                value: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '30d',
                data: {
                    tooltipValue: $localize`Last 30 Days`,
                },
            },
            {
                title: $localize`180D`,
                value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: '180d',
                data: {
                    tooltipValue: $localize`Last 6 months`,
                },
            },
            {
                title: $localize`Year`,
                value: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                icon: 'av_timer',
                class: 'year',
                data: {
                    tooltipValue: $localize`Last Year`,
                },
            },
            {
                title: $localize`Defined period`,
                value: 'select_date_range',
                icon: 'date_range',
                class: 'date_range',
                data: {
                    tooltipValue: $localize`Choose a period with start and end date`,
                },
            },
        ];
    }

    static getSectionsSortingOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Recommended`, value: 'priority', icon: 'thumb_up_off_alt' },
            {
                title: $localize`Popular`,
                value: 'popularity',
                icon: 'grade',
                class: 'material-icons-outlined',
            },
            { title: $localize`Latest`, value: 'updated_at', icon: 'update' },
            { title: $localize`Alphabetical`, value: 'title', icon: 'sort_by_alpha' },
        ];
    }
    static getWorldTimeZones(): SelectFilterOption[] {
        return [
            { title: 'UTC-12:00 (BST) Baker Island Time', value: 'BST', icon: '' },
            { title: 'UTC-11:00 (SST) Samoa Standard Time', value: 'SST', icon: '' },
            { title: 'UTC-10:00 (HST) Hawaii-Aleutian Standard Time', value: 'HST', icon: '' },
            { title: 'UTC-9:00 (AKST) Alaska Standard Time', value: 'AKST', icon: '' },
            { title: 'UTC-8:00 (PST) Pacific Standard Time', value: 'PST', icon: '' },
            { title: 'UTC-7:00 (MST) Mountain Standard Time', value: 'MST', icon: '' },
            { title: 'UTC-6:00 (CST) Central Standard Time', value: 'CST', icon: '' },
            { title: 'UTC-5:00 (EST) Eastern Standard Time', value: 'EST', icon: '' },
            { title: 'UTC-4:00 (AST) Atlantic Standard Time', value: 'AST', icon: '' },
            { title: 'UTC-3:00 (BRT) Brasilia Time', value: 'BRT', icon: '' },
            { title: 'UTC-2:00 (FNT) Fernando de Noronha Time', value: 'FNT', icon: '' },
            { title: 'UTC-1:00 (AZOT) Azores Standard Time', value: 'AZOT', icon: '' },
            { title: 'UTC+0:00 (GMT) Greenwich Mean Time', value: 'GMT', icon: '' },
            { title: 'UTC+1:00 (CET) Central European Time', value: 'CET', icon: '' },
            { title: 'UTC+2:00 (EET) Eastern European Time', value: 'EET', icon: '' },
            { title: 'UTC+3:00 (MSK) Moscow Standard Time', value: 'MSK', icon: '' },
            { title: 'UTC+4:00 (GET) Georgia Standard Time', value: 'GET', icon: '' },
            { title: 'UTC+5:00 (PKT) Pakistan Standard Time', value: 'PKT', icon: '' },
            { title: 'UTC+6:00 (BST) Bangladesh Standard Time', value: 'BST', icon: '' },
            { title: 'UTC+7:00 (ICT) Indochina Time', value: 'ICT', icon: '' },
            { title: 'UTC+8:00 (HKT) Hong Kong Time', value: 'HKT', icon: '' },
            { title: 'UTC+9:00 (JST) Japan Standard Time', value: 'JST', icon: '' },
            { title: 'UTC+10:00 (AEST) Australian Eastern Standard Time', value: 'AEST', icon: '' },
            { title: 'UTC+11:00 (VLAT) Vladivostok Time', value: 'VLAT', icon: '' },
            { title: 'UTC+12:00 (NZST) New Zealand Standard Time', value: 'NZST', icon: '' },
            { title: 'UTC+13:00 (TOT) Tonga Time', value: 'TOT', icon: '' },
            { title: 'UTC+14:00 (LINT) Line Islands Time', value: 'TOT', icon: '' },
        ];
    }
    static getcatalogSourceOptions(): SelectFilterOption[] {
        return [
            { title: $localize`My academy`, value: 'customer', icon: 'folder_shared' },
            { title: $localize`KOL`, value: 'public', icon: 'folder_special' },
            { title: $localize`All`, value: 'publicOrCustomer', icon: 'library_books' },
        ];
    }

    static getfunctionOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Course`, value: 3, icon: 'kd-icon-task', isMainResource: true },
            {
                title: $localize`:@@user:User`,
                value: 2,
                icon: 'person',
            },
            {
                title: $localize`:@@group:Group`,
                value: 12,
                icon: 'groups',
            },
            { title: $localize`Survey`, value: 4, icon: 'kd-icon-survey', isMainResource: true },
            {
                title: $localize`:@@category:Category`,
                value: 11,
                icon: 'folder',
            },
            {
                title: $localize`:@@tag:Tag`,
                value: 10,
                icon: 'tag',
            },
            { title: $localize`Test`, value: 5, icon: 'kd-icon-test', isMainResource: true },
            {
                title: $localize`:@@customer:Academy`,
                value: 13,
                icon: 'supervised_user_circle',
            },
            {
                title: $localize`:@@section:Section`,
                value: 9,
                icon: 'widgets',
            },
            { title: $localize`E-course`, value: 6, icon: 'kd-icon-player', isMainResource: true },
            {
                title: $localize`:@@folder:Folder`,
                value: 8,
                icon: 'folder_open',
            },
            {
                title: $localize`:@@scorm:SCORM`,
                value: 20,
                icon: 'business_center',
            },
            { title: $localize`Material`, value: 7, icon: 'kd-icon-document', isMainResource: true },
            {
                title: $localize`:@@answer:Answer`,
                value: 16,
                icon: 'fact_check',
            },
            {
                title: $localize`:@@alternative:Alternative`,
                value: 15,
                icon: 'alt_route',
            },
            {
                title: $localize`:@@task:Task`,
                value: 17,
                icon: 'task',
            },
            {
                title: $localize`:@@question:Question`,
                value: 14,
                icon: 'contact_support',
            },
        ];
    }
    static getUserRoleOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Master Admin`, value: 'superAdmin', icon: 'admin_panel_settings' },
            { title: $localize`Main Admin`, value: 'customerAdmin', icon: 'groups' },
            { title: $localize`Group Admin`, value: 'groupAdmin', icon: 'group' },
            { title: $localize`Learner`, value: 'user', icon: 'person' },
        ];
    }

    static getTodoActionsOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Call`, value: 'call', icon: 'touch_app' },
            { title: $localize`Email`, value: 'emal', icon: 'touch_app' },
            { title: $localize`Follow up`, value: 'follow', icon: 'touch_app' },
            { title: $localize`Zoom`, value: 'zoom', icon: 'touch_app' },
        ];
    }

    static getTodoPrioOptions(): SelectFilterOption[] {
        return [
            { title: $localize`A1`, value: 'a1', icon: 'electric_bolt' },
            { title: $localize`A2`, value: 'a2', icon: 'electric_bolt' },
            { title: $localize`A3`, value: 'a3', icon: 'electric_bolt' },
            { title: $localize`B1`, value: 'b1', icon: 'electric_bolt' },
            { title: $localize`B2`, value: 'b2', icon: 'electric_bolt' },
            { title: $localize`B3`, value: 'b3', icon: 'electric_bolt' },
            { title: $localize`C1`, value: 'c1', icon: 'electric_bolt' },
            { title: $localize`C2`, value: 'c2', icon: 'electric_bolt' },
            { title: $localize`C3`, value: 'c3', icon: 'electric_bolt' },
        ];
    }
    static getResourceStateOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            {
                title: $localize`Not on Publish`,
                value: 'not_last_published',
                icon: 'unpublished',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
        ];
    }

    static getItemSelectedOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Filter by selected`, value: '1', icon: 'done_all' },
            { title: $localize`Filter by unselected`, value: '0', icon: 'remove_done' },
        ];
    }

    static getInstructorTitlesOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Instructor`, value: 'instructor', icon: 'engineering' },
            { title: $localize`Teacher`, value: 'teacher', icon: 'engineering' },
            { title: $localize`Tutor`, value: 'tutor', icon: 'engineering' },
        ];
    }

    static getMessageScopesOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Case`, value: 'case', icon: 'school' },
            { title: $localize`Chat`, value: 'dialog', icon: 'forum' },
            { title: $localize`Report`, value: 'report', icon: 'warning_amber' },
            { title: $localize`Important`, value: 'important', icon: 'comment_bank' },
            { title: $localize`Unread`, value: 'unread', icon: 'mark_unread_chat_alt' },
            { title: $localize`Validation`, value: 'validation', icon: 'mark_chat_read' },
        ];
    }

    static getMedia(url: string): Media {
        return (
            this.getAssetsImg(url) ||
            this.getYoutubeMedia(url) ||
            this.getVimeoMedia(url) ||
            this.getWebsiteMedia(url) ||
            this.getLinkMedia(url)
        );
    }

    static isVideoUrl(url: string): boolean {
        return this.isYoutubeURL(url) || this.isVimeoURL(url) || !!this.internalLink(url);
    }

    static internalLink(url: string) {
        return url.match(/.+?\.k3\.io/) || url.match(/.+?\.klickdata\.se/);
    }

    static isUrl(url: string) {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(url);
    }

    /**
     * Handle category parent/children titles.
     * @param categories Categories
     * @returns Resource category array.
     */
    static mapParentCategories<T extends ParentMapper<T>>(categories: T[]): T[] {
        const categoryMap = new Map<Number, T>();
        const orphans: T[] = [];
        categories.forEach((cat) => {
            if (cat.parent_id === null && !categoryMap.has(cat.id)) {
                cat.loadedChildren = []; // initialized children array
                categoryMap.set(cat.id, cat); // add parent to map, if not yet added.
            } else if (cat.parent_id != null && categoryMap.has(cat.parent_id)) {
                const parent = categoryMap.get(cat.parent_id);
                this.addUniqueItem(parent.loadedChildren, cat);
            } else if (cat.parent_id != null) {
                // add categories his parent not yet added o orphans.
                this.addUniqueItem(orphans, cat);
            }
        });

        for (let i = orphans.length; i--; ) {
            const cat = orphans[i];
            const parent = categoryMap.get(cat.parent_id);
            if (parent) {
                this.addUniqueItem(parent.loadedChildren, cat);
                orphans.splice(i, 1);
            }
        }

        // const results = Array.from(categoryMap.values()).concat(orphans);
        const results: T[] = [];
        categoryMap.forEach((cat) => {
            // results.push(cat); // Stop adding parent and just map children with parents
            cat.loadedChildren.forEach((child) => {
                child.title = `${cat.title}/${child.title}`;
                results.push(child);
            });
            cat.loadedChildren = null; // Remove children array.
        });
        return results.concat(orphans);
    }

    /**
     * Ensure not duplication on children array
     * @param items Array than contains unique items.
     * @param item Item to be add to array ignore duplication.
     */
    static addUniqueItem<T extends { id: number }>(items: T[], item: T) {
        const index = items.findIndex((child) => child.id === item.id);
        if (index !== -1) {
            items.splice(index, 1);
        }
        items.push(item);
    }

    /**
     * Check the equality of two objects.
     * @param a The first object.
     * @param b the second object.
     * @returns true when every key-value in equal same value in b
     */
    public static objEqual(a: {}, b: {}): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }

        if (Object.keys(a).length !== Object.keys(b).length) {
            return false;
        }
        return Object.entries(a).every((el) => el[1] === b[el[0]]);
    }

    /**
     * Check the equality of two objects.
     * @param a The first object.
     * @param b the second object.
     * @returns true when every key-value in equal same value in b
     */
    public static isEqual<T>(a: T, b: T, equals: (a: T, b: T) => boolean = (a, b) => a === b): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }

        if (Array.isArray(a) && Array.isArray(b)) {
            return Utils.arraysEqual(a, b);
        }

        if (typeof a === 'object' && typeof b === 'object') {
            return Utils.objEqual(a, b);
        }

        return equals(a, b);
    }

    /**
     * Check the equality of two arrays.
     * @param a The first T array.
     * @param b the second T array.
     * @param sort boolean consider array sorting.
     * @param equals condition default is aItem === bItem, but you can overide the equility condition by closure callback.
     *
     * Example: `(ai, bi) => ai.id === bi.id` => equals when id in a item equals id in b item.
     *
     * @returns true when two arrays are equals ignoring order, false otherwise.
     */
    public static arraysEqual<T>(
        a: T[],
        b: T[],
        sort = true,
        equals: (ai: T, bi: T) => boolean = (ai, bi) => ai === bi
    ): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        if (sort) {
            a.sort();
            b.sort();
        }
        for (let i = 0; i < a.length; ++i) {
            if (!equals(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }

    // loop over b, add the elements of a if it doesn't exist in a
    public static mergeUnique<T>(a: T[], b: T[], cond: (ai: T, bi: T) => boolean): T[] {
        return b.reduce(
            (acc, bItem) => {
                const index = a.findIndex((aItem) => cond(aItem, bItem));
                if (index === -1) {
                    acc.push(bItem);
                }
                return acc;
            },
            [...a]
        ); // initialize the new Array with the contents of array1
    }

    public static isEmpty(value: any) {
        if (value == null) {
            return true;
        }
        if (Array.isArray(value)) {
            return !value.length;
        }
        if (typeof value === 'object') {
            return !Object.keys(value).length;
        }

        if (typeof value === 'string') {
            return !value.trim().length;
        }

        if (typeof value === 'boolean') {
            return false;
        }
        return !value;
    }

    /**
     * Sync model many relation.
     * @param value model value
     * @returns
     */
    public static modelSync(value: ModelSync | ModelSync[]): any {
        if (Array.isArray(value)) {
            const res = value.filter(Utils.modelSyncItem);
            return !Utils.isEmpty(res) ? res : undefined;
        }
        return Utils.modelSyncItem(value);
    }

    private static modelSyncItem(value: ModelSync): any {
        return ObjectValidator.isValidKeys(value, ['id'], ['sync_all', 'attach_ids', 'detach_ids']) ? value : undefined;
    }

    public static resetModelSync(value: ModelSync, id: number): {} {
        const isDirty = ObjectValidator.isDirty(value, ['sync_all', 'attach_ids', 'detach_ids']);
        return {
            ...value,
            id: isDirty ? id : null,
            sync_all: null,
            attach_ids: [],
            detach_ids: [],
        };
    }

    public static nullableSync(value: { [key: string]: string }) {
        return !Utils.isEmpty(value) ? value : undefined;
    }

    /**
     *
     * @param a
     * @param b
     * @param sort
     * @param equals
     * @returns
     */
    public static filterChanged<T>(a: Filter<T>, b: Filter<T>): boolean {
        if (a === b) {
            return false;
        }
        if (a == null || b == null) {
            return false;
        }
        return a.property === b.property && Utils.arraysEqual(a.items, b.items);
    }

    public static getSubdomain(): string {
        const host = window.location.host;
        return host.match(/^(?:https?:\/\/)?(?:www\.)?([A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?)\./)?.[1] ?? null;
    }

    public static routeWithWindowLocationAssign(targetLocation: string) {
        const baseLocation = window.location.href.split('?')[0].replace(window.location.pathname, '');
        window.location.assign(`${baseLocation}${targetLocation}`);
    }

    public static getDomain(): string {
        const host = window.location.host.split(':')[0];
        const parts = host.split('.');
        // is there a subdomain?
        // while (parts.length > 2 || (parts.findIndex(p => p.indexOf('localhost') !== -1) !== -1 && parts.length > 1)) {
        if (parts.length > 2) {
            parts.shift();
        }
        return parts.join('.');
    }

    /**
     * Get all activated route snapshot param under lazy load modules
     * @see RouterModule.forChild(routes)
     */
    public static getSnapshotParams(route: ActivatedRoute): { [key: string]: number } {
        const params: { [key: string]: number } = {};
        do {
            const values = route.snapshot.params;
            Object.keys(values).forEach((key) => (params[key] = values[key]));
            route = route.parent;
        } while (route);
        return params;
    }

    /**
     * Get all activated route stream observable param under lazy load modules
     * @see RouterModule.forChild(routes)
     * @see Observable
     */
    public static getRouteParam(route: ActivatedRoute, routeKey: string): Observable<number> {
        const obs: Observable<Params>[] = [];
        do {
            obs.push(route.params);
            route = route.parent;
        } while (route);
        return merge(...obs).pipe(
            filter((param) => !!param[routeKey]),
            map((param) => param[routeKey])
        );
    }

    public static getSelectedMobileTabsOption(router: Router, activityOptions: any[]): any {
        return activityOptions.map((option) => option.value).includes(router.url.split('/').pop())
            ? activityOptions.find((option) => option.value === router.url.split('/').pop())
            : activityOptions[0];
    }

    /**
     * Capitalize first letter
     */
    public static capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Check if prompt is done
     */
    public static promptCompleted(p1: { prompt_status: PromptStatus }, p2: { prompt_status: PromptStatus }): boolean {
        return p1?.prompt_status === 'PROCESSING' && p2?.prompt_status === 'COMPLETED';
    }

    /**
     * Check if prompt is done
     */
    public static changedControls<T extends IDataModel>(formGroup: FormGroup): T {
        const formData = {};
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (!control.pristine) {
                formData[controlName] = control.value;
            }
        });
        return <T>formData;
    }

    /**
     * Check if prompt is done
     */
    public static getHeadline(body: string): string {
        const text = Utils.convertToPlain(body);
        const firstSentence = text.split(/[.،,\n:]/u, 2)[0] || text;
        const firstWords = Utils.words(text, 8);
        const titles = [firstSentence, firstWords].sort();
        return titles[0];
    }

    public static convertToPlain(html: string) {
        const tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }
    public static words(text: string, n: number): string {
        const words = text.split(' ');
        const firstNWords = words.slice(0, n).join(' ');
        return firstNWords;
    }
}
