import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItemData } from '@klickdata/core/resource-item';
import { ResourceEventType } from '@klickdata/core/resource/src/resource.model';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { Utils } from '@klickdata/core/util';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
export interface Currency {
    code: string;
    sign: string;
    label: string;
}
@Component({
    selector: 'app-course-manager-core-secodary-controls',
    templateUrl: './course-manager-core-secodary-controls.component.html',
    styleUrls: ['./course-manager-core-secodary-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CourseManagerCoreSecodaryControlsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() resource: Resource;
    @Input() resourceEventType: ResourceEventType[];
    public itemsHaveTests: boolean;
    public showPublisherMediaUploader: boolean;
    public mandatoryTestResourceItems: Observable<ResourceItemData[]>;
    @ViewChild('uploadInput') uploadInput: ElementRef;
    @Input() set courseResourceItems(courseResourceItems: Observable<ResourceItemData[]>) {
        this.mandatoryTestResourceItems = courseResourceItems.pipe(
            map((items) => items.filter((item) => item.mandatory && item.item_type_value === 'test')),
            tap((items) => {
                if (items.length !== 0) {
                    this.itemsHaveTests = true;
                    if (!this.resourceForm.get('examination')?.value.final_test_id) {
                        this.resourceForm
                            .get('examination')
                            .patchValue({ final_test_id: items[items.length - 1].child_resource_id });
                    }
                }
                this.cdRef.markForCheck();
            })
        );
    }

    public resourceForm: FormGroup;
    public defaultResourceSummaryCount: number;
    public defaultResourceOnlineEventId: number;
    public maxResourceInstructors: number;
    public defaultCourseGoalCount: number;
    public isArticleCodeValid: Observable<boolean>;
    public currencies: Currency[];
    ResourceStaffRoles = ResourceStaffRoles;
    public saving: boolean;
    public playTimeOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showMonths: false,
        showDays: false,
        showWeeks: false,
    };
    public durationOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showSeconds: false,
    };
    vaildArticleCode: Observable<number>;
    public instructorUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public customer$: Observable<Customer>;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected configService: ConfigService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected mobileService: MobileService,
        protected router: Router,
        protected auth: AuthService,
        protected s3MediaService: S3MediaService,
        protected mediaService: MediaService
    ) {
        this.currencies = Utils.getAllCurrencies();
        this.defaultResourceSummaryCount = this.configService.config.defaultResourceSummaryCharCount;
        this.defaultCourseGoalCount = this.configService.config.defaultResourceGoalCharCount;
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
        this.maxResourceInstructors = this.configService.config.maxResourceInstructors;
        this.resourceForm = parentFormDirective.form;
        this.buildForm(this.resourceForm);
    }

    ngOnInit(): void {
        this.vaildArticleCode = this.resourceForm.get('article_code').valueChanges.pipe(
            filter((query) => !!query && typeof query === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => {
                if (query) {
                    return this.resourceService.checkFieldValidaty({ article_code: query });
                }
            }),
            map((res) => (res ? 1 : -1)),
            share(),
            tap((isValidCode) =>
                this.resourceForm.get('article_code').setErrors(isValidCode === -1 ? { incorrect: true } : null)
            ),
            takeUntil(this.destroy)
        );
        this.resourceForm
            .get('unlimited_seats')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((unlimited) => {
                unlimited
                    ? this.resourceForm.get('total_seats').disable()
                    : this.resourceForm.get('total_seats').enable();
            });
        this.resourceForm
            .get('medias')
            ?.get('publisher')
            ?.valueChanges.pipe(
                filter((val) => !!val),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.showPublisherMediaUploader = false;
                this.cdRef.markForCheck();
            });
        this.customer$ = this.auth.getCustomer().pipe(first());
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource) {
            this.updateForm();
        }
    }
    public toggleLoading(ev: boolean) {
        ev ? this.instructorUploading.next(true) : this.instructorUploading.next(false);
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    private buildForm(resForm: FormGroup) {
        resForm.addControl('goal', new FormControl(''));
        resForm.addControl('time_limit', new FormControl(''));
        resForm.addControl('duration', new FormControl(''));
        resForm.addControl('other_info', new FormControl(''));
        resForm.addControl('price', new FormControl(''));
        resForm.addControl('promo_code', new FormControl(''));
        resForm.addControl('booking_needed', new FormControl(false));
        resForm.addControl('article_code', new FormControl(''));
        resForm.addControl('subtitles', new FormControl(''));
        resForm.addControl('unlimited_seats', new FormControl(true));
        resForm.addControl('show_publisher_logo', new FormControl(false));
        resForm.addControl('total_seats', new FormControl({ value: '', disabled: true }));
        resForm.addControl('audience', new FormControl(''));
        resForm.addControl('pre_skills', new FormControl(''));
        resForm.addControl('agenda', new FormControl(''));
        resForm.addControl('episode', new FormControl(''));
        resForm.addControl('extra_seats', new FormControl(''));
        resForm.addControl('copyrights', new FormControl(''));
        resForm.addControl('priority', new FormControl(''));
        resForm.addControl('currency', new FormControl('USD'));
        resForm.addControl('always_available', new FormControl(true));
        resForm.addControl('start_date', new FormControl(''));
        resForm.addControl('end_date', new FormControl(''));
        resForm.addControl(
            'medias',
            this.fb.group({
                background: [],
                screenshot: [],
                publisher: [],
            })
        );

        resForm.addControl(
            'examination',
            this.fb.group({
                type: ['Participation'],
                grade_system_id: [],
                final_test_id: [],
                diploma_level: [],
            })
        );
    }
    private updateForm() {
        const data = this.resource.getData();
        if (data.duration) {
            this.resourceForm.patchValue({ duration: moment.duration({ s: <number>data.duration }).toISOString() });
        }
        if (data.time_limit) {
            this.resourceForm.patchValue({ time_limit: moment.duration({ s: <number>data.time_limit }).toISOString() });
        }

        this.resourceForm.patchValue(data);
        FormHelper.resetForm(this.resourceForm);
    }
    onTypeChange() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'group_work',
                title: $localize`Can't change course type`,
                contentBody: $localize`If you want to create onsite, hybrid  or blended course. It'll be event. Event is the type that you can manage classroom, webinar, interview or conference in KLMS.`,
                positiveBtn: $localize`Create an event`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                if (action) {
                    this.router.navigate(['/admin/content/events/create']);
                }
            });
    }
    public fileHandler(event: any, mediaControlName: string) {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        this.s3MediaService
            .uploadMediaToS3(file, this.progress$)
            .pipe(
                takeUntil(this.destroy),
                switchMap((media) =>
                    this.mediaService.uploadMediaByLink({
                        ...media.getData(),
                        scope_id: AppScope.RESOURCES,
                    })
                )
            )
            .subscribe(
                (media) => {
                    this.saving = false;
                    if (media && media.id) {
                        this.resourceForm.get('medias').get(mediaControlName).patchValue([media.id]);
                        FormHelper.markForm(this.resourceForm);
                        this.progress$.next(null);
                        this.cdRef.markForCheck();
                    }
                },
                (err) => {} // TODO on error
            );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
