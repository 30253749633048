<div fxLayout="column" class="main-wrapper-core">
    <span i18n class="title-label">Test questions</span>
    <div class="w-100">
        <app-resource-builder
            fxFlex="0 0 100%"
            #builderForm
            [resource]="resource"
            [language_id]="resourceForm?.get('language_id').value"
            [questionTypes]="questionTypes"
            [resource_type_id]="ResourceTypes.GeneralTest"
            [resourceType]="ResourceTypes.TEST"
            (saved)="$event && savingQuestions && submitResourceRegardlessQs()"
            [active]="bottomToolSheet"
            [hideAddItems]="false"
            class="res-builder-column-layout w-100 m-10-0"
            [ngClass]="{ 'rb-is-empty': isEmpty(resourceBuilder?.getService().getForm() | async) }"
        >
        </app-resource-builder>
    </div>
</div>

<ng-template #contentTemplateToBeInParent>
    <!--
            Grade system KL,EB agreed about:
            1. Test should has it's own default grade.
            2. When test included into course (as final test) = test default grade suggested for course creator to use it or choose another.
        -->
    <app-admin-grade-system-control
        class="res-creator"
        fxFlex="0 1 100%"
        [resource]="resource"
        [languageId]="resourceForm?.get('language_id').value"
        [gradeControl]="resourceForm?.get('grade_system_id') | cast"
    >
    </app-admin-grade-system-control>
</ng-template>
