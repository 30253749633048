import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AppScope,
    Resource,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceTag,
    ResourceTypeService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { Location } from '@angular/common';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { SignupFormComponent } from '@klickdata/guest/src/signup/signup-form/signup-form.component';
import { takeUntil, filter, shareReplay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { User } from '@klickdata/core/user';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AuthService } from '@klickdata/core/auth';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { MessageService } from '@klickdata/core/message';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { ResourceListingSheetComponent } from '../../resource-listing-sheet/resource-listing-sheet.component';
import { ResourcesAssignSheetComponent } from '../../resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceEditDialogComponent } from '../../resource-edit-dialog/resource-edit-dialog.component';
import { ResourceInviteSheetComponent } from '../../resource-assignment/resource-invite-sheet/resource-invite-sheet.component';
import { ShareService } from '../../share/share.service';
import { Utils } from '@klickdata/core/util';
import { LanguageService } from '@klickdata/core/localization';
import { Customer } from '@klickdata/core/customer';
import * as moment from 'moment';
import { Quote, QuoteService } from '@klickdata/core/quote';

@Component({
    selector: 'app-resource-details-info',
    templateUrl: './resource-details-info.component.html',
    styleUrls: ['./resource-details-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsInfoComponent implements OnInit, OnDestroy {
    @ViewChild('specsExpansionPanel') expansionPanel: MatExpansionPanel;
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() resourceItems: ResourceItem[];
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @Input() resourceLabel: string;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private platform: string;
    AppScope = AppScope;
    public isMobile: boolean;
    public scrolledItemWidth = 355;
    public showQuotesItems: boolean;
    public showOriginalPrice: boolean;
    public moment = moment();
    public qrCodeData: string;
    public quotes: Quote[];
    customOptions: OwlOptions = {
        lazyLoadEager: 2,
        loop: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        autoWidth: true,
        navText: ['<img src="assets/images/arrow.svg" width="20">', '<img src="assets/images/arrow.svg" width="20">'],
        nav: true,
    };

    constructor(
        protected router: Router,
        protected location: Location,
        protected mobile: MobileService,
        protected route: ActivatedRoute,
        protected resourceService: ResourceService,
        protected resourceItemService: ResourceItemService,
        protected resourceTypeService: ResourceTypeService,
        protected userStatisticsService: UserStatisticsService,
        protected cd: ChangeDetectorRef,
        protected auth: AuthService,
        protected fb: FormBuilder,
        protected tagService: TagService,
        protected categoriesService: ResourceCategoryService,
        protected bottomSheet: MatBottomSheet,
        public shareService: ShareService,
        protected dialog: MatDialog,
        protected downloadHelper: DownloadHelperService,
        protected itemService: ResourceItemOpportunityService,
        protected searchService: GloalSearchService,
        protected message: MessageService,
        protected elementRef: ElementRef,
        private localizationService: LanguageService,
        protected quoteService: QuoteService
    ) {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
    }

    ngOnInit(): void {
        this.getQrCode();
    }

    getQrCode() {
        combineLatest([
            this.localizationService.getLanguageByKey(this.resource.language_id),
            this.customer ? of(this.customer) : this.auth.getCustomer(),
        ])
            .pipe(takeUntil(this.destroy))
            .subscribe(
                ([lang, customer]) =>
                    (this.qrCodeData = customer.subdomains[0]
                        ? `${customer.subdomains[0]}.${Utils.getDomain()}/${
                              lang.value
                          }/home/dashboard/resource-details/${this.resource.id}`
                        : `${Utils.getDomain()}/${lang.value}/home/dashboard/resource-details/${this.resource.id}`)
            );
    }
    public takeResource(resource: Resource, user: User, item?: ResourceItem) {
        if (ResourceTypes.parentType(resource.type_id) === ResourceTypes.EVENT) {
            this.registerEvent(resource, user);
            return;
        }
        if (this.platform === 'guest') {
            this.dialog
                .open(SignupFormComponent, {
                    panelClass: 'signUp-wrapper',
                    backdropClass: 'signUp-backdrop',
                    id: 'signupDialog',
                    closeOnNavigation: true,
                })
                .afterClosed()
                .pipe(
                    takeUntil(this.destroy),
                    filter((result) => !!result)
                )
                .subscribe(() => this.startResource(resource, item));
        } else {
            this.startResource(resource, item);
        }
    }
    public assign(resource: Resource) {
        this.bottomSheet.open(ResourcesAssignSheetComponent, {
            data: {
                width: '70%',
                id: resource.id,
                context: $localize`Assign`,
                contextIcon: 'assignment_ind',
                title: resource.title,
                referenceIdKey: 'resource_id',
                referenceFilter: 'assignedResource',
            },
            panelClass: 'sheet-wrapper',
        });
    }
    public download(resource: Resource) {
        this.downloadHelper.download(resource.downloads);
    }
    protected startResource(resource: Resource, item?: ResourceItem) {
        this.router.navigate(['/player/resource', resource.id, ResourceTypes.playerRoute(resource.type_id)], {
            queryParams: {
                queryParams: item ? { position: item.play_position } : {},
                referrer: this.location.path(),
            },
        });
    }
    registerEvent(res: Resource, user: User) {
        if (res.price) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.EVENT_REGISTER,
                data:
                    user.role_value === 'guest'
                        ? {
                              resource: res,
                          }
                        : {
                              resource: res,
                              user: user,
                          },
            });
        } else {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.COURSE_REGISTER,
                data:
                    user.role_value === 'guest'
                        ? {
                              resource: res,
                          }
                        : {
                              resource: res,
                              user: user,
                          },
            });
        }
    }
    public editResource(resource: Resource, isAdmin: boolean) {
        if (resource.type_id === ResourceTypes.General_E_Course) {
            const dialogRef = this.dialog.open(ResourceEditDialogComponent, {
                width: !this.isMobile ? '80%' : '100%',
                data: { ...resource, scope_id: AppScope.E_COURSE },
                disableClose: true,
                panelClass: 'sheet-wrapper',
            });
            dialogRef.afterClosed().subscribe((result) => {
                // TODO after update.
            });
        } else if (resource.type_id === ResourceTypes.GeneralCoursePlan) {
            isAdmin
                ? this.router.navigate(['/admin/content/courses/', resource.id])
                : this.router.navigate(['/home/manage/course-plan/', resource.id]);
        } else {
            isAdmin
                ? this.router.navigate([`/admin/content/${ResourceTypes.playerRoute(resource.type_id)}s/`, resource.id])
                : this.router.navigate([`/home/manage/${ResourceTypes.playerRoute(resource.type_id)}/`, resource.id]);
        }
    }
    public invite(resource: Resource) {
        this.bottomSheet.open(ResourceInviteSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Invite`,
                contextIcon: 'outgoing_mail',
                title: resource.title,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    public startCoursePopUp(resource: Resource, user: User, item?: ResourceItem) {
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                panelClass: 'confirm-wrapper',
                data: {
                    icon: 'play_circle',
                    title: $localize`Do you want to enroll in the ` + ResourceTypes.label(resource.type_id) + ' ?',
                    positiveBtn: $localize`:@@start:Start`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe(() => this.takeResource(resource, user, item));
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '60%',
            data: id,
        });
    }
    getSliderWidth() {
        this.scrolledItemWidth =
            this.elementRef.nativeElement.getElementsByClassName('quotes-slider-wrapper')[0]?.offsetWidth / 2;
        this.cd.markForCheck();
    }
    public likeResource(resource: Resource) {
        resource.like = !resource.like;
        this.resourceService
            .update(
                {
                    id: resource.id,
                    like: resource.like,
                },
                true
            )
            .subscribe();
    }

    public favoriteResource(resource: Resource) {
        resource.favorite = !resource.favorite;
        this.resourceService
            .update(
                {
                    id: resource.id,
                    favorite: resource.favorite,
                },
                true
            )
            .subscribe();
    }

    public showTagResources(tag: ResourceTag) {
        this.showResources({ id: tag.id, label: tag.name, scope_id: AppScope.TAGS });
    }
    public showCatgeoryResources(category: ResourceCategory) {
        this.showResources({ id: category.id, label: category.title, scope_id: AppScope.CATEGORIES });
    }
    private showResources(item: GlobalSearchData) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    showManagers(managers: Educator[]) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.RESOURCE_MANAGERS,
            data: {
                title: this.resource.title,
                managers: managers,
            },
        });
    }
    showQuotesInSlide() {
        if (this.quotes?.length) {
            this.initQuotesSlideIn();
        } else {
            this.getQuotes().subscribe((quotes) => {
                this.quotes = quotes;
                this.initQuotesSlideIn();
            });
        }
    }
    private initQuotesSlideIn() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ALL_QUOTES,
            data: this.quotes,
        });
    }
    toggleQuotes(show: boolean) {
        if (this.quotes?.length) {
            this.toggleCarouselQuotes(show);
        } else {
            this.getQuotes().subscribe((quotes) => {
                this.quotes = quotes;
                this.toggleCarouselQuotes(show);
            });
        }
    }
    private toggleCarouselQuotes(show: boolean) {
        this.showQuotesItems = show;
        this.cd.markForCheck();
    }
    private getQuotes(): Observable<Quote[]> {
        return this.quoteService.getQuotes(this.resource.quote_ids).pipe(takeUntil(this.destroy), shareReplay());
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
