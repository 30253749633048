<mat-form-field class="chip-list" fxFlex="0 1 100%" class="mb-4">
    <mat-chip-list class="cats-chips" #chipList aria-label="Select Categories">
        <mat-chip
            *ngFor="let category of selection"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(category)"
            class="cat-chip-item"
        >
            {{ category.title }}
            <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
        </mat-chip>
        <input
            #categoryInput
            placeholder="Search and select category"
            i18n-placeholder
            [formControl]="categoryCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete
        #auto="matAutocomplete"
        (closed)="optionClosed()"
        (optionActivated)="optionActivated($event)"
        (optionSelected)="onSelected($event)"
    >
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
            {{ category.title }}
        </mat-option>
    </mat-autocomplete>
    <button
        type="button"
        class="suffix-form-field-btn"
        mat-icon-button
        matSuffix
        (click)="$event.stopPropagation(); showCategories()"
    >
        <mat-icon>visibility</mat-icon>
    </button>
    <mat-error i18n>Categories are required!</mat-error>
</mat-form-field>
