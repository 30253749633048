import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Post } from '@klickdata/core/post/src/post/post.model';

@Component({
    selector:    'app-post-page-content',
    templateUrl: './post-page-content.component.html',
    styleUrls:   [ './post-page-content.component.scss' ]
})
export class PostPageContentComponent implements OnDestroy {
    @Input()
    public aPost: Observable<Post>;

    constructor(
        protected router: Router,
        protected dialogRef: MatDialogRef<PostPageContentComponent>
    ) {
    }

    public ngOnDestroy(): void {
        this.close();
    }

    public close(): void {
        this.dialogRef.close();
        this.router.navigate([ {outlets: {popup: null}} ]);
    }
}
