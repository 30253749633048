<div>
    <button
        type="button"
        (click)="createMaterial()"
        class="start-record"
        mat-icon-button
        matTooltip="Use AI to create Material"
        i18n-matTooltip
    >
        <mat-icon>smart_toy</mat-icon>
    </button>
</div>
