import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceTagData } from '@klickdata/core/resource';
import { Post } from '../../post.model';
import { User } from '@klickdata/core/user';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-post-item',
    templateUrl: './post-item.component.html',
    styleUrls: ['./post-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostItemComponent implements OnInit {
    @Input() post: Post;
    @Input() user: User;
    @Input() expandAll: boolean;
    @Input() expandedItem: boolean;
    @Input() public fullPostBody = false;
    @Output() onFilterByTag: EventEmitter<ResourceTagData> = new EventEmitter<ResourceTagData>();
    constructor(protected downloadHelper: DownloadHelperService, protected router: Router) {}

    ngOnInit(): void {}
    edit() {
        this.router.navigate(['/admin/content/posts', this.post.id]);
    }
    public download() {
        this.downloadHelper.download(this.post.downloads);
    }
}
