import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { AuthService, AwsService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import * as AWS from 'aws-sdk';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Media, MediaData } from './media.model';

interface S3UploadOptions {
    file: File;
    bucket: string;
    filePath: string;
    path: string;
    acl: string;
}

@Injectable()
export class S3MediaService {
    private _materials = 'materials';
    public get materials(): string {
        return this._materials;
    }

    constructor(
        protected auth: AuthService,
        protected awsService: AwsService,
        protected logger: LoggerService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected error: HttpErrorService,
        protected zone: NgZone,
        protected http: HttpClient
    ) {}

    public uploadMaterialToS3(customer_id: number, file: File, progress: BehaviorSubject<number>): Observable<Media> {
        const uuid = uuidv4();
        const id = `${this.materials}/${customer_id}/${uuid}`;
        const options = {
            file: file,
            bucket: this.config.config.AW_S3_MATERIAL_BUCKET,
            path: id,
            filePath: `${id}_${file.name}`,
            acl: 'public-read',
        };
        return this.uploadToS3(options, progress);
    }

    public uploadScormToS3(file: File, progress: BehaviorSubject<number>): Observable<Media> {
        const uuid = uuidv4();
        const options = {
            file: file,
            bucket: this.config.config.AW_S3_SCORM_ARCHIVE_BUCKET,
            path: uuid,
            filePath: `${this.config.config.buildMode}/${uuid}/${file.name}`,
            acl: null,
        };
        return this.uploadToS3(options, progress);
    }

    public uploadMediaToS3(file: File, progress: BehaviorSubject<number>): Observable<Media> {
        const path = `media/${uuidv4()}`;
        const options = {
            file: file,
            bucket: this.config.config.AW_S3_MEDIA_BUCKET,
            path: path,
            filePath: `${this.config.config.buildMode}/${path}`,
            acl: null,
        };
        return this.uploadToS3(options, progress);
    }

    private uploadToS3(options: S3UploadOptions, progress: BehaviorSubject<number>): Observable<Media> {
        const params = {
            Bucket: options.bucket,
            Key: options.filePath,
            Body: options.file,
            // ACL: options.acl,
            ContentType: options.file.type,
        };
        const uploadProgress = (event) => progress.next(Math.trunc((event.loaded / event.total) * 100));
        return this.configureS3().pipe(
            switchMap((s3) => from(s3.upload(params).on('httpUploadProgress', uploadProgress).promise())),
            catchError((err) => {
                this.resetToken();
                this.logger.error(`There was an error uploading your file: ${err.message}`);
                // other errors we don't know how to handle and throw them further.
                return throwError(err);
            }),
            map((data: AWS.S3.ManagedUpload.SendData) => {
                if (data) {
                    this.logger.info('Successfully uploaded file.');
                    const mediaData: MediaData = {};
                    mediaData.path = options.path;
                    mediaData.src = data.Key;
                    mediaData.filename = options.file.name;
                    mediaData.type = options.file.type;
                    return new Media(mediaData);
                }
            })
        );
    }
    public downloadScorm(key: string): Observable<AWS.S3.GetObjectOutput> {
        const params = {
            Bucket: this.config.config.AW_S3_SCORM_ARCHIVE_BUCKET,
            Key: `${this.config.config.buildMode}/${key}`,
        };
        return this.configureS3().pipe(
            switchMap((s3) => from(s3.getObject(params).promise())),
            catchError((err) => {
                this.resetToken();
                this.logger.error(`There was an error while get Object: ${err.message}`);
                // other errors we don't know how to handle and throw them further.
                return throwError(err);
            })
        );
    }

    public readMetadataFromS3(key: string): Observable<Media> {
        const params = {
            Bucket: this.config.config.AW_S3_MATERIAL_BUCKET,
            Key: key,
        };
        return this.configureS3().pipe(
            switchMap((s3) => from(s3.headObject(params).promise())),
            catchError((err) => {
                this.resetToken();
                this.logger.error(`There was an error while get file head: ${err.message}`);
                // other errors we don't know how to handle and throw them further.
                return throwError(err);
            }),
            map((data) => {
                if (data) {
                    this.logger.info(`Successfully get file head type: ${data.ContentType}`);
                    const media = new Media({
                        path: key,
                        src: `${this.config.config.AW_S3_MATERIAL_URL}/${key}`,
                        filename: this.getFileNameByKey(key),
                        type: data.ContentType,
                    });
                    return media;
                } else {
                    this.logger.info('Error data is null!');
                    return null;
                }
            })
        );
    }

    public getFileNameByKey(key: string): string {
        const parts = key.split('/');
        return parts[parts.length - 1].replace(/([12]\d{3}_(0[1-9]|1[0-2])_(0[1-9]|[12]\d|3[01]))_[0-9]\d{5}_/g, '');
    }

    /**
     * @TODO Move this into enviroment staging/production
     */
    private configureS3(): Observable<AWS.S3> {
        return this.awsService.getAWSCredentials().pipe(
            map(
                (creds) =>
                    new AWS.S3({
                        region: this.config.config.AW_S3_region,
                        credentials: creds,
                    })
            )
        );
    }

    private resetToken(): void {
        this.awsService.resetCredentials();
    }
}
