import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Resource, ResourceCategory, ResourceTag, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { ResourceDetailsInfoComponent } from '../resource-details-info/resource-details-info.component';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-resource-details-survey',
    templateUrl: './resource-details-survey.component.html',
    styleUrls: ['./resource-details-survey.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsSurveyComponent implements OnInit {
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() resourceItems: ResourceItem[];
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @ViewChild(ResourceDetailsInfoComponent) infoComp: ResourceDetailsInfoComponent;
    ResourceTypes = ResourceTypes;
    constructor() {}

    ngOnInit(): void {}
}
