<mat-card class="flex-item nk3-signup" *ngIf="customerLanding$ | async; let customerLanding">
    <mat-card-content fxLayout="column" fxFlexAlign="center center">
        <app-logotype
            [image]="customerLanding.logotype_url"
            [padding]="customerLanding.logotype_padding"
            [alt]="customerLanding.title"
        ></app-logotype>
        <h3 class="subdomain">
            <span i18n="@@welcomeTo">Welcome to</span>
            {{ customerLanding.title }}
        </h3>
        <p class="welcomeInfo" i18n>Enjoy and Explore a free account to KLMS to get many great free courses!</p>
        <!-- <app-social-login class="mt-1"></app-social-login> -->
        <div class="signUp-form-wrapper" fxLayout="row wrap" fxLayoutAlign="center center">
            <div *ngIf="error$ | async; let error">
                <app-form-error
                    *ngIf="409 !== error.error.status_code"
                    header="Register failed"
                    i18n-header
                    [messages]="error.error.messages"
                    i18n
                >
                </app-form-error>

                <app-form-error
                    *ngIf="409 === error.error.status_code"
                    header="Account already registered!"
                    i18n-header
                    [messages]="error.error.messages"
                >
                    <span i18n>Please use </span><a [routerLink]="['/guest/login']" i18n>Login</a>
                    <span i18n> to login to your account, or use </span>
                    <a [routerLink]="['/guest/password/forget']" i18n>Forgot your password</a>
                    <span i18n> to reset your account password.</span>
                </app-form-error>
            </div>
            <form (ngSubmit)="onSubmit()" #loginForm fxLayout="column wrap" fxLayoutAlign="center">
                <div fxFlex="1 1 100%" class="fields-wrapper" fxLayout="column" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="1 1 100%" appearance="fill">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input
                            matInput
                            type="email"
                            [(ngModel)]="register.email"
                            name="email"
                            (blur)="register.username = register.email"
                            required
                        />
                        <mat-error i18n="@@tryEmailError">Email is required</mat-error>
                    </mat-form-field>
                </div>
                <div
                    fxFlex="1 1 100%"
                    fxLayoutGap.gt-sm="10px"
                    class="fields-wrapper"
                    fxLayout="column"
                    fxLayoutAlign="space-between center"
                >
                    <mat-form-field fxFlex="1 1 100%" appearance="fill">
                        <mat-label i18n="@@choosePassword">Choose password</mat-label>
                        <input
                            #password
                            matInput
                            type="password"
                            [(ngModel)]="register.password"
                            name="password"
                            minlength="6"
                            required
                        />
                        <mat-hint fxHide.lt-md align="end" aria-live="polite">
                            {{ password.value.length }} / {{ passwordComponent.max }}
                        </mat-hint>
                    </mat-form-field>
                    <!--@angular-material-extensions/password-strength's main component-->
                    <mat-password-strength #passwordComponent [password]="password.value"> </mat-password-strength>
                    <!--Password's strength info-->
                    <mat-password-strength-info
                        [lowerCaseCriteriaMsg]="lowerCaseCritiriaMsg"
                        [upperCaseCriteriaMsg]="upperCaseCritiriaMsg"
                        [digitsCriteriaMsg]="digitsCriteriaMsg"
                        [specialCharsCriteriaMsg]="specialCharsCriteriaMsg"
                        [minCharsCriteriaMsg]="minCharsCriteriaMsg"
                        [passwordComponent]="passwordComponent"
                    >
                    </mat-password-strength-info>
                </div>

                <mat-accordion displayMode="flat">
                    <mat-expansion-panel #panel>
                        <mat-expansion-panel-header>
                            <div class="horizontalText">
                                <mat-icon *ngIf="panel.expanded">expand_more</mat-icon>
                                <mat-icon *ngIf="!panel.expanded">expand_less</mat-icon>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="extra-items-wrapper">
                            <div
                                fxFlex="1 1 100%"
                                class="fields-wrapper"
                                fxLayout="column"
                                fxLayoutAlign="space-between center"
                            >
                                <mat-form-field fxFlex="1 1 100%" appearance="fill">
                                    <mat-label i18n="@@firstName">First name</mat-label>
                                    <input matInput [(ngModel)]="register.fname" name="firstname" />
                                </mat-form-field>
                            </div>
                            <div
                                fxFlex="1 1 100%"
                                class="fields-wrapper"
                                fxLayout="column"
                                fxLayoutAlign="space-between center"
                            >
                                <mat-form-field fxFlex="1 1 100%" appearance="fill">
                                    <mat-label i18n="@@lastName">Last name</mat-label>
                                    <input matInput [(ngModel)]="register.lname" name="lastname" />
                                </mat-form-field>
                            </div>

                            <mat-form-field fxFlex="1 1 100%" appearance="fill">
                                <mat-label i18n="@@company/Organization/School"
                                    >Company / Organization / School</mat-label
                                >
                                <input matInput [(ngModel)]="register.organisation" name="organisation" />
                            </mat-form-field>
                            <mat-form-field fxFlex="1 1 100%" appearance="fill">
                                <mat-label i18n="@@phoneNumber">Phone number</mat-label>
                                <input matInput [(ngModel)]="register.phone" name="phone" />
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="inst-wrapper" fxFlex="1 1 100%" fxFlex.lt-md="1 1 90%">
                    <div fxLayout="column" class="red" fxLayoutAlign="space-between start">
                        <div class="blue right-block" fxLayout="column" fxLayoutAlign="center center">
                            <p i18n="@@byRegisterYouAcceptOur">By creating an account, you agree to our</p>
                            <p>
                                <a
                                    target="_blank"
                                    class="red"
                                    href="https://klickdata.se/kd-licens"
                                    i18n="@@termsAndConditions"
                                    >Terms of Use</a
                                >
                                <span i18n="@@and">and</span>
                                <a
                                    target="_blank"
                                    class="red pr-0"
                                    [href]="
                                        localeId === 'en'
                                            ? 'https://klickdata.se/gdpr-eng'
                                            : 'https://klickdata.se/gdpr'
                                    "
                                    i18n="@@privacyPolicy"
                                    >Personal Data Policy</a
                                >
                                .
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayoutAlign="center center" fxLayout="column" fxFlex="1 1 100%" class="signup-button mt-1">
                    <div class="reg-wrapper" fxFlex="1 1 90%" fxFlex.lt-md="1 1 100%">
                        <button mat-raised-button color="primary" type="submit" i18n="@@register">Register</button>
                    </div>
                    <div fxFlex="1 1 48%" fxFlex.lt-md="1 1 100%">
                        <p class="red already">
                            <strong fxLayoutAlign="center center" fxLayout="column">
                                <span i18n="alreadyRegistered?">Already registered?&nbsp;</span>
                                <span class="pointer" i18n (click)="handleLoginRequest()">Click here to login</span>
                            </strong>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
<app-footer class="login-footer" fxFlex="1 1 auto"></app-footer>
