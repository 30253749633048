import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Resource, ResourceService, AppScope } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-builder-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseComponent implements OnDestroy {
    @Input() selection: number[] = [];
    @Input()
    public set childGroup(childGroup: FormGroup) {
        this._childGroup = childGroup;

        if (childGroup.value.child_resource_id) {
            this.loading = true;
            this.resourceService
                .getResource(childGroup.value.child_resource_id)
                .pipe(takeUntil(this.destroy))
                .subscribe(resource => {
                    this.selected = resource;
                    this.loading = false;
                    this.changeRef.markForCheck();
                });
        }
    }

    public get childGroup(): FormGroup {
        return this._childGroup;
    }
    protected _childGroup: FormGroup;
    protected destroy: Subject<boolean> = new Subject<boolean>();
    @Input()
    public item: ResourceItem;
    public selected: Resource;
    public selectControl: FormControl;

    public courses: Observable<Resource[]>;
    public filteredCourses: Observable<Resource[]>;

    public loading: boolean;
    public updatedName = false;
    public updatedDesc = false;
    AppScope = AppScope;

    constructor(
        protected resourceService: ResourceService,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef
    ) {}

    selectResource(resource: Resource) {
        this.selected = resource;
        const patch = {
            child_resource_id: resource.id,
            child_resource_published: resource.published,
            child_resource_language_id: resource.language_id,
        };
        // Inheritance of title and description when select test, If not defined by user.
        if (!this.childGroup.value.name) {
            patch['name'] = resource.title;
            this.updatedName = true;
        }
        if (!this.childGroup.value.description) {
            patch['description'] = resource.description;
            this.updatedDesc = true;
        }
        this.childGroup.patchValue(patch);
    }

    changeCourse() {
        // Empty the selected resource container
        this.selected = undefined;
        // Reset the form group related controls
        if (this.childGroup.get('name').untouched && this.updatedName) {
            this.childGroup.patchValue({
                name: null,
            });
        }
        if (this.childGroup.get('description').untouched && this.updatedDesc) {
            this.childGroup.patchValue({
                description: '',
            });
        }
        this.childGroup.patchValue({
            child_resource_id: null,
        });
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
