import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { MessageDeletedComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { CategoryChipSelectComponent } from '@klickdata/shared-components/src/chip-selection/category-chip-select/category-chip-select.component';
import { TagChipSelectComponent } from '@klickdata/shared-components/src/tag-chip-select/tag-chip-select.component';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceAvailabilitySheetComponent } from '../../resource-assignment/resource-availability-sheet/resource-availability-sheet.component';
import { ResourceDetailsSheetComponent } from '../../resource-assignment/resource-details-sheet/resource-details-sheet.component';
import { ResourceManagerEvent } from '../resource-manager-event';
import { ResourceStaffRoles } from './../../../../core/resource/src/types.enum';
import { ResourceBuilderComponent } from './../../resource-builder/resource-builder.component';
import { VTQ, VTQService } from '@klickdata/core/vtq';

@Component({
    selector: 'app-resource-creation-manager-common-controls',
    templateUrl: './resource-creation-manager-common-controls.component.html',
    styleUrls: ['./resource-creation-manager-common-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ResourceCreationManagerCommonControlsComponent implements OnInit, OnChanges {
    public resourceForm: FormGroup;
    @Input() resource: Resource;
    @Input() typeId: ResourceTypes;
    @Input() resourceBuilder: ResourceBuilderComponent;
    ResourceTypes = ResourceTypes;
    @ViewChild('publishCheckbox') publishCheckbox: MatCheckbox;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Input() public resKeysValidaty: { [key: string]: string | boolean }[];
    @ViewChild(CategoryChipSelectComponent) categoriesComp: CategoryChipSelectComponent;
    @ViewChild(TagChipSelectComponent) tagsComp: TagChipSelectComponent;
    @Input() public loading: boolean;
    // public loadingUserManager: boolean;
    public defaultResourceOnlineEventId: number;
    public onFormReset: Subject<boolean> = new Subject<boolean>();
    public accessLevel: 'all' | 'partial' | 'none' = 'none';
    @Input() user: User;
    ResourceStaffRoles = ResourceStaffRoles;
    public resSubmitDisabledStatus$: Observable<boolean>;
    public vTQ$: Observable<VTQ>;

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected bottomSheet: MatBottomSheet,
        protected message: MessageService,
        protected cdr: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected resourceService: ResourceService,
        protected location: Location,
        protected downloadHelper: DownloadHelperService,
        protected configService: ConfigService,
        protected router: Router,
        protected activityLogService: ActivityLogService,
        protected vTQService: VTQService
    ) {
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
    }

    ngOnInit(): void {
        this.resourceForm = this.parentFormDirective.form;
        this.resSubmitDisabledStatus$ = this.resourceService.getResSubmitDisabled();
        this.vTQ$ = this.vTQService.getActiveVTQ();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue) {
            this.handleAccessLevelIndicator(this.resource);
        }

        if (changes.resKeysValidaty) {
            this.resKeysValidaty = changes.resKeysValidaty.currentValue;
            this.cdr.markForCheck();
        }
    }

    public handleAccessLevelIndicator(res: Resource) {
        this.accessLevel = res.users_access_open
            ? 'all'
            : res.users_attached || res.groups_attached
            ? 'partial'
            : 'none';
        this.cdr.markForCheck();
    }
    public onSubmit() {
        this.parentFormDirective.ngSubmit.emit(ResourceManagerEvent.SUBMIT);
    }
    resetForm() {
        FormHelper.resetForm(this.resourceForm);
        this.cdr.markForCheck();
    }

    public specify() {
        this.bottomSheet
            .open(ResourceAvailabilitySheetComponent, {
                data: {
                    context: 'publish',
                    contextTitle: $localize`:@@specifyPublish:Specify Publish`,
                    contextIcon: 'publish_outline',
                    resource: this.resource,
                    title: this.resource.title,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value: Resource) => {
                this.message.openMessage(MessageSavedComponent);
                this.resource.users_access_open = value.users_access_open;
                this.handleAccessLevelIndicator(value);
                this.resourceForm.markAsDirty();
            });
    }
    public view(type: ResourceTypes, resource: Resource) {
        this.router.navigate(['/home/dashboard/resource-details/' + resource.id]);
        return;

        if (
            (100 * this.resKeysValidaty.filter((key) => key.mandatory && key.hasValidValue).length) /
                this.resKeysValidaty.filter((key) => key.mandatory).length <
            100
        ) {
            this.mobileService.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: 'visibility',
                    title: $localize`View ${ResourceTypes.label(type, { capitalize: false })}`,
                    contentBody: $localize`You can't see how this ${ResourceTypes.label(type, {
                        capitalize: false,
                    })} looks for the learners until you fill the needed fields for publish`,
                    positiveBtn: $localize`Ok`,
                },
            });
            return;
        }

        const items = this.resourceBuilder?.form?.pipe(
            map((form: FormGroup) => form.get('items')),
            filter((items: FormArray) => items.valid),
            map((items: FormArray) => items.value)
        );

        const data = new Resource(this.resourceForm.value);
        data.type_id = type;
        this.bottomSheet.open(ResourceDetailsSheetComponent, {
            data: {
                resource: data,
                tags: this.tagsComp.selection,
                categories: this.categoriesComp.selection,
                items: items,
                resImg: this.resourceForm.get('media_id')?.value
                    ? this.resourceForm.get('media_id')?.value
                    : `assets/images/Default_${ResourceTypes.label(type, { plural: true, capitalize: false })}_img.png`,
            },
            panelClass: 'res-details-sheet-wrapper',
        });
    }
    createQuiz(vTQ: VTQ) {
        vTQ.user_id = this.user.id;
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.VIDEO_TO_QIUZ,
            data: {
                resource: this.resource,
                vTQ: vTQ,
            },
        });
    }
    public checkEfficiency(type: ResourceTypes) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.CHECK_EFFICIENCY,
            data: {
                icon: 'task_alt',
                title: $localize`Check before publish`,
                contentBody: $localize`Before you publish, make sure you review the quality of the ${ResourceTypes.label(
                    type,
                    { capitalize: false }
                )}. Some fields are mandatory and some is complimentary.
                You can improve the quality of your ${ResourceTypes.label(type, {
                    capitalize: false,
                })} by adding more information.`,
                positiveBtn: $localize`Ok`,
                mandatory: this.resKeysValidaty.filter((key) => key.mandatory),
                optional: this.resKeysValidaty.filter((key) => !key.mandatory),
            },
        });
    }
    public delete(resource: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete ${resource.title}`,
                contentBody: $localize`You are about to delete this ${ResourceTypes.label(this.typeId, {
                    capitalize: false,
                })}. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.resourceService.destroy(resource);
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    this.message.openMessage(MessageDeletedComponent);
                    FormHelper.resetForm(this.resourceForm);
                    this.location.back();
                }
            });
    }
    public addResNote(resource: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                privacy: null,
                resources: [resource],
                styleClass: 'playerTC',
                status: 'create',
            },
        });
    }
    public getLocalizedLabel(isSearchLabel: boolean) {
        return this.typeId === ResourceTypes.GeneralCoursePlan
            ? isSearchLabel
                ? $localize`Search authors`
                : $localize`Create a new author`
            : !isSearchLabel
            ? $localize`Create a new tutor`
            : $localize`Search tutors`;
    }
    public download(resource: Resource) {
        this.downloadHelper.download(resource.downloads);
    }

    public getMainEduTooltip() {
        return this.typeId === ResourceTypes.EVENT
            ? $localize`Main educator or organizer of the event.`
            : $localize`Main author`;
    }
    public list(resType: string) {
        this.router.navigate([`/admin/content/${resType}/list`]);
    }
    public viewResLog(res: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_PROGRESS,
            data: {
                resource: res,
                logs: this.activityLogService.getUserRelatedResourceActivities(
                    this.typeId === ResourceTypes.MATERIAL
                        ? { resource: res.id, eager: ['user_prompt'], sort: 'activity_id' }
                        : { resource: res.id }
                ),
            },
        });
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
