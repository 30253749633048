import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { EchoService } from '@klickdata/core/echo/echo.service';
import { Observable } from 'rxjs';

@Injectable()
export class HttpLocaleInterceptor implements HttpInterceptor {
    constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        protected auth: AuthService,
        protected echoService: EchoService
    ) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'Accept-Language': this.localeId,
                'Nk3-Platform': this.auth.getNK3PlatformValue(),
                'X-Socket-ID': this.echoService.socketId,
            },
        });

        return next.handle(req);
    }
}
