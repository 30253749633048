<div class="user-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label="@@status"
            [property]="{ name: GlobalFilterProperty.STATUS, type: GlobalFilterPropertyType.STATUS_USER }"
            [options]="userStatusOptions"
            userStatusRef
        >
        </app-select-filter>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>

    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
            <span i18n="@@numberOfUsers" class="label">Number of users:</span>
            <span class="total">{{ total }}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
            <span class="selectedNu" i18n="@@selected">Selected:</span>
            {{ selected }}
        </div>
    </div>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        (matSortChange)="onSortDataChages($event)"
        matSort
        [class.mobileView]="isMobile"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="masterToggle()"
                    [checked]="isChecked()"
                    [indeterminate]="isIndeterminate()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(user)"
                    [checked]="modelChecked(user)"
                    [disabled]="user.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@name" class="mobile-label"> Name </span>
                {{ user.name }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@username"> User name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@username" class="mobile-label"> User name </span>
                {{ user.username }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email"> Email </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@email" class="mobile-label"> Email </span>
                {{ user.email }}</mat-cell
            >
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let user; columns: columns"
            [class.disable-row]="user.disabled"
            (click)="!user.disabled && selectToggle(user)"
        ></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
