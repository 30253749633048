<ng-container *ngIf="languages$ | async; let languages; else: loading">
    <button
        *ngIf="currentLanguage | async; let lang; else: loading"
        mat-icon-button
        [matMenuTriggerFor]="locale"
        [matTooltip]="tooltip"
        matTooltipShowDelay="1000"
        [disableRipple]="disableRipple"
        type="button"
        class="flag-btn-lang"
        (click)="$event.stopPropagation()"
        [disabled]="disabled || languages.length <= 1"
    >
        <span *ngIf="lang.value" class="flag-icon flag-icon-{{ lang.flag }}"></span>
        <mat-icon *ngIf="!lang.value" color="primary">language</mat-icon>
        <span *ngIf="showLabel" class="label">{{ lang.label }}</span>
    </button>
    <mat-menu
        class="lang-btn-menu menu-trigger-lang"
        [class]="param ? 'section-menu-lang' : ''"
        #locale="matMenu"
        [overlapTrigger]="false"
    >
        <a mat-menu-item *ngIf="globe && languages.length > 1" (click)="changeLanguage(languages)">
            <mat-icon color="primary">language</mat-icon>
            <span i18n>All</span>
        </a>

        <a mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
            <span class="flag-icon flag-icon-list flag-icon-{{ lang.flag }}"></span>
            {{ lang.label }}
        </a>
    </mat-menu>
</ng-container>

<ng-template #loading>
    <div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
