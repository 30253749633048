<div fxLayout="row wrap" class="res-item qs-form-build">
    <mat-accordion
        class="resources-list"
        fxFlex="0 1 100%"
        fxFlex.lt-lg="100%"
        fxFlex.lt-md="100%"
        cdkDropList
        [multi]="true"
        #accordion
        (cdkDropListDropped)="drop($event)"
    >
        <mat-expansion-panel
            *ngFor="let item of itemFormArray.controls; let i = index"
            #panel
            class="item no-shadow"
            [class.disabled]="item.disabled"
            cdkDrag
            cdkDragLockAxis="y"
            [expanded]="expanded === i || allExpanded"
            (opened)="onOpen(i)"
            [disabled]="item.disabled"
        >
            <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'" class="question-header">
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div
                        fxFlex="0 1 auto"
                        class="question-title-index"
                        [ngClass]="{ 'wok-qus': item.get('question')?.value['isWOKQs'] }"
                    >
                        {{ i + 1 }}
                    </div>
                    <div fxFlex="0 1 auto" fxLayout="column" class="item-desc">
                        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                            <ng-container [ngSwitch]="displayCorrectIcon(item.get('item_type_value').value)">
                                <span *ngSwitchCase="'question'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon>live_help</mat-icon>
                                    <span i18n>Question</span>
                                </span>
                                <span *ngSwitchCase="'e-course'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
                                    <span i18n>E-course</span>
                                </span>
                                <span *ngSwitchCase="'course'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-task"></mat-icon>
                                    <span i18n>Course</span>
                                </span>
                                <span
                                    *ngSwitchCase="'test'"
                                    fxFlex="0 1 auto"
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                >
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                                    <span i18n>Test</span>
                                </span>
                                <span *ngSwitchCase="'survey'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
                                    <span i18n>Survey</span>
                                </span>
                                <span *ngSwitchCase="'doc'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Document material</span>
                                </span>
                                <span *ngSwitchCase="'url'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Link material</span>
                                </span>
                                <span *ngSwitchCase="'text'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Textmaterial</span>
                                </span>
                                <span *ngSwitchCase="'material'" fxFlex="0 1 auto" fxLayout="row">
                                    <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                    <span i18n>Material</span>
                                </span>
                            </ng-container>
                        </div>

                        <p *ngIf="!panel.expanded" fxFlex="0 1 auto">{{ item.get('name').value }}</p>
                    </div>
                </mat-panel-title>

                <mat-panel-description fxLayout="column" *ngIf="!item.valid && !item.disabled">
                    <ng-container
                        *ngIf="item.get('item_type_value').value === 'question' && item.touched && !panel.expanded"
                    >
                        <p class="color-warn" i18n="@@QuestionFormError">
                            Question contains errors, fill in the required* fields.
                        </p>
                        <p
                            *ngIf="item.get('question.alternatives').errors?.minLength"
                            class="color-warn"
                            i18n="@@alternativesShouldBeMoreThan1"
                        >
                            Alternatives should be more than 1.
                        </p>

                        <p *ngIf="item.get('question.alternatives').errors?.missCorrect" class="color-warn" i18n>
                            Correct answer is required!
                        </p>
                    </ng-container>
                </mat-panel-description>
                <mat-panel-description class="btns-desc" fxLayout="row" fxLayoutAlign="end center">
                    <button
                        mat-icon-button
                        *ngIf="editable(items[i])"
                        (click)="toggleDisable($event, item); panel.close()"
                    >
                        <mat-icon *ngIf="!item.disabled" fxFlex="0 1 auto" class="remove-item"> delete </mat-icon>
                        <mat-icon *ngIf="item.disabled" fxFlex="0 1 auto" class="remove-item"> undo </mat-icon>
                    </button>
                    <button
                        *ngIf="item.get('item_type_value').value === 'question'"
                        mat-icon-button
                        (click)="$event.stopPropagation(); !items[i].media?.url && addMedia(item, items[i])"
                    >
                        <mat-icon
                            *ngIf="items[i].media?.url || item.get('question').value.media_id; else add_q_image"
                            class="has-img"
                            [ngClass]="{ 'ai-uploaded': items[i].media?.type === 'gpt' }"
                            >image</mat-icon
                        >
                        <ng-template #add_q_image>
                            <mat-icon>add_photo_alternate</mat-icon>
                        </ng-template>
                    </button>
                    <!-- The ngIf directive for preventing appearance in type question -->
                    <app-language-selector
                        *ngIf="items[i].child_resource_language_id && item.get('item_type_value').value !== 'question'"
                        class="icon-resource-builder"
                        fxFlex="0 1 auto"
                        (click)="$event.stopPropagation()"
                        [formControl]="item.get('child_resource_language_id') | cast"
                    ></app-language-selector>
                    <button
                        *ngIf="!item.disabled && editable(items[i])"
                        mat-icon-button
                        (click)="duplicate($event, item)"
                    >
                        <mat-icon fxFlex="0 1 auto" class="duplicate-item"> content_copy </mat-icon>
                    </button>

                    <mat-icon cdkDragHandle fxFlex="0 1 auto" class="question-reorder"> reorder </mat-icon>
                    <mat-slide-toggle
                        #sliderToggle
                        [matTooltip]="getTooltipText(sliderToggle.checked)"
                        *ngIf="item.get('item_type_value').value !== 'question'"
                        class="ml-1 mandatory-toggle"
                        color="accent"
                        [checked]="item.get('mandatory')?.value"
                        [formControl]="item.get('mandatory') | cast"
                        (click)="$event.stopPropagation()"
                    >
                    </mat-slide-toggle>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-resource-item-form
                #res_item_ref
                *ngIf="panel.expanded"
                [itemGroup]="item | cast"
                [item]="items[i]"
                [index]="i"
                [usePoints]="usePoints"
            ></app-resource-item-form>
        </mat-expansion-panel>
    </mat-accordion>
</div>
