import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
} from '@angular/core';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-core-items',
    templateUrl: './course-manager-core-items.component.html',
    styleUrls: ['./course-manager-core-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseManagerCoreItemsComponent implements OnInit, OnDestroy {
    @Input() selection: number[] = [];
    AppScope = AppScope;
    public showAddMaterialBtn = true;
    public showAddTestBtn = true;
    public showAddSurveyBtn = true;
    public showAddEventBtn = true;
    public displayTab: ResourceBuildingItems;
    // @Output() add: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() add: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    public destroy = new Subject<boolean>();
    @ViewChild('compRef') compRef: any;

    constructor(protected mobileService: MobileService, protected cdr: ChangeDetectorRef) {}

    ngOnInit() {}
    public addResource(response: { res: Resource; isParentSubmission?: boolean }): boolean {
        if (response && response.res instanceof Resource) {
            this.add.emit(response);
            return true;
        }
        return false;
    }
    public addItem(scope: AppScope) {
        if (scope === AppScope.EVENT) {
            this.mobileService.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: 'event',
                    title: $localize`Add event`,
                    contentBody: $localize`You will be able to add an Event to a Course shortly. Currently you can go to Event and create the Event so you can add it here later.`,
                    positiveBtn: $localize`Ok`,
                },
            });
            return;
        }
        const buildingItems =
            scope === AppScope.MATERIAL
                ? [
                      ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL,
                      ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL,
                      ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL,
                      ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL,
                      ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL,
                      ResourceBuildingItems.MATERIAL_LIBRARY,
                  ]
                : scope === AppScope.TEST
                ? [ResourceBuildingItems.CREATE_NEW_TEST, ResourceBuildingItems.TEST_LIBRARY]
                : [ResourceBuildingItems.CREATE_NEW_SURVEY, ResourceBuildingItems.SURVEY_LIBRARY];

        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_QUESTIONS,
            data: {
                type: scope,
                buildingItems: buildingItems,
                icon: 'add_circle_outline',
                context: 'courseCreation',
                title:
                    scope === AppScope.MATERIAL
                        ? $localize`Add Material to Course`
                        : scope === AppScope.TEST
                        ? $localize`Add Test to Course`
                        : $localize`Add Survey to Course`,
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === scope),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                this.displayTab = data.value;
                if (scope === AppScope.MATERIAL) {
                    if (data.resource) {
                        this.addResource(data.resource);
                    }
                    this.showAddMaterialBtn = false;
                    this.showAddSurveyBtn = true;
                    this.showAddTestBtn = true;
                } else if (scope === AppScope.SURVEY) {
                    this.showAddMaterialBtn = true;
                    this.showAddSurveyBtn = false;
                    this.showAddTestBtn = true;
                } else {
                    this.showAddMaterialBtn = true;
                    this.showAddSurveyBtn = true;
                    this.showAddTestBtn = false;
                }
                this.cdr.markForCheck();
            });
    }
    submitItems() {
        this.compRef?.submit();
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
