import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application';
import { Nullable } from '@klickdata/core/application/src/model/model-interface';
import { Media } from '@klickdata/core/media/src/media.model';
import { ResourceCategory, ResourceTagData } from '@klickdata/core/resource';
import { Medias, Staff } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user';
import { PromptStatus, Utils } from '@klickdata/core/util';
import { Observable } from 'rxjs';

export enum QuoteTypes {
    AUDIO = 1,
    VIDEO = 2,
    TEXT = 3,
    IMAGE = 4,
}
export interface QuoteData extends IDataModel {
    id: number;
    quote?: string;
    quote_type?: string;
    source?: string;
    tags?: ResourceTagData[];
    tag_ids: number[];
    resource_ids?: number[];
    categories?: ResourceCategory[];
    created_at?: string;
    updated_at?: string;
    published?: string;
    published_at?: string;
    author?: number;
    public?: number;
    created_by?: number;
    customer_id?: number;
    language_id?: number;
    status?: QuoteStatus;
    prompt_status?: PromptStatus;
    staff?: Staff;
    medias?: Medias;
}

export class Quote extends Model<QuoteData> {
    id: number;
    quote: string;
    quote_type: string;
    source?: string;
    tags?: ResourceTagData[];
    tag_ids?: number[];
    categories?: ResourceCategory[];
    created_by: number;
    customer_id: number;
    public: boolean;
    quotee: { name: string; media: {} };
    public media$: Observable<Media>;
    @Nullable()
    resource_ids: number[];
    @Ignore()
    public created_at: moment.Moment;
    @Ignore()
    public published_at: moment.Moment;
    @Ignore()
    public updated_at: moment.Moment;
    @Ignore()
    public published: moment.Moment;
    author$: Observable<User>;
    language_id?: number;
    @Cast(CastType.CLOSURE, Utils.nullableSync)
    public medias?: Medias;
    @Cast(CastType.OBJECT)
    public staff?: Staff;

    @Ignore()
    prompt_status: PromptStatus;

    get status(): QuoteStatus {
        if (!this.published_at) {
            return { label: $localize`Draft`, color: '#a7a7a7', icon: 'public_off' };
        } else if (this.public) {
            return { label: $localize`Public`, color: '#3e5365', icon: 'public' };
        } else {
            return { label: $localize`:@@academy:Academy`, color: '#93cbd1', icon: 'published_with_changes' };
        }
    }
}
export interface QuoteStatus {
    icon: string;
    label: string;
    color: string;
}
