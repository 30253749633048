import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { MediaType, RecordingOutput } from '@klickdata/core/media/src/media-type';
import { MediaComponent } from '@klickdata/core/media/src/media.component';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceManagerEvent } from './resource-manager-event';

@Component({
    selector: 'app-resource-creation-manager',
    templateUrl: './resource-creation-manager.component.html',
    styleUrls: ['./resource-creation-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceCreationManagerComponent implements OnInit, OnDestroy, OnChanges, CanComponentDeactivate {
    public destroy: Subject<boolean> = new Subject<boolean>();
    public resIsPublished: Subject<boolean> = new Subject<boolean>();
    public loadingUploader: Subject<boolean> = new Subject<boolean>();
    public isAIUploadedImg: Subject<boolean> = new Subject<boolean>();
    public resKeysValidaty: { [key: string]: string | boolean }[];
    ResourceTypes = ResourceTypes;
    public defaultResourceSummaryCount: number;
    public defaultResourceGoalCount: number;
    @Input() public resourceForm: FormGroup;
    @ViewChild('pulishCheckbox') pulishCheckbox: MatCheckbox;
    @ViewChild('compRef') compRef: any;
    @ViewChild(MediaComponent) mediaCompRef: MediaComponent;
    @ViewChild('mainTabs') mainTabs: MatTabGroup;
    @ViewChild('coverImagePanel') coverImagePanel: MatExpansionPanel;
    @Input() resource: Resource;
    @Input() typeId: ResourceTypes;
    @Input() public fieldsLabels: { [key: string]: string }[];
    @Input() public recorderOutput: RecordingOutput;
    public loading: boolean;
    @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() user: User;
    ResourceManagerEvent = ResourceManagerEvent;
    public uploader: FileUploader;
    public defaultImgPath: string;
    public isMobile: boolean;
    public mediaAIPrompterText: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        protected resourceService: ResourceService,
        protected fb: FormBuilder,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected mobileService: MobileService,
        protected configService: ConfigService,
        protected auth: AuthService,
        protected mediaService: MediaService,
        protected dialog: MatDialog,
        protected elementRef: ElementRef,
        protected messageService: MessageService
    ) {
        this.defaultResourceSummaryCount = this.configService.config.defaultResourceSummaryCharCount;
        this.defaultResourceGoalCount = this.configService.config.defaultResourceGoalCharCount;
    }

    ngOnInit(): void {
        this.buildForm();
        if (this.resource) {
            this.updateForm(this.resource);
            this.resIsPublished.next(!!this.resource.last_publish);
        }
        this.resourceForm
            .get('publish')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((value) => {
                value ? this.resourceForm.get('public').enable() : this.resourceForm.get('public').disable();

                if (!value) {
                    this.resourceForm.get('public').setValue(false);
                }
            });
        this.mobileService.isMobile().subscribe((isMob) => (this.isMobile = isMob));
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource) {
            this.updateForm(this.resource);
            this.resIsPublished.next(!!this.resource.last_publish);
        }

        if (this.typeId) {
            this.defaultImgPath = `assets/images/Default_${ResourceTypes.label(this.typeId, {
                plural: true,
                capitalize: false,
            })}_img.png`;
        }
    }

    private buildForm() {
        this.resourceForm = this.fb.group({
            id: [],
            publish: [false],
            title: ['', Validators.required],
            public: [{ value: false, disabled: true }],
            language_id: ['', Validators.required],
            tag_ids: [],
            media_id: [],
            category_ids: [[], Validators.required],
            priority: [],
            description: [''],
            manager_ids: [[]],
            instructions: [''],
            bullets: [''],
            users_attach: [],
            users_detach: [],
            groups_attach: [],
            groups_detach: [],
            sync_all_users: [],
            sync_all_groups: [],
            users_access_open: [],
            staff: this.fb.group({
                educator: [],
                manager: [],
                publisher: [],
                host: [],
                organizer: [],
            }),
        });
        this.updateManager();
    }

    private updateForm(resource: Resource) {
        this.resourceForm.patchValue({
            id: resource.id,
            title: resource.title,
            description: resource.description,
            instructions: resource.instructions,
            bullets: resource.bullets,
            media_id: resource.media_id,
            tag_ids: resource.tag_ids,
            priority: resource.priority,
            staff: resource.staff,
            language_id: resource.language_id,
            category_ids: resource.category_ids,
            public: resource.public,
            publish: resource.last_publish,
            users_access_open: resource.users_access_open,
        });
        FormHelper.resetForm(this.resourceForm);
        this.cdr.markForCheck();
    }
    clearField(field: string) {
        this.resourceForm.get(field).setValue(null);
        this.resourceForm.get(field).markAsDirty();
    }

    public getFieldLabel(key: string, isTitleLabel?: boolean): string {
        if (this.fieldsLabels) {
            const item = this.fieldsLabels.find((item) => item.key === key);
            return isTitleLabel ? item?.title : item?.info;
        }
    }
    public showMobileToolTip(key: string) {
        if (this.fieldsLabels && this.isMobile) {
            const item = this.fieldsLabels.find((item) => item.key === key);
            this.messageService.openMessage(MessageSavedComponent, item.info);
        }
    }
    public addMedia(errorMsg?: string) {
        if (this.resourceForm.value.media_id) {
            this.mainTabs.selectedIndex = 1;
            this.coverImagePanel.open();
            return;
        }
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_MEDIA,
            data: {
                icon: 'add_photo_alternate',
                title: $localize`Add an image`,
                description: $localize`Please select your preferred way to add an image.`,
                type: this.typeId,
                errorMsg: errorMsg,
            },
        });
        this.getMediaTypeFromSlideIn();
    }
    private getMediaTypeFromSlideIn() {
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === this.typeId),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && data.value) {
                    this.resourceForm.patchValue({ media_id: data.mediaId });
                    if (data.value === ResourceMediaTypes.FILE) {
                        this.isAIUploadedImg.next(false);
                        this.mediaAIPrompterText.next(null);
                    } else {
                        this.loadingUploader.next(true);
                        this.initAIUpload(data);
                    }
                    this.resourceForm.markAsDirty();
                }
            });
    }
    private initAIUpload(data: any) {
        this.mediaService
            .uploadMediaByLink({
                prompt: data.aiText,
                type: MediaType.PROMPT,
                scope_id: ResourceTypes.scopeByChildType(this.typeId),
            })
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    this.handleAIRequestFailure(err);
                    return of(null);
                })
            )
            .subscribe((media) => {
                this.loadingUploader.next(false);
                if (media && media.id) {
                    this.isAIUploadedImg.next(true);
                    this.resourceForm.patchValue({ media_id: media.id });
                    this.mediaAIPrompterText.next(data.aiText);
                }
            });
    }
    handleAIRequestFailure(err) {
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
            this.addMedia(err.error.error.messages.join('/n'));
        }
    }

    public review() {
        if (this.resource && this.resource.id) {
            this.router.navigate(['/home/dashboard/resource-details/' + this.resource.id]);
        }
    }

    private updateManager() {
        this.resourceService
            .getEducatorByUserId()
            .pipe(takeUntil(this.destroy))
            .subscribe((edu) => {
                this.resourceForm
                    .get('staff')
                    .patchValue(
                        this.resourceForm.value.staff?.manager && this.resourceForm.value.staff.manager?.length
                            ? { manager: this.resourceForm.value.staff.manager[0] }
                            : { manager: [edu.id] }
                    );
                this.cdr.markForCheck();
            });
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    public canDeactivate(): boolean {
        return this.compRef.canDeactivate();
    }

    public saveAndDeactivate(): Observable<boolean> {
        return this.compRef.saveAndDeactivate();
    }

    @HostListener('window:beforeunload', ['$event'])
    handleClose($event) {
        setTimeout(() => {
            $event.returnValue = this.canDeactivate();
        });
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
