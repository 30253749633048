import { SelectFilterOption } from './../../../core/table/src/table-filter/filter';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators,
} from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceCreationManagerBase,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceEventType } from '@klickdata/core/resource/src/resource.model';
import { Utils } from '@klickdata/core/util';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, take, takeUntil, filter } from 'rxjs/operators';

@Component({
    selector: 'app-event-manager-core',
    templateUrl: './event-manager-core.component.html',
    styleUrls: ['./event-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class EventManagerCoreComponent extends ResourceCreationManagerBase implements OnInit, OnChanges {
    @Input() resource: Resource;
    @Input() active: boolean;
    typeId = ResourceTypes.EVENT;
    public startDate: Date | null;
    public endDate: Date | null;
    ResourceTypes = ResourceTypes;
    public selection: number[] = [];
    public resourceEventType$: Observable<ResourceEventType[]>;
    public notSetYet = new FormControl(false);
    public eventTypes: { value: ResourceTypes; label: string; eventTypeId: number }[];
    public defaultResourceOnlineEventId: number;
    Utils: Utils;
    AppScope = AppScope;
    public timezones: SelectFilterOption[];

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected configService: ConfigService,
        protected fb: FormBuilder
    ) {
        super(categoryService, auth, parentFormDirective, messageService);
        this.eventTypes = ResourceTypes.getEventTypes();
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
        this.timezones = Utils.getWorldTimeZones();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('agenda', new FormControl(''));
        this.resourceForm.addControl(
            'event_occasions',
            this.fb.array([
                this.fb.group({
                    start_date: [moment().hour(0).minute(0).second(0)],
                    end_date: [moment().hour(0).minute(0).second(0).add(1, 'h')],
                }),
            ])
        );
        // this.resourceForm.addControl('start_date', new FormControl(moment().hour(0).minute(0).second(0)));
        // this.resourceForm.addControl('end_date', new FormControl(moment().hour(23).minute(59).second(0).add(24, 'h')));
        this.resourceForm.addControl('time_zone', new FormControl('CET'));
        this.resourceForm.addControl('type_id', new FormControl(ResourceTypes.getEventTypes()[0].value));
        this.resourceForm.addControl('event_type_id', new FormControl(1));
        this.resourceForm.addControl('location', new FormControl(''));
        this.resourceForm.addControl('event_link', new FormControl(''));
        this.resourceForm.addControl('event_password', new FormControl(''));
        this.resourceForm.addControl('event_other_info', new FormControl(''));
        this.resourceForm.addControl('address', new FormControl(''));
        this.resourceForm.addControl('location_extra_info', new FormControl(''));
        this.resourceForm.addControl(
            'medias',
            this.fb.group({
                background: [],
                screenshot: [],
                publisher: [],
                trailer: [[]],
                brochure: [[]],
                exercise: [[]],
            })
        );

        this.resourceForm
            .get('publish')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((publish) => {
                this.resourceForm.get('agenda').setValidators(publish ? [Validators.required] : []);
                this.resourceForm.get('bullets').setValidators(publish ? [Validators.required] : []);
            });

        this.resourceForm
            .get('event_occasions')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((occasions) => {
                if (occasions.length === 0) {
                    this.notSetYet.setValue(true, { emitEvent: false });
                }
            });

        this.notSetYet.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
            this.resourceForm.markAsDirty();
            if (value) {
                this.resourceForm.get('event_occasions').patchValue([]);
            }
        });

        this.resourceForm
            .get('event_type_id')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((eventTypeId) => {
                if (eventTypeId === 2) {
                    this.resourceForm.get('type_id').patchValue(ResourceTypes.WEBINAR);
                }
            });
        this.resourceEventType$ = this.resourceService.getResourceEventType();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue && this.resourceForm) {
            this.resourceForm.patchValue({
                end_date: changes.resource.currentValue.end_date,
                medias: changes.resource.currentValue.medias,
                time_zone: changes.resource.currentValue.time_zone,
                type_id: changes.resource.currentValue.type_id,
                event_type_id: changes.resource.currentValue.event_type_id,
                location: changes.resource.currentValue.location,
                event_link: changes.resource.currentValue.event_link,
                event_password: changes.resource.currentValue.event_password,
                event_other_info: changes.resource.currentValue.event_other_info,
                address: changes.resource.currentValue.address,
                location_extra_info: changes.resource.currentValue.location_extra_info,
            });
            if (!changes.resource.currentValue.event_occasions) {
                this.notSetYet.patchValue(true);
            }
            this.updateEventOccasions(changes.resource.currentValue.event_occasions);
            FormHelper.resetForm(this.resourceForm);
        }
    }
    updateEventOccasions(occasions: { start_date: string; end_date: string }[]) {
        let occFGs: FormGroup[] = [];
        occFGs = occasions.map((date: { start_date: string; end_date: string }) =>
            this.fb.group({
                start_date: date.start_date,
                end_date: date.end_date,
            })
        );

        const occasionsFormArray = this.fb.array(occFGs);
        this.resourceForm.setControl('event_occasions', occasionsFormArray);
    }
    filteredEventTypes() {
        return this.eventTypes.filter((type) => type.eventTypeId === this.resourceForm.value.event_type_id);
    }
    public onSubmit() {
        this.loading.emit(true);
        this.checkSubmitValid();
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }
    addDate() {
        this.notSetYet.setValue(false, { emitEvent: false });
        this.eventOccasions.push(
            this.fb.group({
                start_date: [moment().hour(0).minute(0).second(0)],
                end_date: [moment().hour(0).minute(0).second(0).add(1, 'h')],
            })
        );
        this.resourceForm.get('event_occasions').markAsDirty();
    }
    removeDate(i: number) {
        const control = <FormArray>this.resourceForm.controls['event_occasions'];
        control.removeAt(i);
        this.resourceForm.get('event_occasions').markAsDirty();
    }
    get eventOccasions(): FormArray {
        return this.resourceForm.get('event_occasions') as FormArray;
    }
    checkSubmitValid() {
        if (this.resourceForm.invalid) {
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.resourceForm, this.formats)} is missing`
            );
            this.loading.emit(false);
            return of(false);
        }
        if (this.publish) {
            if (!this.resourceForm.value.event_occasions.length && !this.notSetYet.value) {
                this.messageService.openMessage(
                    MessageErrorComponent,
                    $localize`You need to set the date before you publish your event.`
                );
                this.loading.emit(false);
                return of(false);
            }
        }

        super.performResSubmit();
        return of(true);
    }

    public prepareSubmit(): Observable<Resource> {
        return this.prepareForm().pipe(
            takeUntil(this.destroy),
            switchMap((data) => (!data.id ? this.resourceService.store(data) : this.resourceService.update(data)))
        );
    }

    public prepareForm(): Observable<ResourceData> {
        return this.auth.getUser().pipe(
            first(),
            map((user) => {
                const extras = {
                    examination: this.resourceForm.get('examination').value,
                    duration: moment.duration(this.resourceForm.value.duration).asSeconds(),
                    time_limit: moment.duration(this.resourceForm.value.time_limit).asSeconds(),
                };

                const data = this.resource
                    ? this.resource.getData({ ...this.resourceForm.value, ...extras })
                    : new Resource({ ...this.resourceForm.value, ...extras }).getData();

                if (!data.id) {
                    data.customer_id = user.customer_id;
                    data.author_id = user.id;
                }

                if (this.publish) {
                    if ((data && data.published) || (this.resource && this.resource?.published)) {
                        data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        data.published = moment().format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                if (this.resourceForm.value.show_publisher_logo === -1) {
                    data.show_publisher_logo = null;
                }

                if (!this.publish && data) {
                    data.last_publish = null;
                }
                if (this.notSetYet.value) {
                    data.event_occasions = null;
                }
                return new Resource(data).getData();
            })
        );
    }

    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Event title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Event language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Event categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            // {
            //     key: 'items',
            //     title: $localize`Event structure`,
            //     hasValidValue: this.itemsData.length > 0,
            //     mandatory: true,
            // },
            {
                key: 'media_id',
                title: $localize`Event image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Event tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Event Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: true,
                info: $localize`The short summary of this event that will be displayed udner the title (max 256 characters).`,
            },
            // {
            //     key: 'manager_ids',
            //     title: $localize`Event tutor`,
            //     hasValidValue: this.resourceForm.value.manager_ids?.length,
            //     mandatory: true,
            //     info: $localize`The educator or organizer of the event.`,
            // },
            {
                key: 'agenda',
                title: $localize`Event agenda`,
                hasValidValue: this.resourceForm.value.agenda,
                mandatory: true,
                info: $localize`List the subjects covered in the event. The content in bullets for the lessons, modules, and classes. Suitable for onsite teaching, classroom, or webinar audience. In short, a list of what you will learn.`,
            },
            {
                key: 'availability',
                title: $localize`Event date`,
                hasValidValue:
                    this.resourceForm.value.always_available ||
                    this.resourceForm.value.start_date ||
                    this.resourceForm.value.end_date,
                mandatory: true,
                info: $localize`The time period where event will be available for the authorized learners.`,
            },
            // {
            //     key: 'practice_document',
            //     title: $localize`Exercise Documents`,
            //     hasValidValue: this.resourceForm.value.practice_document,
            //     mandatory: false,
            //     info: $localize`Upload exercise document that belongs to the event for practice. It can be a documents pre-event, during event or post the event.`,
            // },
            {
                key: 'description',
                title: $localize`Event description`,
                info: $localize`A description of the event that will be visible befor learners take the event.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Event instructions`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
            {
                key: 'audience',
                title: $localize`Event audience`,
                hasValidValue: this.resourceForm.value.audience,
                mandatory: false,
                info: $localize`Who is the target for the Event? The person or group who are the learners who will benefit from the knowledge covered in this event.`,
            },
            {
                key: 'goal',
                title: $localize`Event goal`,
                hasValidValue: this.resourceForm.value.goal,
                mandatory: false,
                info: $localize`The goal of this event.`,
            },
            {
                key: 'type_id',
                title: $localize`Event type`,
                hasValidValue: this.resourceForm.value.event_type_id,
                mandatory: false,
                info: $localize`Where the event take place. Online in KLMS or onsite, like in a classroom, conference, webinar, or blended learning (mixed). `,
            },

            {
                key: 'examination',
                title: $localize`End reward`,
                hasValidValue: this.resourceForm.get('examination')?.value.type,
                mandatory: false,
                info: $localize`Set the reward for this Event. When you have a Final Test in the Event; the learner will receive either a Participation Certificate, a Diploma or a Certification.`,
            },
            {
                key: 'time_limit',
                title: $localize`Play time`,
                hasValidValue: this.resourceForm.value.time_limit,
                mandatory: false,
                info: $localize`Event play time`,
            },
            {
                key: 'pre_skills',
                title: $localize`Pre skills`,
                hasValidValue: this.resourceForm.value.pre_skills,
                mandatory: false,
                info: $localize`The prerequisite knowledge that would be required from the participant prior to the course or the event.`,
            },
            {
                key: 'episode',
                title: $localize`Occasions`,
                hasValidValue: this.resourceForm.value.episode,
                mandatory: false,
                info: $localize`The event time on a calendar when onsite, webinar, classroom, or synchronous teaching.`,
            },
            {
                key: 'duration',
                title: $localize`Duration`,
                hasValidValue: this.resourceForm.value.duration,
                mandatory: false,
                info: $localize`Event duration`,
            },
            {
                key: 'other_info',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.other_info,
                mandatory: false,
                info: $localize`Here you can add other information needed for the users who will take this event`,
            },
            // {
            //     key: 'bg_img',
            //     title: $localize`Background image`,
            //     hasValidValue: this.resourceForm.get('screenshots')?.value.bg_img,
            //     mandatory: false,
            //     info: $localize`Background for the event in the event details page.`,
            // },
            // {
            //     key: 'screenshot',
            //     title: $localize`Screenshot image`,
            //     hasValidValue: this.resourceForm.get('screenshots')?.value.screenshot,
            //     mandatory: false,
            //     info: $localize`A thumbnail from the video (or a homepage) This image is a Screenshot from the creator to be uploaded.`,
            // },
            // {
            //     key: 'images',
            //     title: $localize`Instructor`,
            //     hasValidValue:
            //         (this.resourceForm.get('instructor')?.value.images &&
            //             this.resourceForm.get('instructor')?.value.images.length > 0) ||
            //         this.resourceForm.get('instructor')?.value.name,
            //     mandatory: false,
            //     info: $localize`The one who explain and teach in the Course. Can be the narrator in the video on screen or voice over. In the film industry: This will be equal to Actor `,
            // },
            // {
            //     key: 'educator_ids',
            //     title: $localize`Educators`,
            //     hasValidValue: this.resourceForm.value.educator_ids?.length,
            //     mandatory: false,
            //     info: $localize`Enter the educators of this event.`,
            // },

            {
                key: 'promo_code',
                title: $localize`Promo code`,
                hasValidValue: this.resourceForm.value.promo_code,
                mandatory: false,
                info: $localize`The event promo code`,
            },
            {
                key: 'article_code',
                title: $localize`SKU/ Article number`,
                hasValidValue: this.resourceForm.value.article_code && this.resourceForm.get('article_code').valid,
                mandatory: false,
                info: $localize`Event code is a unique field, autogenerated when empty.`,
            },
            {
                key: 'subtitles',
                title: $localize`Subtitle languages`,
                hasValidValue: this.resourceForm.value.subtitles,
                mandatory: false,
                info: $localize`If Subtitles are availible in the video for exampel ; Info Copied from TED or YouTube`,
            },
            {
                key: 'total_seats',
                title: $localize`Event seats`,
                hasValidValue: this.resourceForm.value.total_seats || this.resourceForm.value.unlimited_seats,
                mandatory: false,
                info: $localize`Total seats can be unlimited or specified`,
            },
            {
                key: 'extra_seats',
                title: $localize`Extra seats`,
                hasValidValue: this.resourceForm.value.extra_seats,
                mandatory: false,
                info: $localize`Extra seats activated when seats are full.`,
            },
            {
                key: 'copyrights',
                title: $localize`Copyrights`,
                hasValidValue: this.resourceForm.value.copyrights,
                mandatory: false,
                info: $localize`Set event copyrights`,
            },
            {
                key: 'priority',
                title: $localize`Priority`,
                hasValidValue: this.resourceForm.value.priority,
                mandatory: false,
                info: $localize`Set event priority`,
            },
        ];
    }
    public canDeactivate(): boolean {
        return super.canDeactivate();
    }
}
