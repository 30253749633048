import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { MobileService } from '@klickdata/core/mobile';
import { VgApiService } from '@videogular/ngx-videogular/core';
import * as Plyr from 'plyr';
import { Subscription } from 'rxjs';
import { Media } from '../media.model';

@Component({
    selector: 'app-media-player',
    templateUrl: './media-player.component.html',
    styleUrls: ['./media-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPlayerComponent implements OnInit, OnDestroy {
    @Input() media: Media;
    @Input() mediaCompleted: EventEmitter<boolean>;
    @Input() playerReady: EventEmitter<void>;
    @Input() played: EventEmitter<boolean>;
    private player: Plyr;
    private playerSub: Subscription;
    vgAPI: VgApiService;

    /**
     * return ended/completed status.
     */
    get ended(): boolean {
        if (this.player) {
            return this.player.ended;
        } else if (this.vgAPI) {
            return this.vgAPI.getDefaultMedia().isCompleted;
        }
        return true;
    }

    get currentTime(): number {
        const currentTime = this.player
            ? this.player.currentTime
            : this.vgAPI
            ? this.vgAPI.getDefaultMedia().currentTime
            : 0;
        return Math.round(currentTime);
    }

    get mediaType(): string {
        return this.media.provider === 'html5' ? this.media.type : this.media.provider;
    }

    constructor(
        public mobileService: MobileService,
        @Inject(LOCALE_ID) protected localeId: string,
        protected logger: LoggerService
    ) {}

    ngOnInit() {}

    public onInit(player: Plyr) {
        this.player = player;
    }

    public onReady() {
        this.playerReady.emit();
    }

    /**
     * @todo When player error sometime not fire with vimeo
     * https://github.com/sampotts/plyr/issues/508#issuecomment-332518723
     * @param $event
     */
    public onError($event) {
        this.logger.log($event);
    }

    public onPlayerReady(api: VgApiService) {
        this.vgAPI = api;
        this.playerSub = api.getDefaultMedia().subscriptions.play.subscribe(() => {
            // trigger played event.
            this.played.emit(true);
        });
    }

    play(currentTime: number) {
        if (this.player) {
            this.player.currentTime = currentTime;
            this.player.muted = false; // fix play after update currentTime cause plays without audio.
            this.player.play();
        } else if (this.vgAPI) {
            this.vgAPI.currentTime = currentTime;
            this.vgAPI.play();
        }
    }

    ngOnDestroy() {
        if (this.playerSub) {
            this.playerSub.unsubscribe();
            this.playerSub = null;
        }
    }
}
