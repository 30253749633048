import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { RecordingOutput } from '@klickdata/core/media/src/media-type';
import { Media } from '@klickdata/core/media/src/media.model';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceCreationManagerBase,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { Utils } from '@klickdata/core/util/src/utils';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import moment from 'moment';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-material-file-manager-core',
    templateUrl: './material-file-manager-core.component.html',
    styleUrls: ['./material-file-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialFileManagerCoreComponent extends ResourceCreationManagerBase implements OnInit, OnChanges {
    @Input() resource: Resource;
    @Input() resourceForm: FormGroup;
    @Output() onControlDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
    typeId = ResourceTypes.MATERIAL;
    public media: Media;
    Utils = Utils;
    AppScope = AppScope;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    @Output() onSavingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
    public showChapters: Subject<boolean> = new Subject<boolean>();
    @Input() recorderOutput: RecordingOutput;
    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    private resourceItem: ResourceItem;

    constructor(
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected itemService: ResourceItemService,
        protected mediaService: S3MediaService,
        protected error: HttpErrorService,
        protected vTQService: VTQService
    ) {
        super(categoryService, auth, parentFormDirective, messageService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('file', new FormControl(''));
        if (this.recorderOutput && this.recorderOutput.file) {
            this.resourceForm.patchValue({ title: this.resourceForm.value.title || this.recorderOutput.title });
            this.fileHandler(this.recorderOutput.file);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue && this.resourceForm) {
            const obs = [this.itemService.listenToResourceItem(this.resource)];
            if (this.resource.items$) {
                obs.push(this.resource.items$.pipe(map((items) => items[items.length - 1])));
            }
            merge(...obs)
                .pipe(takeUntil(this.destroy))
                .subscribe((items) => {
                    this.updateFile(Array.isArray(items) ? items[0] : items);
                });
        }
    }

    private updateFile(item: ResourceItem) {
        this.resourceItem = item;
        this.resourceForm.patchValue({
            file: this.resourceItem.name,
        });
        FormHelper.resetForm(this.resourceForm);
    }

    public cleanControl() {
        if (this.resourceForm.get('file')) {
            this.resourceForm.removeControl('file');
        }
    }
    public onSubmit(): void {
        this.loading.emit(true);
        this.checkSubmitValid();
    }
    checkSubmitValid(): Observable<boolean> {
        if (!this.resourceForm.valid) {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.loading.emit(false);
            return of(false);
        }
        if (this.publish && !this.resourceForm.value.file) {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`You can't publish the material before uploading a file`
            );
            this.loading.emit(false);
            return of(false);
        }
        super.performResSubmit();
        return of(true);
    }

    public prepareSubmit(): Observable<Resource> {
        return this.prepareData().pipe(
            switchMap((data: ResourceData) =>
                this.resource?.id ? this.resourceService.update(data, true) : this.resourceService.store(data)
            ),
            takeUntil(this.destroy)
        );
    }

    protected prepareData(): Observable<ResourceData> {
        const data = new Resource(this.resourceForm.value).getData();
        data.resource_items = this.prepareMaterialFileItem();
        if (this.publish) {
            if ((data && data.published) || (this.resource && this.resource?.published)) {
                data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
            } else {
                data.published = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        }
        if (!this.publish && data) {
            data.last_publish = null;
        }
        if (this.media && this.media.path) {
            data.article_code = this.media.path;
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.DocMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    protected prepareMaterialFileItem(): ResourceItemData[] {
        if (this.resourceForm.value.file !== '') {
            return this.resourceItem && this.resourceItem.id
                ? [
                      {
                          id: this.resourceItem.id,
                          name: this.resourceForm.value.file,
                      },
                  ]
                : [
                      {
                          name: this.resourceForm.value.file,
                          item_type_value: this.media ? this.media.type : 'doc_material', // TODO refactor item_type_value.
                      },
                  ];
        } else {
            if (this.resource && this.resourceItem?.id) {
                return [{ id: this.resourceItem.id, deleted: true }];
            }
        }
    }
    public prepareFile(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.fileHandler(fileList[0]);
        }
    }

    public fileHandler(file: File) {
        this.onSavingFile.emit(true);
        this.resourceService.setResSubmitDisabled(true);
        this.auth
            .getCustomer()
            .pipe(
                first(),
                takeUntil(this.destroy),
                switchMap((customer) => this.mediaService.uploadMaterialToS3(customer.id, file, this.progress$))
            )
            .subscribe(
                (mediaKey) => {
                    this.onSavingFile.emit(false);
                    this.resourceService.setResSubmitDisabled(false);
                    if (mediaKey) {
                        this.resourceForm.patchValue({
                            file: mediaKey.src,
                            title: this.resourceForm.value.title || mediaKey.filename,
                        });
                        this.onControlDirty.emit(true);
                        this.media = mediaKey;
                        FormHelper.markForm(this.resourceForm);
                        if (file && mediaKey.mediaType === 'video') {
                            this.vTQService.setActiveVTQ(
                                new VTQ({
                                    material_name: mediaKey.filename,
                                    material_type: mediaKey.type,
                                    language_id: this.resourceForm.value.language_id,
                                })
                            );
                        } else {
                            this.vTQService.setActiveVTQ(null);
                        }
                    }
                },
                (err) => {} // TODO on error
            );
    }
    clearVTQ() {
        this.vTQService.setActiveVTQ(null);
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Material title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Material language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Material categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Material file`,
                hasValidValue: this.resourceForm.get('file')?.value && this.resourceForm.get('file')?.enabled,
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Material image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Material tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Material Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this test that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Material description`,
                info: $localize`A description of the test that will be visible befor you take the test.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
        ];
    }
}
