import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { AbstractFormGroup, AfterParentAttached, FormGeneratorService, FormHelper } from '@klickdata/core/form';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { instructorFormValidator } from 'apps/klickdata/src/app/shared/validator/instructor.validator';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { finalize, first, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class SettingsForm extends AbstractFormGroup<Resource> implements AfterParentAttached, OnDestroy {
    protected destroy: Subject<boolean> = new Subject<boolean>();
    public onParentUpdated: Subject<Resource> = new Subject<Resource>();
    public instructorForm: FormGroup;
    // public publisherForm: FormGroup;
    public examinationForm: FormGroup;
    // public screenshotsForm: FormGroup;
    private user: User;

    constructor(
        protected auth: AuthService,
        protected generator: FormGeneratorService,
        protected resourceService: ResourceService,
        protected categoryService: ResourceCategoryService,
        protected fb: FormBuilder
    ) {
        super(Resource, generator);
        this.instructorForm = this.fb.group(
            {
                name: [],
                images: [[]],
            },
            { validator: instructorFormValidator.instNameExitIfImgUpload }
        );
        // this.screenshotsForm = this.fb.group({
        //     bg_img: [],
        //     screenshot: [],
        // });
        // this.publisherForm = this.fb.group({
        //     name: [],
        // });
        this.examinationForm = this.fb.group({
            type: ['Participation'],
            grade_system_id: [],
            final_test_id: [],
            diploma_level: [],
        });
    }

    /**
     * Initialize a base form for creating a new course plan.
     */
    public create(): Observable<Resource> {
        this.model.type_id = ResourceTypes.GeneralCoursePlan;
        this.model.always_available = true; // Make course open by default
        // this.model.verified = false; // Make course verified by default
        this.model.currency = 'USD'; // Make USD is default currency
        this.model.unlimited_seats = true; // make with unlimited seats by default.
        this.form.patchValue(this.model);
        // this.initDatePeriod();
        FormHelper.resetForm(this.form);

        return this.auth
            .getUser()
            .pipe(first())
            .pipe(
                map((user) => {
                    this.user = user;
                    this.model.customer_id = user.customer_id;
                    this.model.author_id = user.id;
                    this.model.author_name = user.name;
                    this.form.patchValue({ author_name: user.name });
                    return this.model;
                })
            );
    }

    public initDatePeriod(open: boolean) {
        // this.model.start_date = moment()
        //     .hour(0)
        //     .minute(0)
        //     .second(0);
        // this.model.end_date = moment()
        //     .hour(23)
        //     .minute(59)
        //     .second(0)
        //     .add(1, 'M');
        if (open) {
            this.form.patchValue({
                start_date: null,
                end_date: null,
            });
        } else {
            this.form.patchValue({
                start_date: this.model.start_date || moment().hour(0).minute(0).second(0),
                end_date: this.model.end_date || moment().hour(0).minute(0).second(0).add(1, 'M'),
            });
        }
    }

    public afterParentAttached() {
        this.parent.modelUpdate.pipe(takeUntil(this.destroy)).subscribe((coursePlan: Resource) => {
            this.onParentUpdated.next(coursePlan);
            this.import(coursePlan);
        });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public submit(publish?: boolean): Observable<Resource> {
        const data = this.export();
        if (publish) {
            data.published = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        if (data.always_available) {
            data.start_date = null;
            data.end_date = null;
        }

        if (this.model.id) {
            return this.resourceService.update(data).pipe(
                finalize(() => {
                    FormHelper.resetForm(this.form);
                })
            );
        } else {
            return this.resourceService.store(new Resource(data).getData()).pipe(
                finalize(() => {
                    FormHelper.resetForm(this.form);
                })
            );
        }
    }

    /**
     * Import/update the form with new values.
     */
    public import(resource: Resource): Resource {
        const data = resource.getData();
        const updates = this.model ? this.model.getData(data) : data;

        // If updates contains only id == no updates.
        if (Object.keys(updates).length > 1) {
            this.model = new Resource(this.model ? { ...this.model.getData(), ...updates } : data);

            if (this.model.end_date && this.model.end_date?.isBefore(moment())) {
                const endDate = this.form.get('end_date');
                if (endDate) {
                    endDate.enable();
                }
            }

            // if (!(this.model.start_date && this.model.end_date)) {
            //     this.initDatePeriod();
            //     updates.start_date = this.model.start_date.format('YYYY-MM-DD');
            //     updates.end_date = this.model.end_date.format('YYYY-MM-DD');
            // }

            if (!this.user.canEdit(resource)) {
                if (this.model.always_available) {
                    this.form.get('start_date').disable();
                    this.form.get('end_date').disable();
                }

                if (this.model.isPublished() && this.model.start_date?.isBefore(moment())) {
                    this.form.get('start_date')?.disable();
                    this.form.get('end_date')?.disable();
                    this.form.get('always_available')?.disable();
                }
            }

            // if (updates.screenshots) {
            //     this.screenshotsForm.patchValue({
            //         bg_img: updates.screenshots[0],
            //         screenshot: updates.screenshots[1],
            //     });
            // }
            if (updates.duration) {
                updates.duration = moment.duration({ s: <number>updates.duration }).toISOString();
            }
            if (updates.time_limit) {
                updates.time_limit = moment.duration({ s: <number>updates.time_limit }).toISOString();
            }
            if (updates.instructor) {
                this.instructorForm.patchValue(updates.instructor);
            }
            // if (updates.publisher) {
            //     this.publisherForm.patchValue(updates.publisher);
            // }
            if (updates.examination) {
                this.examinationForm.patchValue(updates.examination);
            }

            this.form.patchValue(updates);
            FormHelper.resetForm(this.form);
        }
        return this.model;
    }

    /**
     * Get course plan data as a resource.
     */
    public export(): ResourceData {
        const extras = {
            // screenshots:
            //     this.screenshotsForm.value.bg_img || this.screenshotsForm.value.screenshot
            //         ? [this.screenshotsForm.value.bg_img, this.screenshotsForm.value.screenshot]
            //         : null,
            // background_id: this.screenshotsForm.get('screenshots').value.bg_img,
            // screenshot_id: this.screenshotsForm.get('screenshots').value.screenshot,
            instructor: this.instructorForm.value,
            // publisher: this.publisherForm.value,
            examination: this.examinationForm.value,
            duration: moment.duration(this.form.value.duration).asSeconds(),
            time_limit: moment.duration(this.form.value.time_limit).asSeconds(),
        };
        return this.model.id
            ? this.model.getData({
                  ...this.form.value,
                  ...extras,
              })
            : new Resource({ ...this.model, ...this.form.value, ...extras }).getData();
    }

    get dirty(): boolean {
        return (
            this.form.dirty ||
            this.instructorForm.dirty ||
            // this.publisherForm.dirty ||
            this.examinationForm.dirty
            // this.screenshotsForm.dirty
        );
    }
}
