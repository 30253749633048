import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile/src/mobile.service';
import { DateRange } from '@klickdata/core/table/src/table-filter/date-filter/date-range';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';
@Component({
    selector: 'app-table-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateFilterComponent),
            multi: true,
        },
        { provide: FilterBase, useExisting: DateFilterComponent },
    ],
})
export class DateFilterComponent extends FilterBase implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() color: string;
    @Input() init: boolean;
    @Input() label = $localize`:@@enterADateRange:Enter a date range`;
    @Input() max = moment().utc();
    @Input() required: boolean;
    @Input() inGlobalFilter: boolean;
    @Output() filterChange: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    @Output() filterRemoval: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    @Output() rangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.DATE,
        items: [],
        composite: [
            { property: 'begin', items: [] },
            { property: 'end', items: [] },
        ],
        label: $localize`:@@date:Date`,
        icon: 'event',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-date-filter-selector',
    };
    public isMobile = false;
    protected destroy: Subject<boolean> = new Subject<boolean>();
    public rangeForm: FormGroup;
    public minDate: moment.Moment;
    public min: Observable<moment.Moment>;

    constructor(protected mobile: MobileService, protected fb: FormBuilder, protected authService: AuthService) {
        super();
    }

    public ngOnInit(): void {
        this.buildRangeForm();

        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));

        this.rangeForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter((value) => value && value.begin && value.end),
                filter((range) => this.vaildRange(range))
            )
            .subscribe((value) => {
                const begin = value.begin.format('YYYY-MM-DD');
                const end = value.end.format('YYYY-MM-DD');
                this.setFilterItems([begin, end]);
                this.filterChange.emit(this._filter);
                this.rangeChange.emit({ begin: value.begin, end: value.end });
                this.propagateChange({ begin: value.begin, end: value.end });
            });

        this.min = this.authService.getCustomer().pipe(
            first(),
            map((customer) => {
                this.minDate = customer.published;
                if (this.init) {
                    this.rangeForm.patchValue({ begin: this.minDate, end: this.max });
                }
                return this.minDate;
            })
        );
    }

    /**
     * @override
     * @param items
     */
    protected setFilterItems(items: string[]) {
        this._filter.items = items;
        this._filter.composite[0].items = [items[0]];
        this._filter.composite[1].items = [items[1]];
    }

    protected buildRangeForm(): void {
        this.rangeForm = this.fb.group({
            begin: [null, this.required ? Validators.required : null],
            end: [null, this.required ? Validators.required : null],
        });
    }

    private vaildRange(range: { begin: moment.Moment; end: moment.Moment }): boolean {
        return (
            range.begin.isSameOrBefore(range.end) &&
            range.begin.isBetween(this.minDate, this.max, 'day', '[]') &&
            range.end.isBetween(this.minDate, this.max, 'day', '[]')
        );
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public clear(): void {
        if (this.rangeForm) {
            this.rangeForm.reset();
            this.filterRemoval.emit(new Filter('begin', []));
            this.filterRemoval.emit(new Filter('end', []));
        }
    }

    public writeValue(value: DateRange) {
        if (value) {
            this.rangeForm.patchValue(value);
        }
    }

    public propagateChange = (_: any) => {};
    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    public stopPropagation(ev: MouseEvent): void {
        ev.stopPropagation();
    }

    public get filter(): Filter<string> {
        return this._filter;
    }
}
