import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { GenericTabsOption } from '@klickdata/shared-components/src/generic-mobile-tabs/generic-mobile-tabs.component';
import { Observable } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-core-add-material',
    templateUrl: './course-manager-core-add-material.component.html',
    styleUrls: ['./course-manager-core-add-material.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseManagerCoreAddMaterialComponent implements OnInit {
    @Input() selection: number[] = [];
    @Input() display: ResourceBuildingItems;
    ResourceBuildingItems = ResourceBuildingItems;
    @Output() select: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onCloseView: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('compRef') compRef: any;
    AppScope = AppScope;
    public isMobile: Observable<boolean>;
    public isAdmin: boolean;
    public showCreationOption: boolean;
    public activityOptions: GenericTabsOption[];
    constructor(protected auth: AuthService, protected mobile: MobileService, protected cdref: ChangeDetectorRef) {
        this.activityOptions = [
            {
                label: $localize`Add link`,
                url: '',
                value: 'addLink',
                index: 0,
            },
            {
                label: $localize`Upload file`,
                url: '',
                value: 'uploadFile',
                index: 1,
            },
            {
                label: $localize`My material`,
                url: '',
                value: 'myMaterial',
                index: 2,
            },
            {
                label: $localize`Library`,
                url: '',
                value: 'library',
                index: 3,
            },
            {
                label: $localize`Textmaterial`,
                url: '',
                value: 'textMaterial',
                index: 4,
            },
        ];
    }
    ngOnInit() {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.auth
            .getUser()
            .pipe(
                first(),
                map((user) => user.isAdmin())
            )
            .subscribe((isAdmin) => {
                this.isAdmin = isAdmin;
                this.activityOptions = isAdmin
                    ? this.activityOptions
                    : this.activityOptions.filter((opt) => opt.index === 2 || opt.index === 3);
                this.cdref.markForCheck();
            });
    }

    submit() {
        if (this.display !== ResourceBuildingItems.MATERIAL_LIBRARY) {
            this.compRef.submitMaterial();
        }
    }
}
