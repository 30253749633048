<div [formGroup]="itemGroup" fxLayout="row wrap" (keydown.enter)="$event.preventDefault()">
    <!-- displaying Qs image before the question -->
    <div
        xFlex="1 0 100%"
        class="imag-view-wrapper res-item-form"
        *ngIf="itemGroup.value.item_type_value === ItemTypes.QUESTION"
        fxLayout="column"
        fxLayoutAlign="center"
    >
        <app-image-view
            fxFlex="0 1 100%"
            [proportional]="false"
            *ngIf="itemGroup.get('question').value['media_id'] || itemGroup.get('question').value['img_url']"
            [media_id]="itemGroup.get('question').value['media_id'] || itemGroup.get('question').value['img_url']"
        >
            <button
                *ngIf="
                    editable &&
                    (itemGroup.get('question').value['media_id'] || itemGroup.get('question').value['img_url'])
                "
                class="remove-question-image"
                mat-icon-button
                color="warn"
                matTooltip="Remove image"
                i18n-matTooltip="Remove question image"
                (click)="removeImage()"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
    </div>
    <div fxFlex="1 1 100%" fxLayoutAlign="start start">
        <mat-form-field fxFlex="0 1 100%">
            <input
                *ngIf="itemGroup.value.item_type_value !== ItemTypes.QUESTION"
                #title
                matInput
                type="text"
                formControlName="name"
                required
                [placeholder]="namePlaceholder"
            />
            <ng-container *ngIf="itemGroup.value.item_type_value === ItemTypes.QUESTION">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="name"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="10"
                    required
                    [placeholder]="namePlaceholder"
                    [maxLength]="256"
                >
                </textarea>
                <span *ngIf="title.value" class="char-count">
                    {{ title.value.length }}
                </span>
            </ng-container>

            <mat-error i18n>This field is required!</mat-error>
        </mat-form-field>
        <div
            fxLayoutAlign="center center"
            fxHide="true"
            *ngIf="
                itemGroup.value.item_type_value === ItemTypes.QUESTION &&
                questionLoaded &&
                !itemGroup.get('question.media_id').value &&
                !itemGroup.get('question.img_url').value
            "
            fxFlex="0 0 40px"
            class="coursePlan-layout"
        >
            <app-media
                #app_media
                fxFlex="0 0 auto"
                [simple]="true"
                [formControl]="
                    (itemGroup.get('question.media_id') | cast) || (itemGroup.get('question.img_url') | cast)
                "
                [scope]="AppScope.QUESTIONS"
                dropLabel="Drop question image here"
                i18n-dropLabel="Drop for question images@@questionImageDnD"
                dropLabel="Drop question image"
                i18n-dropLabel="@@questionImageDropzoneLabel"
            ></app-media>
        </div>
        <div
            class="toggle-button-wrapper"
            fxFlex="0 0 auto"
            *ngIf="itemGroup.value.item_type_value === ItemTypes.QUESTION && questionLoaded"
            [ngClass]="{
                'has-explain':
                    itemGroup.get('question').value.description ||
                    itemGroup.get('question').value.learning_field ||
                    itemGroup.get('question').value.references
            }"
        >
            <button mat-button (click)="getQsExtraInfo(itemGroup.get('question'))">
                <div class="indicator"></div>
                <mat-icon>feed</mat-icon>
            </button>
        </div>
    </div>

    <div
        fxLayoutGap="5px"
        *ngIf="itemGroup.value.item_type_value === ItemTypes.QUESTION"
        fxFlex="grow"
        fxLayout="column"
    >
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.description">
            <div i18n>Description:</div>
            <span [innerHtml]="itemGroup.get('question').value.description"></span>
        </div>
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.learning_field">
            <div i18n>Learning Field:</div>
            <span [innerHtml]="itemGroup.get('question').value.learning_field"></span>
        </div>
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.references">
            <div i18n>References:</div>
            <span [innerHtml]="itemGroup.get('question').value.references"></span>
        </div>
    </div>
    <!-- Question extras info panels -->
    <!-- Deprecated as it'll bee shown in slider -->
    <mat-expansion-panel #questionExtrasPanel class="question-extras-wrapper" *ngIf="false" fxFlex="0 1 100%">
        <div
            [formGroup]="itemGroup.get('question') | cast"
            fxFlex="1 0 100%"
            fxLayout="column"
            fxLayoutAlign="center"
            class="res-list-items"
        >
            <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                <mat-expansion-panel #questionDescPanel>
                    <mat-expansion-panel-header>
                        <div *ngIf="!questionDescPanel.expanded" i18n="@@Description">Description</div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <app-text-editor
                            fxFlex="1 1 95%"
                            title="Description"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="description"
                        ></app-text-editor>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                <mat-expansion-panel #questionLearningPanel>
                    <mat-expansion-panel-header>
                        <div *ngIf="!questionLearningPanel.expanded" i18n="@@learningField">Learning Field</div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <app-text-editor
                            fxFlex="1 1 95%"
                            title="Learning Field"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="learning_field"
                        ></app-text-editor>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                <mat-expansion-panel #questionRefPanel>
                    <mat-expansion-panel-header>
                        <div *ngIf="!questionRefPanel.expanded" i18n="@@references">References</div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <app-text-editor
                            fxFlex="1 1 95%"
                            title="References"
                            i18n-title
                            [showTemplates]="false"
                            formControlName="references"
                        ></app-text-editor>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-expansion-panel>
    <!-- End of question extra panels -->

    <ng-container [ngSwitch]="itemGroup.value.item_type_value">
        <app-resource-builder-question
            *ngSwitchCase="ItemTypes.QUESTION"
            fxFlex="1 1 100%"
            [group]="itemGroup.get('question') | cast"
            [item]="item"
            [editable]="editable"
            [usePoints]="usePoints"
        ></app-resource-builder-question>

        <app-resource-builder-course
            *ngSwitchCase="ItemTypes.COURSE"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-course>

        <app-resource-builder-test
            *ngSwitchCase="ItemTypes.TEST"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-test>

        <app-resource-builder-survey
            *ngSwitchCase="ItemTypes.SURVEY"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-survey>

        <app-resource-builder-material
            *ngSwitchCase="ItemTypes.MATERIAL"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-material>
    </ng-container>
    <mat-accordion fxFlex="0 1 100%" displayMode="flat" *ngIf="itemGroup.value.item_type_value !== ItemTypes.QUESTION">
        <mat-expansion-panel #expansionPanel>
            <mat-expansion-panel-header>
                <div *ngIf="!expansionPanel.expanded">
                    <span i18n="@@contentDescription" *ngIf="itemGroup.value.item_type_value === ItemTypes.MATERIAL">
                        Content description
                    </span>
                    <span i18n="@@description" *ngIf="itemGroup.value.item_type_value !== ItemTypes.MATERIAL">
                        Description
                    </span>
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-text-editor
                    fxFlex="1 1 100%"
                    [title]="
                        itemGroup.value.item_type_value !== ItemTypes.MATERIAL ? 'Description' : 'Content description'
                    "
                    i18n-title
                    [showTemplates]="false"
                    formControlName="description"
                ></app-text-editor>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion fxFlex="0 1 100%" displayMode="flat" *ngIf="itemGroup.value.item_type_value === ItemTypes.MATERIAL">
        <mat-expansion-panel #expansionPanelDesc>
            <mat-expansion-panel-header>
                <div *ngIf="!expansionPanelDesc.expanded" i18n="@@contentInformation">Content information</div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-text-editor
                    fxFlex="1 1 100%"
                    title="Content information"
                    i18n-title
                    [showTemplates]="false"
                    formControlName="instructions"
                ></app-text-editor>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
