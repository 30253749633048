<div [ngClass]="{ 'has-mat-item': materialAddedItem }" class="creta-mat-column" fxLayout="column">
    <div *ngIf="!resource || !resource?.id" fxLayout="column">
        <span *ngIf="!materialAddedItem" i18n class="extra">Material Type</span>
        <div class="material-accordion" fxLayout="column">
            <div class="material-wrap" *ngIf="materialAddedItem" fxLayout="column">
                <button
                    matTooltip="Change"
                    mat-icon-button
                    (click)="onCancelMaterial()"
                    i18n-matTooltip
                    class="cancel-btn"
                >
                    <mat-icon>cancel</mat-icon>
                </button>
                <ng-container [ngSwitch]="materialAddedItem">
                    <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL">
                        <ng-container [ngTemplateOutlet]="urlMaterial"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL">
                        <ng-container [ngTemplateOutlet]="docMaterial"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <ng-container [ngTemplateOutlet]="textMaterial"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div *ngIf="!materialAddedItem && !resource?.id" fxFlex="grow" fxLayoutAlign="start center" class="mt-1">
                <button mat-button type="button" class="add-mat" (click)="addMaterial()">
                    <mat-icon>add_circle</mat-icon>
                    <span i18n>Add material type</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="resource" fxLayout="column">
        <ng-container [ngSwitch]="resource.type_id">
            <ng-container *ngSwitchCase="ResourceTypes.URLMaterial">
                <ng-container [ngTemplateOutlet]="urlMaterial"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.TextMaterial">
                <ng-container [ngTemplateOutlet]="textMaterial"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="ResourceTypes.DocMaterial">
                <ng-container [ngTemplateOutlet]="docMaterial"></ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-template #docMaterial>
    <app-material-file-manager-core
        #compRef
        [resource]="resource"
        [resourceForm]="resourceForm"
        [recorderOutput]="recorderOutput"
        (onControlDirty)="materialIsDirty = $event"
        (onResKeysValidatyChanges)="onResKeysValidatyChanges.emit($event)"
        (saved)="saved.emit($event)"
        (loading)="loading.emit($event)"
    ></app-material-file-manager-core>
</ng-template>
<ng-template #urlMaterial>
    <app-material-link-manager-core
        #compRef
        [resource]="resource"
        [resourceForm]="resourceForm"
        (onControlDirty)="materialIsDirty = $event"
        (onResKeysValidatyChanges)="onResKeysValidatyChanges.emit($event)"
        (saved)="saved.emit($event)"
        (loading)="loading.emit($event)"
    ></app-material-link-manager-core>
</ng-template>
<ng-template #textMaterial>
    <app-material-text-manager-core
        #compRef
        [resource]="resource"
        [resourceForm]="resourceForm"
        [customer]="customer$ | async"
        [active]="active"
        [materialAddedItem]="materialAddedItem"
        (onControlDirty)="materialIsDirty = $event"
        (onResKeysValidatyChanges)="onResKeysValidatyChanges.emit($event)"
        (saved)="saved.emit($event)"
        (loading)="loading.emit($event)"
        [actionQueue]="actionQueue"
    ></app-material-text-manager-core>
</ng-template>
