import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '@klickdata/core/form';
import { Question, QuestionType, QuestionTypes } from '@klickdata/core/question';

@Component({
    selector: 'app-resource-builder-question-form',
    templateUrl: './question-form.component.html',
    styleUrls: ['./question-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionFormComponent implements OnInit, OnChanges {
    @Input()
    public question: Question;

    @Input()
    public editable: boolean;

    @Input()
    public group: FormGroup;

    @Input()
    public availableTypes: QuestionType[];

    @Input()
    public usePoints = true;

    public QuestionTypes = QuestionTypes;

    constructor(protected fb: FormBuilder) {}

    ngOnInit() {
        if (this.question && this.group) {
            this.updateQuestion(this.question);
        }
    }

    ngOnChanges() {
        if (this.question && this.group) {
            this.updateQuestion(this.question);
        }
    }

    protected updateQuestion(question: Question) {
        this.group.addControl('id', this.fb.control(this.question.id));
        this.group.addControl('item_id', this.fb.control(this.question.item_id));
        this.group.addControl('media_id', this.fb.control(question.media_id));
        this.group.addControl('img_url', this.fb.control(question.img_url));
        this.group.addControl(
            'question_type_value',
            this.fb.control(question.question_type_value, AppValidators.notDelete(Validators.required))
        );
        this.group.addControl(
            'alternatives',
            this.fb.array([], [AppValidators.minLength(2), AppValidators.hasCorrect(this.usePoints)])
        );
        this.group.addControl('other_alternative', this.fb.control(this.question.other_alternative));
        this.group.addControl(
            'randomize_alternatives',
            this.fb.control(this.question.randomize_alternatives ?? this.usePoints)
        );
        this.group.addControl('description', this.fb.control(this.question.description));
        this.group.addControl('learning_field', this.fb.control(this.question.learning_field));
        this.group.addControl('references', this.fb.control(this.question.references));
    }
}
