import { AppScope } from './type.scope.enum';
import { ResourceType } from './type/resource-type.model';

/**
 * App resources types
 * https://git.klickportalen.se/klickdata2016/nk3-datastore/blob/develop/docs/types.json
 */
export enum ResourceTypes {
    COURSE = 0,
    SURVEY = 1,
    TEST = 2,
    E_COURSE = 3,
    MATERIAL = 4,

    ANONYMOUS_COURSE = 6,
    GeneralCoursePlan = 7,
    TASK_COURSE = 8,

    GeneralSurvey = 9,
    STUDY_SURVEY = 10,

    DIPLOMA_TEST = 11,
    GeneralTest = 12,
    STUDY_TEST = 13,

    General_E_Course = 14,
    DocMaterial = 15,
    TextMaterial = 16,
    URLMaterial = 17,
    WebsiteMaterial = 18,

    PACKAGE = 19,
    PACKAGE_COURSE = 20,
    PACKAGE_E_COURSE = 21,

    SCORM = 22,
    SCORM_12 = 23,
    SCORM_2004 = 24,

    EVENT = 25,
    CLASSROOM = 26,
    WEBINAR = 27,
    CONFERENCE = 28,
    INTERVIEW = 29,
    PRIVATE_LESSON = 30,
    TELECONF = 31,
    ONEONONE = 32,
    OTHER = 33,
}
export enum ResourceTableActions {
    EDIT = 0,
    SHARE = 1,
    DELETE = 2,
    PUBLISH = 3,
    UNPUBLISH = 4,
    PUBLIC = 6,
    UNPUBLIC = 7,
    DUPLICATE = 8,
    ASSIGN = 9,
    SENDCOURSE = 10,
    ANALYTICS = 11,
    EMAIL = 12,
    VIEW = 13,
    TEST_RESULTS = 14,
    USER_EDIT = 15,
    ACTIVATION_EMAIL = 16,
    RECOMMEND = 17,
    COLLECT = 18,
    NOTES = 19,
    DOWNLOAD = 20,
}
export enum MsgTableActions {
    MARK_AS_READ = 0,
    SAVE = 1,
    MAKE_IMPORTANT = 2,
    DELETE = 3,
}

export enum SectionType {
    ACTIVITY_SECTION = 0,
    REGULAR_SECTION = 1,
    GLOBAL_SEARCH_SECTION = 2,
    ADMIN_SECTION = 3,
}
export enum ActivitySectionType {
    ALL_SECTIONS = 0,
    COMPLETED_SECTIONS = 1,
    ASSIGNED_SECTIONS = 2,
    ONGOING_SECTIONS = 3,
    RECOMMENDED_SECTIONS = 4,
}

export enum ResourceStaffRoles {
    PUBLISHER = 'publisher',
    EDUCATOR = 'educator',
    MANAGER = 'manager',
    HOST = 'host',
    ORGANIZER = 'organizer',
    STAFF = 'staff',
    QUOTEE = 'quotee',
}
export enum ResourceBuildingItems {
    TEST_LIBRARY = 0,
    SURVEY_LIBRARY = 1,
    MATERIAL_LIBRARY = 2,
    MY_TESTS = 3,
    MY_MATERIALS = 4,
    MY_SURVEYS = 5,
    CREATE_NEW_TEST = 6,
    CREATE_NEW_SURVEY = 7,
    CREATE_NEW_FILE_MATERIAL = 8,
    CREATE_NEW_LINK_MATERIAL = 9,
    CREATE_NEW_TEXT_MATERIAL = 10,
    CREATE_NEW_RECORD_MATERIAL = 11,
    CREATE_AI_PROMPTER_MATERIAL = 12,
}
export namespace ResourceTypes {
    export function scope(type: ResourceType): string {
        switch (type?.scope_id) {
            case AppScope.COURSE:
                return 'course-plan';
            case AppScope.SURVEY:
                return 'survey';
            case AppScope.TEST:
            case AppScope.QUESTIONS:
            case AppScope.ALTERNATIVES:
                return 'test';
            case AppScope.E_COURSE:
                return 'course';
            case AppScope.SCORM:
                return 'scorm';
            case AppScope.MATERIAL:
                return 'material';
            case AppScope.FOLDERS:
                return 'folder';
            case AppScope.EVENT:
                return 'event';
        }
    }

    export function playerRoute(type: ResourceTypes, plural?: boolean): string {
        switch (ResourceTypes.parentType(type)) {
            case ResourceTypes.PACKAGE:
            case ResourceTypes.COURSE:
                return plural ? 'courses' : 'course';
            case ResourceTypes.SURVEY:
                return plural ? 'surveys' : 'survey';
            case ResourceTypes.TEST:
                return plural ? 'tests' : 'test';
            case ResourceTypes.E_COURSE:
                return plural ? 'e-courses' : 'e-course';
            case ResourceTypes.MATERIAL:
                return plural ? 'materials' : 'material';
            case ResourceTypes.SCORM:
                return plural ? 'scorms' : 'scorm';
            case ResourceTypes.EVENT:
                return plural ? 'events' : 'event';
        }
    }

    export function getEventTypes(): { value: ResourceTypes; label: string; eventTypeId: number; icon?: string }[] {
        return [
            { value: ResourceTypes.CLASSROOM, label: $localize`Classroom`, eventTypeId: 1, icon: 'meeting_room' },
            { value: ResourceTypes.CONFERENCE, label: $localize`Conference`, eventTypeId: 1, icon: 'reduce_capacity' },
            { value: ResourceTypes.INTERVIEW, label: $localize`Interview`, eventTypeId: 1, icon: 'social_distance' },
            {
                value: ResourceTypes.PRIVATE_LESSON,
                label: $localize`Private lesson`,
                eventTypeId: 1,
                icon: 'private_connectivity',
            },
            { value: ResourceTypes.OTHER, label: $localize`Other`, eventTypeId: 1, icon: '' },
            {
                value: ResourceTypes.WEBINAR,
                label: $localize`Webinar/Video meeting`,
                eventTypeId: 2,
                icon: 'cast_for_education',
            },
            { value: ResourceTypes.TELECONF, label: $localize`Teleconference`, eventTypeId: 2, icon: 'contact_phone' },
            { value: ResourceTypes.ONEONONE, label: $localize`One-On-One`, eventTypeId: 2, icon: 'cast' },
        ];
    }

    export function getEventTypeByTypeId(typeId: ResourceTypes): { title: string; icon: string; color: string } {
        switch (typeId) {
            case ResourceTypes.CLASSROOM:
                return { title: $localize`Classroom`, icon: 'meeting_room', color: '#3e5365' };
            case ResourceTypes.WEBINAR:
                return { title: $localize`Webinar`, icon: 'cast_for_education', color: '#3e5365' };
            case ResourceTypes.CONFERENCE:
                return { title: $localize`Conference`, icon: 'reduce_capacity', color: '#3e5365' };
            case ResourceTypes.PRIVATE_LESSON:
                return { title: $localize`Private lesson`, icon: 'private_connectivity', color: '#3e5365' };
            case ResourceTypes.INTERVIEW:
                return { title: $localize`Interview`, icon: 'social_distance', color: '#3e5365' };
            case ResourceTypes.TELECONF:
                return { title: $localize`Teleconference`, icon: 'contact_phone', color: '#3e5365' };
            case ResourceTypes.ONEONONE:
                return { title: $localize`One-On-One`, icon: 'cast', color: '#3e5365' };
            default:
                return { title: $localize`Event`, icon: 'event', color: '#3e5365' };
        }
    }

    export function scopeById(scope_id: AppScope): string {
        switch (scope_id) {
            case AppScope.COURSE:
                return 'courses';
            case AppScope.SURVEY:
                return 'surveys';
            case AppScope.TEST:
                return 'tests';
            case AppScope.E_COURSE:
                return 'e-courses';
            case AppScope.MATERIAL:
                return 'materials';
            case AppScope.FOLDERS:
                return 'folders';
            case AppScope.PACKAGE:
                return 'packages';
            case AppScope.USERS:
                return 'user';
            case AppScope.SCORM:
                return 'scorm';
            case AppScope.EVENT:
                return 'event';
        }
    }

    export function scopeByType(type: ResourceTypes): AppScope {
        switch (type) {
            case ResourceTypes.COURSE:
                return AppScope.COURSE;
            case ResourceTypes.SURVEY:
                return AppScope.SURVEY;
            case ResourceTypes.TEST:
                return AppScope.TEST;
            case ResourceTypes.E_COURSE:
                return AppScope.E_COURSE;
            case ResourceTypes.MATERIAL:
                return AppScope.MATERIAL;
            case ResourceTypes.PACKAGE:
                return AppScope.PACKAGE;
            case ResourceTypes.SCORM:
                return AppScope.SCORM;
            case ResourceTypes.EVENT:
                return AppScope.EVENT;
        }
    }

    export function scopeByChildType(type: ResourceTypes): AppScope {
        return ResourceTypes.scopeByType(ResourceTypes.parentType(type));
    }

    export function parentType(type: ResourceTypes): ResourceTypes {
        switch (type) {
            case ResourceTypes.ANONYMOUS_COURSE:
            case ResourceTypes.GeneralCoursePlan:
            case ResourceTypes.TASK_COURSE:
                return ResourceTypes.COURSE;

            case ResourceTypes.GeneralSurvey:
            case ResourceTypes.STUDY_SURVEY:
                return ResourceTypes.SURVEY;

            case ResourceTypes.DIPLOMA_TEST:
            case ResourceTypes.GeneralTest:
            case ResourceTypes.STUDY_TEST:
                return ResourceTypes.TEST;

            case ResourceTypes.PACKAGE_COURSE:
            case ResourceTypes.PACKAGE_E_COURSE:
                return ResourceTypes.PACKAGE;

            case ResourceTypes.SCORM_12:
            case ResourceTypes.SCORM_2004:
                return ResourceTypes.SCORM;

            case ResourceTypes.INTERVIEW:
            case ResourceTypes.CLASSROOM:
            case ResourceTypes.CONFERENCE:
            case ResourceTypes.WEBINAR:
                return ResourceTypes.EVENT;

            case ResourceTypes.General_E_Course:
                return ResourceTypes.E_COURSE;
            case ResourceTypes.DocMaterial:
            case ResourceTypes.TextMaterial:
            case ResourceTypes.WebsiteMaterial:
            case ResourceTypes.URLMaterial:
                return ResourceTypes.MATERIAL;

            default:
                return type;
        }
    }

    export function fontIconType(type: ResourceTypes): string {
        switch (ResourceTypes.parentType(type)) {
            case ResourceTypes.COURSE:
                return 'kd-icon-task';
            case ResourceTypes.TEST:
                return 'kd-icon-test';
            case ResourceTypes.E_COURSE:
                return 'kd-icon-player';
            case ResourceTypes.SURVEY:
                return 'kd-icon-survey';
            default:
                return 'kd-icon-document';
        }
    }

    export function materialType(type: ResourceTypes): string {
        switch (type) {
            case ResourceTypes.TextMaterial:
                return $localize`Text`;
            case ResourceTypes.DocMaterial:
                return $localize`Document`;
            case ResourceTypes.URLMaterial:
                return $localize`URL`;
            case ResourceTypes.WebsiteMaterial:
                return $localize`Website`;
            default:
                return $localize`Material`;
        }
    }

    export function materialTypeIcon(type: ResourceTypes): string {
        switch (type) {
            case ResourceTypes.TextMaterial:
                return 'post_add';
            case ResourceTypes.DocMaterial:
                return 'cloud_upload';
            case ResourceTypes.URLMaterial:
                return 'add_link';
            case ResourceTypes.WebsiteMaterial:
                return 'http';
            default:
                return 'source';
        }
    }

    export function eventType(type: ResourceTypes): string {
        switch (type) {
            case ResourceTypes.INTERVIEW:
                return $localize`Interview`;
            case ResourceTypes.CLASSROOM:
                return $localize`Classroom`;
            case ResourceTypes.CONFERENCE:
                return $localize`Conference`;
            case ResourceTypes.WEBINAR:
                return $localize`Webinar`;
            default:
                return $localize`Event`;
        }
    }

    export function getTypeFromRouteSegmentPath(segmentPath: string): ResourceTypes {
        switch (segmentPath) {
            case 'courses':
            case 'course-plan':
            case 'course':
                return ResourceTypes.GeneralCoursePlan;
            case 'tests':
            case 'test':
                return ResourceTypes.GeneralTest;
            case 'surveys':
            case 'survey':
                return ResourceTypes.GeneralSurvey;
            case 'material':
            case 'materials':
                return ResourceTypes.MATERIAL;
            case 'event':
            case 'events':
                return ResourceTypes.EVENT;
        }
    }

    export function label(type: ResourceTypes, options?: { plural?: boolean; capitalize?: boolean }): string {
        switch (ResourceTypes.parentType(type)) {
            case ResourceTypes.COURSE:
                return options?.plural && options?.capitalize
                    ? $localize`Courses`
                    : options?.plural && !options?.capitalize
                    ? $localize`courses`
                    : !options?.plural && options?.capitalize
                    ? $localize`Course`
                    : $localize`course`;
            case ResourceTypes.SURVEY:
                return options?.plural && options?.capitalize
                    ? $localize`Surveys`
                    : options?.plural
                    ? $localize`surveys`
                    : !options?.plural && options?.capitalize
                    ? $localize`Survey`
                    : $localize`survey`;
            case ResourceTypes.TEST:
                return options?.plural && options?.capitalize
                    ? $localize`Tests`
                    : options?.plural
                    ? $localize`tests`
                    : !options?.plural && options?.capitalize
                    ? $localize`Test`
                    : $localize`test`;
            case ResourceTypes.E_COURSE:
                return options?.plural && options?.capitalize
                    ? $localize`E-courses`
                    : options?.plural
                    ? $localize`e-courses`
                    : !options?.plural && options?.capitalize
                    ? $localize`E-course`
                    : $localize`e-course`;
            case ResourceTypes.MATERIAL:
                return options?.plural && options?.capitalize
                    ? $localize`Materials`
                    : options?.plural
                    ? $localize`materials`
                    : !options?.plural && options?.capitalize
                    ? $localize`Material`
                    : $localize`material`;
            case ResourceTypes.PACKAGE:
                return options?.plural && options?.capitalize
                    ? $localize`Packages`
                    : options?.plural && !options?.capitalize
                    ? $localize`packages`
                    : !options?.plural && options?.capitalize
                    ? $localize`Package`
                    : $localize`package`;
            case ResourceTypes.SCORM:
                return options?.plural && options?.capitalize
                    ? $localize`SCORMs`
                    : options?.plural && !options?.capitalize
                    ? $localize`scorms`
                    : !options?.plural && options?.capitalize
                    ? $localize`SCORM`
                    : $localize`scorm`;
            case ResourceTypes.EVENT:
                return options?.plural && options?.capitalize
                    ? $localize`Events`
                    : options?.plural && !options?.capitalize
                    ? $localize`events`
                    : !options?.plural && options?.capitalize
                    ? $localize`Event`
                    : $localize`event`;
        }
    }
}
