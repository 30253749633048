import { Injectable } from '@angular/core';
import { Channel } from 'laravel-echo/dist/channel';
import Echo from 'laravel-echo/dist/echo';
import Pusher from 'pusher-js';
import { EMPTY, Observable, interval } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { LoggerService } from '../application/src/logger/logger.service';
import { AuthService } from '../auth/src/token/auth.service';
import { ConfigService } from '../config/src/config.service';

@Injectable({
    providedIn: 'root',
})
export class EchoService {
    private echo: Echo;
    private userChannel: Observable<Channel>;

    constructor(protected configService: ConfigService, protected auth: AuthService, protected logger: LoggerService) {
        Window.bind('Pusher', Pusher);
        this.echo = this.getEchoInstance();
        this.auth.getToken().subscribe((token) => {
            this.setEchoAuthToken(token);
        });
        this.userChannel = this.auth.getUser().pipe(
            first(),
            map((user) => this.echo.private(`App.User.${user.id}`)),
            shareReplay()
        );
    }

    private getEchoInstance() {
        return new Echo({
            broadcaster: 'pusher',
            disableStats: true,

            wsHost: this.configService.config.WS_ECHO_SERVER,
            wsPort: this.configService.config.WS_ECHO_PORT,
            wssPort: this.configService.config.WSS_ECHO_PORT,

            key: this.configService.config.WS_ECHO_APP_KEY,
            cluster: this.configService.config.WS_ECHO_CLUSTER,
            authEndpoint: this.configService.config.WS_ECHO_AUTH,

            forceTLS: this.configService.config.WS_ECHO_TLS,
            encrypted: this.configService.config.WS_ECHO_TLS,
            enabledTransports: ['ws', 'wss'],
        });
    }

    /**
     * Set token to keep echo auth token updated.
     * @param token
     */
    private setEchoAuthToken(token: string) {
        // this.echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${token}`;
        this.echo.connector.options.auth.headers['Authorization'] = `Bearer ${token}`;
    }

    public listen<T>(channel: string, event: string): Observable<T> {
        return new Observable((subs) => {
            this.echo.listen(channel, event, (data: T) => subs.next(data));
        });
    }

    public listenPrivate<T>(channel: string, event: string, looper: Observable<T> = null): Observable<T> {
        if (this.socketId) {
            return new Observable((subs) => {
                this.echo.private(channel).listen(event, (data: T) => subs.next(data));
            });
        } else if (looper) {
            return interval(5000).pipe(switchMap(() => looper));
        } else {
            return EMPTY;
        }
    }

    public listenUserChannel<T>(event: string): Observable<T> {
        return this.userChannel.pipe(
            switchMap((ch) => {
                return new Observable<T>((subs) => {
                    ch.listen(event, (data: T) => subs.next(data));
                });
            })
        );
    }

    public get socketId(): string {
        return this.echo.socketId() ?? '';
    }
}
