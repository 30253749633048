export enum ResourceItemTypes {
    QUESTION = 'question',
    TEXT = 'text',
    VIDEO = 'video',
    COURSE = 'course',
    E_COURSE = 'e-course',
    TEST = 'test',
    SURVEY = 'survey',
    MATERIAL = 'material'
}

export class ResourceItemType {
}
