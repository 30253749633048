import { Cast, IDataModel, Ignore, InitialValue, Model } from '@klickdata/core/application';
import {
    CastType,
    MethodType,
    ModelSync,
    Nullable,
    SortedArray,
} from '@klickdata/core/application/src/model/model-interface';
import { Notification } from '@klickdata/core/notification';
import { Resource } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { SelectFilterOption } from '@klickdata/core/table';
import { UserRole } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { WidgetData } from '@klickdata/core/widget';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Occasion } from './user-occasion/user-occasion.model';

export interface Answers {
    opportunity_id: number;
    answer: string;
}

export interface UserData extends IDataModel {
    id?: number;
    title?: string;
    notes?: string;
    username?: string;
    fname?: string;
    lname?: string;
    email?: string;
    avatar?: string;
    alias?: string;
    status?: number;
    disabled?: boolean;
    customer_id?: number;
    media_id?: number;
    role_value?:
        | 'guest'
        | 'user'
        | 'groupadmin'
        | 'customeradmin'
        | 'masteradmin'
        | 'superadmin'
        | 'customerMasteradmin';
    end_date?: string;
    type?: string;
    verified?: boolean;
    activated?: boolean;
    created_by?: User;
    lang?: string;
    pref_lang_ids?: number[];
    employee_no?: string;
    use_manager_email?: boolean;
    manager_id?: string;
    phone?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    opportunity_id?: number;
    permissions?: { [key: string]: string };

    sync_all_groups?: boolean;
    groups_attach?: number[];
    groups_detach?: number[];
    categories_attach?: number[];
    categories_detach?: number[];

    managed_groups?: number[];
    cases_count?: number;
    author_cases_count?: number;
    last_seen?: string;
    about?: string;
    references?: string[];
    section_ids?: number[];
    creator?: UserData;
    widgets?: WidgetData[];
    prompt_delimiters_disabled?: boolean;

    /**
     * statistics
     */
    total_groups?: number;
    total_activities?: number;
    started_course_plan?: number;
    started_survey?: number;
    started_test?: number;
    started_course?: number;
    completed_course_plan?: number;
    completed_survey?: number;
    completed_test?: number;
    completed_course?: number;
    passed_test?: number;
    failed_test?: number;
    material_taken?: number;
    test_occasions?: number;
    course_occasions?: number;
    survey_occasions?: number;
    overall_time_spent?: number;
    learning_time_spent?: number;

    /**
     * tools
     */
    submitText?: string;

    /**
     * User create - edit
     */
    groups?: {};
    resources?: ModelSync[];
}

export class User extends Model<UserData> {
    id: number;
    username: string;
    fname: string;
    lname: string;
    email: string;
    avatar: string;
    alias: string;
    status: number;
    disabled: boolean;
    customer_id: number;
    media_id: number;
    role_value:
        | 'guest'
        | 'user'
        | 'groupadmin'
        | 'customeradmin'
        | 'masteradmin'
        | 'superadmin'
        | 'customerMasteradmin';
    @Cast(CastType.MOMENT)
    @Nullable(MethodType.PUT)
    end_date: moment.Moment;
    @Ignore()
    type: string;
    @Ignore()
    verified: boolean;
    @Ignore()
    activated: boolean;
    @Ignore()
    created_by: User;
    lang: string; // siteLanguage
    pref_lang_ids: number[]; // Content languages
    employee_no: string;
    @Ignore()
    use_manager_email: boolean;
    manager_id: string;
    phone: string;
    title: string;
    notes: string;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    deleted_at: moment.Moment;
    opportunity_id: number;
    total_groups: number;
    old_password: string;
    new_password: string;
    permissions: { [key: string]: string };
    test_occasions: number;
    course_occasions: number;
    survey_occasions: number;
    @InitialValue([])
    groups_attach: number[];
    @InitialValue([])
    groups_detach: number[];
    @InitialValue([])
    categories_attach: number[];
    @InitialValue([])
    categories_detach: number[];
    @InitialValue([])
    @SortedArray()
    references: string[];
    overall_time_spent: number;
    learning_time_spent: number;
    cases_count: number;
    author_cases_count: number;
    @Cast(CastType.MOMENT)
    last_seen: moment.Moment;
    about: string;
    @InitialValue([])
    section_ids: number[];
    @Ignore()
    checked: boolean;
    @Ignore()
    creator: UserData;
    widgets: WidgetData[];
    prompt_delimiters_disabled: boolean;
    @Ignore()
    occasions: Occasion[];
    @Ignore()
    answers: Answers[];

    /**
     * User create - edit
     */
    @Cast(CastType.CLOSURE, Utils.modelSync)
    groups?: ModelSync;
    @Cast(CastType.CLOSURE, Utils.modelSync)
    resources?: ModelSync[];

    get name(): string {
        return this.fname && this.lname ? `${this.fname} ${this.lname}` : this.fname || this.lname || null;
    }

    get finalResult(): Occasion {
        return Array.isArray(this.occasions) ? this.occasions[0] : this.occasions;
    }

    get finalAnswer(): Answers {
        return this.answers[0];
    }

    get userRoleOptions(): SelectFilterOption[] {
        return [
            { title: $localize`Main Admin`, value: 'customerAdmin', icon: 'groups' },
            { title: $localize`Group Admin`, value: 'groupAdmin', icon: 'group' },
            { title: $localize`User`, value: 'user', icon: 'person' },
        ];
    }

    get userStatusOptions(): SelectFilterOption[] {
        return [
            { title: $localize`:@@active:Active`, value: 'active', icon: 'notifications_active' },
            { title: $localize`Inactive (30d)`, value: 'inactive', icon: 'verified_user' },
            { title: $localize`Unactivated`, value: 'unactivated', icon: 'sync_disabled' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
            { title: $localize`Imported`, value: 'imported', icon: 'label_important' },
        ];
    }

    managed_groups: number[];
    role: Observable<UserRole>;

    /**
     * Need extra validation when we will handle resource editing privilege.
     * And differentiate between each user role according
     * a. Resource author.
     * b. Resource customer. connected with a.
     * c. Resource public
     */
    public canEdit(resource: { customer_id: number; author_id?: number }): boolean {
        return this.isOwner(resource) || this.hasPrivilege(resource);
    }

    public canDownload(resource: { downloads: Downloads }): boolean {
        return !!resource.downloads;
    }
    // public canDownloadPDF(resource: Resource): boolean {
    //     // return (
    //     //     resource.occasionStatus === 'done' ||
    //     //     (resource.occasionStatus !== 'done' && this.role_value !== 'guest') ||
    //     //     (resource.occasionStatus !== 'done' && this.role_value !== 'user')
    //     // );
    //     // Only courses can be downloaded
    //     return (
    //         resource.downloads &&
    //         (ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
    //             resource.scope_id === AppScope.COURSE)
    //     );
    // }
    public canEditMultiple(users: { customer_id: number; author_id?: number }[]): boolean {
        return !users.find((user) => !this.canEdit(user));
    }

    public canEmail(resource: { customer_id: number; author_id?: number }): boolean {
        return this.canEdit(resource);
    }

    public canPublish(resource: {
        customer_id: number;
        author_id?: number;
        ready_publish?: boolean;
        published: string;
        last_publish?: string;
    }): boolean {
        return !resource.last_publish && this.hasPublishPrivilege(resource);
    }

    public canUnPublished(resource: {
        customer_id: number;
        author_id?: number;
        published: string;
        ready_publish?: boolean;
        last_publish?: string;
    }): boolean {
        return (resource.published || resource.last_publish) && this.hasPublishPrivilege(resource);
    }

    public hasPublishPrivilege(resource: {
        customer_id: number;
        ready_publish?: boolean;
        author_id?: number;
    }): boolean {
        return (
            (resource.ready_publish == null || resource.ready_publish) &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.unpublished))
        );
    }

    public canAssign(resource: Resource): boolean {
        return (
            resource.isPublished() &&
            resource.isAvailable() &&
            (this.hasPrivilege(resource) || this.isOwner(resource) || resource.public)
        );
    }
    public canCollect(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public canRecommend(resource: Resource): boolean {
        return this.canAssign(resource);
    }
    public isMessageAuthor(message: Notification): boolean {
        return message.author?.id === this.id;
    }

    public canBrowse(resource: { customer_id: number; author_id?: number; published: string }): boolean {
        return !!resource.published;
    }

    public canPublicize(resource: {
        customer_id: number;
        author_id?: number;
        public: boolean;
        published: boolean;
    }): boolean {
        return (
            !resource.public &&
            !!resource.published &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.publicize))
        );
    }

    public canUnPublicized(resource: { customer_id: number; author_id?: number; public: boolean }): boolean {
        return (
            resource.public &&
            (this.hasPrivilege(resource) || (this.isOwner(resource) && !!this.permissions?.unpublicized))
        );
    }

    /**
     * Check if user is the owner of resource if resoure has ownership author_id property
     */
    private isOwner(resource: { author_id?: number }): boolean {
        return (
            this.role_value !== 'guest' &&
            ((resource instanceof User && resource.id === this.id) || // user can edit his profile.
                // typeof resource.author_id === 'undefined' ||
                resource.author_id === this.id)
        );
    }

    private hasPrivilege(resource: { customer_id: number }): boolean {
        return (
            this.role_value === 'superadmin' ||
            (resource.customer_id === this.customer_id &&
                (this.isAdmin() || (resource instanceof User && resource.role_value === 'user')))
        );
    }

    public isAdmin(): boolean {
        return this.role_value !== 'user' && this.role_value !== 'guest';
    }

    /**
     * Limited recording duration in ms when user is not admin
     * and his academy didn't pay for him (last condition not applied until EB clarify)
     */
    public canRecordUnlimited(): boolean {
        return this.role_value !== 'user' && this.role_value !== 'guest';
    }

    public isMasterAdmin(): boolean {
        return this.role_value === 'superadmin';
    }

    public preventChangeLibraryResStatus(isLibraryRes: boolean): boolean {
        return !this.isMasterAdmin() && isLibraryRes;
    }

    public canDelete(resource: { id: number; customer_id: number; author_id: number; default?: boolean }): boolean {
        return !resource.default && !this.isMe(resource) && this.canEdit(resource); // user can't delete his profile.
    }

    public canDeleteMultiple(users: any): boolean {
        return !users.find((user) => !this.canDelete(user));
    }

    private isMe(resource: { id: number }): boolean {
        return resource instanceof User && resource.id === this.id;
    }

    public canSendActivation(user: User): boolean {
        return this.canEdit(user) && !user.activated;
    }

    get matIcon(): string {
        return this.role_value === 'superadmin'
            ? 'admin_panel_settings'
            : this.role_value === 'customeradmin'
            ? 'groups'
            : this.role_value === 'groupadmin'
            ? 'group'
            : 'person';
    }
    get roleValueSpecs(): { [key: string]: string } {
        return this.role_value === 'superadmin'
            ? { icon: 'admin_panel_settings', title: $localize`Master Admin`, color: '#ff9961' }
            : this.role_value === 'customeradmin'
            ? { icon: 'groups', title: $localize`Main Admin`, color: '#3e5365' }
            : this.role_value === 'customerMasteradmin'
            ? { icon: 'supervised_user_circle', title: $localize`Academy Master Admin`, color: '#ff9961' }
            : this.role_value === 'groupadmin'
            ? { icon: 'group', title: $localize`Group Admin`, color: '#bfd8d0' }
            : { icon: 'person', title: $localize`Learner`, color: '#dfdfdf' };
    }
    get userPermissionLevels(): string[] {
        return ['MASTER', 'CLUSTER', 'PUBLIC', 'CUSTOMER', 'GROUP', 'USER'];
    }
}
