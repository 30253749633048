import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { PublishMessage, Resource, ResourceService } from '@klickdata/core/resource';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CoursePlanForm } from '../course-plan.form';
import { PublishForm } from './publish.form';

@Component({
    selector: 'app-course-plan-publish',
    templateUrl: './course-plan-publish.component.html',
    styleUrls: ['./course-plan-publish.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PublishForm],
})
export class CoursePlanPublishComponent implements OnInit, OnDestroy {
    @Input() hideActions: boolean;
    @Input()
    public publishing: Subject<boolean> = new Subject<boolean>();
    protected destroy: Subject<boolean> = new Subject<boolean>();
    @Output() onPublish: EventEmitter<PublishMessage> = new EventEmitter<PublishMessage>();
    max = moment()
        .add(1, 'M')
        .utc();
    public isLoading: boolean;
    @Input() referenceIdKey = 'resource_id';
    @Input() referenceFilter = 'assignedResource';

    constructor(
        protected publishForm: PublishForm,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef
    ) {}

    public get form(): FormGroup {
        return this.publishForm.form;
    }

    public get coursePlanForm(): CoursePlanForm {
        return <CoursePlanForm>this.publishForm.parent;
    }

    public get parentModel(): Resource {
        return <Resource>this.publishForm.parent?.model;
    }

    ngOnInit() {
        this.coursePlanForm.modelUpdate
            .pipe(
                takeUntil(this.destroy),
                switchMap(model => this.publishForm.create(model))
            )
            .subscribe(() => this.cdRef.markForCheck());
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public publish() {
        if (this.publishForm.form.invalid) {
            FormHelper.markForm(this.publishForm.form);
            this.cdRef.markForCheck();
            return;
        }
        this.publishing.next(true);
        this.isLoading = true;

        this.publishForm
            .submit()
            .pipe(takeUntil(this.destroy))
            .subscribe(publishMessage => {
                this.publishing.next(false);
                this.isLoading = false;
                this.onPublish.emit(publishMessage);
            });
    }
}
