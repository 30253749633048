import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-date-time-displayed-calendar-picker',
    templateUrl: './date-time-displayed-calendar-picker.component.html',
    styleUrls: ['./date-time-displayed-calendar-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeDisplayedCalendarPickerComponent),
            multi: true,
        },
    ],
})
export class DateTimeDisplayedCalendarPickerComponent implements ControlValueAccessor {
    @Input() icon = 'hourglass_top';
    @Input() title: string;
    @Input() disabled = false;
    public dateValue = new Date();
    public timeValue = new Date();
    public endDate = new FormControl();

    constructor(protected cdr: ChangeDetectorRef) {}

    writeValue(dateTime: string): void {
        if (dateTime) {
            this.timeValue = new Date(dateTime);
            this.dateValue = new Date(dateTime);
            this.cdr.markForCheck();
        }
    }
    public notify() {
        setTimeout(() => {
            this.propagateChange(this.prepareDateTimeValue());
        }, 100);
    }
    private prepareDateTimeValue() {
        const hours = this.timeValue.getHours();
        const minutes = this.timeValue.getMinutes();
        const dateTimeValue = new Date(this.dateValue);
        dateTimeValue.setHours(hours);
        dateTimeValue.setMinutes(minutes);
        return dateTimeValue;
    }
    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
}
