import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GlobalSearchData } from '@klickdata/core/global-search';

@Component({
    selector: 'app-resource-listing-sheet',
    templateUrl: './resource-listing-sheet.component.html',
    styleUrls: ['./resource-listing-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceListingSheetComponent implements OnInit {
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceListingSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: GlobalSearchData
    ) {}

    ngOnInit(): void {}
    public dismissSheet() {
        this.bottomSheetRef.dismiss();
    }
}
