import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RequestBuilder } from '@klickdata/core/http/src/request/request-builder';
import { TableFilterComponent } from '@klickdata/core/table/src/table-filter/table-filter.component';

export interface TableHttpService<T> {
    fetchData(source);
}

export namespace TableHttpHelpers {
    export function manageSort<T>(sort: MatSort, builder: RequestBuilder<T>): RequestBuilder<T> {
        if (sort && sort.active) {
            builder.param('sort', sort.active);
        }

        if (sort && sort.direction) {
            builder.param('dir', sort.direction);
        }

        return builder;
    }

    export function managePagination<T>(pagination: MatPaginator, builder: RequestBuilder<T>): RequestBuilder<T> {
        if (pagination) {
            builder.page(pagination.pageIndex);
            builder.limit(pagination.pageSize);
        }

        return builder;
    }

    /**
     * Inject filter to request builder.
     * @param filterComponent
     * @param builder
     * @param ignoreFilters Filter properties array to ignore from request.
     */
    export function mapFilter<T>(
        filterComponent: TableFilterComponent,
        builder: RequestBuilder<T>,
        ignoreFilters?: string[]
    ): RequestBuilder<T> {
        if (!filterComponent) {
            return builder;
        }

        if (filterComponent.filter.query) {
            builder.param('query', filterComponent.filter.query);
        }

        if (filterComponent.filter.filters) {
            for (const filter of filterComponent.filter.filters) {
                if (!filter.items || (ignoreFilters && ignoreFilters.indexOf(filter.property) !== -1)) {
                    continue;
                }

                // Ignore empty filter property.
                if (filter.items.length > 0) {
                    builder.param(filter.property, filter.items.join(','));
                }
            }
        }

        return builder;
    }
}
