import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { AppScope } from '@klickdata/core/resource/src/type.scope.enum';
import { ResourceTypes } from '@klickdata/core/resource/src/types.enum';
import { Observable, of } from 'rxjs';
import { catchError, first, map, share, switchMap } from 'rxjs/operators';
import { MediaType } from './media-type';
import { Media, MediaData } from './media.model';

@Injectable()
export class MediaService {
    public mediaURL: string;
    protected defaultMediaUrl: string;
    // protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    constructor(
        protected auth: AuthService,
        protected logger: LoggerService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected error: HttpErrorService,
        protected http: HttpClient
    ) {
        this.mediaURL = `${config.config.apiUrl}media`;
        this.defaultMediaUrl = `${this.config.config.apiUrl}default-media`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Upload media!
     */
    public uploadMedia(item: File): Observable<Media> {
        const formData: FormData = new FormData();
        formData.append('file', item);

        return this.http
            .post<ResponseData<MediaData>>(this.mediaURL, formData, {
                reportProgress: true,
            })
            .pipe(map((res) => new Media(res.data)));
    }

    public createAIMedia(text: string): Observable<Media> {
        const formData: FormData = new FormData();
        formData.append('prompt', text);

        return this.http
            .post<ResponseData<MediaData>>(this.mediaURL, formData, {
                reportProgress: true,
            })
            .pipe(map((res) => new Media(res.data)));
    }

    /**
     * Upload media!
     */
    public uploadMediaByLink(data: MediaData): Observable<Media> {
        return this.http
            .post<ResponseData<MediaData>>(this.mediaURL, data, {
                reportProgress: true,
            })
            .pipe(map((res) => new Media(res.data)));
    }

    /**
     * Get resource media
     * @param mediaId media id of this resource.
     * @param scope Resource Scope
     * @param artnr article code for this resource
     * @param artpos article video position then we can display relative media.
     * @returns Resource Media obj.
     */
    public getMedia(mediaId: number, scope?: AppScope, artnr?: string): Observable<Media> {
        return this.auth.check().pipe(
            first(),
            switchMap((authorized) => {
                if (mediaId) {
                    const url = authorized ? this.mediaURL : `${this.mediaURL}/public`;
                    return this.builder
                        .get<MediaData>(`${url}/${mediaId}`)
                        .request()
                        .pipe(
                            map((res) => this.mapMedia(res.data, scope || res.data.scope_id)),
                            catchError(() => of(this.mapMedia({}, scope))),
                            share()
                        );
                } else if (scope) {
                    return of(this.mapMedia({}, scope));
                }

                return of(null);
            })
        );
    }

    /**
     * Get default media per scope.
     * @param scope media scope
     * @returns return media including object including url, default_url and fallback_url
     */
    public getDefaultMedia(scope: AppScope): Observable<Media> {
        return this.builder
            .get<MediaData>(`${this.defaultMediaUrl}/scope/${scope}`)
            .param('customer', this.customer_id)
            .request()
            .pipe(map((res) => new Media(res.data)));
    }

    /**
     * Get media by ids.
     * @param ids
     * @returns return medias
     */
    public getMediaByIds(ids: number[]): Observable<Media[]> {
        return this.builder
            .get<MediaData[]>(`${this.mediaURL}`)
            .param('ids', ids.join(','))
            .request()
            .pipe(map((res) => res.data.map((item) => new Media(item))));
    }

    /**
     * Post default media for scope.
     * @param scope media data
     * @returns return media including object including url, default_url and fallback_url
     */
    public setDefaultMedia(data: MediaData): Observable<Media> {
        return this.builder
            .post<MediaData>(this.defaultMediaUrl, data)
            .request()
            .pipe(map((res) => new Media(res.data)));
    }

    /**
     * Remove default scope media.
     * @param data
     * @returns
     */
    public removeDefaultMedia(id: string): Observable<any> {
        return this.builder.delete<any>(`${this.defaultMediaUrl}/${id}`).request();
    }

    public restoreDefaultMedia(id: string): Observable<Media> {
        return this.builder
            .put<MediaData>(`${this.defaultMediaUrl}/restore/${id}`, null)
            .request()
            .pipe(map((res) => new Media(res.data)));
    }

    private mapMedia(data: MediaData, scope: AppScope): Media {
        const media = new Media(data);
        media.mediaType = MediaType.IMAGE;
        const type = scope && ResourceTypes.scopeById(scope);
        if (type) {
            media.fallback_url = `assets/images/Default_${type}_img.png`;
        } else {
            media.fallback_url = `assets/images/bg.png`;
        }
        return media;
    }
}
