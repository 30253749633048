import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    Resource,
    ResourceCategoryService,
    ResourceCreationManagerBase,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { Utils } from '@klickdata/core/util';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface YouTubeVideoData {
    title: string;
    duration: number;
}

@Component({
    selector: 'app-material-link-manager-core',
    templateUrl: './material-link-manager-core.component.html',
    styleUrls: ['./material-link-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialLinkManagerCoreComponent extends ResourceCreationManagerBase implements OnInit, OnChanges {
    @Input() resource: Resource;
    @Input() resourceForm: FormGroup;
    @Output() onControlDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
    public youTubeVideoInfo$: Observable<YouTubeVideoData>;
    isLoadingVideoInfo: boolean;
    typeId = ResourceTypes.MATERIAL;
    public media: Media;
    Utils = Utils;
    private resourceItem: ResourceItem;

    constructor(
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected itemService: ResourceItemService,
        protected error: HttpErrorService,
        protected sanitizer: DomSanitizer,
        protected config: ConfigService,
        protected http: HttpClient,
        protected tagsService: TagService,
        protected vTQService: VTQService
    ) {
        super(categoryService, auth, parentFormDirective, messageService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('link', new FormControl(''));
        this.resourceForm
            .get('link')
            .valueChanges.pipe(takeUntil(this.destroy), debounceTime(300))
            .subscribe((link) => {
                if (link && !Utils.isEmpty(link) && (Utils.isYoutubeURL(link) || Utils.isVimeoURL(link))) {
                    this.vTQService.setActiveVTQ(
                        new VTQ({
                            material_type: 'link',
                            material_name: link,
                            language_id: this.resourceForm.value.language_id,
                        })
                    );
                } else {
                    this.vTQService.setActiveVTQ(null);
                }
                this.onControlDirty.emit(true);
                if (!this.resource?.id) {
                    this.getLinkInfo(link);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue && this.resource && this.resourceForm) {
            this.resource.items$?.pipe(takeUntil(this.destroy)).subscribe((items) => {
                this.resourceItem = items[0];
                this.resourceForm.patchValue({
                    link: this.resourceItem.name,
                });
                this.getLinkInfo(this.resourceItem.name);
                FormHelper.resetForm(this.resourceForm);
            });
        }
    }
    public cleanControl() {
        if (this.resourceForm.get('link')) {
            this.resourceForm.removeControl('link');
        }
    }

    get mediaKey() {
        const link = this.resourceForm.value.link;
        if (link && Utils.isVideoUrl(link)) {
            this.media = Utils.getMedia(link);
            return link;
        }
        this.media = null;
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }
    public onSubmit(): void {
        this.loading.emit(true);
        this.checkSubmitValid();
    }

    checkSubmitValid(): Observable<boolean> {
        this.resourceForm.patchValue({
            link: this.resourceForm.get('link').value.replace(/\s/g, ''),
        });
        if (this.resourceForm.value.link && !Utils.isUrl(this.resourceForm.value.link)) {
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`:@@linkShoudBeValidURL:The link should be a valid URL`
            );
            this.loading.emit(false);
            return of(false);
        }
        if (this.publish && this.resourceForm.value.link === '') {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`You can't publish the material before adding a link`
            );
            this.loading.emit(false);
            return of(false);
        }
        if (!this.resourceForm.valid) {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.loading.emit(false);
            return of(false);
        }
        super.performResSubmit();
        return of(true);
    }

    public prepareSubmit(): Observable<Resource> {
        return this.prepareData().pipe(
            switchMap((data: ResourceData) =>
                this.resource?.id ? this.resourceService.update(data, true) : this.resourceService.store(data)
            ),
            takeUntil(this.destroy)
        );
    }

    protected prepareData(): Observable<ResourceData> {
        const data = new Resource(this.resourceForm.value).getData();
        data.resource_items = this.prepareMaterialLinkItem();
        if (Utils.isUrl(this.resourceForm.value.media_id)) {
            data.media_url = this.resourceForm.value.media_id;
        }
        if (this.publish) {
            if ((data && data.published) || (this.resource && this.resource?.published)) {
                data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
            } else {
                data.published = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        }
        if (!this.publish && data) {
            data.last_publish = null;
        }
        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.URLMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    protected prepareMaterialLinkItem(): ResourceItemData[] {
        if (this.resourceForm.value.link !== '') {
            return this.resourceItem && this.resourceItem.id
                ? [
                      {
                          id: this.resourceItem.id,
                          name: this.resourceForm.value.link,
                      },
                  ]
                : [
                      {
                          name: this.resourceForm.value.link,
                          item_type_value: 'url_material',
                      },
                  ];
        } else {
            if (this.resource && this.resourceItem?.id) {
                return [{ id: this.resourceItem.id, deleted: true }];
            }
        }
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Material title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Material language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Material categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Material link`,
                hasValidValue: Utils.isUrl(this.resourceForm.value.link),
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Material image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Material tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Material Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this test that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Material description`,
                info: $localize`A description of the test that will be visible befor you take the test.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
        ];
    }
    private getLinkInfo(link: string) {
        if (Utils.isYoutubeURL(link)) {
            this.isLoadingVideoInfo = true;
            const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${Utils.getYoutubeMedia(link).src}&key=${
                this.config.config.YOUTUBE_DATA_API_KEY
            }&part=snippet,contentDetails`;
            this.youTubeVideoInfo$ = this.http.get<any>(apiUrl).pipe(
                map((res) => {
                    if (res?.items?.length) {
                        return res.items[0];
                    } else {
                        this.isLoadingVideoInfo = false;
                        return null;
                    }
                }),
                filter((res) => !!res),
                switchMap((item) => combineLatest([of(item), this.auth.getCustomer()])),
                switchMap(([item, customer]) =>
                    combineLatest([
                        of(item),
                        item.snippet?.tags && !!item.snippet?.tags?.length
                            ? this.tagsService
                                  .createManyTags(item.snippet?.tags, this.resourceForm.value.language_id, customer.id)
                                  .pipe(map((tags) => tags.map((tag) => tag.id)))
                            : of([]),
                    ])
                ),
                map(([item, tagIds]) => {
                    this.isLoadingVideoInfo = false;
                    this.resourceForm.patchValue({
                        title: item.snippet.title,
                        bullets: this.resourceForm.value.bullets
                            ? this.resourceForm.value.bullets
                            : item.snippet?.description?.split('.')?.shift(),
                        description: this.resourceForm.value.description
                            ? this.resourceForm.value.description
                            : item.snippet?.description,
                        media_id: this.resourceForm.value.media_id
                            ? this.resourceForm.value.media_id
                            : item.snippet?.thumbnails?.medium?.url,
                        tag_ids: this.resourceForm.value.tag_ids ? this.resourceForm.value.tag_ids : tagIds,
                    });
                    return <YouTubeVideoData>{
                        title: item.snippet.title,
                        duration: moment.duration(item.contentDetails.duration).asSeconds(),
                    };
                })
            );
        } else {
            this.youTubeVideoInfo$ = of(null);
        }
    }
}
