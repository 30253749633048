import { Inject, Injectable, Injector, LOCALE_ID, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { Customer, CustomerData, CustomerLanding } from '@klickdata/core/customer/src/customer.model';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { Language } from '@klickdata/core/localization';
import { UserLogin } from '@klickdata/core/user/src/user-login.model';
import { User, UserData } from '@klickdata/core/user/src/user.model';
import { CacheUtils } from '@klickdata/core/util';
import { Utils } from '@klickdata/core/util/src/utils';
import * as Sentry from '@sentry/browser';
import { UserIdleService } from 'angular-user-idle';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, combineLatest, interval, of } from 'rxjs';
import {
    catchError,
    filter,
    finalize,
    first,
    map,
    share,
    shareReplay,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { LoginData } from '../auth/login.model';
import { Register } from '../auth/register.model';

export type PlatformType = 'guest' | 'player' | 'user' | 'admin' | 'master';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private token: BehaviorSubject<string> = new BehaviorSubject(null);
    private userCacheExpired: boolean;
    private customerCacheExpired: boolean;
    private user$: Observable<User>;
    private customer$: Observable<Customer>;
    private sharedUser = new BehaviorSubject<User>(null);
    private sharedCustomer = new BehaviorSubject<Customer>(null);
    private authUrl: string;
    private usersUrl: string;
    private destroy = new Subject<boolean>();
    private _loginUrl = '/guest/login';
    private _homeUrl = '/home/dashboard';
    private _startUrl = '/guest/start';
    protected customerLandingUrl: string;
    private _customerLandingObs: Observable<CustomerLanding>;
    private get loginUrl(): string {
        return this._loginUrl;
    }
    private get startUrl(): string {
        return this._startUrl;
    }
    public get homeUrl(): string {
        return this._homeUrl;
    }
    /**
     * Timer of user's inactivity is in progress.
     */
    private isIdleDetected: boolean;
    private idleSubscription: Subscription;
    private authenticated: Observable<boolean>;
    private userFetchStatus = new BehaviorSubject<boolean>(false);
    private customerFetchStatus = new BehaviorSubject<boolean>(false);
    /** Cache TTL variables */
    private cacheTTLMillisec: number;
    private userCacheTTLCheckSub: Subscription;
    private customerCacheTTLCheckSub: Subscription;
    private nk3Platform = new BehaviorSubject<PlatformType>('guest');

    /**
     *
     * @param localeId
     * @param builder
     * @param config
     * @param error
     * @param logger
     * @param jwtHelper
     * @param userIdle
     * @param injector
     * @param zone
     * @param cookieService
     */

    constructor(
        @Inject(LOCALE_ID) private localeId: string,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        private error: HttpErrorService,
        private logger: LoggerService,
        private jwtHelper: JwtHelperService,
        private userIdle: UserIdleService,
        private injector: Injector,
        protected zone: NgZone,
        private cookieService: CookieService
    ) {
        this.cacheTTLMillisec = this.config.config.cacheTTL * 1000;
        this.authUrl = `${this.config.config.apiUrl}auth`;
        this.usersUrl = `${this.config.config.apiUrl}users`;
        this.customerLandingUrl = `${config.config.apiUrl}auth/customer/landing`;
        this.loadToken();
        this.startWatchingIdle();
        this.authenticated = this.isValid();
        this.createUserObservable();
        this.createCustomerObservable();
    }

    /**
     * Login
     */
    login(login: LoginData): Observable<ResponseData<any>> {
        const token = localStorage.getItem('token');
        if (token) {
            login.oldToken = token;
        }

        return this.builder
            .post<any>(`${this.authUrl}/login`, login)
            .request()
            .pipe(
                take(1),
                map((res) => {
                    if (res.data.token) {
                        return this.handleLogin(res, login);
                    }
                    return res;
                }),
                catchError((err) => this.error.handle(err))
            );
    }

    storeLogin() {
        return this.builder
            .get<any>('https://api.ipregistry.co/?key=tryout')
            .request()
            .pipe(
                take(1),
                map((data) => new UserLogin(data)),
                switchMap((userLogin) => this.builder.post<any>(`${this.usersUrl}/login`, userLogin).request())
            )
            .subscribe();
    }

    /**
     * Register trail accounts.
     */
    register(register: Register): Observable<ResponseData<User>> {
        return this.builder
            .post<User>(`${this.authUrl}/register`, { ...register, short_name: Utils.getSubdomain() })
            .request()
            .pipe(
                map((res) => this.handleLogin(res)),
                // catchError(error => this.error.handle(error)),
                takeUntil(this.destroy)
            );
    }

    /**
     * Reset authentication and reload.
     * Used for debugging.
     */
    public reset(): void {
        this.destroyToken();
        window.location.reload();
    }

    /**
     * Check if the user is authenticated.
     */
    public check(): Observable<boolean> {
        return this.authenticated;
    }

    /**
     * Fetch the authenticated useUser from store.
     */
    public getUser(): Observable<User> {
        return this.user$;
    }

    /**
     * Fetch the authenticated contacts customer from store.
     */
    public getCustomer(): Observable<Customer> {
        return this.customer$;
    }
    public getCustomerLanding(): Observable<CustomerLanding> {
        if (!this._customerLandingObs) {
            this._customerLandingObs = this.builder
                .get<CustomerLanding>(this.customerLandingUrl)
                .param('short_name', Utils.getSubdomain())
                .request()
                .pipe(
                    shareReplay(),
                    map((res) => res.data),
                    catchError(() =>
                        of({
                            title: 'Klickdata',
                            logotype_padding: 0,
                            logotype_url: 'assets/images/klickdata-logo-small.png',
                            background_url: 'assets/images/login-bg.jpg',
                            enable_anonymous_register: false,
                        })
                    )
                );
        }
        return this._customerLandingObs;
    }
    public handleLogin(response: ResponseData<any>, user?: LoginData): ResponseData<any> {
        if (response.data.token) {
            this.clearCache();
            this.setToken(response.data.token, true, user);
        }

        return response;
    }

    /**
     * Get user from cache
     */
    private getSharedUser(): Observable<User> {
        return this.sharedUser.pipe(
            filter((user) => !!user)
            // tap(() => {
            //     this.logger.info('User from cache');
            // })
        );
    }

    /**
     * Get customer from cache
     */
    private getSharedCustomer(): Observable<Customer> {
        return this.sharedCustomer.pipe(
            filter((customer) => !!customer)
            // tap(() => {
            //     this.logger.info('Customer from cache');
            // })
        );
    }

    /**
     * Creates a customer observable.
     * Updates on token updates.
     */
    private createUserObservable() {
        this.user$ = this.authenticated.pipe(
            switchMap((authenticated) => {
                // check is token valid
                if (!authenticated) {
                    return EMPTY;
                }

                /**
                 * Get user from cache when is available and vaild.
                 */
                if (this.sharedUser.getValue() && !this.userCacheExpired) {
                    return this.getSharedUser();
                }

                /**
                 * Handle synchronized fetch request, when request in progress wait util finished,
                 * and result update cache, then get from cache.
                 */
                if (this.userFetchStatus.value) {
                    this.logger.info('Waiting for fetch user then share...');
                    return this.userFetchStatus.pipe(
                        filter((fetching) => !fetching),
                        switchMap(() => this.getSharedUser())
                    );
                }

                this.logger.info('Fetching User from store');
                this.userFetchStatus.next(true);
                return this.fetchUser();
            })
        );
    }
    private fetchUser() {
        return this.builder
            .get<UserData>(`${this.authUrl}/user`)
            .request()
            .pipe(
                map((res) => {
                    const user = new User({ ...res.data, lang: this.localeId });
                    // Set user information, as well as tags and further extras
                    Sentry.configureScope((scope) => {
                        scope.setUser({
                            id: `${user.id}`,
                            username: user.username,
                            email: user.email,
                            user_mode: user.role_value,
                        });
                    });
                    this.siteLanguage = user.lang;
                    this.sharedUser.next(user);
                    this.logger.info('User fetch completed!');
                    return user;
                }),
                share(),
                catchError((err) => this.error.handle(err)),
                takeUntil(this.destroy),
                finalize(() => {
                    // reset cache expiration flag use cached data until @cacheTTL ended.
                    this.userCacheExpired = false;
                    this.startUserCacheTTLPing();
                    this.userFetchStatus.next(false);
                })
            );
    }

    /**
     * Creates a customer observable.
     * Updates on token updates.
     */
    private createCustomerObservable() {
        this.customer$ = this.authenticated.pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    return EMPTY;
                }

                /**
                 * Get customer from cache when is available and vaild.
                 */
                if (this.sharedCustomer.getValue() && !this.customerCacheExpired) {
                    return this.getSharedCustomer();
                }

                /**
                 * Handle synchronized fetch request, when request in progress wait util finished,
                 * and result update cache, then get from cache.
                 */
                if (this.customerFetchStatus.value) {
                    this.logger.info('Waiting for fetch customer then share...');
                    return this.customerFetchStatus.pipe(
                        filter((fetching) => !fetching),
                        switchMap(() => this.getSharedCustomer())
                    );
                }

                this.logger.info('Fetching Customer from store');
                this.customerFetchStatus.next(true);
                return this.fetchCustomer();
            })
        );
    }
    private fetchCustomer() {
        return this.builder
            .get<CustomerData>(`${this.authUrl}/customer`)
            .request()
            .pipe(
                map((res) => {
                    const customer = new Customer(res.data);
                    this.sharedCustomer.next(customer);
                    this.logger.info('Customer fetch completed!');
                    return customer;
                }),
                share(),
                catchError((err) => this.error.handle(err)),
                takeUntil(this.destroy),
                finalize(() => {
                    this.customerCacheExpired = false;
                    this.startCustomerCacheTTLPing();
                    this.customerFetchStatus.next(false);
                })
            );
    }

    /**
     * Creates a subscription that ping cache TTL every @cacheTTL
     */
    startUserCacheTTLPing() {
        if (this.userCacheTTLCheckSub) {
            this.userCacheTTLCheckSub.unsubscribe();
        }
        this.userCacheTTLCheckSub = interval(this.cacheTTLMillisec)
            .pipe(
                filter(() => !this.userCacheExpired),
                tap(() => {
                    this.userCacheExpired = true;
                    this.logger.info('userCacheExpired');
                })
            )
            .subscribe();
    }

    startCustomerCacheTTLPing() {
        if (this.customerCacheTTLCheckSub) {
            this.customerCacheTTLCheckSub.unsubscribe();
        }
        this.customerCacheTTLCheckSub = interval(this.cacheTTLMillisec)
            .pipe(
                filter(() => !this.customerCacheExpired),
                tap(() => {
                    this.customerCacheExpired = true;
                    this.logger.info('customerCacheExpired');
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
        this.saveIdleExpiration();
        this.stopWatching();

        if (this.userCacheTTLCheckSub) {
            this.userCacheTTLCheckSub.unsubscribe();
        }

        if (this.customerCacheTTLCheckSub) {
            this.customerCacheTTLCheckSub.unsubscribe();
        }
    }

    public updateUserLanguage(language: Language) {
        this.siteLanguage = language.lang;
        if (this.getNK3PlatformValue() !== 'guest') {
            this.getUser()
                .pipe(
                    filter((user) => user.lang !== language.lang),
                    first(),
                    switchMap((user) =>
                        this.builder
                            .put<UserData>(`${this.usersUrl}/${user.id}`, { id: user.id, lang: language.lang })
                            .request()
                    )
                )
                .subscribe(() => this.changeSiteLang(language.value));
        } else {
            this.changeSiteLang(language.value);
        }
    }

    private changeSiteLang(lang: string) {
        const router = this.injector.get(Router);
        window.location.assign(`/${lang}${router.url}`);
    }

    public setNK3Platform(scope: PlatformType) {
        this.nk3Platform.next(scope);
    }

    public getNK3Platform(): Observable<PlatformType> {
        return this.nk3Platform.asObservable();
    }

    public getNK3PlatformValue(): PlatformType {
        return this.nk3Platform.value;
    }

    public checkPlatform(type: PlatformType): boolean {
        return this.nk3Platform.value === type;
    }

    public handleInvalidToken() {
        this.destroyTokenAndOpenPage(true);
    }

    /**
     * Handle TOKEN
     */

    /**
     * Start watching for user idle when user in active session (user has a vaild/not expired token.)
     */
    private startWatchingIdle() {
        if (this.token.value) {
            this.idleSubscription = this.userIdle.onIdleStatusChanged().subscribe((status) => {
                this.isIdleDetected = status;
                if (status) {
                    this.saveIdleExpiration();
                } else {
                    localStorage.removeItem('expired_at');
                }
            });
            this.userIdle.startWatching();
            this.idleSubscription.add(
                combineLatest([this.userIdle.onTimerStart(), this.userIdle.onTimeout()])
                    .pipe(
                        filter(([count, timedOut]) => timedOut), // we don't support countdown fire only when timedout.
                        first()
                    )
                    .subscribe(() => this.idleTimedOut())
            );
            this.startPingToken();
        }
    }

    saveIdleExpiration() {
        const idleExpiration = moment().add(this.config.config.idle, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        localStorage.setItem('expired_at', idleExpiration);
        this.logger.info(`Set Expired => ${idleExpiration}`);
    }

    /**
     * Creates a subscription that updates every
     * @this.pingToken if there is a token assigned.
     */
    startPingToken() {
        this.userIdle.ping$
            .pipe(
                takeUntil(this.destroy),
                filter(() => {
                    if (!this.isIdleDetected && !!this.token.value) {
                        if (this.jwtHelper.isTokenExpired(this.token.value)) {
                            // If token expired detroy local token and open login page.
                            this.destroyTokenAndOpenPage(true);
                            return false;
                        }
                        return true;
                    }
                    return false;
                }),
                switchMap(() => {
                    return this.updateToken();
                })
            )
            .subscribe((token: string) => {
                this.setToken(token);
            });
    }

    private idleTimedOut() {
        if (this.token.value && !this.jwtHelper.isTokenExpired(this.token.value)) {
            this.logout(true);
        } else {
            this.destroyTokenAndOpenPage(true);
        }
    }

    /**
     * Set the sting token and notifies observers.
     */
    public setToken(token: string | null, isLogin?: boolean, user?: LoginData): void {
        if (token === 'null' || (token && this.jwtHelper.isTokenExpired(token))) {
            this.token.next(null);
            return;
        }

        // cache academy short name for next login
        if (user?.short_name) {
            localStorage.setItem('academy_short_name', user.short_name);
        }

        this.saveToken(token);
        this.token.next(token);

        if (isLogin) {
            this.stopWatching();
            this.startWatchingIdle();
        }
    }

    /**
     * Destroys the token and notifies observers.
     */
    public destroyToken(): void {
        this.removeToken();
        this.clearCache();
        this.removeIdleExpiration();
        this.token.next(null);
        this.stopWatching();
        this._customerLandingObs = null;
    }

    private stopWatching() {
        if (this.idleSubscription) {
            this.userIdle.stopWatching();
            this.userIdle.stopTimer();
            this.idleSubscription.unsubscribe();
            this.idleSubscription = null;
        }
    }

    /**
     * Get an observable for tokenString
     * Notifies on token updates.
     *
     * @returns Observable<string>
     */
    public getToken(): Observable<string> {
        return this.token.asObservable();
    }

    /**
     * Get a specific claim from token.
     * Notifies on token updates.
     */
    public getClaim(claim: string): Observable<any> {
        return this.token.pipe(
            filter((token) => !!token && !this.jwtHelper.isTokenExpired(token)),
            map((token) => this.jwtHelper.decodeToken(token)[claim])
        );
    }

    public sameLogin(newToken: string): Observable<boolean> {
        return this.getClaim('sub').pipe(map((userId) => userId === this.jwtHelper.decodeToken(newToken)['sub']));
    }

    /**
     * Check if the token is valid.
     * Notifies on token updates.
     */
    public isValid(): Observable<boolean> {
        return this.token.pipe(
            map((token) => {
                const authenticated = token && !this.jwtHelper.isTokenExpired(token);
                if (!authenticated) {
                    // Listen token authenticated when expired or not authenticated then clear cache.
                    this.destroyTokenAndOpenPage(true);
                }
                return authenticated;
            })
        );
    }

    /**
     * Saves the token to localStorage if available
     */
    private saveToken(token: string) {
        // Do not save an empty token.
        if (!token) {
            return;
        }

        // Is local storage available?
        if (localStorage) {
            localStorage.setItem('token', token);
        }
    }

    /**
     * Load token
     */
    private loadToken() {
        let tokenString = localStorage.getItem('token');

        // Fix token
        if (tokenString === 'null') {
            this.removeToken();
            tokenString = undefined;
        }

        this.token.next(tokenString);
        /**
         * Check stored token idle timedOut.
         */
        const expired = localStorage.getItem('expired_at');
        if (expired && moment().isSameOrAfter(moment(expired, 'YYYY-MM-DD HH:mm:ss'))) {
            this.idleTimedOut();
        }
    }

    /**
     * Removes the token from localStorage.
     */
    private removeToken() {
        if (localStorage) {
            localStorage.removeItem('token');
        }
    }

    private removeIdleExpiration() {
        if (localStorage) {
            localStorage.removeItem('expired_at');
        }
    }

    /**
     * Check if the assigned token as expired.
     */
    private checkExpiration(): void {
        if (this.jwtHelper.isTokenExpired(this.token.value)) {
            // this.setToken(null);
        }
    }

    /**
     * Check and update token.
     */
    private updateToken(): Observable<string> {
        return this.builder
            .post<{ token: string }>(`${this.authUrl}/token/update`, null)
            .request()
            .pipe(
                map((res) => res.data.token),
                catchError((err) => this.error.handle(err))
            );
    }

    /**
     * Update user token
     * will expire/refresh user/customer cache
     */
    public refreshToken(): Observable<string> {
        this.userCacheExpired = true;
        this.customerCacheExpired = true;
        return this.updateToken().pipe(
            take(1),
            tap((token) => this.setToken(token))
        );
    }

    public logout(saveRedirectUrl = false): void {
        this.doLogout().subscribe();
        // Logout after notify server.
        this.destroyTokenAndOpenPage(saveRedirectUrl, true);
    }

    private doLogout(): Observable<ResponseData<any>> {
        return this.builder
            .post<any>(`${this.authUrl}/logout`, {})
            .request()
            .pipe(
                first(),
                catchError((err) => this.error.handle(err))
            );
    }

    /**
     * Check current domain under klickdata directly or from agent/academy.
     * @returns true when subdomain from klickdata academy.
     */
    public checkSubdomain(): Observable<boolean> {
        return this.builder
            .get<boolean>(`${this.authUrl}/subdomain/check`)
            .param('short_name', Utils.getSubdomain())
            .request()
            .pipe(
                first(),
                map((res) => res.data),
                catchError(() => of(true))
            );
    }

    /**
     * Destroy token and open login page when idle timedOut or token expired.
     */
    public destroyTokenAndOpenPage(saveUri = false, landing = false) {
        if (this.token.value) {
            this.destroyToken();
            if (!landing) {
                this.openLoginPage(saveUri);
            } else {
                this.openStartPage(saveUri);
            }
        }
    }

    public openLoginPage(saveUri: boolean, router = this.injector.get(Router), uri = router.url) {
        this.zone.run(() =>
            router.navigate([this.loginUrl], {
                queryParams: saveUri && this.validRedirectUri(uri) ? { redirect_uri: uri } : {},
            })
        );
    }

    private openStartPage(saveUri: boolean, router = <Router>this.injector.get(Router)) {
        this.zone.run(() =>
            router.navigate([this.startUrl], {
                queryParams: saveUri && this.validRedirectUri(router.url) ? { redirect_uri: router.url } : {},
            })
        );
    }

    private clearCache() {
        this.userCacheExpired = true;
        this.customerCacheExpired = true;
        this.sharedUser.next(null);
        this.sharedCustomer.next(null);
        this.nk3Platform.next('guest');
        CacheUtils.removeAllCachedFilters();
    }

    public handleForbidden() {
        const router = this.injector.get(Router);
        router.navigateByUrl('/unauthorised');
    }

    set siteLanguage(langStr: string) {
        this.cookieService.set('lang_ui', langStr, {
            path: '/',
        });
    }

    get siteLanguage(): string {
        return this.cookieService.get('lang_ui');
    }

    /**
     * Store url for redirect after login.
     * Don't redirect to undefined/empty/root/home/guest urls
     */
    private validRedirectUri(redirect_uri: string) {
        return (
            redirect_uri &&
            !['', '/', '/unauthorised', this.homeUrl].some((url) => url === redirect_uri) &&
            redirect_uri.indexOf('/guest/') === -1
        );
    }
}
