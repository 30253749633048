<div class="resource-tools-column" fxLayout="column">
    <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="0px"
        fxLayoutAlign.lt-md="end center"
        fxLayoutGap.lt-lg="10px"
        class="res-action-btn"
    >
        <p fxHide.lt-md="true" i18n class="mt-1 third-column-title">Save options</p>
        <button
            type="button"
            class="specify-btn"
            mat-icon-button
            (click)="list(ResourceTypes.playerRoute(typeId, true))"
        >
            <mat-icon>list</mat-icon>
        </button>
        <button
            *ngIf="resource && resource.downloads"
            class="grey"
            type="button"
            matTooltip="Download"
            i18n-matTooltip="@@download"
            (click)="download(resource)"
            mat-icon-button
        >
            <mat-icon>save_alt</mat-icon>
        </button>

        <button
            type="button"
            matTooltip="Specify which learners and groups who will have access."
            i18n-matTooltip
            class="specify-btn"
            mat-icon-button
            *ngIf="(resource && resource?.id) || (resourceBuilder?.getResource() | async)"
            (click)="specify()"
        >
            <mat-icon>manage_accounts</mat-icon>
            <div class="have-attached {{ accessLevel }}"></div>
        </button>

        <button
            matTooltip="View how it looks for usrs"
            i18n-matTooltip
            type="button"
            mat-icon-button
            (click)="view(typeId, resource)"
        >
            <mat-icon>visibility</mat-icon>
        </button>
        <ng-container *ngIf="vTQ$ | async; let vTQ">
            <button
                matTooltip="Create a test with AI"
                i18n-matTooltip
                mat-icon-button
                class="create-quiz-btn"
                (click)="createQuiz(vTQ)"
            >
                <mat-icon
                    fxFlex="0 0 auto"
                    fontSet="klickdata"
                    fontIcon="kd-icon-test"
                    class="course-plan-icon side-nave-icon no-shadow"
                ></mat-icon>
            </button>
        </ng-container>
        <button
            *ngIf="resKeysValidaty"
            matTooltip="Check what you can improve before save."
            i18n-matTooltip
            type="button"
            mat-icon-button
            [ngStyle]="{ color: (resKeysValidaty | resourceProgress)?.color }"
            (click)="checkEfficiency(typeId)"
        >
            <mat-icon>task_alt</mat-icon>
        </button>
    </div>

    <ng-container [ngTemplateOutlet]="actionBtn" [ngTemplateOutletContext]="{ resource: resource }"></ng-container>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox #publishCheckbox formControlName="publish" color="primary" class="draft-checkbox">
            <div class="publish-label-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="option">published_with_changes</mat-icon>
                <span fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span i18n>Publish the</span>
                    <span>{{ ResourceTypes.label(typeId, { capitalize: false }) }}</span>
                    <span i18n>in academy</span>
                </span>
            </div>
            <div class="publish-label-cut" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="option">published_with_changes</mat-icon>
                <span fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span i18n>Publish in academy</span>
                </span>
            </div>
        </mat-checkbox>
    </div>
    <mat-checkbox formControlName="public" color="primary" class="draft-checkbox">
        <div
            class="publish-label-full"
            [ngClass]="{ 'disabled-control': resourceForm.get('public').disabled }"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
        >
            <mat-icon class="option">public</mat-icon>
            <span fxLayoutAlign="start center" fxLayoutGap="5px">
                <span i18n>Make</span>
                <span>{{ ResourceTypes.label(typeId, { capitalize: false }) }}</span
                ><span i18n>public in KLMS</span>
            </span>
        </div>
        <div
            class="publish-label-cut"
            [ngClass]="{ 'disabled-control': resourceForm.get('public').disabled }"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
        >
            <mat-icon class="option">public</mat-icon>
            <span fxLayoutAlign="start center" fxLayoutGap="5px">
                <span i18n>Make public</span>
            </span>
        </div></mat-checkbox
    >
    <div class="lang-select-profile mt-1">
        <span i18n="@@displayLanguage">Display Language</span>
        <app-language-selector
            formControlName="language_id"
            [showLabel]="true"
            [disableRipple]="true"
            (afterInit)="resetForm()"
        ></app-language-selector>
    </div>
    <app-tag-chip-select
        class="mt-1"
        placeholder="Add Tag"
        i18n-placeholder
        [sortedAlph]="true"
        [language_id]="resourceForm.value.language_id"
        formControlName="tag_ids"
    ></app-tag-chip-select>
    <app-category-chip-select
        fxFlex="0 1 100%"
        [resource_id]="resource?.id"
        [language_id]="resourceForm.get('language_id').value"
        formControlName="category_ids"
        [type_id]="typeId"
    ></app-category-chip-select>
    <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 1 100%">
        <mat-form-field class="w-100" fxFlex="0 1 100%">
            <input #title matInput placeholder="Set publisher name" />
        </mat-form-field>
    </div> -->
    <ng-container formGroupName="staff">
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 1 100%" class="manager-wrapper">
            <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
                <div class="manager-label">
                    <span i18n>Publisher</span>
                    <mat-icon class="icon-explain" matTooltip="The academy which publish this resource." i18n-matTooltip
                        >help_outline</mat-icon
                    >
                </div>
                <app-resource-educator-handler
                    class="publisher-edu-handler"
                    [resource]="resource"
                    [maxNumberOfEducatore]="1"
                    formControlName="publisher"
                    searchPlaceholder="Search existing publishers"
                    [setPredefinedEducator]="!resourceForm.value.staff?.publisher"
                    i18n-searchPlaceholder
                    removeBtn="autorenew"
                    [educatorRole]="ResourceStaffRoles.PUBLISHER"
                    i18n-addingLabel
                    addingLabel="Add a new publisher"
                ></app-resource-educator-handler>
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 1 100%" class="manager-wrapper mt-1">
            <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
                <ng-container [ngTemplateOutlet]="managerLabel"></ng-container>
                <app-resource-educator-handler
                    [resource]="resource"
                    [searchPlaceholder]="getLocalizedLabel(true)"
                    i18n-searchPlaceholder
                    [addingLabel]="getLocalizedLabel(false)"
                    i18n-addingLabel
                    [mainEducatorTooltip]="getMainEduTooltip()"
                    [setDefault]="true"
                    [educatorRole]="ResourceStaffRoles.MANAGER"
                    class="w-100 res-third-column-edu-wrapper"
                    formControlName="manager"
                ></app-resource-educator-handler>
            </div>
        </div>
    </ng-container>
    <div class="tools-lower-actions-btns" fxLayoutAlign="space-between start">
        <div class="infor-res-wrap" *ngIf="resource && resource.id" fxLayout="column" fxLayoutAlign="start start">
            <div class="info-res" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>edit</mat-icon>
                    <span i18n>Created:</span>
                </div>
                <span class="inf">{{ resource.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
            </div>
            <div
                *ngIf="resource.published"
                class="info-res"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="5px"
            >
                <div fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>published_with_changes</mat-icon>
                    <span i18n>Published:</span>
                </div>
                <span class="inf">{{ resource.published | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
            </div>
            <div class="info-res" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>update</mat-icon>
                    <span i18n>Updated:</span>
                </div>
                <span class="inf">{{ resource.updated_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button
                matTooltip="Add note to this cource"
                mat-icon-button
                type="button"
                (click)="addResNote(resource)"
                i18n-matTooltip="@@AddNoteToThisCource"
                *ngIf="resource && typeId === ResourceTypes.GeneralCoursePlan"
            >
                <mat-icon class="material-icons-outlined">note_alt</mat-icon>
            </button>
            <button
                matTooltip="View log"
                mat-icon-button
                type="button"
                (click)="viewResLog(resource)"
                *ngIf="resource && resource.id"
            >
                <mat-icon>touch_app</mat-icon>
            </button>

            <button
                [matTooltip]="'Delete ' + ResourceTypes.label(typeId, { capitalize: false })"
                i18n-matTooltip
                mat-icon-button
                type="button"
                (click)="delete(resource)"
                *ngIf="resource"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>
<ng-template #actionBtn let-resource="resource">
    <ng-container *ngIf="loading">
        <button mat-raised-button type="button" class="save-btn draft">
            <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container loader-res">
                <app-kd-logo-loading size="small"></app-kd-logo-loading>
            </div>
        </button>
    </ng-container>
    <ng-container *ngIf="!loading">
        <button
            [ngClass]="{
                draft: !resourceForm.value.publish,
                'is-btn-disabled': !resourceForm.value.title || resourceForm.pristine,
                'disabled-btn': resSubmitDisabledStatus$ | async
            }"
            (click)="onSubmit()"
            [disabled]="!resourceForm.value.title || resourceForm.pristine"
            mat-raised-button
            [disableRipple]="!resourceForm.value.title"
            class="save-btn"
        >
            <mat-icon>save</mat-icon>
            <div fxLayoutAlign="start start" fxLayoutGap="5px">
                <span i18n>Save</span>
                <span>{{ ResourceTypes.label(typeId, { capitalize: false }) }}</span>
                <span *ngIf="!resourceForm.value.publish" i18n>as private</span>
            </div>
        </button>
    </ng-container>
</ng-template>
<ng-template #managerLabel>
    <div class="manager-label" *ngIf="typeId !== ResourceTypes.EVENT">
        <span i18n>Author</span>
        <mat-icon
            class="icon-explain"
            matTooltip="Authors are the main teachers, You can justify the main author."
            i18n-matTooltip
            >help_outline</mat-icon
        >
    </div>
    <div class="manager-label" *ngIf="typeId === ResourceTypes.EVENT">
        <span i18n>Tutor</span>
        <mat-icon
            class="icon-explain"
            matTooltip="Tutors are the main teachers. You can justify the main tutor."
            i18n-matTooltip
            >help_outline</mat-icon
        >
    </div>
</ng-template>
