<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select assignmnt-wrapper">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submitAction()"
        (onCancel)="onClose()"
        [savingTitle]="actionText"
        [savingStatus]="isLoading"
    ></app-sticky-header>

    <mat-horizontal-stepper #stepper linear class="transparent" fxFlex="0 0 100%">
        <mat-step label="Assign" i18n-label="@@assign" fxLayout="row wrap" class="mt-1 mb-1" fxLayoutAlign="center">
            <app-course-plan-publish
                [hideActions]="true"
                mat-dialog-content
                [appHasOne]="courseplanFrom.publish"
                [referenceFilter]="data.referenceFilter"
                [referenceIdKey]="data.referenceIdKey"
                (onPublish)="onPublished($event)"
            >
            </app-course-plan-publish>
        </mat-step>
        <mat-step
            optional
            label="Reminders"
            i18n-label="@@reminders"
            fxLayout="row wrap"
            class="mt-1 mb-1"
            fxLayoutAlign="center"
        >
            <mat-slide-toggle
                *ngIf="!hideReminderToggle"
                class="reminder-toggle"
                [formControl]="reminderControl"
                color="primary"
                i18n
            >
                Enable reminders
            </mat-slide-toggle>
            <fieldset [disabled]="!reminderControl.value">
                <app-course-plan-reminders
                    [hideActions]="true"
                    mat-dialog-content
                    (loaded)="onReminderLoaded($event)"
                    (done)="onReminderDone($event)"
                    [appHasOne]="courseplanFrom.reminder"
                >
                </app-course-plan-reminders>
            </fieldset>
        </mat-step>
    </mat-horizontal-stepper>
</div>
