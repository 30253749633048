import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PostService } from '../post.service';
import { PostPageContentComponent } from './post-page-content/post-page-content.component';

@Component({
    selector: 'app-post-page',
    templateUrl: './post-page.component.html',
    styleUrls: ['./post-page.component.scss'],
})
export class PostPageComponent implements OnInit {
    protected dialogRef: MatDialogRef<PostPageContentComponent>;

    constructor(protected route: ActivatedRoute, protected postService: PostService, protected mdDialog: MatDialog) {
        this.dialogRef = this.mdDialog.open(PostPageContentComponent, {
            width: '70%',
            position: {
                top: '120px',
            },
        });
    }

    public ngOnInit(): void {
        this.dialogRef.componentInstance.aPost = this.route.params.pipe(
            switchMap((params: Params) => {
                return this.postService.getPost(params['id']);
            })
        );
    }
}
