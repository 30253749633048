import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { QuestionTypes } from '@klickdata/core/question';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceBuilderService } from '../../resource-builder.service';
import { QuestionService } from '../../shared/question.service';
/**
 * Example structure for what the WOK Team might have in alternative.
 */
export interface WOKAlternative {
    text: string;
    status: boolean; // If this is a correct alternative
}

/**
 * Example structure for what the WOK Team might have in questions.
 */
export interface WOKQuestion {
    qtext: string;
    alternatives: WOKAlternative[];
    img_url: string;
}

@Component({
    selector: 'app-wok-question',
    templateUrl: './wok-question.component.html',
    styleUrls: ['./wok-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WokQuestionComponent implements OnInit, OnDestroy {
    data: any[];
    data_questions: WOKQuestion[];
    loading: boolean;
    loading_questions: boolean;
    url: string;
    test_title: string;
    test_desc: string;
    tags_arr: string[];
    picked_data: any[];
    total_number_of_qs_value: number;
    total_number_of_qs_value_step_3: number;
    min_total_number_of_qs_value: number;
    max_total_number_of_qs_value: number;
    disable_slider: boolean;
    max_number_of_picked_tags: number;
    wok_JSON_data_key: string;
    disable_save_btn: boolean;
    test_saved_notice: boolean;
    loading_save: boolean;
    test_published: boolean;
    sliderIsTouched: boolean;
    public destroy = new Subject<boolean>();
    @Input() questionType: QuestionTypes.CHECKBOX | QuestionTypes.RADIO;
    public items: Observable<ResourceItem[]>;
    public searchControl: FormControl = new FormControl();
    public lang: FormControl = new FormControl();

    constructor(
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        protected message: MessageService,
        public resourceBuilder: ResourceBuilderService,
        public languageService: LanguageService,
        protected questionService: QuestionService,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.tags_arr = [];
        this.picked_data = [];
        this.data_questions = [];
        this.total_number_of_qs_value = 20;
        this.min_total_number_of_qs_value = 1;
        this.max_total_number_of_qs_value = 100;
        this.disable_slider = false;
        this.total_number_of_qs_value_step_3 = 0;
        this.max_number_of_picked_tags = 20;
        this.wok_JSON_data_key = 'data';
        this.test_title = null;
        this.test_desc = null;
        this.disable_save_btn = false;
        this.test_saved_notice = false;
        this.test_published = false;
    }

    ngOnInit() {
        combineLatest([
            this.searchControl.valueChanges,
            this.lang.valueChanges.pipe(switchMap((lngid) => this.languageService.getLanguageByKey(lngid))),
        ])
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy),
                switchMap(([search, language]) => {
                    const searchValue = search?.trim();
                    if (searchValue?.length) {
                        this.loading = true;
                        this.ref.markForCheck();
                        this.url = `https://api.wokcraft.com/v2.7/test/nk3?tag_name=${encodeURI(
                            searchValue
                        )}&language=${language.value}`;
                        return this.http.get(this.url);
                    } else {
                        return of({ data: [] });
                    }
                })
            )
            .subscribe((response: { data: [] }) => {
                this.data = response.data;
                this.loading = false;
                this.ref.markForCheck();
            });
    }

    pickTag(tag: any): void {
        const tag_name = tag.innerText;
        if (this.tags_arr.indexOf(tag_name) === -1 && this.tags_arr.length < this.max_number_of_picked_tags) {
            const i = this.picked_data.length;
            this.tags_arr[this.tags_arr.length] = tag_name;
            this.picked_data[i] = this.data[tag.attributes['id'].value];
            this.picked_data[i]['selected_amount'] = 0;
            this.picked_data[i]['full'] = false;
            this.data.splice(tag.attributes['id'].value, 1);
            this.reset_picked_data_var_info();
            this.max_total_number_of_qs_value = 0;
            this.picked_data.forEach((data) => {
                this.updateSliderValues(data.tqt, true);
            });
            this.distribute_number_of_questions(this.total_number_of_qs_value);
        }
    }
    updateSliderValues(tagQs, add) {
        if (add) {
            this.max_total_number_of_qs_value += tagQs;
        } else {
            this.max_total_number_of_qs_value -= tagQs;
        }
        if (this.max_total_number_of_qs_value <= this.total_number_of_qs_value) {
            this.total_number_of_qs_value = this.max_total_number_of_qs_value;
        } else if (!this.sliderIsTouched) {
            this.total_number_of_qs_value = 20;
        }
        if (this.max_total_number_of_qs_value === 0) {
            this.total_number_of_qs_value = 20;
            this.max_total_number_of_qs_value = 100;
        }
    }

    generateQuestions() {
        const tags = { ids: [], limit: 0 };
        this.picked_data.forEach((tag) => {
            tags.ids.push(tag.tid);
            tags.limit += tag.selected_amount;
        });
        if (tags.ids.length && tags.limit) {
            this.loading_questions = true;
            this.url = `https://api.wokcraft.com/v2.7/test/nk3/generate?tid=${tags.ids}&limit=${tags.limit}`;
            this.http.get(this.url).subscribe((data) => {
                this.data_questions = data[this.wok_JSON_data_key];
                this.addQuestions();
                this.ref.detectChanges();
                this.loading_questions = false;
                this.total_number_of_qs_value_step_3 = this.data_questions.length;
            });
        }
    }

    public addQuestions() {
        // Reset search
        this.searchControl.patchValue('');
        this.searchControl.markAsUntouched();
        this.searchControl.markAsPristine();

        // Reset picked tags.
        this.picked_data.length = 0;
        this.resourceBuilder.showWOKGenerator.next(false);
        this.data_questions.forEach((question) => {
            this.resourceBuilder.createItem({
                item_type_value: ResourceItemTypes.QUESTION,
                questionForm: this.questionService.createWOKFormGroup(
                    this.questionType,
                    question.alternatives.length,
                    question.alternatives,
                    question.img_url
                ),
                name: question.qtext,
            });
        });
        this.resourceBuilder.submit(false); // triger save action.
    }

    reprepareQuestions() {
        this.generateQuestions();
        return false;
    }

    remove_tag_from_picked(item: any) {
        this.updateSliderValues(item.tqt, false);
        const index = this.picked_data.indexOf(item);
        this.picked_data.splice(index, 1);
        this.reset_picked_data_var_info();
        this.distribute_number_of_questions(this.total_number_of_qs_value);
        this.data.push(item);
        const tagsArrItem = item.tag_name + ' ' + item.tqt;
        const index2 = this.tags_arr.indexOf(tagsArrItem);
        this.tags_arr.splice(index2, 1);
    }

    remove_question_from_list(item: any) {
        const index = this.data_questions.indexOf(item);
        this.data_questions.splice(index, 1);
        this.total_number_of_qs_value_step_3 = this.data_questions.length;
    }

    total_number_of_qs(event: any) {
        this.sliderIsTouched = true;
        this.total_number_of_qs_value = event.value;
        this.reset_picked_data_var_info();
        this.distribute_number_of_questions(this.total_number_of_qs_value);
    }

    distribute_number_of_questions(total_questions: number): boolean {
        const tags_count = this.count_unfull_tags();

        if (tags_count === 0) {
            return false;
        }
        let each_tag_portion = Math.floor(total_questions / tags_count);
        if (each_tag_portion === 0) {
            each_tag_portion = 1;
            if (this.total_number_of_qs_value === total_questions) {
                this.total_number_of_qs_value = tags_count;
            }
        }

        for (const i in this.picked_data) {
            if (this.picked_data[i]['full'] === false) {
                if (this.picked_data[i]['tqt'] < each_tag_portion + this.picked_data[i]['selected_amount']) {
                    this.picked_data[i]['selected_amount'] = this.picked_data[i]['tqt'];
                    this.picked_data[i]['full'] = true;
                } else {
                    this.picked_data[i]['selected_amount'] += each_tag_portion;
                }

                if (this.sum_picked_questions_from_tags() === this.total_number_of_qs_value) {
                    return false;
                }
            }
        }

        if (this.total_number_of_qs_value > this.sum_picked_questions_from_tags()) {
            this.distribute_number_of_questions(this.total_number_of_qs_value - this.sum_picked_questions_from_tags());
        }

        return false;
    }

    count_unfull_tags(): number {
        let counter = 0;
        for (const i in this.picked_data) {
            if (this.picked_data[i]['full'] === false) {
                counter++;
            }
        }
        return counter;
    }

    sum_picked_questions_from_tags(): number {
        let sum = 0;
        for (const picked_data of this.picked_data) {
            sum += picked_data['selected_amount'];
        }
        return sum;
    }

    reset_picked_data_var_info() {
        for (const i in this.picked_data) {
            if (!this.picked_data[i]['selected_amount_manual']) {
                this.picked_data[i]['selected_amount'] = 0;
                this.picked_data[i]['full'] = false;
            }
        }
    }

    reset_picked_tags() {
        for (const i in this.picked_data) {
            if (this.picked_data.hasOwnProperty(i)) {
                this.picked_data[i]['selected_amount_manual'] = 0;
                this.picked_data[i]['selected_amount'] = 0;
                this.picked_data[i]['full'] = false;
            }
        }

        this.disable_slider = false;
        this.total_number_of_qs_value = 100;
        this.data_questions = [];
        this.total_number_of_qs_value_step_3 = 0;
        this.distribute_number_of_questions(this.total_number_of_qs_value);
    }

    manual_pic_number_of_qs(item: any, event: any): boolean {
        const index = this.picked_data.indexOf(item);
        this.picked_data[index]['selected_amount_manual'] = +event.target.value;
        this.picked_data[index]['selected_amount'] = +event.target.value;
        this.picked_data[index]['full'] = true;
        this.disable_slider = true;
        return false;
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
