<div *ngIf="user$ | async; let user" class="layout res-details-page course-details-wrapper">
    <div fxLayout="column" class="relative">
        <div class="mb-1 bg-wrapper">
            <div class="lay-over"></div>
            <app-image-view
                [paddingBottom]="'450px'"
                [media_id]="(resource.medias?.background?.length && resource.medias?.background[0]) || resource.media$"
                (srcLoaded)="mediaUrl = $event"
            >
            </app-image-view>
        </div>
        <div class="course-content-wrapper" fxLayout="column">
            <div class="pre-wrapper" fxLayout="row" fxLayoutGap="3em">
                <!-- Main img and course items in desktop -->
                <div *ngIf="!(isMobile | async)" class="left-info-wrapper" fxLayout="column">
                    <div class="kd-lib">
                        <ng-container
                            [ngTemplateOutlet]="academyLogo"
                            [ngTemplateOutletContext]="{ resource: resource }"
                        ></ng-container>
                    </div>
                    <div class="main-img">
                        <app-image-view [media_id]="resource.media$" (srcLoaded)="mediaUrl = $event"></app-image-view>
                    </div>

                    <ng-container
                        *ngIf="resourceType !== ResourceTypes.MATERIAL && resourceType !== ResourceTypes.EVENT"
                    >
                        <div *ngIf="resourceItems$ | async; let items" class="res-items mt-1" fxLayout="column">
                            <div fxLayoutGap="7px" fxLayoutAlign="start center" class="items-label">
                                <mat-icon>category</mat-icon>
                                <span
                                    >{{ resourceLabel }} <span i18n="@@include">include</span
                                    ><ng-container
                                        *ngIf="
                                            resourceType === ResourceTypes.SURVEY || resourceType === ResourceTypes.TEST
                                        "
                                    >
                                        {{ items.length }}
                                        <span i18n="@@questions">Questions</span>
                                    </ng-container>
                                </span>
                            </div>
                            <ng-container
                                *ngIf="
                                    resourceType === ResourceTypes.COURSE ||
                                    resourceType === ResourceTypes.E_COURSE ||
                                    resourceType === ResourceTypes.SCORM
                                "
                            >
                                <div
                                    (click)="
                                        resource.opportunity_id
                                            ? takeResource(resource, user)
                                            : startCoursePopUp(resource, user, item)
                                    "
                                    class="res-bull mt-1"
                                    *ngFor="let item of items; let index = index"
                                >
                                    <ng-container *ngIf="resourceType !== ResourceTypes.COURSE">
                                        <span class="index" [class.taken-task]="!!item.child_opportunity_done">{{
                                            index + 1
                                        }}</span>
                                        <span
                                            class="res-item-name"
                                            [class.ecourse]="
                                                resourceType === ResourceTypes.E_COURSE ||
                                                resourceType === ResourceTypes.SCORM
                                            "
                                            [class.parent]="!item.parent_id"
                                            >{{ item.name }}</span
                                        >
                                    </ng-container>
                                    <div class="course-items-bull" *ngIf="resourceType === ResourceTypes.COURSE">
                                        <span class="index">{{ index + 1 }}</span>

                                        <app-image-view
                                            [smallSpinner]="true"
                                            [media_id]="item.media$"
                                            [proportional]="true"
                                        ></app-image-view>
                                        <span class="res-item-name">{{ item.name }}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="resourceType === ResourceTypes.EVENT && resource.agenda">
                        <div class="res-items mt-1" fxLayout="column">
                            <div fxLayoutGap="7px" fxLayoutAlign="start center" class="items-label">
                                <mat-icon>ballot</mat-icon>
                                <span i18n>Agenda</span>
                            </div>

                            <div class="res-bull mt-1">
                                <div [innerHtml]="styleInnerHTML(resource.agenda)"></div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="right-info-wrapper">
                    <div class="right-top-wrapper" fxLayoutAlign="space-between start" fxLayout="column">
                        <!-- lang, date, ...... in desktop -->
                        <div *ngIf="!(isMobile | async)" class="play-btn" fxLayoutGap="5px" fxLayout="column">
                            <button (click)="takeResource(resource, user)" class="take" mat-raised-button>
                                <mat-icon>play_circle</mat-icon>
                                <ng-container
                                    [ngTemplateOutlet]="actionBtn"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                ></ng-container>
                            </button>
                            <div class="small-info flag item-lang-main mt-1">
                                <mat-icon>flag</mat-icon>
                                <div class="dates-wrapper">
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n="@@Language">Language </span>
                                    </span>
                                    <app-language-selector
                                        [language_id]="resource.language_id"
                                        class="icon-resource-builder"
                                        fxFlex="0 0 auto"
                                    ></app-language-selector>
                                </div>
                            </div>
                            <div *ngIf="resource.public" class="timer-off small-info">
                                <mat-icon>public</mat-icon>
                                <span>
                                    {{ resourceLabel }}
                                    <span i18n="@@isPublic"> is public </span>
                                </span>
                            </div>
                            <div class="timer-off small-info">
                                <mat-icon>publish</mat-icon>
                                <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                    <span i18n>Published by</span>
                                    <ng-container
                                        [ngTemplateOutlet]="publishedBy"
                                        [ngTemplateOutletContext]="{ resource: resource }"
                                    ></ng-container>
                                </span>
                            </div>
                            <div *ngIf="resourceType === ResourceTypes.EVENT" class="timer-off small-info">
                                <mat-icon>diversity_2</mat-icon>
                                <span> {{ ResourceTypes.eventType(resourceType) }} </span>
                            </div>
                            <div
                                *ngIf="resourceType === ResourceTypes.COURSE || resourceType === ResourceTypes.EVENT"
                                class="timer-off small-info"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="resAvailability"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                ></ng-container>
                            </div>
                        </div>
                        <div class="title-des-wrapper" [ngClass]="{ 'w-70': !resource.description }">
                            <div class="mobile-top-part">
                                <div class="kol-title-wrapper">
                                    <div *ngIf="(isMobile | async) && resource.public" class="kd-lib">
                                        <img src="assets/images/KD_KOL_logo_long.png" alt="KlickData" />
                                    </div>
                                    <h2>
                                        {{ resource.title }}
                                    </h2>
                                </div>

                                <div class="main-img mobile-main-img" *ngIf="isMobile | async">
                                    <app-image-view
                                        [media_id]="resource.media$"
                                        (srcLoaded)="mediaUrl = $event"
                                    ></app-image-view>
                                </div>
                            </div>
                            <span *ngIf="resource.bullets" class="desc-res">{{ resource.bullets }}</span>
                            <!-- <div *ngIf="resourceType === ResourceTypes.EVENT">Tutor</div> -->
                            <!-- lang, date, ...... in mobile -->
                            <div *ngIf="isMobile | async" class="mobile-tools">
                                <div class="play-btn-wrapper-mob"></div>
                                <div class="down-arrow-wrapper-mob">
                                    <button (click)="togglePanel(); hideImg = !hideImg" class="expand" mat-icon-button>
                                        <span i18n="@@info">Info</span>
                                        <mat-icon [ngClass]="{ 'rotate-expand-icon': hideImg }">expand_more</mat-icon>
                                    </button>
                                    <button (click)="takeResource(resource, user)" class="take" mat-raised-button>
                                        <mat-icon>play_circle</mat-icon>
                                        <ng-container
                                            [ngTemplateOutlet]="actionBtn"
                                            [ngTemplateOutletContext]="{ resource: resource }"
                                        ></ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="right-middle-wrapper mb-2"
                        [fxLayoutGap]="(isMobile | async) ? '1px' : '3em'"
                        [fxLayout]="(isMobile | async) ? 'column' : 'row'"
                    >
                        <div
                            *ngIf="isMobile | async"
                            fxLayout="row"
                            class="mobile-course-extraInfo-wrapper"
                            fxLayoutAlign="space-between center"
                        >
                            <div class="mobile-course-extraInfo">
                                <mat-accordion displayMode="flat">
                                    <mat-expansion-panel #specsExpansionPanel>
                                        <mat-expansion-panel-header>
                                            <mat-icon>announcement</mat-icon>
                                            <ng-container>
                                                {{ resourceLabel }}
                                                <span i18n="@@specifications">specifications</span>
                                            </ng-container>
                                        </mat-expansion-panel-header>
                                        <!-- Language -->
                                        <div class="res-details desc-elem mob-lang-wrapper">
                                            <div class="pre">
                                                <mat-icon>flag</mat-icon>
                                                <span *ngIf="resourceType === ResourceTypes.COURSE">
                                                    <span i18n="@@courseLanguage">Course Language</span>
                                                </span>
                                                <span *ngIf="resourceType !== ResourceTypes.COURSE">
                                                    {{ resourceLabel }}
                                                    <span i18n="@@Language">Language </span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                <app-language-selector
                                                    [language_id]="resource.language_id"
                                                    class="icon-resource-builder"
                                                    fxFlex="0 0 auto"
                                                ></app-language-selector>
                                            </span>
                                        </div>

                                        <!-- Duration -->
                                        <div *ngIf="resource.duration" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>timelapse</mat-icon>
                                                <span *ngIf="resourceType === ResourceTypes.COURSE">
                                                    <span i18n="@@courseDuration">Course duration</span>
                                                </span>
                                                <span *ngIf="resourceType !== ResourceTypes.COURSE">
                                                    {{ resourceLabel }}
                                                    <span i18n="@@duration">duration</span>
                                                </span>
                                            </div>
                                            <span class="normal"
                                                >{{
                                                    resource?.duration
                                                        | formatIsoDuration
                                                            : resourceType === ResourceTypes.E_COURSE ||
                                                                  resourceType === ResourceTypes.SCORM
                                                }}
                                            </span>
                                        </div>
                                        <!-- Play time -->
                                        <div *ngIf="resource.time_limit" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>timer</mat-icon>
                                                <span i18n="@@playTime">Play time </span>
                                            </div>
                                            <span class="normal">{{ resource?.time_limit | formatIsoDuration }} </span>
                                        </div>
                                        <!-- Instruuctor refactor needed -->
                                        <!-- Creator -->
                                        <!-- <div *ngIf="resource.instructor?.name" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>engineering</mat-icon>
                                                <span i18n="@@instructor">Instructor </span>
                                            </div>
                                            <span class="normal">
                                                {{ resource.instructor?.name }}
                                            </span>
                                        </div> -->
                                        <!-- Course code -->
                                        <div *ngIf="resource.article_code" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>center_focus_strong</mat-icon>
                                                <span *ngIf="resourceType === ResourceTypes.COURSE">
                                                    <span i18n="@@courseCode">Course code</span>
                                                </span>
                                                <span *ngIf="resourceType === ResourceTypes.TEST">
                                                    <span i18n="@@testCode">Test code</span>
                                                </span>
                                                <span *ngIf="resourceType === ResourceTypes.SURVEY">
                                                    <span i18n="@@surveyCode">Survey code</span>
                                                </span>
                                                <span *ngIf="resourceType === ResourceTypes.E_COURSE">
                                                    <span i18n="@@ecourseCode">E-course code</span>
                                                </span>
                                                <span *ngIf="resourceType === ResourceTypes.SCORM">
                                                    <span i18n="@@scormCode">SCORM code</span>
                                                </span>
                                                <span *ngIf="resourceType === ResourceTypes.MATERIAL">
                                                    <span i18n="@@materialCode">Material code</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ resource.article_code }}
                                            </span>
                                        </div>

                                        <!-- Material type -->
                                        <div
                                            *ngIf="resourceType === ResourceTypes.MATERIAL"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>save</mat-icon>
                                                <span i18n="@@materialType">Material type </span>
                                            </div>

                                            <span class="normal">
                                                {{ resourceType | materialTypeLabel }}
                                            </span>
                                        </div>
                                        <!-- Price -->
                                        <div
                                            *ngIf="resource.price !== 0 && resource.price"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>local_offer</mat-icon>
                                                <span i18n="@@price">Price </span>
                                            </div>

                                            <span class="normal">
                                                {{ resource.price }}&nbsp;{{ resource.currency }}
                                            </span>
                                        </div>
                                        <!-- Total seats -->
                                        <div *ngIf="resource.total_seats" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>event_seat</mat-icon>
                                                <span>
                                                    {{ resourceLabel }}
                                                    <span i18n="@@seats">seats</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ resource.total_seats }}
                                            </span>
                                        </div>
                                        <!-- Extra seats -->
                                        <div *ngIf="resource.extra_seats" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>weekend</mat-icon>
                                                <span>
                                                    {{ resourceLabel }}
                                                    <span i18n="@@extraSeats">extra seats</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ resource.extra_seats }}
                                            </span>
                                        </div>
                                        <!-- Booking -->
                                        <div *ngIf="resource.booking_needed" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>confirmation_number</mat-icon>
                                                <span i18n>Booking needed </span>
                                            </div>
                                            <span *ngIf="resource.booking_needed" class="normal" i18n="@@yes">yes</span>
                                        </div>
                                        <!-- Cats -->
                                        <div
                                            *ngIf="resourceCategories$ | async as cats"
                                            fxLayoutAlign="center start"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>apps</mat-icon>
                                            </div>
                                            <mat-chip-list class="mt-1">
                                                <mat-chip
                                                    class="cat-chip-item ml-0"
                                                    *ngFor="let cat of cats"
                                                    (click)="showCatgeoryResources(cat)"
                                                    >{{ cat.title }}</mat-chip
                                                >
                                            </mat-chip-list>
                                        </div>

                                        <!-- Tags -->
                                        <div
                                            *ngIf="resource.tags_attached"
                                            fxLayoutAlign="center start"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>label</mat-icon>
                                            </div>
                                            <mat-chip-list class="mt-1">
                                                <mat-chip
                                                    *ngFor="let tag of resourceTags$ | async"
                                                    (click)="showTagResources(tag)"
                                                    class="tag-chip-item"
                                                >
                                                    <span>{{ tag.name }}</span>
                                                    <app-language-selector
                                                        class="flag tag-chip-lang-select"
                                                        fxFlex="0 0 auto"
                                                        [language_id]="tag.language_id"
                                                    >
                                                    </app-language-selector>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                        <!-- End reward -->
                                        <div *ngIf="resource.examination?.label" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>military_tech</mat-icon>
                                                <span i18n="@@endReward">End reward </span>
                                            </div>
                                            <span class="middle-details">
                                                {{ resource.examination?.label }}
                                            </span>
                                        </div>
                                        <!-- Creator -->
                                        <div class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>account_circle</mat-icon>
                                                <span i18n>Created by </span>
                                            </div>
                                            <span class="middle-details">
                                                {{ resource.author_name || $any(resource.author$ | async)?.name }}
                                            </span>
                                        </div>

                                        <!-- Screenshot img -->
                                        <div
                                            class="res-details desc-elem"
                                            *ngIf="
                                                resource.medias?.screenshot?.length > 0 &&
                                                resource.medias?.screenshot[0]
                                            "
                                        >
                                            <div class="pre">
                                                <mat-icon>center_focus_strong</mat-icon>
                                                <span i18n="@@screenshotImage">Screenshot image </span>
                                            </div>
                                            <div class="middle-details-img">
                                                <div class="screenshoot-img-wrapper first-screenshot">
                                                    <app-image-view
                                                        [media_id]="resource.medias.screenshot[0]"
                                                        [paddingBottom]="'200px'"
                                                    >
                                                    </app-image-view>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                        <!-- First line after BG on mobie -->
                        <div
                            *ngIf="isMobile | async"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                            class="mobile-right-middle m-0"
                        >
                            <!-- Instruuctor refactor needed -->
                            <!-- <div
                                *ngIf="!resource.instructor?.name"
                                fxLayoutGap="4px"
                                fxLayout="column"
                                fxLayoutAlign="start start"
                                class="creator-inst-wrapper-mobile ml-10"
                                [ngClass]="{ authorNameExistLetters: showAuthorName }"
                            >
                                <div fxLayoutAlign="center start" fxLayout="column">
                                    <div
                                        *ngIf="!hideImg"
                                        fxLayoutAlign="center center"
                                        fxLayout="column"
                                        class="user-img-wrapper"
                                    >
                                        <button
                                            class="toggle-author-name"
                                            (click)="showAuthorName = !showAuthorName"
                                            mat-icon-button
                                        >
                                            <mat-icon [ngClass]="{ 'rotate-expand-icon': showAuthorName }"
                                                >expand_more</mat-icon
                                            >
                                        </button>
                                        <div class="user-img">
                                            {{
                                                resource.author_name || $any(resource.author$ | async)?.name | nameSign
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="{ 'fix-layout': hideImg }"
                                        fxLayoutAlign="center start"
                                        fxLayout="column"
                                        class="inst-name-wrapper"
                                    >
                                        <div
                                            *ngIf="showAuthorName"
                                            class="instructor"
                                            fxLayoutGap="5px"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon>account_circle</mat-icon>
                                            <span i18n="@@author">Author</span>
                                        </div>
                                        <div *ngIf="showAuthorName" class="instructor-name">
                                            {{ resource.author_name || $any(resource.author$ | async)?.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="resource.instructor?.name"
                                fxLayoutGap="4px"
                                fxLayout="column"
                                fxLayoutAlign="start start"
                                class="creator-inst-wrapper-mobile ml-10"
                                [ngClass]="{ authorNameExist: showAuthorName }"
                            >
                                <div fxLayoutAlign="center start" fxLayout="column">
                                    <div *ngIf="!hideImg" fxLayoutAlign="center center" fxLayout="column">
                                        <div fxLayout="row" class="user-img">
                                            <ng-container *ngIf="resource.instructor?.images?.length > 0">
                                                <div
                                                    *ngFor="let imgId of resource.instructor?.images"
                                                    class="img-wrapper instructor-img"
                                                >
                                                    <app-image-view
                                                        [scope]="AppScope.USERS"
                                                        [media_id]="imgId"
                                                        [paddingBottom]="'52px'"
                                                    >
                                                    </app-image-view>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!resource.instructor?.images?.length">
                                                <div class="user-img mob-img-auth">
                                                    {{ resource.instructor?.name | nameSign }}
                                                </div>
                                            </ng-container>

                                            <button
                                                class="toggle-author-name"
                                                (click)="showAuthorName = !showAuthorName"
                                                mat-icon-button
                                            >
                                                <mat-icon [ngClass]="{ 'rotate-expand-icon': showAuthorName }"
                                                    >expand_more</mat-icon
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="{ 'fix-layout': hideImg }"
                                        fxLayoutAlign="center start"
                                        fxLayout="column"
                                        class="inst-name-wrapper"
                                    >
                                        <div
                                            *ngIf="showAuthorName"
                                            class="instructor"
                                            fxLayoutGap="5px"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon>engineering</mat-icon>
                                            <span i18n="@@instructor">Instructor</span>
                                        </div>
                                        <div *ngIf="showAuthorName" class="instructor-name">
                                            <span *ngIf="resource.instructor?.name">{{
                                                resource.instructor?.name
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div *ngIf="isMobile | async" fxLayoutAlign="center center" class="social-icons m-0">
                                <button
                                    class="grey"
                                    matTooltip="Add to favorite"
                                    i18n-matTooltip="@@addToFavorite"
                                    (click)="favoriteResource(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ resource.favorite ? 'star' : 'star_border' }}</mat-icon>
                                </button>
                                <button
                                    class="grey"
                                    i18n-matTooltip="@@like"
                                    matTooltip="Like"
                                    (click)="likeResource(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ resource.like ? 'favorite' : 'favorite_border' }}</mat-icon>
                                </button>
                                <button
                                    *ngIf="resource.downloads"
                                    class="grey"
                                    matTooltip="Download"
                                    i18n-matTooltip="@@download"
                                    (click)="download(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>save_alt</mat-icon>
                                </button>

                                <!-- Share btns here -->
                                <ng-container
                                    *ngIf="resource.public && resource.published"
                                    [ngTemplateOutlet]="shareTemp"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                ></ng-container>
                                <ng-container
                                    *ngIf="resource.published"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                    [ngTemplateOutlet]="inviteBtn"
                                ></ng-container>
                                <button
                                    *ngIf="
                                        user.role_value !== 'user' &&
                                        user.role_value !== 'guest' &&
                                        user.canAssign(resource)
                                    "
                                    matTooltip="Assign"
                                    i18n-matTooltip="@@assign"
                                    (click)="assign(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                    class="green"
                                >
                                    <mat-icon>assignment_ind</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="
                                        (resourceType === ResourceTypes.E_COURSE && user.role_value === 'superadmin') ||
                                        resourceType !== ResourceTypes.E_COURSE
                                    "
                                >
                                    <div fxFlex="0 0 auto" *ngIf="user.canEdit(resource)">
                                        <button
                                            i18n-matTooltip="@@edit"
                                            (click)="editResource(resource, user.isAdmin())"
                                            matTooltip="Edit"
                                            class="green"
                                            mat-icon-button
                                        >
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <mat-divider *ngIf="isMobile | async"></mat-divider>
                        <!-- Course items on mobile -->
                        <ng-container *ngIf="resourceType !== ResourceTypes.MATERIAL">
                            <div *ngIf="isMobile | async" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="mobile-course-include" *ngIf="resourceItems$ | async; let items">
                                    <div
                                        class="item-include-mob"
                                        *ngIf="
                                            resourceType === ResourceTypes.SURVEY || resourceType === ResourceTypes.TEST
                                        "
                                    >
                                        <mat-icon>category</mat-icon>
                                        <ng-container>
                                            {{ resourceLabel }}
                                            <span style="margin-left: 3px" i18n="@@include">include</span>
                                        </ng-container>
                                        &nbsp;{{ items.length }}&nbsp;
                                        <span i18n="@@questions">Questions</span>
                                    </div>
                                    <mat-accordion
                                        *ngIf="
                                            resourceType === ResourceTypes.COURSE ||
                                            resourceType === ResourceTypes.E_COURSE ||
                                            resourceType === ResourceTypes.SCORM
                                        "
                                        displayMode="flat"
                                    >
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-icon>category</mat-icon>
                                                <ng-container>
                                                    {{ resourceLabel }}
                                                    <span style="margin-right: 3px" i18n="@@include">include</span>
                                                </ng-container>
                                                &nbsp;({{ items.length }})
                                            </mat-expansion-panel-header>
                                            <div
                                                (click)="startCoursePopUp(resource, user)"
                                                class="res-bull mt-1"
                                                *ngFor="let item of items; let index = index"
                                            >
                                                <ng-container
                                                    *ngIf="
                                                        resourceType === ResourceTypes.E_COURSE ||
                                                        resourceType === ResourceTypes.SCORM
                                                    "
                                                >
                                                    <span
                                                        class="index"
                                                        [class.taken-task]="!!item.child_opportunity_done"
                                                        >{{ index + 1 }}</span
                                                    >
                                                    <span
                                                        class="res-item-name ecourse"
                                                        [class.parent]="!item.parent_id"
                                                        >{{ item.name }}</span
                                                    >
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        resourceType !== ResourceTypes.E_COURSE &&
                                                        resourceType !== ResourceTypes.SCORM
                                                    "
                                                >
                                                    <span class="index">{{ index + 1 }}</span>
                                                    <mat-icon
                                                        fontSet="klickdata"
                                                        [fontIcon]="displayCorrectIcon(item.item_type_value)"
                                                        class="kd-icon"
                                                    ></mat-icon>
                                                    <span class="res-item-name">{{ item.name }}</span>
                                                </ng-container>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                            <mat-divider *ngIf="isMobile | async"></mat-divider>
                        </ng-container>
                        <!-- Course main info on mobile -->
                        <div *ngIf="isMobile | async" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="mobile-course-mainInfo" *ngIf="resourceItems$ | async; let items">
                                <div class="mobile-course-info-details">
                                    <div *ngIf="resource.description">
                                        <div class="pre">
                                            <mat-icon>description</mat-icon>
                                            <span>
                                                {{ resourceLabel }}
                                                <span i18n="@@description">description</span>
                                            </span>
                                        </div>
                                        <span
                                            class="middle-details desc"
                                            [innerHtml]="styleInnerHTML(resource.description)"
                                        >
                                        </span>
                                    </div>
                                    <!-- Goals -->
                                    <div *ngIf="resource.goal">
                                        <div class="pre">
                                            <mat-icon>task_alt</mat-icon>
                                            <span>
                                                {{ resourceLabel }}
                                                <span i18n="@@goal">goal</span>
                                            </span>
                                        </div>
                                        <span class="middle-details">
                                            {{ resource.goal }}
                                        </span>
                                    </div>
                                    <!-- Other information -->
                                    <div *ngIf="resource.other_info">
                                        <div class="pre">
                                            <mat-icon>info</mat-icon>
                                            <span i18n="@@otherInformation">Other information </span>
                                        </div>
                                        <span class="middle-details">
                                            {{ resource.other_info }}
                                        </span>
                                    </div>

                                    <!-- Instr -->
                                    <div *ngIf="resource.instructions && resourceType !== ResourceTypes.MATERIAL">
                                        <div class="pre">
                                            <mat-icon>subject</mat-icon>
                                            <span>
                                                {{ resourceLabel }}
                                                <span i18n="@@instruction">instruction</span>
                                            </span>
                                        </div>
                                        <span class="middle-details">
                                            {{ resource.instructions }}
                                        </span>
                                    </div>
                                    <div *ngIf="resource.instructions && resourceType === ResourceTypes.MATERIAL">
                                        <div class="pre">
                                            <mat-icon>subject</mat-icon>
                                            <span>
                                                <span i18n>Content information</span>
                                            </span>
                                        </div>
                                        <span
                                            class="middle-details"
                                            [innerHtml]="styleInnerHTML(resource.instructions)"
                                        >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!(isMobile | async)" class="other-info" fxLayoutGap="2em" fxLayout="column">
                            <!-- Summary -->
                            <div *ngIf="resource.description">
                                <div class="pre">
                                    <mat-icon>description</mat-icon>
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n="@@description">description</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(resource.description)"> </span>
                            </div>

                            <!-- Goals -->
                            <div *ngIf="resource.goal">
                                <div class="pre">
                                    <mat-icon>task_alt</mat-icon>
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n="@@goal">goal</span>
                                    </span>
                                </div>
                                <span class="middle-details">
                                    {{ resource.goal }}
                                </span>
                            </div>
                            <!-- Audience -->
                            <div *ngIf="resource.audience">
                                <div class="pre">
                                    <mat-icon>ads_click</mat-icon>
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n>audience</span>
                                    </span>
                                </div>
                                <span class="middle-details">
                                    {{ resource.audience }}
                                </span>
                            </div>

                            <!-- episode -->
                            <div *ngIf="resource.episode">
                                <div class="pre">
                                    <mat-icon>ads_click</mat-icon>
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n>occasions</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(resource.description)"></span>
                            </div>

                            <!-- Other info -->
                            <div *ngIf="resource.other_info">
                                <div class="pre">
                                    <mat-icon>info</mat-icon>
                                    <span i18n="@@otherInformation">Other information </span>
                                </div>
                                <span class="middle-details">
                                    {{ resource.other_info }}
                                </span>
                            </div>
                            <!-- Instr -->
                            <div *ngIf="resource.instructions && resourceType !== ResourceTypes.MATERIAL">
                                <div class="pre">
                                    <mat-icon>subject</mat-icon>
                                    <span>
                                        {{ resourceLabel }}
                                        <span i18n="@@instruction">instruction</span>
                                    </span>
                                </div>
                                <span class="middle-details">
                                    {{ resource.instructions }}
                                </span>
                            </div>
                            <!-- Material Instr -->
                            <div *ngIf="resource.instructions && resourceType === ResourceTypes.MATERIAL">
                                <div class="pre">
                                    <mat-icon>subject</mat-icon>
                                    <span>
                                        <span i18n>Content information</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(resource.instructions)">
                                </span>
                            </div>

                            <!-- Screenshot img -->
                            <div *ngIf="resource.medias?.screenshot?.length > 0 && resource.medias?.screenshot[0]">
                                <div class="pre">
                                    <mat-icon>center_focus_strong</mat-icon>
                                    <span i18n="@@screenshotImage">Screenshot image </span>
                                </div>
                                <div class="middle-details-img">
                                    <div class="screenshoot-img-wrapper">
                                        <app-image-view
                                            (click)="showImgModal(resource.medias?.screenshot[0])"
                                            [media_id]="resource.medias?.screenshot[0]"
                                            [proportional]="false"
                                        >
                                        </app-image-view>
                                    </div>
                                </div>
                            </div>
                            <!-- Quotes -->
                            <div *ngIf="showQuotesItems">
                                <div class="pre">
                                    <mat-icon>format_quote</mat-icon>
                                    <span i18n>What learners say? </span>
                                </div>
                                <div id="sliderWrapper" class="quotes-slider-wrapper">
                                    <owl-carousel-o class="cd-carousel" [options]="customOptions">
                                        <ng-container *ngFor="let quote of quotes">
                                            <ng-template
                                                carouselSlide
                                                [id]="quote.id.toString()"
                                                [width]="scrolledItemWidth"
                                            >
                                                <app-image-view
                                                    class="quote-img"
                                                    [ngStyle]="{ minWidth: '80px' }"
                                                    [isCircularImg]="true"
                                                    [media_id]="user.media"
                                                    paddingBottom="72px"
                                                ></app-image-view>
                                                <div
                                                    class="quote-wrapper"
                                                    fxLayout="column"
                                                    fxLayoutAlign="center center"
                                                >
                                                    <span class="name">{{ quote.authorName }}</span>
                                                    <mat-icon>format_quote</mat-icon>
                                                    <div>{{ quote.body }}</div>
                                                    <p class="date">Feb 20, 2023</p>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!(isMobile | async)" class="extra-info">
                            <ng-container
                                [ngTemplateOutlet]="instructor"
                                [ngTemplateOutletContext]="{ resource: resource }"
                            ></ng-container>
                            <div fxLayoutAlign="center center" class="social-icons">
                                <button
                                    class="grey"
                                    matTooltip="Add to favorite"
                                    i18n-matTooltip="@@addToFavorite"
                                    (click)="favoriteResource(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ resource.favorite ? 'star' : 'star_border' }}</mat-icon>
                                </button>
                                <button
                                    class="grey"
                                    matTooltip="Like"
                                    i18n-matTooltip="@@like"
                                    (click)="likeResource(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ resource.like ? 'favorite' : 'favorite_border' }}</mat-icon>
                                </button>
                                <!-- Share btns here -->
                                <ng-container
                                    *ngIf="resource.public && resource.published"
                                    [ngTemplateOutlet]="shareTemp"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                ></ng-container>
                                <button
                                    *ngIf="resource.downloads"
                                    class="grey"
                                    matTooltip="Download"
                                    i18n-matTooltip="@@download"
                                    (click)="download(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>save_alt</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="resource.published"
                                    [ngTemplateOutletContext]="{ resource: resource }"
                                    [ngTemplateOutlet]="inviteBtn"
                                ></ng-container>
                                <button
                                    *ngIf="
                                        user.role_value !== 'user' &&
                                        user.role_value !== 'guest' &&
                                        user.canAssign(resource)
                                    "
                                    matTooltip="Assign"
                                    i18n-matTooltip="@@assign"
                                    (click)="assign(resource)"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                    class="green"
                                >
                                    <mat-icon>assignment_ind</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="
                                        (resourceType === ResourceTypes.E_COURSE && user.role_value === 'superadmin') ||
                                        resourceType !== ResourceTypes.E_COURSE
                                    "
                                >
                                    <div fxFlex="0 0 auto" *ngIf="user.canEdit(resource)">
                                        <button
                                            (click)="editResource(resource, user.isAdmin())"
                                            matTooltip="Edit"
                                            i18n-matTooltip="edit"
                                            class="green"
                                            mat-icon-button
                                        >
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="others">
                                <!-- Duration -->
                                <div *ngIf="resource.duration" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>timelapse</mat-icon>
                                        <span *ngIf="resourceType === ResourceTypes.COURSE">
                                            <span i18n="@@courseDuration">Course duration</span>
                                        </span>
                                        <span *ngIf="resourceType !== ResourceTypes.COURSE">
                                            {{ resourceLabel }}
                                            <span i18n="@@duration">duration</span>
                                        </span>
                                    </div>
                                    <span class="normal"
                                        >{{
                                            resource?.duration
                                                | formatIsoDuration
                                                    : resourceType === ResourceTypes.E_COURSE ||
                                                          resourceType === ResourceTypes.SCORM
                                        }}
                                    </span>
                                </div>
                                <!-- Material type -->
                                <div *ngIf="resourceType === ResourceTypes.MATERIAL" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>save</mat-icon>
                                        <span i18n="@@materialType">Material type </span>
                                    </div>
                                    <span class="normal">{{ resourceType | materialTypeLabel }} </span>
                                </div>
                                <!-- Play time -->
                                <div *ngIf="resource.time_limit" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>timer</mat-icon>
                                        <span i18n="@@playTime">Play time </span>
                                    </div>
                                    <span class="normal">{{ resource?.time_limit | formatIsoDuration }} </span>
                                </div>

                                <!-- End reward -->
                                <div *ngIf="resource.examination" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>military_tech</mat-icon>
                                        <span i18n="@@endReward">End reward </span>
                                    </div>
                                    <span class="normal">{{ resource.examination?.label }} </span>
                                </div>

                                <!-- Author -->
                                <div class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>account_circle</mat-icon>
                                        <span i18n>Created by </span>
                                    </div>
                                    <span class="normal"
                                        >{{ resource.author_name || $any(resource.author$ | async)?.name }}
                                    </span>
                                </div>

                                <!-- Tags -->
                                <div *ngIf="resource.tags_attached" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>label</mat-icon>
                                        <span i18n="@@tags">Tags </span>
                                    </div>
                                    <span class="normal">
                                        <mat-chip-list class="mt-1">
                                            <mat-chip
                                                *ngFor="let tag of resourceTags$ | async"
                                                (click)="showTagResources(tag)"
                                                class="tag-chip-item"
                                                ><span>{{ tag.name }}</span>
                                                <app-language-selector
                                                    class="flag tag-chip-lang-select"
                                                    fxFlex="0 0 auto"
                                                    [language_id]="tag.language_id"
                                                >
                                                </app-language-selector
                                            ></mat-chip>
                                        </mat-chip-list>
                                    </span>
                                </div>

                                <!-- Cats -->
                                <div *ngIf="resourceCategories$ | async as cats" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>apps</mat-icon>
                                        <span i18n="@@categories">Categories </span>
                                    </div>
                                    <span class="normal">
                                        <mat-chip-list class="mt-1">
                                            <mat-chip
                                                class="cat-chip-item ml-0"
                                                *ngFor="let cat of cats"
                                                (click)="showCatgeoryResources(cat)"
                                                >{{ cat.title }}</mat-chip
                                            >
                                        </mat-chip-list>
                                    </span>
                                </div>

                                <!-- Educators -->
                                <div
                                    *ngIf="resource.staff?.educator && resource.staff?.educator?.length"
                                    class="res-details desc-elem"
                                >
                                    <div class="pre">
                                        <mat-icon>engineering</mat-icon>
                                        <span i18n="@@educators">Educators</span>
                                    </div>
                                    <span class="normal">
                                        <ng-container
                                            [ngTemplateOutlet]="educators"
                                            [ngTemplateOutletContext]="{ resource: resource }"
                                        ></ng-container>
                                    </span>
                                </div>
                                <!-- Course code -->
                                <div *ngIf="resource.article_code" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>center_focus_strong</mat-icon>

                                        <span *ngIf="resourceType === ResourceTypes.COURSE">
                                            <span i18n="@@courseCode">Course code</span>
                                        </span>
                                        <span *ngIf="resourceType === ResourceTypes.TEST">
                                            <span i18n="@@testCode">Test code</span>
                                        </span>
                                        <span *ngIf="resourceType === ResourceTypes.SURVEY">
                                            <span i18n="@@surveyCode">Survey code</span>
                                        </span>
                                        <span *ngIf="resourceType === ResourceTypes.E_COURSE">
                                            <span i18n="@@ecourseCode">E-course code</span>
                                        </span>
                                        <span *ngIf="resourceType === ResourceTypes.SCORM">
                                            <span i18n="@@scormCode">SCORM code</span>
                                        </span>
                                        <span *ngIf="resourceType === ResourceTypes.MATERIAL">
                                            <span i18n="@@materialCode">Material code</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ resource.article_code }}
                                    </span>
                                </div>

                                <!-- Course Quote -->
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>format_quote</mat-icon>
                                        <span i18n="@@quotes">Quotes</span>
                                    </div>
                                    <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="normal">
                                        <mat-slide-toggle [(ngModel)]="showQuotesItems"></mat-slide-toggle>
                                        <button class="quotes-show" mat-icon-button (click)="showQuotes()">
                                            <mat-icon>view_list</mat-icon>
                                        </button>
                                    </span>
                                </div>

                                <!-- Price -->
                                <div *ngIf="resource.price !== 0 && resource.price" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>local_offer</mat-icon>
                                        <span i18n="@@price">Price </span>
                                    </div>
                                    <span class="normal"> {{ resource.price }}&nbsp;{{ resource.currency }} </span>
                                </div>
                                <!-- Total seats -->
                                <div *ngIf="resource.total_seats" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>event_seat</mat-icon>
                                        <span>
                                            {{ resourceLabel }}
                                            <span i18n="@@seats">seats</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ resource.total_seats }}
                                    </span>
                                </div>
                                <!-- Extra seats -->
                                <div *ngIf="resource.extra_seats" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>weekend</mat-icon>
                                        <span>
                                            {{ resourceLabel }}
                                            <span i18n="@@extraDuration">extra seats</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ resource.extra_seats }}
                                    </span>
                                </div>
                                <!-- Booking -->
                                <div *ngIf="resource.booking_needed" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>confirmation_number</mat-icon>
                                        <span i18n="@@bookingNeeded">Booking needed </span>
                                    </div>
                                    <span *ngIf="resource.booking_needed" class="normal" i18n="@@yes">yes</span>
                                    <span *ngIf="!resource.booking_needed" class="normal" i18n="@@no">No</span>
                                </div>

                                <div
                                    *ngIf="resource.practice_document"
                                    fxLayout="row"
                                    fxLayoutAlign="space-between center"
                                    class="res-details desc-elem"
                                >
                                    <div class="pre">
                                        <mat-icon>topic</mat-icon>
                                        <span i18n>Exercise Documents </span>
                                    </div>
                                    <button
                                        class="down-exercise"
                                        matTooltip="Download"
                                        i18n-matTooltip="@@download"
                                        (click)="downloadPractise()"
                                        mat-icon-button
                                        fxFlex="0 0 auto"
                                    >
                                        <mat-icon>cloud_download</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-footer fxFlex="1 1 auto"></app-footer>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
</div>

<ng-template #small_loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>

<ng-template #shareTemp let-resource="resource">
    <button
        *ngIf="!(shareLoading | async); else shareLoadTemp"
        class="grey"
        fxFlex="0 0 auto"
        matTooltip="Share"
        i18n-matTooltip="@@share"
        mat-icon-button
        (click)="shareService.openShareDialog(resource, shareLoading)"
    >
        <mat-icon>share</mat-icon>
    </button>
</ng-template>
<ng-template #inviteBtn let-resource="resource">
    <button
        class="green"
        matTooltip="Invite a friend"
        i18n-matTooltip
        (click)="invite(resource)"
        mat-icon-button
        fxFlex="0 0 auto"
    >
        <mat-icon>outgoing_mail</mat-icon>
    </button>
</ng-template>

<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>

<ng-template #educators let-resource="resource">
    <div class="educators-wrap-small" *ngIf="$any(resource.educators$ | async); let educators">
        <ng-container *ngIf="educators.length === 1">
            <div class="manager-wrapper" fxLayoutAlign="center start" fxLayout="column">
                <app-image-view
                    *ngIf="educators[0].media_id"
                    class="single-img"
                    [scope]="AppScope.USERS"
                    [media_id]="educators[0].media_id"
                    [paddingBottom]="'52px'"
                >
                </app-image-view>
                <div class="default-inst-img-def" *ngIf="!educators[0].media_id">
                    <mat-icon>engineering</mat-icon>
                </div>

                <div class="manager-name">{{ educators[0].name }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="educators.length === 2">
            <div class="manager-wrapper" fxLayoutAlign="center start" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="-50px">
                    <app-image-view
                        *ngIf="educators[0].media_id"
                        class="double-img"
                        [scope]="AppScope.USERS"
                        [media_id]="educators[0].media_id"
                        [paddingBottom]="'52px'"
                    >
                    </app-image-view>
                    <app-image-view
                        *ngIf="educators[1].media_id"
                        class="double-img"
                        [scope]="AppScope.USERS"
                        [media_id]="educators[1].media_id"
                        [paddingBottom]="'60px'"
                    >
                    </app-image-view>
                    <div class="default-inst-img-def" *ngIf="!educators[0].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="default-inst-img-def" *ngIf="!educators[1].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                </div>
                <div class="manager-name">{{ educators[0].name }} & {{ educators[1].name }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="educators.length > 2">
            <div class="manager-wrapper" fxLayoutAlign="center start" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row">
                    <app-image-view
                        *ngIf="educators[0].media_id"
                        class="multi-img"
                        [scope]="AppScope.USERS"
                        [media_id]="educators[0].media_id"
                        [paddingBottom]="'60px'"
                    >
                    </app-image-view>
                    <app-image-view
                        *ngIf="educators[1].media_id"
                        class="multi-img second"
                        [scope]="AppScope.USERS"
                        [media_id]="educators[1].media_id"
                        [paddingBottom]="'60px'"
                    >
                    </app-image-view>
                    <div class="default-inst-img-def multi" *ngIf="!educators[0].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="default-inst-img-def multi second" *ngIf="!educators[1].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="remain-managers" (click)="showManagers(educators, resource)">
                        +{{ educators.length - 2 }}
                    </div>
                </div>
                <div class="manager-name">{{ educators[0].name }} & {{ educators[1].name }}</div>
                <div class="manager-name" (click)="showManagers(educators, resource)">& Others</div>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #instructor let-resource="resource">
    <div *ngIf="$any(resource.managers$ | async); let managers">
        <ng-container *ngIf="managers.length === 1">
            <div class="manager-wrapper" fxLayoutAlign="center center" fxLayout="column">
                <app-image-view
                    *ngIf="managers[0].media_id"
                    class="single-img"
                    [scope]="AppScope.USERS"
                    [media_id]="managers[0].media_id"
                    [paddingBottom]="'122px'"
                >
                </app-image-view>
                <div class="default-inst-img-def" *ngIf="!managers[0].media_id">
                    <mat-icon>engineering</mat-icon>
                </div>
                <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <mat-icon>engineering</mat-icon>
                    <div *ngIf="resourceType === ResourceTypes.EVENT" i18n>Tutor</div>
                    <div *ngIf="resourceType !== ResourceTypes.EVENT" i18n>Author</div>
                </div>
                <div class="manager-name">{{ managers[0].name }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="managers.length === 2">
            <div class="manager-wrapper" fxLayoutAlign="center center" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="-50px">
                    <app-image-view
                        *ngIf="managers[0].media_id"
                        class="double-img"
                        [scope]="AppScope.USERS"
                        [media_id]="managers[0].media_id"
                        [paddingBottom]="'122px'"
                    >
                    </app-image-view>
                    <app-image-view
                        *ngIf="managers[1].media_id"
                        class="double-img"
                        [scope]="AppScope.USERS"
                        [media_id]="managers[1].media_id"
                        [paddingBottom]="'122px'"
                    >
                    </app-image-view>
                    <div class="default-inst-img-def" *ngIf="!managers[0].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="default-inst-img-def" *ngIf="!managers[1].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                </div>
                <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <mat-icon>engineering</mat-icon>
                    <div *ngIf="resourceType === ResourceTypes.EVENT" i18n>Tutors</div>
                    <div *ngIf="resourceType !== ResourceTypes.EVENT" i18n>Authors</div>
                </div>
                <div class="manager-name">{{ managers[0].name }} & {{ managers[1].name }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="managers.length > 2">
            <div class="manager-wrapper" fxLayoutAlign="center center" fxLayout="column">
                <div fxLayoutAlign="center center" fxLayout="row">
                    <app-image-view
                        *ngIf="managers[0].media_id"
                        class="multi-img"
                        [scope]="AppScope.USERS"
                        [media_id]="managers[0].media_id"
                        [paddingBottom]="'96px'"
                    >
                    </app-image-view>
                    <app-image-view
                        *ngIf="managers[1].media_id"
                        class="multi-img second"
                        [scope]="AppScope.USERS"
                        [media_id]="managers[1].media_id"
                        [paddingBottom]="'96px'"
                    >
                    </app-image-view>
                    <div class="default-inst-img-def multi" *ngIf="!managers[0].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="default-inst-img-def multi second" *ngIf="!managers[1].media_id">
                        <mat-icon>engineering</mat-icon>
                    </div>
                    <div class="remain-managers" (click)="showManagers(managers, resource)">
                        +{{ managers.length - 2 }}
                    </div>
                </div>
                <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <mat-icon>engineering</mat-icon>
                    <div *ngIf="resourceType === ResourceTypes.EVENT" i18n>Tutors</div>
                    <div *ngIf="resourceType !== ResourceTypes.EVENT" i18n>Authors</div>
                </div>
                <div class="manager-name">{{ managers[0].name }} & {{ managers[1].name }}</div>
                <div class="manager-name" (click)="showManagers(managers, resource)">& Others</div>
            </div>
        </ng-container>
    </div>

    <ng-template #author_img>
        <div class="author-wrapper" fxLayoutAlign="center center" fxLayout="column">
            <div class="user-img">
                {{ resource.author_name || $any(resource.author$ | async)?.name | nameSign }}
            </div>
            <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-icon>account_circle</mat-icon>
                <span i18n>Created by </span>
            </div>
            <div>
                {{ resource.author_name || $any(resource.author$ | async)?.name }}
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #resAvailability let-resource="resource">
    <div *ngIf="resource.always_available" class="timer-off small-info times-parent">
        <mat-icon>timer_off</mat-icon>
        <span i18n="@@alwaysAvailable">Always available</span>
    </div>
    <!-- <div *ngIf="resource.not_set_yet" class="timer-off small-info times-parent">
        <mat-icon>timer_off</mat-icon>
        <span i18n>Date not set yet</span>
    </div> -->
    <div
        *ngIf="resource.start_date?.isAfter(moment) && resource.end_date?.isAfter(moment) && !resource.always_available"
        class="small-info timer-off times-parent"
    >
        <mat-icon>timer</mat-icon>
        <div class="dates-wrapper times">
            <!-- <span i18n="@@availableBetween">Available between</span> -->
            <div>
                <span>{{ resource.start_date | dateFormatLocalized: 'MMM d, y' }}</span> /
                <span>{{ resource.end_date | dateFormatLocalized: 'MMM d, y' }}</span>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            resource.start_date?.isBefore(moment) && resource.end_date?.isAfter(moment) && !resource.always_available
        "
        class="small-info timer-off times-parent"
    >
        <mat-icon>timelapse</mat-icon>
        <div class="dates-wrapper times">
            <!-- <span i18n="@@availableBetween">Available between</span> -->
            <div>
                <span>{{ resource.start_date | dateFormatLocalized: 'MMM d, y' }}</span> /
                <span>{{ resource.end_date | dateFormatLocalized: 'MMM d, y' }}</span>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #publishedBy let-resource="resource">
    <ng-container *ngIf="resource.staff?.publisher && resource.staff?.publisher[0]">
        <ng-container *ngIf="$any(resource.publishers$ | async); let publishers; else: smallLoading">
            <span>{{ publishers[0].name }}</span>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #academyLogo let-resource="resource">
    <ng-container *ngIf="resource.public">
        <img src="assets/images/KD_KOL_logo_long_white.png" alt="KlickData" />
    </ng-container>
    <ng-container *ngIf="!resource.public">
        <ng-container *ngIf="$any(resource.publishers$ | async); let publishers; else: smallLoading">
            <app-logotype [image]="publishers[0].media_url" [alt]="publishers[0].name"></app-logotype>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #actionBtn let-resource="resource">
    <span *ngIf="!resource.opportunity_id">
        <span *ngIf="resourceType === ResourceTypes.COURSE" i18n="@@enroll">Enroll</span>
        <span *ngIf="resourceType === ResourceTypes.EVENT" i18n="@@register">Register</span>
        <span *ngIf="resourceType !== ResourceTypes.COURSE && resourceType !== ResourceTypes.EVENT" i18n="@@start"
            >Start</span
        >
    </span>
    <span *ngIf="resource.opportunity_id">
        <span i18n="@@continue">Continue</span>
    </span>
</ng-template>
<ng-template #smallLoading>
    <app-kd-logo-loading class="small-load-header" size="small"></app-kd-logo-loading>
</ng-template>
