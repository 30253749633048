import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { Language } from '@klickdata/core/localization/src/language';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { LanguageService } from '../language.service';

@Component({
    selector: 'app-language-selector',
    templateUrl: 'language-selector.component.html',
    styleUrls: ['language-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LanguageSelectorComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, OnChanges {
    /** propagate initial value */
    @Input() propagate = true;
    @Input() showLabel: boolean;
    @Input() disableRipple: boolean;
    @Input() language_id: number | string;
    @Input() tooltip = $localize`:@@languageSelectorShortcut:Switch Language`;
    public disabled: boolean;
    @Input() globe: boolean;
    @Input() globeDefault: boolean;
    @Input() prefLangIds: number[];
    @Input() param: { [key: string]: string };
    @Output() afterInit: EventEmitter<any> = new EventEmitter<any>();
    private _controlValue: number | number[];
    loaded: boolean;
    public get controlValue(): number | number[] {
        return this._controlValue;
    }
    public set controlValue(value: number | number[]) {
        this._controlValue = value;
        this.propagateChange(value);
    }
    public currentLanguage: BehaviorSubject<Language> = new BehaviorSubject<Language>(null);
    public languages$: Observable<Language[]>;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private localizationService: LanguageService,
        protected injector: Injector,
        protected cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // handle UI language.
        if (this.language_id) {
            // handle display language flag by key.
            this.updateFlag();
        }
    }
    private updateFlag() {
        this.localizationService
            .getLanguageByKey(this.language_id)
            .pipe(takeUntil(this.destroy))
            .subscribe((language) => {
                setTimeout(() => {
                    this.currentLanguage.next(language);
                    if (!this.controlValue) {
                        this.disabled = true;
                    }
                });
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.loaded && changes.language_id) {
            this.updateFlag();
        }
    }

    ngAfterViewInit() {
        // Handle form control disable.
        if (this.controlValue) {
            const ngControl = this.injector.get(NgControl);
            this.updateReadonlyMode(ngControl?.status === 'DISABLED');
            ngControl?.statusChanges
                .pipe(
                    map((changes) => this.updateReadonlyMode(changes === 'DISABLED')),
                    takeUntil(this.destroy)
                )
                .subscribe();
            // reset form control changes
            if (ngControl?.control instanceof FormControl) {
                FormHelper.resetForm(ngControl.control);
            }
        } else if (!this.language_id) {
            // Handle UI flag language selector.
            setTimeout(() => {
                this.currentLanguage.next(this.localizationService.getCurrentLanguage());
            });
        }

        // Handle init language list.
        this.languages$ = this.globe
            ? this.getAttachedLanguages()
            : this.controlValue
            ? this.localizationService.supportLanguages
            : of(this.localizationService.getLanguages());
        this.loaded = true;
        this.cdRef.detectChanges();
    }

    private getAttachedLanguages(): Observable<Language[]> {
        return this.prefLangIds
            ? this.localizationService.getLanguagesByKeys(this.prefLangIds)
            : this.param
            ? this.localizationService.getAttachedLanguages(this.param)
            : this.localizationService.attachedLanguages;
    }

    private updateReadonlyMode(disabled: boolean) {
        this.disabled = disabled;
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    changeLanguage(lang: Language | Language[]) {
        if (Array.isArray(lang)) {
            if (!Array.isArray(this.controlValue)) {
                this.currentLanguage.next(lang.length === 1 ? lang[0] : <Language>{ id: -1 });
                this.controlValue = lang.map((lng) => lng.id);
            }
        } else if (this.controlValue) {
            this.currentLanguage.next(lang);
            if (this.controlValue !== lang.id) {
                this.controlValue = lang.id;
            }
        } else {
            this.localizationService.updateUserLanguage(lang);
        }
        this.cdRef.markForCheck();
    }

    public propagateChange = (_: any) => {};

    public writeValue(value: number | string): void {
        this._controlValue = typeof value === 'number' ? value : -1;
        if (!this.globe) {
            this.writeSingleLanguage(value);
        } else {
            combineLatest([this.localizationService.getLanguageByKey(value), this.getAttachedLanguages()])
                .pipe(
                    takeUntil(this.destroy),
                    map(([select, languages]) =>
                        (this.globeDefault || !languages?.length) && this.controlValue === -1
                            ? languages
                            : this.matchedOrFirst(select, languages)
                    )
                )
                .subscribe((languages) => setTimeout(() => this.changeLanguage(languages)));
        }
    }

    private writeSingleLanguage(value: string | number) {
        this.localizationService
            .getLanguageByKey(value)
            .pipe(takeUntil(this.destroy))
            .subscribe((language) => {
                this.currentLanguage.next(language);
                if (this.propagate) {
                    setTimeout(() => {
                        this.controlValue = language.id;
                        this.afterInit.emit();
                        const control = this.injector.get(NgControl)?.control;
                        if (control instanceof FormControl) {
                            FormHelper.resetForm(control);
                        }
                    });
                } else {
                    this._controlValue = language.id;
                }
            });
    }

    /**
     * Return Current language if contained on attached languages or first from the list
     */
    private matchedOrFirst(lang: Language, languages: Language[]) {
        return !languages?.length || !!languages.find((lng) => lng.id === lang.id) ? lang : languages[0];
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    public stopPropagation(ev: MouseEvent): void {
        ev.stopPropagation();
    }
}
