import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { EchoService } from '@klickdata/core/echo/echo.service';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { ResourceService } from '@klickdata/core/resource';
import { UserService } from '@klickdata/core/user';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CaseStatus, MsgCase, MsgCaseData } from './msg-case.model';
import { MsgDialog, MsgDialogData } from './msg-dialog.model';
import { Notification, NotificationData } from './notification.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public notificationUrl: string;
    public notificationBadgeUrl: string;
    public casesUrl: string;

    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected userSevice: UserService,
        protected resourceService: ResourceService,
        protected auth: AuthService,
        protected echoService: EchoService,
        protected error: HttpErrorService
    ) {
        this.notificationUrl = `${configService.config.apiUrl}messages`;
        this.casesUrl = `${configService.config.apiUrl}cases`;
        this.notificationBadgeUrl = `${configService.config.apiUrl}notifications/badge`;
    }

    public listenToNewMessage(): Observable<Notification> {
        return this.echoService
            .listenUserChannel('NewMessage')
            .pipe(map((data: NotificationData) => this.mapNotification(data)));
    }

    public listenToMessageWithNewDialog(): Observable<Notification> {
        return this.echoService
            .listenUserChannel('MessageDialogBadge')
            .pipe(map((data: NotificationData) => this.mapNotification(data)));
    }

    public listenToNewCaseStatus(caseId: number): Observable<MsgCase> {
        return this.echoService
            .listenUserChannel(`.NewCaseStatus.${caseId}`)
            .pipe(map((data: MsgCaseData) => this.mapCase(data)));
    }

    public listenToNotificationBadge(): Observable<number> {
        return this.echoService
            .listenUserChannel('NotificationBadge')
            .pipe(map((res: { messages: number; dialogs: number }) => res.messages + res.dialogs));
    }

    public getNotificatioBadgeNumber(): Observable<number> {
        return this.builder
            .get<number>(`${this.notificationBadgeUrl}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public listenToNewDialog(msgId: number): Observable<MsgDialog> {
        return this.echoService
            .listenPrivate(`message.${msgId}`, 'NewDialog')
            .pipe(map((data: MsgDialogData) => this.mapMsgDialog(data)));
    }

    public getNotification(id: number | string): Observable<Notification> {
        return this.builder
            .get<Notification>(`${this.notificationUrl}/${id}`)
            .request()
            .pipe(map((res) => this.mapNotification(res.data)));
    }

    public getMsgDialogs(id: number | string): Observable<MsgDialog[]> {
        return this.builder
            .get<MsgDialogData[]>(`${this.notificationUrl}/${id}/dialogs`)
            .request()
            .pipe(map((res) => res.data.map((chatDialog) => this.mapMsgDialog(chatDialog))));
    }

    public getMsgCase(id: number): Observable<MsgCase> {
        return this.builder
            .get<MsgCaseData>(`${this.notificationUrl}/${id}/case`)
            .request()
            .pipe(map((res) => this.mapCase(res.data)));
    }

    public create(notification: NotificationData): Observable<Notification> {
        return this.builder
            .post<NotificationData>(this.notificationUrl, notification)
            .request()
            .pipe(map((noti) => this.mapNotification(noti.data)));
    }

    public createMsgDialog(id: number, chat: MsgDialogData): Observable<MsgDialog> {
        return this.builder
            .post<MsgDialogData>(`${this.notificationUrl}/${id}/dialogs`, chat)
            .request()
            .pipe(map((chatDialog) => this.mapMsgDialog(chatDialog.data)));
    }

    public createMsgCase(msgId: number, msgCase: MsgCaseData): Observable<MsgCase> {
        return this.builder
            .post<MsgCaseData>(`${this.notificationUrl}/${msgId}/case`, msgCase)
            .request()
            .pipe(map((res) => this.mapCase(res.data)));
    }

    public updateMsgCase(msgId: number, msgCase: MsgCaseData): Observable<MsgCase> {
        return this.builder
            .put<MsgCaseData>(`${this.notificationUrl}/${msgId}/case`, msgCase)
            .request()
            .pipe(map((res) => this.mapCase(res.data)));
    }

    public updateUserMsgCaseStatus(
        msgId: number,
        status: { type: string; comment?: string },
        id?: number
    ): Observable<MsgCase> {
        const uri = id
            ? `${this.notificationUrl}/${msgId}/case/user/${id}`
            : `${this.notificationUrl}/${msgId}/case/user`;
        return this.builder
            .put<MsgCaseData>(uri, status)
            .request()
            .pipe(map((msgCase) => this.mapCase(msgCase.data)));
    }

    public update(notification: NotificationData): Observable<Notification> {
        return this.builder
            .put<NotificationData>(`${this.notificationUrl}/${notification.id}`, notification)
            .request()
            .pipe(map((noti) => this.mapNotification(noti.data)));
    }

    public changeImportantStatus(id: number, status: boolean): Observable<Notification> {
        return this.builder
            .put<NotificationData>(`${this.notificationUrl}/${id}`, { important: status })
            .request()
            .pipe(map((noti) => this.mapNotification(noti.data)));
    }

    public importantMultipleMsgs(msgIds: number[], isImportant: boolean): Observable<Notification[]> {
        return this.builder
            .put<NotificationData[]>(`${this.notificationUrl}`, { ids: msgIds, important: isImportant })
            .request()
            .pipe(map((res) => res.data.map((msg) => this.mapNotification(msg))));
    }

    public destroyMultipleMsgs(msgIds: number[]): any {
        return this.builder
            .delete<any>(`${this.notificationUrl}/${msgIds}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    public restoreMultipleMsgs(msgIds: number[]): Observable<Notification[]> {
        return this.builder
            .put<NotificationData[]>(`${this.notificationUrl}/${msgIds}/restore`, null)
            .request()
            .pipe(map((res) => res.data.map((msg) => this.mapNotification(msg))));
    }

    protected mapNotification(notificationData: NotificationData): Notification {
        const notificationsData = new Notification(notificationData);
        if (notificationsData.resource_ids) {
            notificationData.resources = this.resourceService.getResources(notificationsData.resource_ids);
        }
        if (notificationsData.author?.id) {
            notificationsData.author$ = this.userSevice.getAuthor(notificationsData.author.id);
        }
        return notificationsData;
    }
    protected mapMsgDialog(msgDialogData: MsgDialogData): MsgDialog {
        const chatData = new MsgDialog(msgDialogData);
        if (msgDialogData.author_id) {
            chatData.author = this.userSevice.getAuthor(msgDialogData.author_id);
        }
        return chatData;
    }
    protected mapCase(data: MsgCaseData): MsgCase {
        const msgCase = new MsgCase(data);

        // if (masgCaseData.resource_ids) {
        //     masgCaseData.resources = this.resourceService.getResources(masgCaseData.resource_ids);
        // }

        if (msgCase.users?.length) {
            return this.parseUserStatus(msgCase);
        }

        if (msgCase.status && msgCase.status.length) {
            return this.prepareCaseStatus(msgCase);
        }

        return msgCase;
    }

    private parseUserStatus(msgCase: MsgCase): MsgCase {
        msgCase.users.forEach((user) => (user.status = this.handleCaseStatus(user.status)));
        return msgCase;
    }

    private prepareCaseStatus(msgCase: MsgCase): MsgCase {
        msgCase.status = this.handleCaseStatus(msgCase.status);
        return msgCase;
    }

    private handleCaseStatus(status: CaseStatus[]): CaseStatus[] {
        status.forEach((item) => (item.date = moment.utc(item.date)));
        status.sort((s1, s2) => s1.date.diff(s2.date));
        return status;
    }
}
