<div class="exercise-doc mt-1">
    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>topic</mat-icon>
                <div i18n>Exercise Documents</div>
                <mat-icon
                    class="icon-explain"
                    matTooltip="Upload exercise document that belongs to the course for practice. It can be a documents pre-course, during course or post the course."
                    i18n-matTooltip
                    >help_outline</mat-icon
                >
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: onControlDirty.value }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column">
            <div
                fxFlex="0 1 100%"
                fxLayout="row wrap"
                fxLayoutAlign="center center"
                *ngIf="!resourceForm.value.practice_document"
            >
                <div
                    [ngStyle]="dropStyle"
                    fxFlex="0 0 50%"
                    fxFlex.lt-md="0 0 90%"
                    class="media-dropzone"
                    fxLayout="row wrap"
                    fxLayoutAlign="center center"
                >
                    <input
                        class="media-upload-button"
                        type="file"
                        (change)="prepareFile($event)"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf,.xlsx,.xls, image/*, video/3gpp, video/mp4,
                        video/webm,audio/*, application/vnd.apple.keynote"
                    />
                </div>
                <ng-container *ngIf="!resourceForm.value.practice_document">
                    <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
                        <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
                        <span class="progress-value">{{ progress }}%</span>
                    </div>
                </ng-container>
            </div>
            <app-media-view
                *ngIf="resourceForm.value.practice_document"
                fxLayoutAlign="center center"
                fxFlex="0 0 90%"
                [media_id]="resourceForm.value.practice_document"
            >
                <button
                    class="remove-file"
                    color="warn"
                    type="button"
                    fxFlex="0 1 auto"
                    mat-icon-button
                    matTooltip="Remove file"
                    i18n-matTooltip
                    (click)="resourceForm.patchValue({ practice_document: '' }); onControlDirty.next(false)"
                >
                    <mat-icon class="md-24">delete_outline</mat-icon>
                </button>
            </app-media-view>
        </div>
    </mat-expansion-panel>
</div>
