import { Directive, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { Resource, ResourceCategoryService, ResourceTypes } from '@klickdata/core/resource';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class ResourceCreationManagerBase implements OnInit, OnDestroy, CanComponentDeactivate {
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public abstract typeId: ResourceTypes;
    public isAdmin$: Observable<boolean>;
    public formats: { [key: string]: string };
    public resourceForm: FormGroup;
    public autoSubmitAndDeactivate: boolean;
    public publish: boolean;
    @Output() onResKeysValidatyChanges: EventEmitter<{ [key: string]: string | boolean }[]> = new EventEmitter<
        { [key: string]: string | boolean }[]
    >();
    @ViewChild('contentTemplateToBeInParent') contentTemplateToBeInParent: TemplateRef<any>;

    constructor(
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService
    ) {
        this.formats = {
            title: $localize`:@@title:Title`,
            description: $localize`:@@description:Description`,
            grade_system_id: $localize`:@@gradeSystem:Grade system`,
            categories: $localize`:@@categories:Categories`,
            start_date: $localize`:@@startDate:Start date`,
            end_date: $localize`:@@endDate:End date`,
            bullets: $localize`:@@summary:Summary`,
            article_code: $localize`:@@courseCode:Course code`,
            agenda: $localize`:@@agenda:Agenda`,
        };
    }
    ngOnInit(): void {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
        this.resourceForm = this.parentFormDirective.form;
        this.resourceForm.valueChanges
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.onResKeysValidatyChanges.emit(this.getResKeysValidaty()));
        // this.setDefaultCategory();
    }

    public submit() {
        this.updatePublishStatus();
        this.onSubmit(this.publish);
    }
    private updatePublishStatus() {
        this.publish =
            this.resourceForm.get('publish').value ||
            this.resourceForm.value.users_attach?.length ||
            this.resourceForm.value.users_detach?.length ||
            this.resourceForm.value.groups_attach?.length ||
            this.resourceForm.value.groups_detach?.length ||
            this.resourceForm.value.sync_all_users ||
            this.resourceForm.value.sync_all_groups;
    }
    public performResSubmit() {
        if (this.autoSubmitAndDeactivate) {
            this.autoSubmitAndDeactivate = false;
            return;
        }
        this.prepareSubmit().subscribe((resource) => {
            this.messageService.openMessage(MessageSavedComponent);
            this.loading.emit(false);
            this.resourceForm.reset();
            FormHelper.resetForm(this.resourceForm);
            this.saved.emit(resource);
        });
    }
    public abstract onSubmit(publish: boolean): void;
    public abstract getResKeysValidaty(): { [key: string]: string | boolean }[];
    public abstract prepareSubmit(): Observable<Resource>;
    public abstract checkSubmitValid(): Observable<boolean>;

    public setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(this.typeId)
            .pipe(
                takeUntil(this.destroy),
                filter((cat) => cat && !!cat.id)
            )
            .subscribe((category) => {
                if (!this.resourceForm.controls.category_ids.value?.length) {
                    this.resourceForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.resourceForm.controls.category_ids);
                }
            });
    }
    public canDeactivate(): boolean {
        const prisitneControls = Object.entries(this.resourceForm.controls).filter((val) => !val[1].pristine);
        if (prisitneControls.length === 0) {
            this.resourceForm.markAsPristine();
        }
        return this.resourceForm.pristine;
    }

    public saveAndDeactivate(): Observable<boolean> {
        this.autoSubmitAndDeactivate = true;
        this.updatePublishStatus();
        return this.checkSubmitValid().pipe(
            switchMap((isValid: boolean) => (isValid ? this.prepareSubmit().pipe(map(() => true)) : of(false)))
        );
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
