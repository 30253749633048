import { ResourceService } from '@klickdata/core/resource';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { first, map, share, catchError, shareReplay } from 'rxjs/operators';
import { MediasItem, Post, PostData } from './post.model';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';
import { UserService } from '@klickdata/core/user/src/user.service';
import { GroupService } from '@klickdata/core/group/src/group.service';
import { CustomerService } from '@klickdata/core/customer/src/customer.service';
import { User } from '@klickdata/core/user';
import { Customer } from '@klickdata/core/customer';
import { EchoService } from '@klickdata/core/echo/echo.service';
import { ResponseData } from '@klickdata/core/http';

@Injectable({
    providedIn: 'root',
})
export class PostService {
    protected resourceUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    private authorsMap = new Map<number, Observable<User>>();

    constructor(
        protected auth: AuthService,
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected groupService: GroupService,
        protected mediaService: MediaService,
        protected echoService: EchoService,
        protected resourceService: ResourceService
    ) {
        this.resourceUrl = `${config.config.apiUrl}posts`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Fetch posts from remote by user
     */
    public getUserPosts(limit = 0): Observable<Post[]> {
        const req = this.builder
            .get<PostData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('sort', 'published')
            .param('dir', 'desc');

        if (limit) {
            req.limit(limit);
        }

        return req.request().pipe(map((res) => this.mapPost(res.data)));
    }

    public getPosts(params: { property: string; items: any[] }[]): Observable<Post[]> {
        const req = this.builder
            .get<PostData[]>(this.resourceUrl)
            .param('sort', 'created_at')
            .param('dir', 'desc')
            .param('eager', 'tags');

        params.forEach((param) => {
            if (param.property && param.items && param.items.length) {
                req.param(param.property, param.items.join());
            }
        });

        return req.request().pipe(map((res) => this.mapPost(res.data)));
    }
    public getAllPosts(): Observable<Post[]> {
        const req = this.builder
            .get<PostData[]>(this.resourceUrl)
            .param('publicOrCustomer', this.customer_id)
            .param('sort', 'published')
            .param('dir', 'desc');

        return req.request().pipe(map((res) => this.mapPost(res.data)));
    }

    protected mapPost(data: PostData[]): Post[] {
        return data.map((item) => this.createPost(item));
    }
    public createPost(data: PostData): Post {
        const post = new Post(data);
        post.author$ = post.author_id ? this.getAuthor(post.author_id) : of(new User({ fname: 'Anonymous' }));
        post.short_body = post.body.replace(/<(?:.|\n)*?>/gm, ' ').slice(0, 50) + ' ...';
        if (post.staff) {
            if (post.staff.manager && post.staff.manager.length) {
                post.managers$ = this.resourceService.getResourceEducators({ ids: post.staff.manager });
            }
            if (post.staff.publisher && post.staff.publisher.length) {
                post.publishers$ = this.resourceService.getResourceEducators({ ids: post.staff.publisher });
            }
        }
        return post;
    }

    protected getAuthor(author_id: number): Observable<User> {
        let author = this.authorsMap.get(author_id);
        if (author) {
            return author;
        }
        author = this.userService.getUser(author_id).pipe(
            share(),
            catchError(() => of(new User({ fname: 'Anonymous', lname: '' })))
        );
        this.authorsMap.set(author_id, author);
        return author;
    }
    /**
     * Fetch posts from remote
     */
    public getCustomerPosts(): Observable<Post[]> {
        return this.builder
            .get<PostData[]>(this.resourceUrl)
            .param('customer', this.customer_id)
            .param('sort', 'published')
            .param('dir', 'desc')
            .request()
            .pipe(map((res) => this.mapPost(res.data)));
    }
    public listenToNewPost(postId: number): Observable<Post> {
        return this.echoService
            .listenPrivate(`post.${postId}`, 'PostUpdateEvent', this.getPostData(postId))
            .pipe(map((res) => this.setupPost(res.data)));
    }
    /**
     * Fetch public posts posts
     */
    public getPublicPosts(): Observable<Post[]> {
        return this.builder
            .get<PostData[]>(this.resourceUrl)
            .param('public', 'true')
            .param('sort', 'published')
            .param('dir', 'desc')
            .request()
            .pipe(map((res) => this.mapPost(res.data)));
    }

    /**
     * Fetch post from remote
     *
     * @param id PostID
     */
    public getPost(id: number): Observable<Post> {
        return this.getPostData(id).pipe(map((res) => this.setupPost(res.data)));
    }

    public getPostData(id: number): Observable<ResponseData<PostData>> {
        return this.builder.get<PostData>(`${this.resourceUrl}/${id}`).request();
    }

    /**
     * Create a new post on the backend.
     */
    public create(post: PostData): Observable<Post> {
        return this.builder
            .post<PostData>(this.resourceUrl, post)
            .request()
            .pipe(map((res) => this.setupPost(res.data)));
    }

    public update(data: PostData): Observable<Post> {
        return this.builder
            .put<PostData>(`${this.resourceUrl}/${data.id}`, data)
            .request()
            .pipe(map((res) => this.setupPost(res.data)));
    }

    public prompt(data: PostData): Observable<Post> {
        return this.builder
            .post<PostData>(`${this.resourceUrl}/prompt`, data)
            .request()
            .pipe(map((res) => this.setupPost(res.data)));
    }

    public addQuoteToPost(id: number): Observable<{ success: boolean }> {
        return this.builder
            .put<{ success: boolean }>(`${this.resourceUrl}/${id}/quote`, null)
            .request()
            .pipe(map((res) => res.data));
    }

    /**
     * Delete a post.
     */
    public destroy(post: Post): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${post.id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    /**
     * Restore a soft deleted post.
     */
    public restore(userId: number): Observable<any> {
        return this.builder
            .put<PostData>(`${this.resourceUrl}/${userId}/restore`, null)
            .request()
            .pipe(map((res) => this.setupPost(res.data)));
    }

    protected setupPost(data: PostData): Post {
        const post = new Post(data);
        if (post.medias?.cover) {
            post.medias.cover = post.medias.cover[0];
        }
        if (post.medias?.video) {
            post.videoMedia$ = this.mediaService.getMedia(post.medias.video[0].id);
            post.medias.video = post.medias.video[0];
        }

        post.customer = this.customerService.getCustomer(data.author_id);
        post.author$ = this.userService.getUser(data.author_id).pipe(
            share(),
            catchError(() => of(new User({ fname: 'Anonymous', lname: '' })))
        );
        post.groups = this.groupService.getGroupsByIds(data.group_ids);

        return post;
    }
}
