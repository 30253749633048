<div *ngIf="(aPost | async) as post; else loading" fxLayout="row wrap"  fxLayoutAlign="center center">
    <header fxLayout="row wrap"  fxLayoutAlign="center center" fxFlex="1 0 100%">
        <div fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="end">
            <button class="back-btn" mat-icon-button (click)="close()">
                <mat-icon class="md-36">close</mat-icon>
            </button>
        </div>
        <div fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="space-between top">
            <h1>{{post?.title}}</h1>
            <span fxFlex="0 0 auto" class="published">
            {{post?.published_at | kdDateFormat: 'YYYY-MM-DD'}}
        </span>
        </div>
    </header>
    <article fxFlex="0 0 100%" fxLayout="row wrap">
        <div fxFlex="1 0 100%" class="ingress">
            <b>{{post?.ingress}}</b>
        </div>
        <div fxFlex="1 0 100%" [innerHTML]="post?.body"></div>
    </article>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
