<div fxLayout="column" class="main-wrapper-core">
    <span i18n class="title-label">Course structure</span>
    <div class="w-100">
        <app-resource-builder
            [resource]="resource"
            [active]="false"
            [resource_type_id]="ResourceTypes.GeneralCoursePlan"
            [resourceType]="ResourceTypes.COURSE"
            [hideAddItems]="true"
            [language_id]="resourceForm?.get('language_id').value"
            (saved)="$event && savingItems && submitCourseAfterItemsSubmitted()"
            (removal)="updateRemovedItems($event)"
            [hideTools]="isEmpty(resourceBuilder?.getService().getForm() | async)"
            [ngClass]="{ 'rb-is-empty': isEmpty(resourceBuilder?.getService().getForm() | async) }"
            class="course-manage-rb w-100 m-10-0"
        >
        </app-resource-builder>
    </div>
    <div *ngIf="!resource?.isPublished()" fxFlex.lt-md="100%">
        <ng-container *ngTemplateOutlet="courseItems"></ng-container>
    </div>
</div>
<app-course-manager-core-practice-doc [resource]="resource"></app-course-manager-core-practice-doc>
<ng-template #courseItems>
    <app-course-manager-core-items
        #courseItemsCore
        [selection]="selection"
        (add)="addItem($event)"
        (onError)="loading.emit(false)"
    ></app-course-manager-core-items>
</ng-template>
<ng-template #contentTemplateToBeInParent>
    <app-course-manager-core-secodary-controls
        [courseResourceItems]="courseResourceItems"
        [resource]="resource"
    ></app-course-manager-core-secodary-controls>
</ng-template>
