import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Injector,
    Input,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators,
} from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { Utils } from '@klickdata/core/util';
import { Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-title-translation',
    templateUrl: './title-translation.component.html',
    styleUrls: ['./title-translation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TitleTranslationComponent),
            multi: true,
        },
    ],
})
export class TitleTranslationComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() placeholder: string;
    @Input() type: 'title' | 'description' = 'title';
    private _value: { [key: string]: string } = {};
    public translationsFormArray: FormArray;
    private destroy: Subject<boolean> = new Subject<boolean>();
    subscription: Subscription;
    noMoreLanguages: boolean;

    get value(): { [key: string]: string } {
        return this._value;
    }

    set value(value) {
        this._value = value ? value : {};
        this.propagateChange(this._value);
    }
    constructor(
        protected fb: FormBuilder,
        protected languageService: LanguageService,
        protected injector: Injector,
        protected cdRef: ChangeDetectorRef,
        protected zone: NgZone,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
    }

    public propagateChange = (_: any) => {};

    ngOnInit(): void {
        this.buildFormArray([this.makeTranslationGroup({ key: this.localeId, value: '' })]);
    }

    private buildFormArray(translationGroups: FormGroup[]) {
        this.translationsFormArray = this.fb.array(translationGroups);
        this.cdRef.markForCheck();
        this.destroySubscription();
        this.subscription = this.translationsFormArray.valueChanges
            .pipe(
                switchMap((values) =>
                    this.languageService.getLanguagesByKeys(values.map((obj) => obj.language_id)).pipe(
                        map((languages) => {
                            const obj: { [key: string]: string } = {};
                            for (let i = 0; i < languages.length; ++i) {
                                const lang = languages[i].lang;
                                const value = values[i].value;
                                obj[lang] = value;
                            }
                            return obj;
                        })
                    )
                )
            )
            .subscribe((values) => {
                this.zone.run(() => {
                    /**
                     *  Propagate change when user change and no duplicated keys
                     */
                    if (
                        !(this.translationsFormArray.pristine && Utils.objEqual(this.value, values)) && // user check
                        this.translationsFormArray.controls.length === Object.keys(values).length // no duplicated keys
                    ) {
                        this.value = values;
                    }
                });
            });
    }

    private destroySubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    makeTranslationGroup({ key, value }): FormGroup {
        return this.fb.group({
            language_id: [key],
            value: [value, Validators.required],
        });
    }

    public addLang() {
        if (this.noMoreLanguages) {
            return;
        }
        this.languageService
            .getLanguageNotIn(this.translationsFormArray.value.map((obj) => obj.language_id))
            .pipe(takeUntil(this.destroy))
            .subscribe((language) => {
                if (language) {
                    this.translationsFormArray.push(this.makeTranslationGroup({ key: language.value, value: '' }));
                } else {
                    this.noMoreLanguages = true;
                }
            });
    }

    public writeValue(value): void {
        this._value = value || {};
        if (Object.keys(this.value).length !== 0) {
            const translations = Object.entries(this.value)
                .sort((a, _) => (a[0] === this.localeId ? -1 : 1))
                .map((translation) => this.makeTranslationGroup({ key: translation[0], value: translation[1] }));
            this.buildFormArray(translations);
        }
    }

    removeTranslation(translationForm: AbstractControl) {
        const index = this.translationsFormArray.value.findIndex(
            (values) => values.language_id === translationForm.value.language_id
        );
        if (-1 !== index) {
            this.translationsFormArray.removeAt(index);
            this.noMoreLanguages = false;
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
        this.destroySubscription();
    }

    public markForm() {
        FormHelper.markForm(this.translationsFormArray);
        this.cdRef.markForCheck();
    }
}
