<div
    *ngIf="user$ | async; let user; else: loading"
    fxFlex="1 1 100%"
    fxLayoutAlign="center"
    fxLayout="column wrap"
    fxLayoutAlign="center"
    class="mb-1 mt-2 home-cp"
>
    <mat-vertical-stepper linear class="transparent home-cp-first-step home-cp-v-stepper items-stepper">
        <mat-step state="items" fxLayout="column" fxLayoutAlign="center">
            <ng-template matStepLabel>
                <span>
                    1- {{ ResourceTypes.label(resource_type_id, { capitalize: true }) }}
                    <span i18n="@@items">items</span>
                </span>
                <button
                    fxHide
                    class="tools-btn"
                    mat-icon-button
                    (click)="$event.stopPropagation()"
                    matTooltip="Delete"
                    i18n-matTooltip="@@delete"
                >
                    <mat-icon>expand_less</mat-icon>
                </button>
            </ng-template>
            <div *ngIf="!resource?.isPublished()" fxFlex.lt-md="100%">
                <ng-container *ngTemplateOutlet="coursePlanItems"></ng-container>
            </div>
            <div fxFlex.lt-md="100%">
                <app-resource-builder
                    [resource]="resource"
                    [active]="false"
                    [resourceTypes]="resourceTypes$ | async"
                    [resource_type_id]="resource_type_id"
                    [resourceType]="resourceType"
                    [hideAddItems]="true"
                    [language_id]="language_id"
                    (saved)="$event && savingParts && submitWithParts()"
                    (removal)="updateRemovedItems($event)"
                    [hideTools]="isEmpty(resourceBuilder?.getService().getForm() | async)"
                >
                </app-resource-builder>
            </div>
        </mat-step>
    </mat-vertical-stepper>
    <mat-vertical-stepper
        #vrStepper
        [linear]="!resource"
        class="mt-1 transparent home-cp-v-stepper home-cp-v-stepper-second"
    >
        <mat-step state="info" fxLayout="column" fxLayoutAlign="center" [stepControl]="settingsComponent?.form">
            <ng-template matStepLabel>
                <span> 2- <span i18n="@@CourseInformation">Course information</span> </span>
            </ng-template>
            <div fxFlex.lt-md="100%">
                <ng-container *ngTemplateOutlet="coursePlanInformations"></ng-container>
            </div>
        </mat-step>
        <mat-step
            *ngIf="!resource?.published && !(public$ | async) && resourceType !== ResourceTypes.PACKAGE"
            #accessStep
            state="access"
            label="3- Course access"
            i18n-label="@@courseAccess"
            fxLayout="column"
            [editable]="canPublish(user)"
            fxLayoutAlign="center"
            [stepControl]="accessComponent?.form"
            [aria-labelledby]="!canPublish(user) ? 'user_disabled' : null"
        >
            <div fxFlex.lt-md="100%">
                <ng-container *ngTemplateOutlet="coursePlanAccess"></ng-container>
            </div>
        </mat-step>
        <mat-step
            *ngIf="!resource?.published && !(public$ | async) && resourceType !== ResourceTypes.PACKAGE"
            #reminderStep
            class="disabled"
            state="reminders"
            label="4- Course reminders"
            i18n-label="@@CourseReminders"
            fxLayout="column"
            [editable]="canPublish(user)"
            fxLayoutAlign="center"
            [stepControl]="reminderComponent?.form"
            [aria-labelledby]="!canPublish(user) ? 'user_disabled' : null"
        >
            <div fxFlex.lt-md="100%">
                <ng-container *ngTemplateOutlet="coursePlanReminders"></ng-container>
            </div>
        </mat-step>
        <mat-step
            *ngIf="!resource?.published && !(public$ | async) && resourceType !== ResourceTypes.PACKAGE"
            state="publish"
            label="5- Course Publishing"
            i18n-label="@@CoursePublishing"
            fxLayout="column"
            [editable]="canPublish(user)"
            fxLayoutAlign="center"
            [stepControl]="publishComponent?.form"
            [aria-labelledby]="!canPublish(user) ? 'user_disabled' : null"
        >
            <div fxFlex.lt-md="100%">
                <ng-container *ngTemplateOutlet="coursePlanPublish"></ng-container>
            </div>
        </mat-step>
        <!-- Stepper icons -->
        <ng-template matStepperIcon="items">
            <mat-icon>category</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="info">
            <mat-icon>settings</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="access">
            <mat-icon>lock</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="reminders">
            <mat-icon>query_builder</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="publish">
            <mat-icon>publish</mat-icon>
        </ng-template>
    </mat-vertical-stepper>

    <ng-template #coursePlanItems>
        <app-course-plan-items
            [selection]="selection"
            [resource_type_id]="resource_type_id"
            (add)="addItem($event)"
        ></app-course-plan-items>
    </ng-template>
    <ng-template #coursePlanInformations>
        <app-course-plan-informations
            [appHasOne]="courseplanFrom.settings"
            [resourceType]="resourceType"
            [appScope]="appScope"
            (saved)="saved.emit($event)"
            (onSubmit)="onSubmit($event)"
            (onPublish)="onSubmit($event, true)"
            [canPublish]="canPublish(user)"
        ></app-course-plan-informations>
    </ng-template>
    <ng-template #coursePlanAccess>
        <app-course-plan-access
            *ngIf="vrStepper.selectedIndex === 1 && canPublish(user)"
            (saved)="updateResource($event); vrStepper.next()"
            [appHasOne]="courseplanFrom.access"
        >
        </app-course-plan-access>
    </ng-template>
    <ng-template #coursePlanReminders>
        <app-course-plan-reminders
            *ngIf="vrStepper.selectedIndex === 2 && canPublish(user)"
            (done)="vrStepper.next()"
            [appHasOne]="courseplanFrom.reminder"
        >
        </app-course-plan-reminders>
    </ng-template>
    <ng-template #coursePlanPublish>
        <app-course-plan-publish
            *ngIf="vrStepper.selectedIndex === 3 && canPublish(user)"
            [publishing]="publishing"
            (onPublish)="onPublish()"
            [appHasOne]="courseplanFrom.publish"
        >
        </app-course-plan-publish>
    </ng-template>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
