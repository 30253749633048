<div fxLayout="row">
    <mat-icon class="header-icon" header-icon>
        business
    </mat-icon>

    <mat-form-field *ngIf="academies" fxFlex="0 0 300px" fxFlex.lt-sm="0 0 100%">
        <mat-select placeholder="Select your Academy" i18n-placeholder [formControl]="control" required>
            <mat-option *ngFor="let customer of academies" [value]="customer.short_name">
                {{ customer.name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="control.invalid && control.touched" i18n>
            Academy is required!
        </mat-error>
    </mat-form-field>
</div>
<ng-template #loading>
    <mat-form-field fxFlexAlign="center" fxFlex="0 0 300px" fxFlex.lt-sm="0 0 100%">
        <mat-select disabled="true" placeholder="Select your Academy" i18n-placeholder></mat-select>
    </mat-form-field>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
