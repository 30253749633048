import { AfterViewInit, Directive, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { AppScope } from '@klickdata/core/resource';
import { CacheUtils, Utils } from '@klickdata/core/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filter, FilterSpecs } from './filter';
import { FilterCache } from './filter-core';
@Directive()
export abstract class FilterBase implements FilterCache, AfterViewInit {
    @ViewChild('activeFilterTemplate') activeTemplate: TemplateRef<any>;
    /**
     * When you use this input, don't forget
     * to put the local variable selector
     * check any implementation for checking.
     */
    @Input() set filterSpecs(filterSpecs: FilterSpecs) {
        this._filter = { ...this._filter, ...filterSpecs };
    }
    abstract filterChange: EventEmitter<Filter<string | number>>;
    filterRemoval: EventEmitter<Filter<string | number>>;
    protected abstract _filter: Filter<string | number>;
    private cached: any;
    private ـcacheScope: AppScope;
    public get cacheScope(): AppScope {
        return this.ـcacheScope;
    }
    public set cacheScope(value: AppScope) {
        this.ـcacheScope = value;
    }
    get filter(): Filter<string | number> {
        return this._filter;
    }
    protected setFilterItems(items: any) {
        this.filter.items = items;
    }

    ngAfterViewInit(): void {
        this.filter.template = this.activeTemplate;
    }

    public mapFilterChange(
        predicate: (value: Filter<string | number>) => boolean,
        apply: (fltr: Filter<string | number>) => void
    ): Observable<Filter<string | number> | Filter<string>> {
        return this.filterChange.pipe(
            map((value) => {
                if (predicate(value)) {
                    this.handleComposite(apply);
                    this.addToCache();
                } else {
                    this.setFilterItems(this.cached);
                }
                return this.filter;
            })
        );
    }

    private handleComposite(apply: (fltr: Filter<string | number>) => void) {
        if (this.filter.composite) {
            this.filter.composite.forEach((fltr) => {
                apply(fltr); // pass param to closure fn.
            });
        } else {
            apply(this.filter);
        }
    }

    public mapCache(apply: (fltr: Filter<string | number>) => void): Filter<string | number> {
        const cached = this.fromCache(this.getFilterProperty());
        if (!Utils.isEmpty(cached)) {
            this.cached = cached;
            this.setFilterItems(cached);
            this.handleComposite(apply);
            this.onCacheLoaded(this.cached);
            return this.filter;
        }
    }

    protected setFilter(fltr: Filter<string | number>) {
        this.filter.items = fltr.items;
    }

    public mapByFilter(
        filter: Filter<string | number>,
        apply: (fltr: Filter<string | number>) => void,
        addToCache?: boolean
    ): Filter<string | number> {
        this.setFilter(filter);
        this.handleComposite(apply);
        if (addToCache) {
            this.addToCache();
        }
        return this.filter;
    }

    protected onCacheLoaded(items: any) {}

    protected getFilterProperty(): string {
        return this.filter.property;
    }

    /**
     * update user local cache with latest filter values.
     * @param property filter property name.
     * @param value filter items values
     */
    private addToCache() {
        if (!this.ignoreCache()) {
            CacheUtils.setCache(`table-filter-${this.cacheScope}-${this.filter.property}`, this.getItemsForCache());
        } else {
            this.removeFromCache(this.filter);
        }
    }

    protected ignoreCache(): boolean {
        return false;
    }

    protected getItemsForCache(): any {
        return this.filter.items;
    }

    protected fromCache(property: string) {
        return CacheUtils.getFromCache(`table-filter-${this.cacheScope}-${property}`);
    }

    public removeFromCache(fltr: Filter<string | number>) {
        CacheUtils.removeFromCache(`table-filter-${this.cacheScope}-${fltr.property}`);
    }

    public remove() {
        this.removeFromCache(this.filter);
        this.clear();
    }
    public abstract clear(): void;
    public selectAll(): void {}
    public setQuery(query: string): void {}
    public setChipId(id: number): void {}
    public setSelectedOption(value: string | number): void {}
}
