import { compileNgModule } from '@angular/compiler';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { Resource, ResourceService, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceItemData } from '@klickdata/core/resource-item/src/resource-item';
import { Utils } from '@klickdata/core/util';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    share,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { CreateResourceBaseDirective } from '../../resource-create/create-resource-base.directive';
import { CoursePlanForm } from '../course-plan.form';
import { SettingsForm } from './settings.form';

export interface Currency {
    code: string;
    sign: string;
    label: string;
}

@Component({
    selector: 'app-course-plan-informations',
    templateUrl: './course-plan-informations.component.html',
    styleUrls: ['./course-plan-informations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SettingsForm],
})
export class CoursePlanInformationsComponent extends CreateResourceBaseDirective implements OnInit, OnDestroy {
    @Input() canPublish: boolean;
    @Input() resourceType = ResourceTypes.COURSE;
    @Input() appScope = AppScope.COURSE;
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onPublish: EventEmitter<boolean> = new EventEmitter<boolean>();
    public resource: Resource;
    public itemsHaveTests: boolean;
    public defaultResourceSummaryCount: number;
    public defaultCourseGoalCount: number;
    public testResourceItems: Observable<ResourceItemData[]>;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    public isArticleCodeValid: Observable<boolean>;
    public currencies: Currency[];
    public playTimeOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showMonths: false,
        showDays: false,
        showWeeks: false,
    };
    public durationOptions = {
        zeroValue: null,
        showPreview: false,
        showYears: false,
        showSeconds: false,
    };
    vaildArticleCode: Observable<boolean>;
    public instructorUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        protected settingsForm: SettingsForm,
        protected auth: AuthService,
        public courseplanFrom: CoursePlanForm,
        public cd: ChangeDetectorRef,
        protected configService: ConfigService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {
        super(messageService, resourceService, cdRef, dialog);
        this.currencies = Utils.getAllCurrencies();
        this.defaultResourceSummaryCount = this.configService.config.defaultResourceSummaryCharCount;
        this.defaultCourseGoalCount = this.configService.config.defaultResourceGoalCharCount;
    }

    get form(): FormGroup {
        return this.settingsForm.form;
    }

    get instructorForm(): FormGroup {
        return this.settingsForm.instructorForm;
    }

    // get publisherForm(): FormGroup {
    //     return this.settingsForm.publisherForm;
    // }

    get examinationForm(): FormGroup {
        return this.settingsForm.examinationForm;
    }

    // get screenshotsForm(): FormGroup {
    //     return this.settingsForm.screenshotsForm;
    // }

    get loading(): Subject<boolean> {
        return this.settingsForm.parent.getLoading();
    }

    get published(): boolean {
        return !!this.settingsForm.model?.published;
    }
    get isPublished(): boolean {
        return !!this.settingsForm.model?.isPublished();
    }

    public ngOnInit(): void {
        this.testResourceItems = this.courseplanFrom.courseResourceItems.asObservable().pipe(
            map((items) => items.filter((item) => item.mandatory && item.item_type_value === 'test')),
            tap((items) => {
                if (items.length !== 0) {
                    this.itemsHaveTests = true;
                    if (!this.examinationForm.value.final_test_id) {
                        this.examinationForm.patchValue({ final_test_id: items[items.length - 1].child_resource_id });
                    }
                }
                this.cd.markForCheck();
            })
        );

        this.vaildArticleCode = this.form.get('article_code').valueChanges.pipe(
            filter((query) => !!query && typeof query === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => {
                if (query) {
                    return this.resourceService.checkFieldValidaty({ article_code: query });
                }
            }),
            share(),
            takeUntil(this.destroy)
        );
        this.settingsForm.create().pipe(takeUntil(this.destroy)).subscribe();

        this.settingsForm.onParentUpdated.pipe(first(), takeUntil(this.destroy)).subscribe((coursePlan) => {
            this.resource = coursePlan;
        });
        // handle open fromcontrol update.
        combineLatest([this.auth.getUser(), <Observable<boolean>>this.form.get('always_available').valueChanges])
            .pipe(
                takeUntil(this.destroy),
                filter(([user, open]) => !this.resource || user.canEdit(this.resource)),
                map(([user, open]) => open)
            )
            .subscribe((open) => this.settingsForm.initDatePeriod(open));

        this.form
            .get('unlimited_seats')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((unlimited) => {
                unlimited ? this.form.get('total_seats').disable() : this.form.get('total_seats').enable();
            });
    }

    protected afterPublishDone(newResource: Resource) {
        this.courseplanFrom.import(newResource.getData());
        this.cdRef.markForCheck();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public onManagePublic(value: boolean) {
        this.form.patchValue({ public: !value });
        this.onSubmit.emit(false);
        this.messageService.openMessage(
            MessageSavedComponent,
            value
                ? $localize`:@@CourseMadePublic:The Course is no longer available in the Klick Data Open Library.`
                : $localize`:@@CourseMadePublic:The Course is now public in this Academy and in Klick Data Open Library.`
        );
    }

    public toggleLoading(ev: boolean) {
        ev ? this.instructorUploading.next(true) : this.instructorUploading.next(false);
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
}
