import { Injectable } from '@angular/core';
import { ErrorResponse, ResponseData } from '@klickdata/core/http';
import { Observable } from 'rxjs';
import { AuthService } from '../token/auth.service';

@Injectable()
export class OccasionAuthService extends AuthService {
    public tokenLogin(occasionId: number, token: string): Observable<ResponseData<any> | ErrorResponse<any>> {
        const url = `${this.config.config.apiUrl}auth/occasion/${occasionId}`;

        return this.builder
            .post<any>(url, { token: token })
            .request();
    }
}
