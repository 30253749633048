<div class="main-wrap" fxLayout="column">
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddMaterialBtn ? -1 : 1 }">
        <button type="button" *ngIf="showAddMaterialBtn" mat-button (click)="addItem(AppScope.MATERIAL)">
            <mat-icon>add_circle_outline</mat-icon><span>Add Material</span>
        </button>
        <app-course-manager-core-add-material
            #compRef
            *ngIf="!showAddMaterialBtn"
            [display]="displayTab"
            [selection]="selection"
            (select)="addResource($event) && (showAddMaterialBtn = true)"
            (onError)="onError.emit($event)"
            (onCloseView)="$event && (showAddMaterialBtn = true)"
        ></app-course-manager-core-add-material>
    </div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddTestBtn ? -1 : 2 }">
        <button type="button" *ngIf="showAddTestBtn" mat-button (click)="addItem(AppScope.TEST)">
            <mat-icon>add_circle_outline</mat-icon><span>Add Test</span>
        </button>
        <app-course-manager-core-add-test
            #compRef
            *ngIf="!showAddTestBtn"
            [display]="displayTab"
            [selection]="selection"
            (add)="addResource($event); showAddTestBtn = true"
            (onError)="onError.emit($event)"
            (onCloseView)="$event && (showAddTestBtn = true)"
        >
        </app-course-manager-core-add-test>
    </div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddSurveyBtn ? -1 : 3 }">
        <button type="button" *ngIf="showAddSurveyBtn" mat-button (click)="addItem(AppScope.SURVEY)">
            <mat-icon>add_circle_outline</mat-icon><span>Add Survey</span>
        </button>
        <app-course-manager-core-add-survey
            #compRef
            *ngIf="!showAddSurveyBtn"
            [display]="displayTab"
            [selection]="selection"
            (add)="addResource($event); showAddSurveyBtn = true"
            (onError)="onError.emit($event)"
            (onCloseView)="$event && (showAddSurveyBtn = true)"
        ></app-course-manager-core-add-survey>
    </div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddSurveyBtn ? -1 : 4 }">
        <button type="button" *ngIf="showAddEventBtn" mat-button (click)="addItem(AppScope.EVENT)">
            <mat-icon>add_circle_outline</mat-icon><span>Add Event</span>
        </button>
        <!-- <app-course-manager-core-add-survey
            *ngIf="!showAddSurveyBtn"
            [display]="displayTab"
            [selection]="selection"
            (add)="addResource($event); showAddSurveyBtn = true"
            (onCloseView)="$event && (showAddSurveyBtn = true)"
        ></app-course-manager-core-add-survey> -->
    </div>
</div>
