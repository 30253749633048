<app-resource-details-info
    [resource]="resource"
    [user]="user"
    [customer]="customer"
    [resourceItems]="resourceItems"
    [resourceCategories$]="resourceCategories$"
    [resourceTags$]="resourceTags$"
    [resourceType]="ResourceTypes.parentType(resource.type_id)"
    resourceLabel="Survey"
    i18n-resourceLabel
>
    <ng-container itemsHeader>
        <div fxLayoutGap="7px" fxLayoutAlign="start center">
            <mat-icon>quiz</mat-icon>
            <span i18n>Survey include</span>
            <span>{{ resourceItems?.length }}</span>
            <span i18n>Questions</span>
        </div>
    </ng-container>
    <span managerLabel i18n="@@author">Author</span>
    <ng-container resourceItemsSection></ng-container>
    <span actionBtnLabel i18n="@@start">Start</span>
    <span resCodeLabel i18n>Survey code</span>
    <ng-container resourceDates></ng-container>
    <ng-container resourceCircles>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon matListIcon fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
            </div>
            <span i18n>Survey</span>
        </div>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon class="font">quiz</mat-icon>
            </div>
            <span>{{ resourceItems?.length }} Q</span>
        </div>
    </ng-container>
</app-resource-details-info>
