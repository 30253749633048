import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { QuestionTypes } from '@klickdata/core/question';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceCreationManagerBase,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceBuilderComponent } from '../resource-builder/resource-builder.component';

@Component({
    selector: 'app-test-manager-core',
    templateUrl: './test-manager-core.component.html',
    styleUrls: ['./test-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TestManagerCoreComponent extends ResourceCreationManagerBase implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(ResourceBuilderComponent) resourceBuilder: ResourceBuilderComponent;
    @Input() bottomToolSheet: boolean;
    public questionTypes = [QuestionTypes.CHECKBOX, QuestionTypes.RADIO, QuestionTypes.WOK];
    ResourceTypes = ResourceTypes;
    public savingQuestions: boolean;
    AppScope = AppScope;
    typeId = ResourceTypes.TEST;
    @Input() resource: Resource;

    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected route: ActivatedRoute,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {
        super(categoryService, auth, parentFormDirective, messageService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl(
            'grade_system_id',
            new FormControl(this.resource ? this.resource.grade_system_id : '', Validators.required)
        );
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource) {
            this.resourceForm.get('grade_system_id')?.patchValue(this.resource.grade_system_id);
            FormHelper.resetForm(this.resourceForm.get('grade_system_id') as FormControl);
            if (this.resource.items_attached) {
                this.onResKeysValidatyChanges.emit(this.getResKeysValidaty());
                this.cdRef.markForCheck;
            }
        }
    }
    ngAfterViewInit(): void {
        this.resourceForm.addControl('items', this.resourceBuilder.getService().getCurrentForm().get('items'));
    }
    public isEmpty(form: any) {
        return !(<FormArray>form?.get('items'))?.controls.length;
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Test title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Test language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Test categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'grade_system_id',
                title: $localize`Test grade system`,
                hasValidValue: true,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Test questions`,
                hasValidValue: this.resourceBuilder?.getService()?.readyForSubmit(),
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Test image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Test tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Test Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this test that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Test description`,
                info: $localize`A description of the test that will be visible befor you take the test.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
        ];
    }
    /**
     * Save/Publish button action.
     */
    public onSubmit() {
        this.loading.emit(true);
        this.checkSubmitValid();
    }
    checkSubmitValid() {
        const itemsReady = this.resourceBuilder.getService().readyForSubmit();
        if (this.resourceForm.invalid) {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.resourceForm, this.formats)} is missing`
            );

            this.loading.emit(false);

            return of(false);
        }

        if (!itemsReady) {
            if (this.publish) {
                this.messageService.openMessage(
                    MessageErrorComponent,
                    $localize`Questions are required and must be valid!`
                );
                this.loading.emit(false);
                return of(false);
            } else {
                this.submitResourceRegardlessQs();
                return of(true);
            }
        } else {
            this.savingQuestions = true;
            this.resourceBuilder.getService().submit();
            return of(true);
        }
    }
    /**
     * Submit test after question saved event emitted from RB!
     */
    public submitResourceRegardlessQs() {
        this.savingQuestions = false;
        super.performResSubmit();
    }

    public prepareSubmit(): Observable<Resource> {
        return this.prepareForm().pipe(
            takeUntil(this.destroy),
            switchMap((data) => (!data.id ? this.resourceService.store(data) : this.resourceService.update(data, true)))
        );
    }

    public prepareForm(): Observable<ResourceData> {
        return combineLatest([this.auth.getUser().pipe(first()), this.resourceBuilder.getResource()]).pipe(
            first(),
            map(([user, resource]) => {
                const data = resource
                    ? resource.getData(this.resourceForm.value)
                    : new Resource(this.resourceForm.value).getData();

                if (!data.id) {
                    data.type_id = ResourceTypes.GeneralTest; // Defines that this resource is of type general_test.
                    data.customer_id = user.customer_id;
                    data.author_id = user.id;
                }
                if (this.publish) {
                    if ((data && data.published) || (this.resource && this.resource?.published)) {
                        data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        data.published = moment().format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                if (!this.publish && data) {
                    data.last_publish = null;
                }
                return new Resource(data).getData();
            })
        );
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }

    public canDeactivate(): boolean {
        return super.canDeactivate() && this.resourceBuilder.getService().getCurrentForm().pristine;
    }
}
