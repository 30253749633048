<mat-accordion displayMode="flat">
    <mat-expansion-panel class="wrapper-panel" [expanded]="expandAll || expandedItem" #expansionPanel>
        <mat-expansion-panel-header class="post-item-expansion-header">
            <div *ngIf="!expansionPanel.expanded" class="post-img">
                <app-image-view [smallSpinner]="true" [media_id]="post.media" [proportional]="true"></app-image-view>
            </div>
            <div class="post-header-content-wrapper" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                <h3 [ngClass]="{ blue: !!post.public }">
                    <span [ngClass]="{ 'post-span-title': !expansionPanel.expanded }" class="post-span-title">
                        {{ post.title }}</span
                    >
                </h3>
                <div class="details" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>
                        {{ post.published_at | kdDateFormat : 'MMM D, YYYY' }}
                    </span>
                    <ng-container *ngIf="post.publisher">
                        <span class="hide-mobile" i18n>by</span>
                        <span class="hide-mobile">
                            {{ post.publisher }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="mr-10" fxLayoutAlign="space-between center">
                <button
                    *ngIf="user.isAdmin()"
                    mat-icon-button
                    type="button"
                    fxFlex="0 0 auto"
                    matTooltip="Edit news"
                    i18n-matTooltip="@@editNews"
                    class="toggle-icon edit"
                    (click)="$event.stopPropagation(); edit()"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    *ngIf="post.downloads"
                    mat-icon-button
                    type="button"
                    color="primary"
                    fxFlex="0 0 auto"
                    matTooltip="download news"
                    i18n-matTooltip="@@downloadNews"
                    class="toggle-icon"
                    (click)="$event.stopPropagation(); download()"
                >
                    <mat-icon>save_alt</mat-icon>
                </button>
                <app-language-selector [language_id]="post.language_id" fxFlex="0 0 auto"></app-language-selector>
                <button
                    mat-icon-button
                    *ngIf="expansionPanel.expanded"
                    type="button"
                    color="primary"
                    fxFlex="0 0 auto"
                    matTooltip="Show extra"
                    i18n-matTooltip="@@showExtra"
                    class="toggle-icon"
                    (click)="$event.stopPropagation(); fullPostBody = !fullPostBody"
                >
                    <mat-icon [class.expanded-all]="fullPostBody">{{ fullPostBody ? 'notes' : 'reorder' }}</mat-icon>
                </button>
            </div>
        </mat-expansion-panel-header>

        <div fxLayout="column" fxLayoutGap="10px">
            <div class="post-img-wrapper" [ngClass]="{ 'full-post-details': fullPostBody }">
                <app-image-view *ngIf="post.media" [proportional]="false" [media_id]="post.media" paddingBottom="70%">
                </app-image-view>
            </div>
            <div *ngIf="post.tags && post.tags.length">
                <mat-chip-list class="mt-1">
                    <mat-chip
                        *ngFor="let tag of post.tags"
                        (click)="onFilterByTag.emit(tag)"
                        class="res-chip-item tag-chip"
                        ><span>{{ tag.name }}</span>
                        <app-language-selector
                            class="flag tag-chip-lang-select"
                            fxFlex="0 0 auto"
                            [language_id]="tag.language_id"
                        >
                        </app-language-selector>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div fxLayoutAlign="start center" fxLayoutGap="5px" class="hide-desktop">
                <span i18n>by</span>
                <span *ngIf="post.author$ | async; else smallLoading; let author">
                    {{ author.name }}
                </span>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <p class="item-ingress" *ngIf="post.ingress">{{ post.ingress }}</p>
                <div fxLayout.lt-md="row wrap" *ngIf="!fullPostBody" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div class="short-body" [innerHtml]="post.short_body | innerHtml : 'inner-HTML-course'"></div>
                    <span (click)="fullPostBody = true" class="read-more" i18n>Read more</span>
                </div>
                <div *ngIf="fullPostBody" fxLayoutAlign="center start" fxLayoutGap="5px" fxLayout="column">
                    <div [innerHtml]="post.body | innerHtml : 'inner-HTML-course'"></div>
                    <span (click)="fullPostBody = false" class="read-more" i18n>Read less</span>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <h1>
            <app-kd-logo-loading size="small"></app-kd-logo-loading>
        </h1>
    </div>
</ng-template>
