import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageSent, UserService } from '@klickdata/core/user/src/user.service';
import { Utils } from '@klickdata/core/util';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, share, switchMap } from 'rxjs/operators';
import { CustomerCategoryService } from './customer-category/customer-category.service';
import { CustomerLicenseService } from './customer-license/customer-license.service';
import { CustomerPublish } from './customer-publish.model';
import { CustomerServiceService } from './customer-service/customer-service.service';
import { Customer, CustomerData } from './customer.model';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    protected resourceUrl: string;
    protected customerLogoTypeUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    private customersMap = new Map<number, Observable<Customer>>();

    /**
     * CustomerService constructor
     */
    constructor(
        protected auth: AuthService,
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected serviceService: CustomerServiceService,
        protected userService: UserService,
        protected mediaService: MediaService,
        protected licenseService: CustomerLicenseService,
        protected categoryService: CustomerCategoryService
    ) {
        this.resourceUrl = `${config.config.apiUrl}customers`;
        this.customerLogoTypeUrl = `${config.config.apiUrl}auth/customer/logotype`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Create a RequestBuilder for fetching customers.
     */
    public getCustomers(query?: string, limit?: number): Observable<Customer[]> {
        return this.builder
            .get<CustomerData[]>(this.resourceUrl)
            .param('query', query)
            .limit(limit)
            .request()
            .pipe(map((res) => this.createCustomers(res)));
    }

    public getCustomerLogoType(): Observable<any> {
        const subdomain = Utils.getSubdomain();
        let logotype_id: number;
        return this.auth.check().pipe(
            switchMap((auth) =>
                auth
                    ? this.auth.getCustomer().pipe(
                          filter((customer) => customer?.logotype_id && logotype_id !== customer.logotype_id),
                          switchMap((customer) => {
                              logotype_id = customer.logotype_id;
                              return this.mediaService.getMedia(customer.logotype_id).pipe(
                                  map((media) =>
                                      media
                                          ? {
                                                url: media.url,
                                                title: customer.name,
                                                padding: customer.logotype_padding,
                                            }
                                          : null
                                  )
                              );
                          })
                      )
                    : subdomain
                    ? this.builder
                          .get<CustomerData>(this.customerLogoTypeUrl)
                          .param('short_name', subdomain)
                          .request()
                          .pipe(map((res) => (res.data?.url ? res.data : null)))
                    : of(null)
            )
        );
    }

    public getOwnerCustomers(): Observable<Customer[]> {
        return this.builder
            .get<CustomerData[]>(this.resourceUrl)
            .param('owner', this.user_id)
            .request()
            .pipe(map((res) => this.createCustomers(res)));
    }

    /**
     * Create a new customer.
     */
    public create(customer: CustomerData): Observable<Customer> {
        return this.builder
            .post<CustomerData>(this.resourceUrl, customer)
            .request()
            .pipe(map((res) => this.createCustomer(res.data)));
    }

    /**
     * Update a customer
     */
    public update(customer: CustomerData): Observable<Customer> {
        return this.builder
            .put<CustomerData>(`${this.resourceUrl}/${customer.id}`, customer)
            .request()
            .pipe(map((res) => new Customer(res.data)));
    }

    /**
     * Send publish request for customer to the server.
     */
    public publish(customer: Customer, publish: CustomerPublish): Observable<MessageSent> {
        return this.builder
            .put<MessageSent>(`${this.resourceUrl}/${customer.id}/publish`, publish)
            .request()
            .pipe(map((res) => res.data));
    }

    /**
     * Delete customer
     */
    public destroy(customer: Customer): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${customer.id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    protected createCustomer(customerData: CustomerData): Customer {
        const customer = new Customer(customerData);
        customer.services = this.serviceService.getServices(customer.id);
        customer.owner_contact$ = this.userService.getUser(customer.owner_contact_id);
        customer.license = this.licenseService.getLicense(customer.license_type);
        customer.category = this.categoryService.getCategory(customer.category_value);
        customer.administrators = this.userService.getCustomerAdministrators(customer.id);

        return customer;
    }

    protected createCustomers(res: ResponseData<CustomerData[]>): Customer[] {
        return res.data.map((data) => this.createCustomer(data));
    }

    public getCustomer(customer_id: number): Observable<Customer> {
        let customer = this.customersMap.get(customer_id);
        if (customer) {
            return customer;
        }
        customer = this.fetchCustomer(customer_id).pipe(
            share(),
            catchError(() => of(new Customer({ name: 'Anonymous' })))
        );
        this.customersMap.set(customer_id, customer);
        return customer;
    }

    private fetchCustomer(customerId: number): Observable<Customer> {
        if (!customerId) {
            return of(new Customer());
        }
        return this.builder
            .get<CustomerData>(`${this.resourceUrl}/${customerId}`)
            .request()
            .pipe(map((res) => this.createCustomer(res.data)));
    }
}
