import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application/src/model/model-interface';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import * as moment from 'moment';

export interface ResourceOpportunityData extends IDataModel {
    id?: number;
    resource_id?: number;
    parent_id?: number;
    user_id?: string;
    done?: string;
    diploma?: string; // diploma link
    started_at?: string;
    cancelled?: string;
    updated_at?: string;
    created_at?: string;
    deleted_at?: string;

    // handle route continue..
    continue?: boolean;
}

export class ResourceOpportunity extends Model<ResourceOpportunityData> {
    id: number;
    resource_id: number;
    parent_id: number;
    user_id: string;
    diploma: string;
    @Cast(CastType.MOMENT)
    done: moment.Moment;
    @Cast(CastType.MOMENT)
    started_at: moment.Moment;
    @Cast(CastType.MOMENT)
    cancelled: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    deleted_at: moment.Moment;

    // handle route continue.
    continue: boolean;

    // report, downloads
    @Ignore()
    public downloads?: Downloads;
}

export class ResourceOpportunityWithStatistics extends ResourceOpportunity {}
