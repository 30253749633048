import { Validators } from '@angular/forms';
import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    Model,
    OnExport,
} from '@klickdata/core/application/src/model/model-interface';
import { Prop } from '@klickdata/core/form';
import * as moment from 'moment';

export interface ReminderData extends IDataModel {
    id?: number;
    assign_id?: number;
    subject?: string;
    body?: string;
    date_time?: string;
    dates?: string[];
    created_at?: string;
    updated_at?: string;
}

export class Reminder extends Model<ReminderData> implements OnExport {
    public id: number;
    public assign_id: number;
    @Prop({
        validation: Validators.required,
    })
    public subject: string;

    @Prop({
        validation: Validators.required,
    })
    public body: string;
    public dates: moment.Moment[];

    @Cast(CastType.MOMENT)
    @Ignore()
    public date_time: moment.Moment;

    @Cast(CastType.MOMENT)
    @Ignore()
    public created_at: moment.Moment;

    @Cast(CastType.MOMENT)
    @Ignore()
    public updated_at: moment.Moment;

    onExport(data: ReminderData): void {
        if (data.dates && this.dates) {
            data.dates = this.dates
                .filter((rmd, i, rmds) => rmds.findIndex((d) => d.isSame(rmd, 'day')) === i)
                .map((r) => r.format('YYYY-MM-DD'));
        }
    }
}
