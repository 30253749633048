import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestMethods } from '@klickdata/core/http/src/request/request-methods.enum';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { Filter } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class RequestBuilder<T> {
    protected params: HttpParams = new HttpParams();
    protected getResponse = false;
    protected observables: Observable<{ param: string; value: string | number }>[] = [];

    constructor(
        protected http: HttpClient,
        protected method: RequestMethods,
        protected url: string,
        protected body?: any
    ) {}

    public limit(limit: number): RequestBuilder<T> {
        if (limit) {
            this.params = this.params = this.params.set('limit', limit.toString(10));
        }
        return this as RequestBuilder<T>;
    }

    public page(page: number): RequestBuilder<T> {
        if (page) {
            this.params = this.params.set('page', page.toString(10));
        }
        return this as RequestBuilder<T>;
    }

    public query(query: string): RequestBuilder<T> {
        if (query?.length) {
            this.params = this.params.set('query', query);
        }
        return this as RequestBuilder<T>;
    }

    public ids(ids: number[]): RequestBuilder<T> {
        if (ids?.length) {
            this.params = this.params.set('ids', ids.join());
        }
        return this as RequestBuilder<T>;
    }

    public filter(filter: Filter<string | number>): RequestBuilder<T> {
        if (filter?.items?.length) {
            this.params = this.params.set(filter.property, filter.items.join());
        }
        return this as RequestBuilder<T>;
    }

    public filters(filters: Filter<string | number>[]): RequestBuilder<T> {
        if (filters?.length) {
            filters.forEach((filter) => this.filter(filter));
        }
        return this as RequestBuilder<T>;
    }

    public putParam(params: { [key: string]: any }) {
        if (!Utils.isEmpty(params)) {
            Object.entries(params).forEach(([key, value]) =>
                this.param(key, !Array.isArray(value) ? value : value.join())
            );
        }
        return this as RequestBuilder<T>;
    }

    public param(
        param: string,
        value: string | number | string[] | number[] | Observable<string | number | string[] | number[]>
    ): RequestBuilder<T> {
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            if (typeof value === 'number') {
                value = value.toString();
            }

            this.params = this.params.set(param, value);
        } else if (value instanceof Array) {
            for (const item of value) {
                if (typeof item === 'number') {
                    const sItem = item.toString();
                    this.params = this.params.append(`${param}[]`, sItem);
                } else {
                    this.params = this.params.append(`${param}[]`, item);
                }
            }
        } else if (value instanceof Observable) {
            const obs = value.pipe(
                map((val: string | number): { param: string; value: string | number } => {
                    return { param: param, value: val };
                })
            );

            this.observables.push(obs);
        }

        return this as RequestBuilder<T>;
    }

    public fullResponce(): RequestBuilder<T> {
        this.getResponse = true;
        return this as RequestBuilder<T>;
    }

    public request(): Observable<ResponseData<T>> {
        const options: {
            body?: any;
            params?: HttpParams;
        } = {
            params: this.params,
            body: null,
        };

        if (this.body) {
            options.body = this.body;
        }

        return this.resolveObservables().pipe(
            switchMap((params: { param: string; value: string }[]) => {
                for (const param of params) {
                    options.params = options.params.set(param.param, param.value);
                }

                return this.http.request<ResponseData<T>>(this.method, this.url, options);
            })
        );
    }

    public download(): Observable<Blob> {
        const options: {
            body?: any;
            params?: HttpParams;
            responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
        } = {
            params: this.params,
            body: null,
            responseType: 'blob',
        };

        if (this.body) {
            options.body = this.body;
        }

        return this.resolveObservables().pipe(
            switchMap((params: { param: string; value: string }[]) => {
                for (const param of params) {
                    options.params = options.params.set(param.param, param.value);
                }

                return this.http.request(this.method, this.url, options);
            })
        );
    }

    protected resolveObservables(): Observable<{ param: string; value: string | number }[]> {
        if (this.observables.length) {
            return zip(...this.observables);
        }

        return of([]);
    }
}
