<ng-container *ngIf="!loading; else loadingTemp">
    <div *ngIf="selected; else selectTemp" fxFlex="0 0 100%" class="selected-wrapper" fxLayout="row wrap">
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
            <div [ngStyle]="{ width: '100%' }" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <h2 fxFlex="0 0 auto">{{ selected.title }}</h2>
                <a
                    fxFlex="0 0 auto"
                    [routerLink]="['/player/resource/', selected.id]"
                    target="_blank"
                    mat-icon-button
                    fxHide.lt-md="true"
                    matTooltip="Preview test"
                    i18n-matTooltip="@@previewTest"
                >
                    <mat-icon class="md-24">open_in_new</mat-icon>
                </a>
            </div>
            <button
                fxHide="true"
                (click)="changeTest()"
                fxLayout="row"
                fxLayoutAlign="start center"
                mat-button
                color="primary"
                matTooltip="Change test"
                i18n-matTooltip="@@changeTest"
            >
                <ng-container i18n="@@changeTest">Change test</ng-container>
                <mat-icon class="md-24">edit</mat-icon>
            </button>
        </div>
        <app-image-view
            fxFlex="0 1 20%"
            *ngIf="selected.media_id"
            [media_id]="selected.media_id"
            [proportional]="true"
        ></app-image-view>
        <div fxFlex="0 1 calc(80% - 2em)" [innerHtml]="selected.description"></div>

        <!-- Handle display questions with test under CP parts -->
        <app-resource-builder
            fxFlex="0 0 100%"
            [resource]="selected"
            [language_id]="selected.language_id"
            [questionTypes]="questionTypes"
            [resource_type_id]="ResourceTypes.GeneralTest"
            [resourceType]="ResourceTypes.TEST"
            [active]="true"
        ></app-resource-builder>
    </div>
</ng-container>

<ng-template #selectTemp>
    <app-resource-library
        [selection]="selection"
        [type_scope_id]="AppScope.TEST"
        (select)="selectResource($event)"
    ></app-resource-library>
</ng-template>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
