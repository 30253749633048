import {
    IDataModel,
    Ignore,
    InitialValue,
    MethodType,
    Model,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { Media } from '@klickdata/core/media/src/media.model';
import { Observable } from 'rxjs';
import { Alternative, AlternativeData } from './alternative/alternative';
import { Answer } from './answer/answer';

export interface QuestionData extends IDataModel {
    id?: number;
    item_id?: number;
    img_url: string;
    title?: string;
    description?: string;
    learning_field?: string;
    references?: string;
    points?: number;
    question_type_value?: string;
    required_answers?: boolean;
    media_id?: number;
    alternative_ids?: number[];
    other_alternative?: boolean;
    user_answer?: boolean;
    randomize_alternatives?: boolean;
    alternatives?: AlternativeData[];
    qs_answered?: number[]; // answered question indices/sequences
    max_points?: number;
    user_points?: number;
    sequence?: number;
}

export class Question extends Model<QuestionData> {
    id: number;
    item_id?: number;
    title: string;
    description: string;
    learning_field: string;
    references: string;
    points: number;
    question_type_value: string;
    required_answers: boolean;
    @Nullable(MethodType.PUT)
    media_id: number;
    @Nullable(MethodType.PUT)
    img_url: string;

    @InitialValue([])
    alternative_ids: number[];
    other_alternative: boolean;
    user_answer: boolean;
    randomize_alternatives: boolean;

    alternatives$: Observable<Alternative[]>;
    alternatives: Alternative[];
    media$: Observable<Media>;
    @Ignore()
    qs_answered: number[]; // answered question indices/sequences
    @Ignore()
    answers: Answer[];
    max_points: number;
    user_points: number;
    sequence: number;

    @Ignore()
    correctAnswers: Alternative[];
    @Ignore()
    type: 'radio' | 'checkbox';
}
