<div fxLayout="column">
    <mat-form-field fxFlex="0 1 100%" class="mb-4">
        <input matInput placeholder="Link" type="url" i18n-placeholder formControlName="link" />
        <button
            matTooltip="Open link in new tab"
            i18n-matTooltip
            type="button"
            [disabled]="
                !resourceForm.value.link || (!!resourceForm.value.link && !Utils.isUrl(resourceForm.value.link))
            "
            class="open-link"
            mat-icon-button
            matSuffix
            (click)="goToLink(resourceForm.value.link)"
        >
            <mat-icon>open_in_new</mat-icon>
        </button>
    </mat-form-field>
    <div
        *ngIf="!!resourceForm.value.link && Utils.isUrl(resourceForm.value.link)"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
        fxLayout.lt-md="column"
    >
        <app-media-view
            fxLayoutAlign="center center"
            *ngIf="mediaKey as mediaId"
            fxFlex="0 0 100%"
            [media_id]="mediaId"
        >
        </app-media-view>
        <ng-container *ngIf="!!resourceForm.value.link && Utils.isYoutubeURL(resourceForm.value.link)">
            <ng-container *ngIf="youTubeVideoInfo$ | async; let videoData; else: youTubeNoInfo">
                <div class="info-wrapper" fxLayout="column" fxLayoutGap="10px">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="info-title" i18n>Video title: </span>
                        <span class="info">{{ videoData.title }}</span>
                    </div>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="info-title" i18n>Duration: </span>
                        <span class="info">{{ videoData.duration | formatIsoDuration }}</span>
                    </div>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span class="info-title" i18n>Source: </span>
                        <span class="info">Youtube</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-template #youTubeNoInfo
    ><ng-container *ngIf="isLoadingVideoInfo" [ngTemplateOutlet]="smallLoading"></ng-container>
</ng-template>

<ng-template #smallLoading>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
