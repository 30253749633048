import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppScope } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Observable, of } from 'rxjs';
import { Media, MediaData } from '../media.model';
import { MediaService } from '../media.service';
import { LoggerService } from '@klickdata/core/application';

export type MediaLoadType = string | number | Array<number> | Media | MediaData | Observable<Media>;

@Component({
    selector: 'app-image-view',
    templateUrl: './image-view.component.html',
    styleUrls: ['./image-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewComponent implements OnInit {
    @Input() set media_id(value: MediaLoadType) {
        if (value && !Utils.isEqual(this._media_id, value)) {
            this._media_id = value;
            this.loadMediaOnce(value);
        }
    }
    private _media_id: MediaLoadType;
    @Input() paddingBottom = 100 / (16 / 9) + '%';
    @Input() proportional = true;
    @Input() activateOverLay: boolean;
    @Input() smallSpinner = false;
    @Input() isCircularImg = false;
    @Input() scope: AppScope;
    @Output() srcLoaded: EventEmitter<string> = new EventEmitter<string>();
    public media$: Observable<Media>;
    /**
     * TO display a circular image, paddingBottom should be passed
     * and isCircularImg to be true and add the ngStyle to the host with the
     * paddingBottom value like this example
     * <app-image-view
       [ngStyle]="{ minWidth: '50px' }"
       [isCircularImg]="true"
       [media_id]="user.media"
       paddingBottom="50px"
       ></app-image-view>
     */

    constructor(protected mediaService: MediaService, protected logger: LoggerService) {}

    public ngOnInit(): void {}

    protected loadMediaOnce(value: MediaLoadType) {
        if (value instanceof Observable) {
            this.media$ = value;
        } else if (value instanceof Media) {
            this.media$ = of(value);
        } else if (
            !Array.isArray(value) &&
            typeof value === 'object' &&
            (value.src || value.url || value.default_url || value.fallback_url)
        ) {
            this.media$ = of(new Media(value));
        } else if (typeof value === 'string') {
            this.media$ = of(Utils.getMedia(value));
        } else if (typeof value === 'number') {
            this.media$ = this.mediaService.getMedia(value, this.scope);
        } else if (Array.isArray(value)) {
            this.media$ = this.mediaService.getMedia(value[0], this.scope);
        }
    }

    public onError(item: Media) {
        if (item.url) {
            item.url = null;
        } else if (item.default_url) {
            item.default_url = null;
        }
    }
}
