export const environmentBase = {
    /**
     * Base, default, share config definitions.
     */
    language: 'en-US',
    languages: [
        {
            value: 'de',
            lang: 'de',
            flag: 'de',
            label: 'Deutsch',
            alt: 'German',
        },
        {
            value: 'en',
            lang: 'en-US',
            flag: 'gb',
            label: 'English',
            alt: 'English',
        },
        {
            value: 'es',
            lang: 'es',
            flag: 'es',
            label: 'Español',
            alt: 'Spanish',
        },
        {
            value: 'pt',
            lang: 'pt-BR',
            flag: 'br',
            label: 'Português',
            alt: 'Portuguese (BR)',
        },
        {
            value: 'fr',
            lang: 'fr',
            flag: 'fr',
            label: 'Français',
            alt: 'French',
        },
        {
            value: 'it',
            lang: 'ot',
            flag: 'it',
            label: 'Italiano',
            alt: 'Italy',
        },
        {
            value: 'sv',
            lang: 'sv',
            flag: 'se',
            label: 'Svenska',
            alt: 'Svenska',
        },
        {
            value: 'yo',
            lang: 'yo',
            flag: 'ng',
            label: 'Yoruba',
            alt: 'Yoruba',
        },
        {
            value: 'ar',
            lang: 'ar',
            flag: 'arl',
            label: 'العربية',
            alt: 'arabic',
        },
        {
            value: 'ta',
            lang: 'ta',
            flag: 'ta',
            label: 'தமிழ்',
            alt: 'Tamil',
        },
    ],
    adeccoMaterialCoronaUrl: 'https://store.klickdata.se/adecco/coronavirus/story.html',
    coronaTestId: 2106,

    // Scorm Player languages
    scormPlayerLanguages: [
        {
            key: 'en-gb',
            value: 'en-US',
            label: 'English',
            alt: 'English',
            lang: 'en-US',
            flag: 'gb',
        },
        {
            key: 'sv-se',
            value: 'sv',
            label: 'Svenska',
            alt: 'Svenska',
            lang: 'sv',
            flag: 'se',
        },
        {
            key: 'ar-arl',
            value: 'ar',
            label: 'العربية',
            alt: 'arabic',
            lang: 'ar',
            flag: 'arl',
        },
    ],

    /**
     * Default param ids, you can override this config dev,staging or prod
     */
    defaultGradeSystemId: 22,
    // reservedSubdomains: ['localhost', 'staging', 'kunskap'],
    // Default param ids, you can override this config dev,staging or prod
    /**
     * Session idle, cache time to leave and config
     */
    idle: 7200, // idle time accepted then expire session, in seconds.
    cacheTTL: 300, // active time to expire cache then have to fetch from the store, in seconds.
    pingToken: 60, // active time to keep refresh token, in seconds.
    idleSensitivity: 20, // IdleSensitivity time that activity must remain below the idle detection threshold, in seconds.

    /**
     * Limited recording duration in ms when user is not admin
     * and his academy didn't pay for him
     */
    limitedRecordingDuration: 180000,

    /**
     *
     */
    defaultRoleIdInRoleSettings: 2,

    /**
     *
     */
    defaultResourceGoalCharCount: 1000,
    defaultResourceSummaryCharCount: 256,
    defaultNewsTitleCount: 180,
    defaultNewsIngressCount: 400,

    defaultResourceOnlineEventId: 1,

    maxResourceInstructors: 2,

    /**
     * PayPal
     */
    PAYPAL_CLIENT_ID: 'AdTXpesGElgsYpVlKiPyv5S0f2V5MBMcg-7f8x1p8xjrHmfZy6uWW1l6z_GsWadOuwZcRsxbBl_vEk-t',

    /**
     *
     */
    demoAccountBaseURL: 'https://klickportalen.se/',

    /**
     * AWS S3
     */
    AW_S3_region: 'eu-central-1',

    AW_S3_MATERIAL_BUCKET: 'nk3mp4', // Ecourses and material type file >> S3MediaService.uploadMaterialToS3
    AW_S3_MEDIA_BUCKET: 'nk3media', // Larges images and reports (Downloads) >> S3MediaService.uploadMediaToS3
    AW_S3_MATERIAL_URL: 'https://nk3mp4.s3-eu-central-1.amazonaws.com',
    AW_S3_SCORM_ARCHIVE_BUCKET: 'nk3-scorm-archive',
    AW_S3_SCORM_BUCKET: 'nk3-scorm',

    //
    WS_ECHO_APP_KEY: 'xGTXzwyBfXo6',
    WS_ECHO_PORT: 80,
    WSS_ECHO_PORT: 443,
    WS_ECHO_TLS: true,
    WS_ECHO_CLUSTER: 'mt1',

    //
    YOUTUBE_DATA_API_KEY: 'AIzaSyAk8Co-GcqulQaEasqCdNKqAaGhQZ7WwaI',

    // paytabs testing Client API KEY
    PAYTABS_CLIENT_KEY: 'C2KM7K-7KPV6G-BMNB2G-GRPMKD',
};
