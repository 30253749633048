import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { GlobalFilterProperty, GlobalFilterPropertyType, SelectFilterOption } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { UsersService } from './users.service';

@Component({
    selector: 'app-admin-users-select',
    templateUrl: './users-select.component.html',
    styleUrls: ['./users-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersService],
})
export class UsersSelectComponent
    extends SelectModelBaseDirective<User>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() referenceIdKey = 'id';
    @Input() selectionLabel: string;
    @Input() customer_id: number;
    @Input() sync_all_key = 'sync_all_users';
    @Input() attachKey = 'users_attach';
    @Input() detachKey = 'users_detach';
    public columns = ['select', 'name', 'username', 'email'];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public userStatusOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];

    constructor(protected datatableService: UsersService, protected mobileService: MobileService) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.userStatusOptions = Utils.getUserStatusOptions();
    }

    /**
     * @override
     * @param id
     */
    protected refresh(id?: any): void {
        const filters = [];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.customer_id) {
            filters.push({ property: 'customer', items: [this.customer_id] });
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
}
