<ng-container *ngIf="inGlobalFilter; else withoutGlobalFilter">
    <ng-container *ngIf="(isMobile | async) && toggleFilterButtons" [ngTemplateOutlet]="togglers"></ng-container>
    <div
        fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="space-between start"
        fxLayoutGap.lt-md="0px"
        fxLayoutGap="10px"
        class="select-filters-wrapper"
    >
        <div class="selector-wrapper">
            <!-- <ng-content select="[tagsFilterRef]"></ng-content> -->
            <!-- <ng-container *ngComponentOutlet="selectedTemplate$ | async"></ng-container> -->
            <!-- <ng-container [ngTemplateOutlet]="filterComps.last.templateRef"> </ng-container> -->
            <!-- Need refactoring table filter should be able to query ContentChildren that  -->
            <!-- implement base filter interface defined base/abstract properties/behaviours -->
            <!-- And each child implement/expose his own properties/behaviours values. -->
            <ng-container [ngSwitch]="selectedFilter.value">
                <ng-container *ngSwitchCase="GlobalFilterProperty.SEARCH">
                    <ng-content select="[searchFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.DATE">
                    <ng-content select="[dateFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.TIME_SPENT">
                    <ng-content select="[fixedTimeSpentFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.TAGS">
                    <ng-content select="[tagsFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.CATEGORIES">
                    <ng-content select="[categoriesFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.AUTHOR">
                    <ng-content select="[authorsFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.QUOTEE">
                    <ng-content select="[quoteeFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.EDUCATOR">
                    <ng-content select="[instructorsFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.LANGUAGE">
                    <ng-content select="[langFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.STATUS">
                    <ng-content select="[resourceStatusRef]"></ng-content>
                    <ng-content select="[tagStatusFilterRef]"></ng-content>
                    <ng-content select="[resourceStateFilterRef]"></ng-content>
                    <ng-content select="[userStatusRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.USER_ROLE">
                    <ng-content select="[userRolesRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.TODO_PRIO">
                    <ng-content select="[todoPrioRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.NEWS_TYPE">
                    <ng-content select="[newsTypeRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.TODO_ACTION">
                    <ng-content select="[todoActionRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.CUSTOMER_CONTACT">
                    <ng-content select="[contactFilterRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.SCOPE">
                    <ng-content select="[scopesFilterRef]"></ng-content>
                </ng-container>
                <!-- <ng-container *ngSwitchCase="GlobalFilterProperty.RESOURCE_TYPE">
                    <ng-content select="[resourceTypeFilterRef]"></ng-content>
                </ng-container> -->
                <ng-container *ngSwitchCase="GlobalFilterProperty.SECTION_SORTING">
                    <ng-content select="[sectionSortingRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.LOG_EVENT">
                    <ng-content select="[logEventRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.SELECTED">
                    <ng-content select="[selectedItemsRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.GROUPS">
                    <ng-content select="[groupSearchRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.USERS">
                    <ng-content select="[userSearchRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.CATALOG_SOURCE">
                    <ng-content select="[catalogAcademyRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.CUSTOMERS">
                    <ng-content select="[customerSearchRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.MESSAGE_SCOPEID">
                    <ng-content select="[messageTypesRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.MESSAGE_SUBJECT">
                    <ng-content select="[messageSubjectRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchCase="GlobalFilterProperty.MESSAGE_USER">
                    <ng-content select="[messageUserRef]"></ng-content>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-content select="[searchFilterRef]"></ng-content>
                </ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="!(isMobile | async) && toggleFilterButtons" [ngTemplateOutlet]="togglers"></ng-container>
    </div>

    <div *ngIf="toggleFilterButtons && activeAppliedFilters.length" class="global-filter-chip-list">
        <h4 class="filter-header" i18n="@@chosenSearchCriteria:">Chosen search criteria</h4>
        <mat-chip-list #chipList aria-label="Filter selection">
            <mat-chip
                *ngFor="let filter of activeAppliedFilters"
                [selectable]="false"
                [removable]="true"
                (removed)="remove(filter)"
                [ngStyle]="{ background: filter.color.bg, color: filter.color.fg }"
            >
                <ng-container
                    [ngTemplateOutlet]="filter.template"
                    [ngTemplateOutletContext]="{ filter: filter }"
                ></ng-container>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
</ng-container>
<ng-template #withoutGlobalFilter>
    <ng-content></ng-content>
</ng-template>

<ng-template #togglers>
    <div class="togglers-wrapper btnTogglers" *ngIf="toggleFilterButtons">
        <div class="filter-main-wrapper" fxLayoutAlign="start center" fxLayout="row">
            <section fxLayoutAlign="start center" fxLayout="row" class="filter-wrapper">
                <span class="items" fxLayoutAlign="start center" fxLayout="row">
                    <mat-button-toggle-group
                        #btnGroup
                        [formControl]="selectedFilter"
                        class="button-filter-wrapper"
                        [multiple]="false"
                    >
                        <mat-button-toggle
                            *ngFor="let filter of appliedFilters"
                            [ngStyle]="{ color: filter.color.bg }"
                            [value]="filter.property"
                            [ngClass]="filter.styleClass"
                        >
                            <mat-icon>{{ filter.icon }}</mat-icon>
                            <ng-container>{{ filter.label }}</ng-container>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </span>
            </section>
        </div>
    </div>
</ng-template>
