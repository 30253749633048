<ng-container *ngIf="!loading; else loadingTemp">
    <div *ngIf="selected; else selectTemp" fxFlex="0 0 100%" class="selected-wrapper" fxLayout="row wrap">
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="start start">
                <h2 fxFlex="0 0 95%">{{ selected.title }}</h2>
                <a
                    fxFlex="0 0 auto"
                    [routerLink]="['/player/resource/', selected.id]"
                    target="_blank"
                    mat-icon-button
                    fxHide.lt-md="true"
                    matTooltip="Preview material"
                    i18n-matTooltip="@@previewMaterial"
                >
                    <mat-icon class="md-24">open_in_new</mat-icon>
                </a>
            </div>
            <button
                fxHide="true"
                (click)="changeMaterial()"
                fxLayout="row"
                fxLayoutAlign="start center"
                mat-button
                color="primary"
                matTooltip="Change material"
                i18n-matTooltip="@@changeMaterial"
            >
                <ng-container i18n="@@changeMaterial">Change material</ng-container>
                <mat-icon class="md-24">edit</mat-icon>
            </button>
        </div>
        <app-image-view
            fxFlex="0 1 20%"
            *ngIf="selected.media_id"
            [media_id]="selected.media_id"
            [proportional]="true"
        ></app-image-view>
        <div *ngIf="selected.description" fxFlex="0 1 calc(80% - 2em)" [innerHtml]="selected.description"></div>
        <div
            class="mt-1 mb-1"
            *ngIf="selected.tags_attached"
            fxFlex="0 1 100%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
        >
            <div class="pre"><span i18n="@@tags">Tags </span>:</div>
            <mat-chip-list *ngIf="resourceTags | async; let tags; else: loadingTemp">
                <mat-chip *ngFor="let tag of tags">{{ tag.name }}</mat-chip>
            </mat-chip-list>
        </div>
        <!-- TODO handle material sub types -->
        <div fxFlex="0 0 100%">
            <div
                *ngIf="selected.type_id === ResourceTypes.TextMaterial"
                [innerHtml]="(selected.item$ | async)?.name"
            ></div>
            <app-media-view
                *ngIf="selected.type_id !== ResourceTypes.TextMaterial"
                [media_id]="(selected.item$ | async)?.name"
            ></app-media-view>
        </div>
    </div>
</ng-container>

<ng-template #selectTemp>
    <app-resource-library
        [selection]="selection"
        [type_scope_id]="AppScope.MATERIAL"
        (select)="selectResource($event)"
    ></app-resource-library>
</ng-template>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
