<div fxFlex="grow" [ngClass]="{ 'title-collapsed': !expanded, 'edit-mode-wrapper': editMode.value }">
    <span *ngIf="!editMode.value" class="title-item {{ disabled }}">{{ item.value.title }}</span>
    <mat-form-field *ngIf="editMode.value" fxFlex="0 1 100%">
        <textarea
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="15"
            #input
            matInput
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            type="text"
            [formControl]="item.get('title') | cast"
        >
        </textarea>
        <!-- <input
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            #input
            matInput
            type="text"
            [formControl]="title"
        /> -->
    </mat-form-field>
</div>
<ng-container *ngIf="isMobile$ | async">
    <button
        [ngClass]="{ 'circle-indicator-expanded': expanded }"
        class="circle-trigger"
        (click)="$event.stopPropagation()"
        mat-button
        [matTooltip]="getToolTipValue(item.value.text_validation)"
        [matMenuTriggerFor]="menu"
    >
        <div class="circle-item {{ item.value.text_validation }}"></div>
    </button>
    <!-- <mat-icon *ngIf="disabled" class="add-ai-btn on-loading">smart_toy</mat-icon> -->
</ng-container>
<div
    [ngClass]="{ 'hide-bts': !expanded }"
    fxLayout="row"
    class="m-w-100"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="end center"
    *ngIf="!loadingTitle.value"
>
    <ng-container *ngIf="!editMode.value">
        <button
            [disabled]="disabled"
            (click)="$event.stopPropagation(); editMode.next(true)"
            class="tools-btn"
            mat-icon-button
            matTooltip="Edit title"
            i18n-matTooltip="@@editTitle"
        >
            <mat-icon>edit</mat-icon>
        </button>
        <button
            [disabled]="disabled"
            (click)="$event.stopPropagation(); viewItemLog()"
            class="tools-btn log-file"
            mat-icon-button
            matTooltip="View log"
            i18n-matTooltip="@@viewLog"
        >
            <mat-icon>touch_app</mat-icon>
        </button>
        <button
            [disabled]="disabled"
            (click)="$event.stopPropagation(); sendEmail()"
            class="tools-btn"
            mat-icon-button
            matTooltip="Send Text Block to mail"
            i18n-matTooltip
        >
            <mat-icon class="material-icons-outlined">email</mat-icon>
        </button>
        <button
            [disabled]="!item.value.name"
            (click)="$event.stopPropagation(); tooltip.toggle()"
            (mouseenter)="tooltip.show()"
            class="tools-btn"
            #tooltip="matTooltip"
            mat-icon-button
            matTooltipClass="info-tooltip"
            [matTooltip]="tooltipInfo"
        >
            <mat-icon class="material-icons-outlined">info</mat-icon>
        </button>
        <!-- <button
            [disabled]="disabled"
            (click)="sendEmail()"
            class="tools-btn"
            mat-icon-button
            matTooltip="Send Text Block to mail"
            i18n-matTooltip
        >
            <mat-icon>email</mat-icon>
        </button> -->

        <button
            [disabled]="disabled"
            (click)="$event.stopPropagation(); onRegenerate.emit(index)"
            class="tools-btn-icon tools-btn"
            mat-icon-button
            matTooltip="Regenerate AI response"
            i18n-matTooltip="@@editTitle"
        >
            <mat-icon>autorenew</mat-icon>
        </button>

        <button
            [disabled]="!item.value.name"
            (click)="$event.stopPropagation(); onCopy.emit(index)"
            class="tools-btn-icon tools-btn copy"
            mat-icon-button
            matTooltip="Copy to clipboard"
            i18n-matTooltip="@@CopyToClipboard"
        >
            <mat-icon class="material-icons-outlined">content_copy</mat-icon>
        </button>
        <button
            [disabled]="!item.value.name"
            (click)="$event.stopPropagation(); onDuplicate.emit(index)"
            class="tools-btn-icon tools-btn"
            mat-icon-button
            matTooltip="Duplicate"
            i18n-matTooltip="@@duplicate"
        >
            <mat-icon class="material-icons-outlined">file_copy</mat-icon>
        </button>
        <button
            [disabled]="disabled"
            (click)="$event.stopPropagation(); onDelete.emit(index)"
            class="tools-btn"
            mat-icon-button
            matTooltip="Delete"
            i18n-matTooltip="@@delete"
        >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <app-item-translator
            [ngClass]="item.value.language_id ? 'translator' : 'tools-btn'"
            class="translator"
            [disabled]="disabled || !item.value.name"
            [languageId]="item.value.language_id"
            (onTranslate)="onTranslate.emit($event)"
        ></app-item-translator>
        <!-- <ng-container *ngIf="item.value.title">
            <div *ngIf="item.value.updated_at != item.value.created_at" class="ai-wrapper">
                <mat-icon matTooltip="Edited by human" i18n-matTooltip>smart_toy</mat-icon>
                <div class="bg"></div>
            </div>
            <div *ngIf="item.value.updated_at === item.value.created_at" class="ai-wrapper">
                <mat-icon matTooltip="Pure response from AI" i18n-matTooltip>smart_toy</mat-icon>
            </div>
        </ng-container> -->

        <button
            [disabled]="disabled"
            class="circle-trigger doubled"
            (click)="$event.stopPropagation()"
            mat-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="getToolTipValue(item.value.text_validation)"
        >
            <div class="circle-item {{ item.value.text_validation }}"></div>
        </button>
        <mat-icon *ngIf="disabled" class="add-ai-btn on-loading">smart_toy</mat-icon>
    </ng-container>
    <button
        *ngIf="editMode.value"
        (click)="$event.stopPropagation(); editMode.next(false)"
        class="tools-btn-icon"
        mat-icon-button
        matTooltip="Confirm"
        i18n-matTooltip="@@confirm"
    >
        <mat-icon>check</mat-icon>
    </button>
</div>
<app-kd-logo-loading *ngIf="loadingTitle.value" size="small"></app-kd-logo-loading>
<mat-menu #menu="matMenu">
    <a
        *ngFor="let validationBtn of validationColorBtns"
        class="indicator-btn {{ validationBtn.value }}"
        mat-menu-item
        (click)="changeColor(validationBtn.value)"
    >
        <div class="color-ball"></div>
        <span>{{ validationBtn.label }}</span>
    </a>
</mat-menu>
