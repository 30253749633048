import { Validators } from '@angular/forms';
import { IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';
import { Prop } from '@klickdata/core/form';
import { User } from '@klickdata/core/user';

export interface EditorTemplateData extends IDataModel {
    id?: number;
    author_id?: number;
    default?: boolean;
    field_name?: string;
    title?: string;
    subject?: string;
    body?: string;

    group_ids?: number[];
    customer_id?: number;
    master?: boolean;
    editable?: boolean;
}

export class EditorTemplate extends Model<EditorTemplateData> {
    public id: number;
    public author_id: number;
    public default: boolean;
    public field_name: string;
    public editable?: boolean;

    @Prop({
        validation: Validators.required,
    })
    public title: string;

    @Prop()
    public subject: string;

    @Prop({
        validation: Validators.required,
    })
    public body: string;

    @Prop()
    public group_ids: number[];

    public customer_id: number;
    public master: boolean;
}
