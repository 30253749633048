import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import {
    Resource,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceTag,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Observable, combineLatest } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-resource-details',
    templateUrl: './resource-details.component.html',
    styleUrls: ['./resource-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsComponent implements OnInit, OnDestroy {
    public resource$: Observable<Resource>;
    public resourceItems$: Observable<ResourceItem[]>;
    public resourceCategories$: Observable<ResourceCategory[]>;
    public resourceTags$: Observable<ResourceTag[]>;
    public user$: Observable<User>;
    public customer$: Observable<Customer>;
    public isMob$: Observable<boolean>;
    ResourceTypes = ResourceTypes;

    constructor(
        protected resourceService: ResourceService,
        protected route: ActivatedRoute,
        protected resourceItemService: ResourceItemService,
        protected categoriesService: ResourceCategoryService,
        protected tagService: TagService,
        protected auth: AuthService,
        protected mobilesSerice: MobileService
    ) {
        this.isMob$ = this.mobilesSerice.isMobile();
    }

    ngOnInit() {
        this.user$ = this.auth.getUser().pipe(first());
        this.customer$ = this.auth.getCustomer().pipe(first());
        this.resource$ = this.route.params.pipe(
            switchMap((params) => this.resourceService.getResource(params.id)),
            tap((resource) => {
                this.resourceItems$ = this.resourceItemService.getResourceItems(resource.id);
                this.resourceCategories$ = combineLatest([
                    this.categoriesService.getResourceCategoriesByResourceId(resource.id),
                    this.categoriesService.getSuggestedCategory(ResourceTypes.parentType(resource.type_id)),
                ]).pipe(
                    map(([cats, defaultCat]) =>
                        Utils.arraysEqual(cats, [defaultCat], false, (ai, bi) => ai.id === bi.id) ? null : cats
                    )
                );
                this.resourceTags$ = this.tagService.getResourceTags(resource.id, true);
            })
        );
    }

    ngOnDestroy() {}
}
