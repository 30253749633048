import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionService as QS } from '@klickdata/core/question';
import { AlternativeService } from './alternative.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppValidators, FormHelper } from '@klickdata/core/form';
import { WOKAlternative } from '../resouce-items/wok-question/wok-question.component';

@Injectable()
export class QuestionService {
    constructor(
        protected fb: FormBuilder,
        protected questionService: QS,
        protected alternativeService: AlternativeService
    ) {}

    public duplicateFormGroup(
        question: AbstractControl,
        usePoints: boolean,
        resourceItemId?: number
    ): Observable<FormGroup> {
        if (question.get('id')) {
            const mediaId = question.get('media_id') ? question.get('media_id').value : null;
            const questionType = question.get('question_type_value') ? question.get('question_type_value').value : null;
            const otherAlternative = question.get('other_alternative') ? question.get('other_alternative').value : null;
            const randomizeAlternatives = question.get('randomize_alternatives')
                ? question.get('randomize_alternatives').value
                : null;
            const description = question.get('description') ? question.get('description').value : null;
            const learning_field = question.get('learning_field') ? question.get('learning_field').value : null;
            const references = question.get('references') ? question.get('references').value : null;
            const alternatives = question.get('alternatives') ? question.get('alternatives').value : [];
            const alternativesControls = this.alternativeService.createDuplicateFormGroups(alternatives, usePoints);

            const group = this.createFormGroup({
                questionType: questionType,
                otherAlternative: otherAlternative,
                randomizeAlternatives: randomizeAlternatives,
                alternativesControls: alternativesControls,
                mediaId: mediaId,
                description: description,
                learning_field: learning_field,
                references: references,
                usePoints: usePoints,
            });

            return of(group);
        }

        return this.questionService.getQuestionByItem(resourceItemId).pipe(
            switchMap((loadedQuestion) => {
                return this.alternativeService.createDuplicateFormGroupsFromQuestion(loadedQuestion, usePoints).pipe(
                    map((alternativesControls) => {
                        return this.createFormGroup({
                            questionType: loadedQuestion.question_type_value,
                            otherAlternative: loadedQuestion.other_alternative,
                            randomizeAlternatives: loadedQuestion.randomize_alternatives,
                            alternativesControls: alternativesControls,
                            mediaId: loadedQuestion.media_id,
                            description: loadedQuestion.description,
                            learning_field: loadedQuestion.learning_field,
                            references: loadedQuestion.references,
                            usePoints: usePoints,
                        });
                    })
                );
            })
        );
    }

    public createEmptyFormGroup(type, numberOfAlternatives = 3, usePoints = true) {
        return this.createFormGroup({
            questionType: type,
            alternativesControls: this.createAlternatives(numberOfAlternatives, usePoints),
            usePoints: usePoints,
        });
    }

    public createWOKFormGroup(type, numberOfAlternatives = 1, wokQsAlternatives: WOKAlternative[], img_url: string) {
        const group = this.createFormGroup({
            questionType: type,
            alternativesControls: this.createAlternatives(numberOfAlternatives, true, wokQsAlternatives),
            img_url: img_url,
            usePoints: true, // Create Only test from WOK.
            isWOKQs: true,
        });

        FormHelper.markForm(group);
        return group;
    }

    protected createFormGroup(data: {
        questionType: string;
        alternativesControls: any;
        otherAlternative?: boolean;
        randomizeAlternatives?: boolean;
        mediaId?: number;
        description?: string;
        learning_field?: string;
        references?: string;
        img_url?: string;
        required_answers?: boolean;
        usePoints: boolean;
        isWOKQs?: boolean;
    }): FormGroup {
        return this.fb.group({
            id: [null],
            item_id: [null],
            media_id: [data.mediaId],
            question_type_value: [data.questionType, AppValidators.notDelete(Validators.required)],
            alternatives: this.fb.array(data.alternativesControls, [
                AppValidators.minLength(2),
                AppValidators.hasCorrect(data.usePoints),
            ]),
            description: [data.description],
            learning_field: [data.learning_field],
            references: [data.references],
            other_alternative: [data.otherAlternative],
            randomize_alternatives: [data.randomizeAlternatives ?? data.usePoints],
            required_answers: [data.required_answers],
            img_url: [data.img_url],
            isWOKQs: [data.isWOKQs],
        });
    }

    protected createAlternatives(numberOfAlternatives, usePoints, wokQsAlternatives?: WOKAlternative[]) {
        const alternatives = [];
        for (let i = 0; i < numberOfAlternatives; i++) {
            const wokAlt = wokQsAlternatives ? wokQsAlternatives[i] : null;
            alternatives.push(
                this.alternativeService.createFormGroup(
                    {
                        alternative: String.fromCharCode(65 + i),
                        title: wokAlt?.text,
                        correct: wokAlt?.status ?? (i === 0 && usePoints),
                        points: wokAlt ? (wokAlt.status ? 1 : 0) : i === 0 && usePoints ? 1 : 0,
                    },
                    i,
                    usePoints
                )
            );
        }
        return alternatives;
    }
}
