import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { ChipItem, Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-tag-chip-search-filter',
    templateUrl: './tag-chip-search-filter.component.html',
    styleUrls: ['./tag-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => TagChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: TagChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    @Input() languageChange: Observable<Filter<number>> = new BehaviorSubject(null);
    @Input() inGlobalFilter: boolean;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    private cacheItems: ChipItem[];
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.TAGS,
        items: [],
        label: $localize`:@@tag:Tag`,
        icon: 'tag',
        color: { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 },
        styleClass: 'global-tag-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected tagService: TagService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        combineLatest([this.control.valueChanges, this.languageChange])
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(([query, languageFilter]) => {
                    return query?.trim()
                        ? this.tagService
                              .getCustomerTags({
                                  query: query,
                                  langId: languageFilter?.property ? languageFilter?.items : null,
                                  limit: 10,
                              })
                              .pipe(
                                  map((tags) =>
                                      tags.map((tag, index) => {
                                          return {
                                              id: tag.id,
                                              label: tag.name,
                                              language_id: tag.language_id,
                                              selected: index === 0, // make 1st item selected. EB asked AS on 230221
                                              //   selected: true,
                                          };
                                      })
                                  )
                              )
                        : of([]);
                })
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    /**
     * @override
     * @param items
     */
    protected setChips(items: ChipItem[]) {
        this.cacheItems = items;
        this.filter.chips = items?.reduce(
            (acc, el) => acc.set(el.language_id, [...(acc.get(el.language_id) || []), el]),
            new Map()
        );
    }

    /**
     * @override
     * @returns
     */
    protected getItemsForCache(): any {
        return this.cacheItems;
    }

    public setQuery(query: string): void {
        this.control.setValue(query);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
