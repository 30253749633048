import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Customer, CustomerService } from '@klickdata/core/customer';

@Component({
    selector: 'app-academy-select',
    templateUrl: './academy-select.component.html',
    styleUrls: ['./academy-select.component.scss'],
})
export class AcademySelectComponent implements OnInit, OnChanges {
    @Input() control: FormControl;
    @Input() academies: Customer[];
    constructor(protected customerService: CustomerService) {}

    public ngOnInit(): void {}

    ngOnChanges() {
        if (this.academies) {
            const academyShortName =
                this.academies.length === 1
                    ? this.academies[0].short_name
                    : this.academies.find(cus => cus.short_name === localStorage.getItem('academy_short_name'))
                          ?.short_name;
            if (academyShortName) {
                this.control.patchValue(academyShortName);
            }
        }
    }
}
