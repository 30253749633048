import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    MatAutocomplete,
    MatAutocompleteActivatedEvent,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatChipInputEvent } from '@angular/material/chips';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { MessageService } from '@klickdata/core/message';
import { ResourceCategory, ResourceCategoryService } from '@klickdata/core/resource';
import { Filter } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { CategoriesAssignSheetComponent } from '../../categories-assign-sheet/categories-assign-sheet.component';

@Component({
    selector: 'app-category-chip-select',
    templateUrl: './category-chip-select.component.html',
    styleUrls: ['./category-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CategoryChipSelectComponent),
            multi: true,
        },
    ],
})
export class CategoryChipSelectComponent implements OnInit, ControlValueAccessor {
    @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() visible = true;
    @Input() selectable = true;
    @Input() removable = true;
    @Input() type_id: number;
    @Input() language_id: number;
    @Output() afterInit: EventEmitter<any> = new EventEmitter<any>();
    private _resourceId: number;
    @Input() set resource_id(resource_id: number) {
        if (resource_id) {
            this._resourceId = resource_id;
            this.handleCategoryIds(resource_id);
        }
    }
    separatorKeysCodes: number[] = [ENTER, COMMA];
    categoryCtrl = new FormControl();
    filteredCategories: BehaviorSubject<ResourceCategory[]> = new BehaviorSubject<ResourceCategory[]>([]);
    private _selection: ResourceCategory[] = [];
    private _optionActivated: boolean;

    get selection(): ResourceCategory[] {
        return this._selection;
    }
    set selection(value: ResourceCategory[]) {
        this._selection = value;
        this.notify();
    }

    constructor(
        protected categoryService: ResourceCategoryService,
        protected cdRef: ChangeDetectorRef,
        protected config: ConfigService,
        protected auth: AuthService,
        protected errorService: HttpErrorService,
        protected messageService: MessageService,
        protected injector: Injector,
        protected bottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {
        if (!this.resource_id) {
            this.loadSuggestedCategory();
        }
        this.categoryCtrl.valueChanges
            .pipe(
                filter((term) => typeof term === 'string'),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((term) =>
                    term.trim()
                        ? this.categoryService.getPublicOrCustomerCategories({
                              type_id: this.type_id,
                              parent: 'notNull',
                              query: term,
                          })
                        : of([])
                ),
                takeUntil(this.destroy)
            )
            .subscribe((results) => this.filteredCategories.next(results));
    }

    notify() {
        setTimeout(() => this.propagateChange(this.selection.map((cat) => cat.id)));
    }

    public showCategories() {
        this.bottomSheet
            .open(CategoriesAssignSheetComponent, {
                data: {
                    context: $localize`Select Categories`,
                    contextIcon: 'folder',
                    id: this._resourceId,
                    appliedFilters: [new Filter('type_id', [this.type_id]), new Filter('parent', ['notNull'])],
                    selectedCategories: this.selection,
                    defaultSorting: this._resourceId ? 'checked' : 'title',
                    referenceFilter: this._resourceId ? 'referenceResource' : null,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(takeUntil(this.destroy))
            .subscribe((data) => {
                if (data && data.length) {
                    this.initSelection(data);
                }
            });
    }

    public propagateChange = (_: any) => {};
    writeValue(ids: any): void {
        if (!Utils.isEmpty(ids)) {
            this.categoryService
                .getResourceCategories(ids)
                .pipe(takeUntil(this.destroy))
                .subscribe((cats) => this.initSelection(cats));
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.categoryCtrl.disable() : this.categoryCtrl.enable();
    }

    /**
     * AW on Skype: however we are able to hide this step (collapse) and submit default without bothering creator, Ok?
     * EB on Skyep: Ok. Exactly. Very good.
     * @param event from separators events.
     */
    add(event: MatChipInputEvent): void {
        return; // STOP adding category on the fly.
        // const input = event.input;
        // const value = event.value?.trim();
        // if (!value || this._optionActivated) {
        //     return;
        // }
        // // Add Category
        // const suggested = this.filteredCategories.value.find(item => item?.id && item.title === value);
        // if (suggested) {
        //     this.setCategoryInput(input, suggested);
        // } else {
        //     this.auth
        //         .getUser()
        //         .pipe(
        //             first(),
        //             // Prevent users from create new categories. only admins on this phase.
        //             filter(user => user.role_value !== 'user'),
        //             switchMap(user =>
        //                 combineLatest(
        //                     this.categoryService.getParentDefaultCategory(this.type_id, user.customer_id),
        //                     of(user)
        //                 )
        //             ),
        //             switchMap(([parentCategory, user]) =>
        //                 this.categoryService.createResourceCategory({
        //                     title: value.trim(),
        //                     type_ids: [this.type_id],
        //                     parent_id: parentCategory.id,
        //                     customer_id: user.customer_id,
        //                 })
        //             ),
        //             catchError(err => {
        //                 if (err && err.error && err.error.error) {
        //                     // this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
        //                     this.messageService.openMessage(
        //                         MessageErrorComponent,
        //                         $localize`${value} already exists in another type, Go to admin settings to attach.`
        //                     );
        //                 }
        //                 return of(null);
        //             })
        //         )
        //         .subscribe(category => {
        //             if (category) {
        //                 this.setCategoryInput(input, category);
        //             }
        //         });
        // }
    }

    private setCategoryInput(input: HTMLInputElement, category: ResourceCategory) {
        this.addCategory(category);
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.categoryCtrl.setValue(null);
    }

    remove(category: ResourceCategory): void {
        const index = this.selection.findIndex((cat) => cat?.title === category.title);
        if (index !== -1) {
            this.selection.splice(index, 1);
            this.notify();
            this.categoryCtrl.enable();
        }
    }

    onSelected(event: MatAutocompleteSelectedEvent): void {
        const selected = event.option.value;
        this.addCategory(selected);
    }

    optionActivated(event: MatAutocompleteActivatedEvent) {
        this._optionActivated = !!event.option;
    }

    optionClosed() {
        this._optionActivated = false;
    }

    private addCategory(selected: ResourceCategory) {
        // Duplication not aceptable.
        if (this.selection.findIndex((cat) => cat?.id === selected.id) === -1) {
            this.selection.push(selected);
            this.notify();
            this.categoryInput.nativeElement.value = '';
            this.categoryCtrl.setValue(null);
        }
    }

    private loadSuggestedCategory() {
        this.categoryService
            .getSuggestedCategory(this.type_id)
            .pipe(takeUntil(this.destroy))
            .subscribe((suggestions) => {
                if (suggestions && !this.selection.length) {
                    this.initSelection([suggestions]);
                }
            });
    }

    private handleCategoryIds(id: number) {
        this.categoryService
            .getResourceCategoriesByResourceId(id)
            .pipe(takeUntil(this.destroy))
            .subscribe((cats) => this.initSelection(cats));
    }

    private initSelection(value: ResourceCategory[]) {
        this.selection = value;
        this.afterInit.emit();
        setTimeout(() => {
            this.resetFormControl();
            this.cdRef.markForCheck();
        });
    }

    private resetFormControl() {
        const control = this.injector.get(NgControl)?.control;
        if (control instanceof FormControl) {
            FormHelper.resetForm(control);
        }
    }
}
