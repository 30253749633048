import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private authService: AuthService, private router: Router, private location: Location) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canLoad(route: Route): Observable<boolean> {
        return this.check();
    }

    protected check(): Observable<boolean> {
        return this.authService.check().pipe(
            first(),
            map((authenticated) => {
                if (!authenticated) {
                    this.authService.openLoginPage(true, this.router, this.location.path());
                }
                return !!authenticated;
            })
        );
    }
}
