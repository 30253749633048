import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    InitialValue,
    Model,
} from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { UserMessage } from '@klickdata/core/user';
import { User, UserData } from '@klickdata/core/user/src/user.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export interface GroupData extends IDataModel {
    id?: number;
    name?: string;
    default?: boolean;
    disabled?: boolean;
    expiration?: string;
    customer_id?: number | any;
    grade_system_id?: number | any;
    user_ids?: number[];
    course_ids?: number[];
    material_ids?: number[];
    test_ids?: number[];
    survey_ids?: number[];
    course_plan_ids?: number[];
    color?: string;
    created_by?: string | number | User;
    updated_at?: string;
    created_at?: string;
    user_count?: number;

    message?: UserMessage;

    // Statistics
    total_users?: number;
    total_activities?: number;
    started_course_plan?: number;
    started_survey?: number;
    started_test?: number;
    started_course?: number;
    completed_course_plan?: number;
    completed_survey?: number;
    completed_test?: number;
    completed_course?: number;
    passed_test?: number;
    failed_test?: number;
    material_taken?: number;
    admins?: User[];

    /**
     * Tools
     */
    submitText?: string;
}

export class Group extends Model<GroupData> {
    id: number;
    name: string;
    default: boolean;
    disabled: boolean;
    @Cast(CastType.MOMENT)
    expiration: moment.Moment;
    customer_id: number;
    grade_system_id: number;
    user_ids: number[];
    color: string;
    created_by: string | number | User;

    @Ignore()
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    @Ignore()
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;

    @InitialValue([])
    course_ids: number[];
    @InitialValue([])
    material_ids: number[];
    @InitialValue([])
    test_ids: number[];
    @InitialValue([])
    survey_ids: number[];
    @InitialValue([])
    course_plan_ids: number[];

    customer: Observable<Customer>;
    creator: Observable<User>;
    users: Observable<User[]>;
    admins: User[];
    admins_names: string[];
    @Ignore()
    checked: boolean;
    @Ignore()
    resources_attached: boolean;
    @Ignore()
    courses_attached: boolean;
    @Ignore()
    ecourses_attached: boolean;
    @Ignore()
    materials_attached: boolean;
    @Ignore()
    surveys_attached: boolean;
    @Ignore()
    tests_attached: boolean;
    @Ignore()
    users_attached: boolean;
    @Ignore()
    user_count: number;

    /**
     * Convert object to data.
     */
    public getSettingsData(): GroupData {
        return {
            id: this.id,
            name: this.name,
            default: this.default,
            expiration: this.expirationToString(),
            customer_id: this.customer_id,
            grade_system_id: this.grade_system_id,
            color: this.color,
        };
    }

    /**
     * Convert moment date to string.
     *
     * @returns string
     */
    public expirationToString() {
        return this.expiration && this.expiration.isValid() ? this.expiration.format('YYYY-MM-DD HH:mm:ss') : null;
    }
}
