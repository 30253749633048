<div fxLayout="row" fxLayoutAlign="space-between center" class="res-builder-icon" [dir]="directionality.value">
    <!-- The add items section had a condition (!resource?.isPublished() && !hideAddItems), But EB asked for
    the user to be able to add question all the time even when resource is published -->
    <div fxLayout="row wrap" *ngIf="!hideAddItems">
        <small *ngIf="resourceTypes?.length" fxFlex="0 0 100%" class="app-caption tool-label options" i18n="@@addItems">
            Add items
        </small>
        <small *ngIf="questionTypes?.length" fxFlex="0 0 100%" class="app-caption tool-label options" i18n="@@createQs">
            Create questions
        </small>

        <!-- Question types -->
        <button
            *ngIf="questionTypes.indexOf(QuestionTypes.RADIO) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            mat-raised-button
            color="accent"
            type="button"
            matTooltip="Pick one alternative"
            i18n-matTooltip="@@pickOneAlternative"
            (click)="addQuestion.emit(QuestionTypes.RADIO)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon>radio_button_checked</mat-icon>
                <span class="tool-button-text" i18n="@@singleChoice"> Single Choice </span>
            </span>
        </button>
        <button
            *ngIf="questionTypes.indexOf(QuestionTypes.CHECKBOX) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            mat-raised-button
            color="accent"
            type="button"
            #tooltip="matTooltip"
            matTooltip="Pick one or many alternatives"
            i18n-matTooltip="@@pickOneOrManyAlternatives"
            (click)="addQuestion.emit(QuestionTypes.CHECKBOX)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon>check_box</mat-icon>
                <span class="tool-button-text" i18n="@@multipleChoice"> Multiple Choice </span>
            </span>
        </button>
        <!-- <span
            class="seperator"
            *ngIf="
                questionTypes.indexOf(QuestionTypes.RADIO) != -1 || questionTypes.indexOf(QuestionTypes.CHECKBOX) != -1
            "
        >
            |
        </span> -->
        <button
            *ngIf="questionTypes.indexOf(QuestionTypes.WOK) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            mat-raised-button
            color="accent"
            type="button"
            matTooltip="Create random questions from WOK with one correct alternatives"
            i18n-matTooltip="@@importFromWOKTooltip"
            (click)="addQuestion.emit(QuestionTypes.WOK)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <img src="assets/images/WOK_icon.png" />
                <span class="tool-button-text" i18n="@@importMCQsFromWOK"> Import MCQs from WOK </span>
            </span>
        </button>
        <!-- <button
            *ngIf="questionTypes.indexOf(QuestionTypes.CHECKBOX) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            matTooltip="Create random questions from WOK with one or many correct alternatives"
            i18n-matTooltip="@@pickOneOrManyAlternatives"
            (click)="resourceBuilder.selectedWOKQuestionType.next(QuestionTypes.CHECKBOX)">
            <span
                class="tool-button-inner"
                fxLayout="row wrap"
                fxLayoutAlign="center center">
                <img src="assets/images/WOK_icon.png">
                <span
                    class="tool-button-text"
                    i18n="WOK resource tools checkbox btn@@multipleAnswers">
                    WOK Multiple answers
                </span>
            </span>
        </button> -->
        <button
            *ngIf="questionTypes.indexOf(QuestionTypes.INPUT) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            mat-raised-button
            color="accent"
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a single line answer"
            i18n-matTooltip="@@addSingleLineAnswer"
            (click)="addQuestion.emit(QuestionTypes.INPUT)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon>text_format</mat-icon>
                <span class="tool-button-text" i18n="Resource tools input btn@@input"> Input </span>
            </span>
        </button>
        <button
            *ngIf="questionTypes.indexOf(QuestionTypes.TEXTAREA) != -1"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            mat-raised-button
            color="accent"
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a multi line answer"
            i18n-matTooltip="@@addMultiLineAnswer"
            (click)="addQuestion.emit(QuestionTypes.TEXTAREA)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon>text_fields</mat-icon>
                <span class="tool-button-text" i18n="Resource tools text btn@@text"> Text </span>
            </span>
        </button>

        <!-- Resource types -->

        <button
            *ngIf="containsType(ResourceTypes.MATERIAL)"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a material resource"
            i18n-matTooltip="@@addMaterialResource"
            (click)="addItem.emit(ResourceItemTypes.MATERIAL)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon class="kd-icon-24" fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                <span class="tool-button-text" i18n="Resource tools add material btn@@CourseMaterial">
                    {{ (customer | async)?.short_name }} Course Material
                </span>
            </span>
        </button>
        <button
            *ngIf="containsType(ResourceTypes.TEST)"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a test resource"
            i18n-matTooltip="@@addTestResource"
            (click)="addItem.emit(ResourceItemTypes.TEST)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon class="kd-icon-24" fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                <span class="tool-button-text" i18n="Resource tools add test btn@@test"> Test </span>
            </span>
        </button>
        <button
            *ngIf="containsType(ResourceTypes.SURVEY)"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a survey resource"
            i18n-matTooltip="@@addSurveyResource"
            (click)="addItem.emit(ResourceItemTypes.SURVEY)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon class="kd-icon-24" fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
                <span class="tool-button-text" i18n="Resource tools add survey btn@@survey"> Survey </span>
            </span>
        </button>
        <button
            *ngIf="containsType(ResourceTypes.E_COURSE)"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            matTooltip="Add a course resource"
            i18n-matTooltip="@@addCourseResource"
            (click)="addItem.emit(ResourceItemTypes.COURSE)"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon class="kd-icon-24" fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
                <span class="tool-button-text" i18n="Resource tools add course btn@@e_course">
                    Klick Data E-course
                </span>
            </span>
        </button>
    </div>

    <div fxFlex="1 0 auto" *ngIf="!(resource?.isPublished() || hideAddItems)"></div>
    <!-- The actions part is removed as reorder is implemented in each row and save will be saved with the resource -->
    <!-- <div *ngIf="!(isMobile$ | async)" fxLayout="row wrap">
        <small fxFlex="0 0 100%" class="tool-label app-caption" i18n="@@actions"> Actions </small>
        <button
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="button"
            #tooltip="matTooltip"
            (click)="toggleReorder.emit()"
            matTooltip="Change order of questions"
            i18n-matTooltip
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon *ngIf="!reorder">sort</mat-icon>
                <mat-icon *ngIf="reorder">done</mat-icon>
                <span class="tool-button-text" i18n="Resource tools reorder btn@@reorder"> Reorder </span>
            </span>
        </button>
        <button
            *ngIf="!saving"
            class="tool-button"
            fxFlex="0 0 auto"
            mat-button
            type="submit"
            #tooltip="matTooltip"
            matTooltip="Save"
            i18n-matTooltip
            (click)="submit.emit()"
        >
            <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                <mat-icon *ngIf="!saving">save</mat-icon>
                <mat-icon *ngIf="saved">done</mat-icon>
                <span *ngIf="saveTitle" class="tool-button-text">
                    {{ saveTitle }}
                </span>
                <span *ngIf="!saveTitle" class="tool-button-text" i18n="Resource tools save btn@@save"> Save all </span>
            </span>
        </button>

        <app-saving-button *ngIf="saving"></app-saving-button>
    </div>
    <div class="btns-head-wrapper" *ngIf="isMobile$ | async" fxLayout="column">
        <small fxFlex="0 0 100%" class="tool-label app-caption" i18n="@@actions"> Actions </small>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <button
                class="tool-button"
                fxFlex="0 0 auto"
                mat-button
                type="button"
                #tooltip="matTooltip"
                (click)="toggleReorder.emit()"
                matTooltip="Change order of questions"
                i18n-matTooltip
            >
                <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                    <mat-icon *ngIf="!reorder">sort</mat-icon>
                    <mat-icon *ngIf="reorder">done</mat-icon>
                    <span class="tool-button-text" i18n="Resource tools reorder btn@@reorder"> Reorder </span>
                </span>
            </button>
            <button
                *ngIf="!saving"
                class="tool-button"
                fxFlex="0 0 auto"
                mat-button
                type="submit"
                #tooltip="matTooltip"
                matTooltip="Save"
                i18n-matTooltip
                (click)="submit.emit()"
            >
                <span class="tool-button-inner" fxLayout="row wrap" fxLayoutAlign="center center">
                    <mat-icon *ngIf="!saving">save</mat-icon>
                    <mat-icon *ngIf="saved">done</mat-icon>
                    <span *ngIf="saveTitle" class="tool-button-text">
                        {{ saveTitle }}
                    </span>
                    <span *ngIf="!saveTitle" class="tool-button-text" i18n="Resource tools save btn@@save">
                        Save all
                    </span>
                </span>
            </button>

            <app-saving-button *ngIf="saving"></app-saving-button>
            <button
                class="unFold-all"
                [ngClass]="{ unfold: !foldAll }"
                mat-icon-button
                (click)="$event.stopPropagation(); foldAll = !foldAll; itemsCollapsingState.emit(foldAll)"
            >
                <mat-icon>last_page</mat-icon>
            </button>
        </div>
    </div> -->
    <button
        *ngIf="!(isMobile$ | async)"
        class="unFold-all"
        [ngClass]="{ unfold: !foldAll }"
        mat-icon-button
        (click)="$event.stopPropagation(); foldAll = !foldAll; itemsCollapsingState.emit(foldAll)"
    >
        <mat-icon>last_page</mat-icon>
    </button>
</div>
