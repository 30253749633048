<div class="course-items-switch-wrapper">
    <ng-container *ngIf="display === ResourceBuildingItems.MATERIAL_LIBRARY">
        <mat-tab-group class="res-tabs-course-item" #tabGroup ngClass.xs="xs">
            <mat-tab>
                <ng-template mat-tab-label i18n> My materials </ng-template>
                <ng-template matTabContent>
                    <app-resource-library
                        [type_scope_id]="AppScope.MATERIAL"
                        context="author"
                        [selection]="selection"
                        (select)="select.emit({ res: $event, isParentSubmission: false })"
                    ></app-resource-library>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label i18n> Academy materials </ng-template>
                <ng-template matTabContent>
                    <app-resource-library
                        [selection]="selection"
                        context="academy"
                        [type_scope_id]="AppScope.MATERIAL"
                        (select)="select.emit({ res: $event, isParentSubmission: false })"
                    ></app-resource-library>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label i18n> Open library (KOL) </ng-template>
                <ng-template matTabContent>
                    <app-resource-library
                        [type_scope_id]="AppScope.MATERIAL"
                        context="kol"
                        [selection]="selection"
                        (select)="select.emit({ res: $event, isParentSubmission: false })"
                    ></app-resource-library>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
    <ng-container
        *ngIf="display !== ResourceBuildingItems.MATERIAL_LIBRARY"
        [ngTemplateOutlet]="materialTypes"
    ></ng-container>

    <button
        matTooltip="Change"
        [ngClass]="display === ResourceBuildingItems.MATERIAL_LIBRARY ? 'lib' : 'normal'"
        mat-icon-button
        (click)="display = null; onCloseView.emit(true)"
        i18n-matTooltip
        class="add-creation-tab"
        type="button"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
<ng-template #materialTypes>
    <ng-container [ngSwitch]="display">
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL"
            ><app-add-material-link
                #compRef
                [active]="true"
                [publishOnly]="true"
                publishLabel="Add to course"
                i18n-publishLabel
                (saved)="select.emit($event)"
                (onError)="onError.emit($event)"
            ></app-add-material-link
        ></ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL">
            <app-upload-material-file
                #compRef
                [active]="true"
                [publishOnly]="true"
                publishLabel="Add to course"
                i18n-publishLabel
                (saved)="select.emit($event)"
                (onError)="onError.emit($event)"
            ></app-upload-material-file
        ></ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL"
            ><app-add-material-text
                #compRef
                [active]="true"
                [publishOnly]="true"
                publishLabel="Add to course"
                i18n-publishLabel
                (saved)="select.emit($event)"
                (onError)="onError.emit($event)"
            ></app-add-material-text
        ></ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL"
            ><app-add-material-ai
                #compRef
                [active]="true"
                [publishOnly]="true"
                publishLabel="Add to course"
                i18n-publishLabel
                (saved)="select.emit($event)"
                (onError)="onError.emit($event)"
            ></app-add-material-ai
        ></ng-container>
    </ng-container>
</ng-template>
