import { Injectable } from '@angular/core';
import { EchoService } from '@klickdata/core/echo/echo.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource, ResourceData } from './resource.model';
import { ResourceService } from './resource.service';
@Injectable({
    providedIn: 'root',
})
export class ResourceWSService {
    constructor(protected resourceService: ResourceService, protected echoService: EchoService) {}

    public listenToNewResource(): Observable<Resource> {
        return this.echoService
            .listenUserChannel('NewResource')
            .pipe(map((data: ResourceData) => this.resourceService.createResource(data)));
    }

    public listenToResourceUpdates(res_id: number): Observable<Resource> {
        return this.echoService
            .listenPrivate(`resource.${res_id}`, 'ResourceUpdatedEvent')
            .pipe(map((data: ResourceData) => this.resourceService.createResource(data)));
    }
}
