import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { GlobalSearchData } from '@klickdata/core/global-search';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, ResourceService, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { Filter, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Observable, Subject } from 'rxjs';
import { first, shareReplay, takeUntil } from 'rxjs/operators';
import { ResourceSearchListingService } from './resource-search-listing.service';

@Component({
    selector: 'app-resource-search-listing',
    templateUrl: './resource-search-listing.component.html',
    styleUrls: ['./resource-search-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceSearchListingService],
})
export class ResourceSearchListingComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource = new TableSource<Resource>();
    public columns = ['title', 'author_name', 'published', 'language', 'type', 'tools'];
    public isMobile: Observable<boolean>;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Input() id: number;
    @Input() label: string;
    @Input() stylingClass: string;
    @Input() scope_id: number;
    @Output() onDismissSheet: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public typeIcon: string;
    ResourceTypes = ResourceTypes;

    constructor(
        protected listService: ResourceSearchListingService,
        protected mobile: MobileService,
        protected resourceService: ResourceService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService,
        protected router: Router
    ) {}

    public ngOnInit() {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.dataSource.service = this.listService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;

        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe(user => {
                this.authUser = user;
                // language table filter will emit.
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('assignedOrCustomer', [user.customer_id]),
                    new Filter(this.getFilterType(this.scope_id), [this.id]),
                ]);
            });
    }
    private getFilterType(scopeId: number): string {
        switch (scopeId) {
            case AppScope.CATEGORIES:
                this.typeIcon = 'apps';
                return 'categories';
            case AppScope.TAGS:
                this.typeIcon = 'push_pin';
                return 'tags';
            case AppScope.FOLDERS:
                this.typeIcon = 'folder_open';
                return 'folder';
            case AppScope.SECTIONS:
                this.typeIcon = 'widgets';
                return 'section';
            case AppScope.AUTHOR:
                this.typeIcon = 'account_circle';
                return 'author';
        }
        this.cdRef.markForCheck();
    }

    public openResource(resource: Resource) {
        this.router.navigate(['/home/dashboard/resource-details/', resource.id]);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
