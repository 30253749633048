export interface ActionQueue<T> {
    readonly queue: Map<T, { ids: number[]; count: number }>;
    push(type: T, ids?: number[]): void;
    pop(type: T, ids?: number[]): void;
    popById(ids: number[]): boolean;
    exists(type: T): boolean;
    has(...ids: number[]): boolean;
    count(): number;
}
export class ActionQueueImpl<T> implements ActionQueue<T> {
    public queue: Map<T, { ids: number[]; count: number }> = new Map();

    public push(type: T, ids?: number[]): void {
        const item = this.queue.get(type);
        if (item) {
            if (ids?.length) {
                item.ids.push(...ids);
                item.count += ids.length;
            } else {
                item.count++;
            }
        } else {
            this.queue.set(type, { ids: ids || [], count: ids?.length || 1 });
        }
    }

    public pop(type: T, ids?: number[]): void {
        const item = this.queue.get(type);
        if (item) {
            if (ids?.length) {
                item.ids = item.ids.filter((id) => !ids.includes(id));
                item.count -= ids.length;
            } else {
                item.count--;
            }

            if (item.count <= 0) {
                this.queue.delete(type);
            }
        }
    }

    public popById(ids: number[]): boolean {
        let updated = false;
        for (const item of this.queue.values()) {
            const currLength = item.ids.length;
            item.ids = item.ids.filter((id) => !ids.includes(id));
            const newLength = item.ids.length;
            const diff = currLength - newLength;
            if (diff) {
                updated = true;
                item.count -= diff;
            }
        }

        return updated;
    }

    public exists(type: T): boolean {
        return this.queue.has(type);
    }

    public has(...ids: number[]): boolean {
        for (const item of this.queue.values()) {
            if (ids.some((id) => item.ids.includes(id))) {
                return true;
            }
        }
        return false;
    }

    public count(): number {
        return Array.from(this.queue.values()).reduce((total, item) => total + item.count, 0);
    }
}
