<div fxLayout="column" class="main-wrapper-core">
    <span i18n class="title-label">Survey questions</span>
    <div class="w-100">
        <app-resource-builder
            fxFlex="0 0 100%"
            #builderForm
            [resource]="resource"
            [language_id]="resourceForm?.get('language_id').value"
            [questionTypes]="questionTypes"
            [resource_type_id]="ResourceTypes.GeneralSurvey"
            [resourceType]="ResourceTypes.SURVEY"
            (saved)="$event && savingQuestions && submitResourceRegardlessQs()"
            [active]="bottomToolSheet"
            class="res-builder-column-layout w-100 m-10-0"
            [hideAddItems]="false"
            [ngClass]="{ 'rb-is-empty': isEmpty(resourceBuilder?.getService().getForm() | async) }"
        >
        </app-resource-builder>
    </div>
</div>
