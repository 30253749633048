import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { Customer } from '@klickdata/core/customer';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MobileService } from '@klickdata/core/mobile';
import { Post } from '@klickdata/core/post/src/post/post.model';
import { PostService } from '@klickdata/core/post/src/post/post.service';
import { AppScope, ResourceTag, ResourceTagData } from '@klickdata/core/resource';
import {
    FilterCollection,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { InnerHtmlDisplayDialogComponent } from 'apps/klickdata/src/app/shared/dialog/inner-html-display-dialog/inner-html-display-dialog.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-post-detail',
    templateUrl: './post-detail.component.html',
    styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit, AfterViewInit {
    public posts: Observable<Post[]>;
    @Input() base = 'home';
    @Input() customer: Customer;
    public expandAll = false;
    public fullPostBody = false;
    public user$: Observable<User>;
    public isMobile$: Observable<boolean>;
    public openedId: number;
    public postsTypeControl: FormControl = new FormControl('all');
    AppScope = AppScope;
    public newsOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    constructor(
        protected postService: PostService,
        protected activatedRoute: ActivatedRoute,
        protected auth: AuthService,
        protected dialog: MatDialog,
        protected mediaService: MediaService,
        protected route: ActivatedRoute,
        protected mobile: MobileService,
        protected cdr: ChangeDetectorRef
    ) {
        this.isMobile$ = this.mobile.isMobile();
        this.newsOptions = [
            { title: $localize`All news`, value: 'all', icon: 'speaker_notes' },
            { title: $localize`Academy news`, value: 'customer', icon: 'supervised_user_circle' },
            { title: $localize`Public news`, value: 'public', icon: 'public' },
            { title: $localize`My news`, value: 'user', icon: 'person' },
        ];
    }

    ngOnInit() {
        if (this.activatedRoute.firstChild) {
            this.activatedRoute.firstChild.url.subscribe((item) => {
                this.base = item[0].path;
            });
        }
        this.user$ = this.auth.getUser();

        this.route.queryParams.subscribe((params) => (this.openedId = params.postId ? Number(params.postId) : null));
    }
    ngAfterViewInit(): void {
        if (this.filter) {
            this.filter.setActiveFilterWithSelectedOption(GlobalFilterProperty.NEWS_TYPE, 'all');
        }
    }
    public openDialog(post: Post) {
        this.dialog.open(InnerHtmlDisplayDialogComponent, {
            disableClose: false,
            data: {
                contentBody: post.body,
                contentTitle: post.title,
                contentImg: post.media_id,
                contentIngress: post.ingress,
                contentDate: post.published,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }
    filterByTag(tag: ResourceTagData) {
        this.filter.filterByModel(new ResourceTag(tag));
    }
    updateFilters(filtersColl: FilterCollection<string | number>) {
        const params = filtersColl.filters.map((filter) => {
            return { property: filter.property, items: filter.items };
        });
        this.posts = this.postService.getPosts(params);
    }
}
