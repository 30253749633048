<app-resource-details-info
    [resource]="resource"
    [user]="user"
    [customer]="customer"
    [resourceItems]="resourceItems"
    [resourceCategories$]="resourceCategories$"
    [resourceTags$]="resourceTags$"
    [resourceType]="ResourceTypes.parentType(resource.type_id)"
    resourceLabel="Event"
    i18n-resourceLabel
>
    <ng-container itemsHeader>
        <div *ngIf="resource.agenda" fxLayoutGap="7px" fxLayoutAlign="start center">
            <mat-icon>ballot</mat-icon>
            <span i18n>Agenda</span>
        </div>
    </ng-container>
    <span resCodeLabel i18n>Event code</span>
    <ng-container resourceItemsSection>
        <div *ngIf="resource.agenda" class="res-bull mt-1">
            <div [innerHtml]="styleInnerHTML(resource.agenda)"></div>
        </div>
    </ng-container>
    <span actionBtnLabel i18n="@@register">Register</span>
    <span managerLabel i18n="@@tutor">Tutor</span>
    <ng-container resourceCircles>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon>{{ ResourceTypes.getEventTypeByTypeId(resource.type_id).icon }}</mat-icon>
            </div>
            <span>{{ ResourceTypes.getEventTypeByTypeId(resource.type_id).title }}</span>
        </div>
    </ng-container>
    <ng-container resourceDates>
        <div
            *ngIf="resource.event_occasions && resource.event_occasions.length"
            class="item"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
        >
            <div class="item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>today</mat-icon>
                <div fxFlex="grow" class="item-title" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                    <span i18n>Start</span>
                    <span
                        >{{ resource.event_occasions[0].start_date | kdDateFormat: 'MMM DD, YYYY HH:mm' }}&nbsp;{{
                            resource.time_zone
                        }}</span
                    >
                </div>
                <button
                    [ngClass]="{ flip: showDates }"
                    class="event-btn"
                    mat-icon-button
                    (click)="showDates = !showDates"
                >
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="showDates">
                <div class="start-date" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>event</mat-icon>
                    <span>End</span>
                    <span
                        >{{ resource.event_occasions[0].end_date | kdDateFormat: 'MMM DD, YYYY HH:mm' }}&nbsp;{{
                            resource.time_zone
                        }}</span
                    >
                </div>

                <div class="dates" *ngFor="let occ of resource.event_occasions; let index = index">
                    <ng-container *ngIf="index !== 0">
                        <mat-icon class="date-icon">schedule</mat-icon>
                        <span>{{ occ.start_date | kdDateFormat: 'MMM DD, YYYY HH:mm' }}</span>
                        <span>-</span>
                        <span>{{ occ.end_date | kdDateFormat: 'MMM DD, YYYY HH:mm' }}</span>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container resourceDuration>
        <div class="details-elem">
            <div class="pre">
                <mat-icon>timelapse</mat-icon>
                <span i18n="@@duration">Duration </span>
            </div>
            <span class="normal">{{ resource?.duration | formatIsoDuration: false }} </span>
        </div>
    </ng-container>
</app-resource-details-info>
