import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddLinkDialogComponent } from '@klickdata/core/application/src/add-link-dialog/add-link-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { AppScope } from '@klickdata/core/resource';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { MediaType } from './media-type';
import { MediaService } from './media.service';
import { S3MediaService } from './s3-media.service';

@Directive()
export abstract class UploaderBaseComponent implements OnInit, OnDestroy {
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onUploadOrRemoveCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
    @Input() scope: AppScope;
    @Input() allowedMimeType: string[];
    @Input() uploadUrl: string;
    @Input() maxImgCount: number = 10;
    @Input() itemAlias: string;
    @Input() mediaIdKey = 'id';
    public mediaItems = [];
    private _mediaIds: number[];
    public destroy: Subject<boolean> = new Subject<boolean>();
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public get mediaIds(): number[] {
        return this._mediaIds || [];
    }

    @Input() public set mediaIds(mediaIds: number[]) {
        this._mediaIds = mediaIds;
        if (mediaIds?.length) {
            this.saving.emit(true);
            this.mediaService
                .getMediaByIds(mediaIds)
                .pipe(takeUntil(this.destroy))
                .subscribe((mediaItems) => {
                    this.saving.emit(false);
                    this.mediaItems = mediaItems;
                    this.cd.markForCheck();
                });
        }

        this.propagateChange(this._mediaIds);
    }

    protected getmediaIds(): number[] {
        return this.mediaIds;
    }
    @ViewChild('uploader') uploader: ElementRef;

    constructor(
        protected dialog: MatDialog,
        protected mediaService: MediaService,
        protected auth: AuthService,
        protected token: AuthService,
        protected cd: ChangeDetectorRef,
        protected messageService: MessageService,
        protected s3Service: S3MediaService
    ) {}

    public ngOnInit(): void {}

    public fileHandler(event) {
        const fileList: FileList = event.target.files;
        const file: File = fileList[0];
        this.saving.emit(true);
        const obs: Observable<Media>[] = [];
        for (let i = 0; i < fileList.length; i++) {
            obs.push(this.uploadFile(fileList.item(i)));
        }
        forkJoin(obs)
            .pipe()
            .subscribe(
                (res) => {
                    this.mediaItems.push(...res);
                    this.mediaIds.push(...res.map((media) => Number(media.id)));
                    this.saving.emit(false);
                    this.onUploadOrRemoveCompleted.emit(true);
                    this.cd.markForCheck();
                },
                (err) => {
                    this.onResponseError(err);
                    this.onError.emit(err);
                    this.onUploadOrRemoveCompleted.emit(true);
                }
            );
    }

    private uploadFile(file: File): Observable<Media> {
        return this.s3Service.uploadMediaToS3(file, this.progress$).pipe(
            takeUntil(this.destroy),
            switchMap((media) =>
                this.mediaService.uploadMediaByLink({
                    ...media.getData(),
                    scope_id: this.scope,
                })
            ),
            takeUntil(this.destroy)
        );
    }

    private onResponseError(err) {
        this.saving.emit(false);
        this.uploader.nativeElement.value = '';
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
        }
        this.cd.markForCheck();
    }

    public propagateChange = (_: any) => {};
    public writeValue(mediaIds: number[]): void {
        this._mediaIds = mediaIds;
    }
    public uploadLink() {
        this.dialog
            .open(AddLinkDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result),
                switchMap((url) => {
                    this.saving.emit(true);
                    return this.mediaService.uploadMediaByLink({ link: url, type: MediaType.LINK });
                })
            )
            .subscribe(
                (media) => {
                    this.saving.emit(false);
                    this.mediaItems.push(media);
                    this.mediaIds.push(Number(media.id));
                    this.onUploadOrRemoveCompleted.emit(true);
                    this.cd.markForCheck();
                },
                (err) => {
                    if (err.error.error) {
                        this.onResponseError(err);
                        this.onError.emit(err);
                        this.onUploadOrRemoveCompleted.emit(true);
                    }
                }
            );
    }
    public removeAttachment(index: number) {
        this.mediaIds.splice(index, 1);
        this.mediaItems.splice(index, 1);
        this.onUploadOrRemoveCompleted.emit(true);
        this.cd.markForCheck();
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
