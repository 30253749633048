<h1 matDialogTitle>
    <app-sticky-header
        class="sticky-header"
        savingTitle="Save"
        i18n-savingTitle="@@save"
        [title]="data.title"
        (onSubmit)="onSubmit()"
        [savingStatus]="saving"
        (onCancel)="onClose()"
    ></app-sticky-header>
</h1>
<div
    *ngIf="resource$ | async; let resource; else: loading"
    [ngSwitch]="scope_id"
    class="form-wrapper"
    mat-dialog-content
>
    <app-create-course-plan-simple
        *ngSwitchCase="AppScope.COURSE"
        [resource]="resource"
        (saving)="saving = $event"
    ></app-create-course-plan-simple>

    <app-create-course-plan-simple
        *ngSwitchCase="AppScope.PACKAGE"
        [resource]="resource"
        (saving)="saving = $event"
    ></app-create-course-plan-simple>

    <app-course-settings
        *ngSwitchCase="AppScope.E_COURSE"
        [resource]="resource"
        (saving)="saving = $event"
        (onDismss)="onClose()"
        [showInDialog]="true"
    ></app-course-settings>

    <app-create-test
        *ngSwitchCase="AppScope.TEST"
        [resource]="resource"
        (saving)="saving = $event"
    ></app-create-test>
    <app-create-survey
        *ngSwitchCase="AppScope.SURVEY"
        [resource]="resource"
        (saving)="saving = $event"
    ></app-create-survey>
    <ng-container *ngSwitchCase="AppScope.MATERIAL" [ngSwitch]="resource.type_id">
        <app-add-material-link
            *ngSwitchCase="ResourceTypes.URLMaterial || ResourceTypes.WebsiteMaterial"
            [resource]="resource"
            (saved)="onClose()"
            (saving)="saving = $event"
        ></app-add-material-link>
        <app-add-material-link
            *ngSwitchCase="ResourceTypes.WebsiteMaterial"
            [resource]="resource"
            (saved)="onClose()"
            (saving)="saving = $event"
        ></app-add-material-link>

        <app-upload-material-file
            *ngSwitchCase="ResourceTypes.DocMaterial"
            [resource]="resource"
            (saved)="onClose()"
            (saving)="saving = $event"
        ></app-upload-material-file>
        <app-add-material-text
            *ngSwitchCase="ResourceTypes.TextMaterial"
            [resource]="resource"
            (saved)="onClose()"
            (saving)="saving = $event"
        ></app-add-material-text>
    </ng-container>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
