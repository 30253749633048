import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Media } from '@klickdata/core/media/src/media.model';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { Resource } from '@klickdata/core/resource';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-core-practice-doc',
    templateUrl: './course-manager-core-practice-doc.component.html',
    styleUrls: ['./course-manager-core-practice-doc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CourseManagerCorePracticeDocComponent implements OnInit, OnDestroy {
    @Input() resource: Resource;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public onControlDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resourceForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public media: Media;
    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected mediaService: S3MediaService,
        protected cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.resourceForm = this.parentFormDirective.form;
        this.resourceForm.addControl('practice_document', new FormControl(''));
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.resource && this.resourceForm && this.resourceForm.value.practice_document) {
            this.resourceForm.patchValue({
                practice_document: this.resourceForm.value.practice_document,
            });
            FormHelper.resetForm(this.resourceForm);
        }
    }
    public prepareFile(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.fileHandler(fileList[0]);
        }
    }

    public fileHandler(file: File) {
        this.auth
            .getCustomer()
            .pipe(
                first(),
                takeUntil(this.destroy),
                switchMap((customer) => this.mediaService.uploadMaterialToS3(customer.id, file, this.progress$))
            )
            .subscribe(
                (mediaKey) => {
                    if (mediaKey) {
                        this.resourceForm.patchValue({
                            practice_document: mediaKey.src,
                        });
                        this.onControlDirty.next(true);
                        this.resourceForm.markAsDirty();
                        this.media = mediaKey;
                        this.cdRef.markForCheck();
                    }
                },
                (err) => {} // TODO on error
            );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
