import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApplicationModule } from '@klickdata/core/application';
import { AuthModule } from '@klickdata/core/auth';
import { ConfigModule } from '@klickdata/core/config';
import { HttpModule } from '@klickdata/core/http';
import { MediaViewComponent } from '@klickdata/core/media/src/media-view/media-view.component';
import { MediaComponent } from '@klickdata/core/media/src/media.component';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { KdLoaderModule } from '@klickdata/kd-loader';
import { MaterialModule } from '@klickdata/material';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { PipesModule } from 'apps/klickdata/src/app/shared/pipes/pipes.module';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// Plyr player, (video, audio, image,.. etc)
import { PlyrModule } from 'ngx-plyr';
import { CoursePlayerComponent } from './course-player/course-player.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { MediaAttachmentComponent } from './media-attachment/media-attachment.component';
import { MediaPlayerComponent } from './media-player/media-player.component';
import { MediaScormPlayerComponent } from './media-scorm-player/media-scorm-player.component';
import { MultipleImageViewComponent } from './multiple-image-view/multiple-image-view.component';
import { S3MediaService } from './s3-media.service';
import { ScormPlayerComponent } from './scorm-player/scorm-player.component';
import { ImgUploaderOptionsComponent } from './img-uploader-options/img-uploader-options.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,

        ApplicationModule,
        HttpModule,
        AuthModule,
        ConfigModule,
        FileUploadModule,
        MatProgressSpinnerModule,

        PlyrModule,

        // pdf viewer
        PdfViewerModule,
        MaterialModule,
        PipesModule,

        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        KdLoaderModule,
        NgxDocViewerModule,
    ],
    providers: [MediaService, S3MediaService],
    declarations: [
        MediaComponent,
        MediaViewComponent,
        ImageViewComponent,
        MediaPlayerComponent,
        CoursePlayerComponent,
        MultipleImageViewComponent,
        MediaScormPlayerComponent,
        ScormPlayerComponent,
        MediaAttachmentComponent,
        ImgUploaderOptionsComponent,
    ],
    exports: [
        MediaComponent,
        MediaViewComponent,
        ImageViewComponent,
        CoursePlayerComponent,
        MultipleImageViewComponent,
        ScormPlayerComponent,
        MediaAttachmentComponent,
        ImgUploaderOptionsComponent,
    ],
})
export class MediaModule {}
