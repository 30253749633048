import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Resource, ResourceCategory, ResourceTag, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { ResourceDetailsInfoComponent } from '../resource-details-info/resource-details-info.component';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-resource-details-event',
    templateUrl: './resource-details-event.component.html',
    styleUrls: ['./resource-details-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsEventComponent {
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() resourceItems: ResourceItem[];
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @ViewChild(ResourceDetailsInfoComponent) infoComp: ResourceDetailsInfoComponent;
    ResourceTypes = ResourceTypes;
    public showDates: boolean;
    constructor() {}

    public styleInnerHTML(description: string) {
        if (description) {
            return '<div class="inner-HTML-course">' + description + '</div>';
        } else {
            return;
        }
    }
}
