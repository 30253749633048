<div *ngIf="!inGlobalFilter; else globalFilterTemplate" class="chip-container mr-2" fxLayout="row">
    <div fxLayout="column" fxFlex="0 0 100%">
        <div fxLayout="row" fxFlex="1 1 100%" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex="1 1 350px" class="search-box">
                <input
                    fxFlex="0 0 100%"
                    matInput
                    #search
                    placeholder="Filter by tag name..."
                    i18n-placeholder="@@filterByTagName"
                    [formControl]="control"
                />
                <button class="search-icon-size" matSuffix mat-icon-button *ngIf="!search.value.length">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <mat-chip-list fxFlex="0 0 100%" [ngClass]="{ 'mobile-view': isMobile | async }" [multiple]="multiple">
            <ng-container *ngFor="let item of source">
                <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                    {{ item.label }}
                    <ng-container
                        [ngTemplateOutlet]="tagFlag"
                        [ngTemplateOutletContext]="{ language: item.language_id }"
                    ></ng-container>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </div>
    <div fxFlex="1 1 100%"></div>
</div>

<ng-template #globalFilterTemplate>
    <div class="chip-container" fxLayout="row">
        <div class="chip-container-child" fxLayoutGap="0px" fxLayout="column" fxLayoutGap.gt-sm="10px">
            <div
                fxLayout="row"
                class="chip-container-ances"
                fxLayoutGap.gt-sm="10px"
                fxLayoutGap="0px"
                fxLayoutAlign="space-between center"
            >
                <mat-form-field class="search-box" appearance="fill">
                    <mat-label i18n="@@tag">Tag</mat-label>
                    <input
                        matInput
                        #search
                        placeholder="Filter by tag name..."
                        i18n-placeholder="@@filterByTagName"
                        [formControl]="control"
                    />
                    <!-- <button class="search-icon-size" matSuffix mat-icon-button *ngIf="!search.value.length">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
                    <mat-icon>clear</mat-icon>
                </button> -->
                </mat-form-field>
            </div>
            <h4 *ngIf="source?.length" class="filter-header" i18n="@@chooseFilter">Choose filter</h4>
            <mat-chip-list
                fxFlex="0 0 100%"
                [class.top-align]="source?.length"
                [ngClass]="{ 'mobile-view': isMobile | async }"
                [multiple]="multiple"
            >
                <ng-container *ngFor="let item of source">
                    <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                        {{ item.label }}
                        <ng-container
                            [ngTemplateOutlet]="tagFlag"
                            [ngTemplateOutletContext]="{ language: item.language_id }"
                        ></ng-container>
                    </mat-chip>
                </ng-container>
                <div *ngIf="source?.length" class="seperater"></div>
            </mat-chip-list>
        </div>
    </div>
</ng-template>
<ng-template #tagFlag let-language="language">
    <app-language-selector class="flag" fxFlex="0 0 auto" [language_id]="language"> </app-language-selector>
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <ng-container *ngFor="let item of $any($any(filter.chips) | keyvalue)">
        <ng-container [ngTemplateOutlet]="tagFlag" [ngTemplateOutletContext]="{ language: item.key }"></ng-container>
        <span *ngFor="let item of item.value; let last = last"
            >{{ item.label }}<span *ngIf="!last">,&nbsp;</span>
        </span>
    </ng-container>
</ng-template>
