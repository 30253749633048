import { ChangeDetectionStrategy, ViewChild, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { RecorderTypes, RecordingOutput } from '@klickdata/core/media/src/media-type';
import { RecorderService } from '@klickdata/core/media/src/recorder.service';
import { User } from '@klickdata/core/user';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { RecorderViewComponent } from './recorder-view/recorder-view.component';

@Component({
    selector: 'app-general-recorder',
    templateUrl: './general-recorder.component.html',
    styleUrls: ['./general-recorder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralRecorderComponent implements OnInit, OnDestroy {
    @ViewChild('recorderMenuTrigger') recorderMenuTrigger: MatMenuTrigger;
    public destroy = new Subject<boolean>();
    public isRecording$: Observable<boolean>;
    @Input() user: User;
    constructor(
        protected recordingService: RecorderService,
        protected dialog: MatDialog,
        protected router: Router,
        protected auth: AuthService
    ) {
        this.recordingService
            .onRecorderOptionsBtnClicked()
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.recorderMenuTrigger.openMenu());
    }

    public ngOnInit(): void {
        this.isRecording$ = this.recordingService.isRecordingSub.asObservable();

        combineLatest([
            this.recordingService.recordedSub.asObservable().pipe(distinctUntilChanged()),
            this.recordingService.onToggleRecordingState.asObservable(),
        ])
            .pipe(takeUntil(this.destroy))
            .subscribe(([output, stopped]) => {
                if (output && stopped) {
                    this.showRecordReview(output);
                }
            });
    }
    public stopRecorder() {
        this.recordingService.stopRecorder();
    }
    private showRecordReview(output: RecordingOutput) {
        this.recordingService.onToggleRecordingState.next(false);
        if (output) {
            this.dialog
                .open(RecorderViewComponent, {
                    disableClose: true,
                    data: {
                        recordedOutput: output,
                        type: this.recordingService.activeRecordingType,
                    },
                })
                .afterClosed()
                .pipe(
                    takeUntil(this.destroy),
                    filter((result) => !!result)
                )
                .subscribe(() => {
                    this.router.navigate(
                        [this.user.isAdmin() ? 'admin/content/materials/create' : 'home/manage/material/create'],
                        {
                            queryParams: {
                                naviagteAfterRecord:
                                    this.recordingService.activeRecordingType === RecorderTypes.AUDIO ? 3 : 2,
                            },
                        }
                    );
                });
        }
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
