import { MobileService } from './../../../../core/mobile/src/mobile.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { ResourceTypes } from '@klickdata/core/resource';
import { Resource, ResourceData } from '@klickdata/core/resource/src/resource.model';
import { ResourceTypeService } from '@klickdata/core/resource/src/type/resource-type.service';
import { User, UserService } from '@klickdata/core/user';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, share, shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class ResourceService {
    protected resourceUrl: string;
    private authorsMap = new Map<number, Observable<User>>();
    public infoWrapperWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private takeTaskMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected typeService: ResourceTypeService,
        protected mediaService: MediaService,
        protected userService: UserService,
        protected mobile: MobileService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}resources`;
    }
    public updateTakeTaskMode(isTakeTaskMode: boolean) {
        this.takeTaskMode$.next(isTakeTaskMode);
    }
    public getTakeTaskMode(): Observable<boolean> {
        return combineLatest([this.takeTaskMode$.asObservable(), this.mobile.isMobile()]).pipe(
            map(([isTaskMode, isMobile]) => isTaskMode && isMobile)
        );
    }
    getResource(id: number): Observable<Resource> {
        return this.builder
            .get<ResourceData>(`${this.resourceUrl}/${id}`)
            .request()
            .pipe(map(res => this.createResource(res.data)));
    }

    protected createResource(data: ResourceData) {
        const resource = new Resource(data);
        resource.author$ = resource.author_id
            ? this.getAuthor(resource.author_id)
            : of(new User({ fname: 'Anonymous' }));

        resource.media$ = this.mediaService
            .getMedia(resource.media_id, ResourceTypes.scopeByChildType(resource.type_id), resource.article_code)
            .pipe(shareReplay());
        return resource;
    }
    protected getAuthor(author_id: number): Observable<User> {
        let author = this.authorsMap.get(author_id);
        if (author) {
            return author;
        }
        author = this.userService.getUser(author_id).pipe(
            share(),
            catchError(() => of(new User({ fname: 'Anonymous', lname: '' })))
        );
        this.authorsMap.set(author_id, author);
        return author;
    }
}
