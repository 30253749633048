<form *ngIf="form" [formGroup]="form" class="res-b-form" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-wok-question
        *ngIf="resourceBuilderService.selectedWOKQuestionType | async; let questionType"
        fxFlex="0 1 100%"
        [questionType]="questionType | cast"
    ></app-wok-question>

    <app-resource-items
        fxFlex="0 1 100%"
        formArrayName="items"
        [itemFormArray]="form.get('items') | cast"
        [items]="items | async"
        [usePoints]="usePoints"
    ></app-resource-items>
</form>
