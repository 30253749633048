<div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="material-type-title" fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
        <mat-icon>edit_note</mat-icon>
        <span i18n="@@text">Text</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <app-item-translator
            *ngIf="selectedIndices.length"
            (onTranslate)="translate($event, selectedIndices)"
        ></app-item-translator>
        <button
            class="tools-btn"
            *ngIf="selectedIndices.length > 1"
            type="button"
            (click)="merge(selectedIndices)"
            mat-icon-button
            matTooltip="Merge into one"
            i18n-matTooltip
        >
            <mat-icon>merge_type</mat-icon>
        </button>
        <button
            class="tools-btn"
            *ngIf="selectedIndices.length"
            type="button"
            (click)="download()"
            mat-icon-button
            matTooltip="Export all selected"
            i18n-matTooltip
        >
            <mat-icon>save_alt</mat-icon>
        </button>
        <button
            class="tools-btn"
            *ngIf="selectedIndices.length"
            type="button"
            (click)="delete(selectedIndices)"
            mat-icon-button
            matTooltip="Delete multiple items at once"
            i18n-matTooltip
        >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
        </button>
        <button
            class="tools-btn"
            *ngIf="selectedIndices.length"
            type="button"
            (click)="regenerate(selectedIndices)"
            mat-icon-button
            matTooltip="Regenerate response for multiple items at once"
            i18n-matTooltip
        >
            <mat-icon>autorenew</mat-icon>
        </button>
        <button
            class="tools-btn"
            *ngIf="selectedIndices.length"
            type="button"
            (click)="copyAll()"
            mat-icon-button
            matTooltip="Copy to clipboard"
            i18n-matTooltip
        >
            <mat-icon class="material-icons-outlined">copy_all</mat-icon>
        </button>

        <button
            type="button"
            (click)="onFollowUp(true)"
            class="start-new-prompt"
            mat-icon-button
            matTooltip="Use AI to follow up Material"
            i18n-matTooltip
        >
            <mat-icon>smart_toy</mat-icon>
        </button>
        <button
            *ngIf="items.controls.length"
            mat-icon-button
            type="button"
            class="expand"
            color="primary"
            fxFlex="0 0 auto"
            matTooltip="Open All"
            i18n-matTooltip="@@openAll"
            (click)="expandAll = !expandAll; toggleAll(expandAll)"
        >
            <mat-icon class="expanding" [class.expanded-all]="expandAll">last_page</mat-icon>
        </button>
    </div>
</div>

<div class="third-colmn-editor-wrapper mt-1" fxFlex="0 1 100%" fxLayout="column" fxLayoutGap="20px">
    <mat-accordion
        formArrayName="resource_items"
        class="third-colmn-editor-wrapper"
        fxFlex="0 1 100%"
        fxLayout="column"
        [multi]="true"
        cdkDropList
        [cdkDropListData]="items.controls"
        [cdkDropListDisabled]="actionQueue.count() > 0"
        (cdkDropListDropped)="drop($event)"
    >
        <mat-expansion-panel
            cdkDrag
            #expansionPanel
            *ngFor="let item of items.controls; let i = index"
            [formGroupName]="i"
            [expanded]="i == opened"
            (opened)="opened = i"
            (closed)="opened === i && (opened = -1)"
            [disabled]="actionQueue.has(item.value.id || i)"
        >
            <mat-expansion-panel-header
                class="on-hover-mat-item"
                fxLayoutGap="10px"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <mat-checkbox
                    [disabled]="actionQueue.has(item.value.id || i)"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(i) : null"
                    [checked]="selection.isSelected(i)"
                >
                </mat-checkbox>
                <app-material-text-item-header
                    #textMaterialHeader
                    [ngClass]="{
                        'panel-expanded': expansionPanel.expanded,
                        'in-edit-mode': textMaterialHeader.editMode.value
                    }"
                    [expanded]="expansionPanel.expanded"
                    [fxLayout.lt-md]="expansionPanel.expanded ? 'column' : 'row'"
                    [item]="item | cast"
                    [index]="i"
                    [disabled]="actionQueue.has(item.value.id || i)"
                    (onDelete)="delete([$event])"
                    (onCopy)="copy(item.value.title, item.value.name)"
                    (onTranslate)="translate($event, [i])"
                    (onRegenerate)="regenerate([$event])"
                    (onDuplicate)="duplicate($event)"
                ></app-material-text-item-header>
                <button [class.expanded]="expansionPanel.expanded" class="drag-btn" cdkDragHandle mat-icon-button>
                    <mat-icon>drag_indicator</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <app-text-editor
                *ngIf="active"
                class="text-mat-editor"
                fxFlex="1 1 100%"
                title="Material Text"
                i18n-title
                [showTemplates]="false"
                formControlName="name"
            ></app-text-editor>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="!!totalCharsAndWordsLength.characters || !!totalCharsAndWordsLength.words" class="counters">
        <span i18n
            >{{ totalCharsAndWordsLength.words }} words {{ totalCharsAndWordsLength.characters }} characters</span
        >
    </div>
    <div *ngIf="!showPrompter.value" [ngClass]="{ 'mt--20': items.controls.length <= 0 }" class="follow-up-btn">
        <button class="follow-btn" mat-button (click)="onFollowUp()">
            <mat-icon class="add-ai-btn">smart_toy</mat-icon>
            <span i18n>Follow up</span>
        </button>
        <button class="add-btn" mat-button (click)="addText()">
            <mat-icon class="add-ai-btn">edit_note</mat-icon>
            <span i18n>New</span>
        </button>
    </div>
    <div id="promptInput" fxLayout="column" fxFlex="0 1 100%" *ngIf="showPrompter.value" class="propmpter-wrapper">
        <div class="ask-me" *ngIf="!prompt.value" i18n>Ask AI</div>
        <mat-form-field fxFlex="0 1 100%">
            <textarea
                #input
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="15"
                matInput
                type="text"
                [formControl]="prompt"
            >
            </textarea>
        </mat-form-field>
        <div fxFlex="1 1 100%" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                [disabled]="!prompt.value"
                class="create"
                mat-button
                matTooltip="Create material"
                (click)="addFUQ(); showPrompter.next(false)"
            >
                <mat-icon>smart_toy</mat-icon>
                <span i18n>Create</span>
            </button>
            <button
                class="prompter-cancel-btn"
                type="button"
                (click)="showPrompter.next(false)"
                mat-button
                matTooltip="Cancel"
                i18n-matTooltip
            >
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>
