import { KdLoaderModule } from '@klickdata/kd-loader';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigModule } from '@klickdata/core/config';
import { LanguageSelectorComponent } from '@klickdata/core/localization/src/language-selector/language-selector.component';
import { MaterialModule } from '@klickdata/material';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    imports: [CommonModule, ConfigModule, MaterialModule, ReactiveFormsModule, KdLoaderModule],
    declarations: [LanguageSelectorComponent],
    exports: [LanguageSelectorComponent],
    providers: [CookieService],
})
export class LocalizationModule {}
