import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
} from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { MessageFormErrorComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    Resource,
    ResourceCategoryService,
    ResourceCreationManagerBase,
    ResourceData,
    ResourceService,
    ResourceTypes,
    ResourceWSService,
} from '@klickdata/core/resource';
import { PromptType, ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { ActionQueue, ITextCountable, TextCountableDirective, Utils } from '@klickdata/core/util';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-material-text-manager-core',
    templateUrl: './material-text-manager-core.component.html',
    styleUrls: ['./material-text-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialTextManagerCoreComponent extends ResourceCreationManagerBase implements OnInit {
    @ViewChildren(TextCountableDirective) textCountables: QueryList<ITextCountable>;
    typeId = ResourceTypes.TextMaterial;
    @Input() actionQueue: ActionQueue<PromptType>;
    @Input() customer: Customer;
    @Input() set resource(value: Resource) {
        if (value?.id) {
            this._resource = value;
            this.initResource();
        }
    }
    get resource(): Resource {
        return this._resource;
    }
    private _resource: Resource;
    @Input() resourceForm: FormGroup;
    @Input() active: boolean;
    @Input() materialAddedItem: ResourceBuildingItems;
    @Output() onControlDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
    public selection = new SelectionModel<any>(true, []);
    public showPrompter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public propmpterIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public prompt = new FormControl('');
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @ViewChild(MatAccordion) accordion?: MatAccordion;
    public totalItemsCharsCounters: { id: number; chars: number; words: number }[] = [];
    public totalCharsAndWordsLength: ITextCountable = { characters: 0, words: 0 };
    public isMobile: boolean;
    public expandAll: boolean;
    public opened = 0;
    public duplicatedIndices = [];

    constructor(
        protected categoryService: ResourceCategoryService,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected resourceWSService: ResourceWSService,
        protected itemService: ResourceItemService,
        private fb: FormBuilder,
        protected error: HttpErrorService,
        private clipboard: Clipboard,
        private mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected element: ElementRef,
        protected logger: LoggerService
    ) {
        super(categoryService, auth, parentFormDirective, messageService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selection.changed
            .pipe(
                takeUntil(this.destroy),
                tap(() => this.cdr.markForCheck())
            )
            .subscribe();
        this.resourceForm.addControl('resource_items', new FormArray([]));
        this.items.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => this.buildTextCount()),
                tap(() => this.onControlDirty.emit(this.items.dirty))
            )
            .subscribe();

        if (!this.resource?.id && this.materialAddedItem === ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL) {
            this.addNewItem();
        }
        if (this.materialAddedItem === ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL) {
            this.showPrompter.next(true);
        }
        this.onDownloadHandler();
        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMob) => (this.isMobile = isMob));
    }

    private buildTextCount() {
        this.totalCharsAndWordsLength = this.textCountables.reduce(
            (acc, item) => {
                acc.characters += item.characters;
                acc.words += item.words;
                return acc;
            },
            { characters: 0, words: 0 }
        );
        this.cdr.markForCheck();
    }
    toggleAll(expandAll: boolean) {
        if (expandAll) {
            this.accordion.closeAll();
        } else {
            this.accordion.openAll();
        }
    }

    addFUQ() {
        const promptFormGroup = this.addNewItem({ title: this.prompt.value });
        promptFormGroup.markAsDirty();
        this.prompt.setValue('');
        const promptIndex = this.items.length - 1;
        this.prepareResource('prompt', [promptIndex])
            .pipe(
                takeUntil(this.destroy),
                switchMap((ids) => this.itemService.regenerateItems(ids, 'prompt'))
            )
            .subscribe();
    }

    private prepareResource(type: PromptType, indices: number[]): Observable<number[]> {
        const resourceItemsIsDirty = this.items.controls.some(
            (ctl) => ctl.dirty && (ctl.value.id || ctl.value.name || ctl.value.title)
        );
        const shouldPrepareSubmit = !this.resource?.id || resourceItemsIsDirty;
        const obs = shouldPrepareSubmit ? this.prepareSubmit(true) : of(this.resource);
        /**
         * Reset opened state
         * @todo Make collapse smooth
         * */
        this.resetOpened();

        return <Observable<number[]>>obs.pipe(
            map(() => this.idsFromIndices(indices)),
            tap((ids) => this.actionQueue.push(type, ids))
        );
    }

    private resetOpened() {
        this.opened = -1;
        this.accordion.closeAll();
    }

    private buildSocket() {
        this.itemService
            .listenToResourceItemsUpdates(this.resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((res) => {
                if (res.item_id) {
                    const processedIndex = res.data.findIndex((item) => item.id === res.item_id);
                    if (this.actionQueue.has(processedIndex)) {
                        this.actionQueue.pop(res.type, [processedIndex]);
                        this.items.removeAt(processedIndex);
                    } else {
                        this.actionQueue.pop(res.type, [res.item_id]);
                    }
                    if (res.error) {
                        this.messageService.openMessage(MessageErrorComponent, res.error.messages.join('/n'));
                    }
                    if (-1 === this.opened) {
                        this.openItem(res.data.findIndex((item) => item.id === res.item_id));
                    }
                } else {
                    const noneProcessingIds = res.data.filter((item) => !item.isProcessing).map((item) => item.parent_id || item.id);
                    const updated = this.actionQueue.popById(noneProcessingIds);
                    this.logger.info(`Looper updated: ${updated}`);
                    if (!updated) {
                        return;
                    }
                }

                this.updateItems(res.data);
                this.selection.clear();
                this.items.markAsDirty();
                this.cdr.markForCheck();
            });

        this.resourceWSService
            .listenToResourceUpdates(this.resource.id)
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this._resource = resource;
                this.patchResourceUpdates(resource);
            });
    }

    /**
     *
     * Keep dirty controls by index
     * (server/socket updates)
     * Case 1: Follow Up item = server not stored (with dirty or without)
     * Case 2: AU updates then socket updates. (items under processing disabled, other can have updates)

     * Case 1.1: with item dirty = PASS
     * Case 1.2: with new text item = Fail ()  >> Pass

     * Case 2.1: during socket processing items - AU make dirty item >> Pass
     * Case 2.2: during socket processing items - AU add new text item >> Pass
     * Case 2.3: during socket processing items - AU add Follow up >> Pass

     * FormArray (local) - (server)

     * Case 3.1: Item with ID. (dirty) - save - back
     * Case 3.2: Item new with body/name - save - back with ID = compare with index
     * Case 3.3: Item new with title - save - back with ID = compare with index
     * Case 3.4: Follow up item with title - new Item with tilte.
     * Case 3.4: store as item then generate body if any failure at least AU save his prompt item.

     * Case 4.1: Irrelevant content after free text :  Fail (No stable)
     * Case 4.2: Irrelevant content after AI text :  Fail

     *
     * @param items
     */
    private updateItems(items: ResourceItem[]) {
        const dirtyItems = this.items.controls
            .map((ctrl, index) => ({ index: index, control: ctrl }))
            .filter((item) => item.control.dirty);
        // Clear FormArray
        this.items.clear();
        // Remove dirty items with ids from items before pushing
        items = items.filter(
            (item) =>
                !dirtyItems
                    .filter((dirtyItem) => !!dirtyItem.control.value.id)
                    .some((removeItem) => removeItem.control.value.id === item.id)
        );

        // Add items into FormArray
        items.forEach((item) => this.items.push(this.createItemFormGroup(item)));
        // Restore dirty controls at their original positions
        dirtyItems.forEach((dirtyItem) => this.items.insert(dirtyItem.index, dirtyItem.control));
    }

    private removeFormArrayIds(ids: number[]) {
        /** itemsToKeep */
        this.items.controls = this.items.controls.filter((item) => !ids.includes(item.value.id));
    }

    get selectedIndices(): number[] {
        return this.selection.selected;
    }

    clearSelection() {
        this.selection.clear();
    }

    download() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.DOWNLOAD,
            data: {
                data: [
                    { value: 'resource_item_csv', label: $localize`Download CSV`, canSee: true },
                    { value: 'resource_item_pdf', label: $localize`Download PDF`, canSee: true },
                    { value: 'resource_item_doc', label: $localize`Download document`, canSee: true },
                ],
                returnValue: true,
            },
        });
    }
    private onDownloadHandler() {
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                takeUntil(this.destroy)
            )
            .subscribe((result) => {
                const ids = this.idsFromIndices(this.selectedIndices);
                if (!Utils.isEmpty(result?.value) && !Utils.isEmpty(ids)) {
                    this.itemService.downlaod(ids, result.value.join()).subscribe((res) => {
                        const file = new Blob([res], { type: res.type });
                        const fileUrl = URL.createObjectURL(file);
                        const anchor = document.createElement('a');
                        anchor.href = fileUrl;
                        anchor.download = `KLMS_${moment().format('yMD_hms')}`;
                        anchor.click();
                        this.clearSelection();
                    });
                }
            });
    }

    delete(indices: number[]) {
        const idsToDelete = this.items.controls
            .filter((item, index) => indices.includes(index) && item.value.id)
            .map((item) => item.value.id);
        /** itemsToKeep */
        this.items.controls = this.items.controls.filter((item, index) => !indices.includes(index) || item.value.id);
        if (idsToDelete.length) {
            this.loading.emit(true);
            this.itemService
                .destroy(idsToDelete)
                .pipe(
                    tap(() => this.removeFormArrayIds(idsToDelete)),
                    tap(() => this.loading.emit(false))
                )
                .pipe(takeUntil(this.destroy))
                .subscribe(() => this.clearSelection());
        } else {
            this.clearSelection();
        }
    }

    /**
     * Translate set of items
     * @param langId language id of the output
     * @param indices index of items to translate
     */
    translate(langId: number, indices: number[]) {
        this.loading.emit(true);

        const itemsToTranslate: { index: number; item: ResourceItem }[] = this.items.controls
            .map((ctrl, i) => ({ control: ctrl, index: i }))
            .filter((res) => indices.includes(res.index))
            .map((res, i) => ({
                index: res.index + i + 1,
                item: new ResourceItem({ ...res.control.value, language_id: langId, parent_id: res.control.value.id }),
            }));

        itemsToTranslate.forEach((itemToTranslate) => this.duplicateItem(itemToTranslate.item, itemToTranslate.index));

        this.prepareResource(
            'translate',
            itemsToTranslate.map((item) => item.index)
        )
            .pipe(
                switchMap((ids: number[]) => this.itemService.translateItems(ids, langId)),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.loading.emit(false);
                this.selection.clear();
            });
    }

    regenerate(indices: number[]) {
        this.loading.emit(true);
        this.prepareResource('regenerate', indices)
            .pipe(switchMap((ids: number[]) => this.itemService.regenerateItems(ids)))
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.loading.emit(false);
                this.clearSelection();
            });
    }

    /**
     * Duplicate by index.
     * @param index of the item.
     */
    duplicate(index: number) {
        const item = this.items.controls[index].value;
        this.duplicateItem(item, index);
    }

    duplicateItem(item: ResourceItem, index: number) {
        const newItemCtl = this.createItemFormGroup(item);
        this.items.insert(index, newItemCtl);
        newItemCtl.patchValue({ id: null });
        newItemCtl.markAsDirty();
    }

    merge(indices: number[]) {
        this.loading.emit(true);
        this.prepareResource('merge', indices)
            .pipe(
                takeUntil(this.destroy),
                switchMap((ids: number[]) =>
                    this.itemService.mergeItems(ids).pipe(map((item) => ({ ids: ids, item: item })))
                )
            )
            .subscribe((res) => this.mergeItems(res.ids, res.item));
    }

    public mergeItems(ids: number[], item: ResourceItem) {
        this.clearSelection();
        this.loading.emit(false);
        this.removeFormArrayIds(ids);
        this.items.push(this.createItemFormGroup(item));
        this.openItem();
        this.actionQueue.pop('merge', ids);
        this.cdr.markForCheck();
        /** put action for generate auto headline using GPT ai */
        this.actionQueue.push('merge', [item.id]);
    }

    idsFromIndices(indices: number[]): number[] {
        return this.controlsFromIndices(indices).map((ctl, i) => ctl.value.id || indices[i]);
    }

    controlsFromIndices(indices: number[]): AbstractControl[] {
        return this.items.controls.filter((item, index) => indices.includes(index));
    }

    copyAll() {
        const content = this.controlsFromIndices(this.selectedIndices)
            .reduce((acc, item) => {
                acc.push(
                    new DOMParser().parseFromString(item.value.title, 'text/html').body.textContent,
                    new DOMParser().parseFromString(item.value.name, 'text/html').body.textContent
                );
                return acc;
            }, [])
            .join('\n');
        this.clipboard.copy(content);
        this.messageService.openMessage(
            MessageSavedComponent,
            $localize`Item texts have been copied to the clipboard.`
        );
        this.clearSelection();
    }

    copy(title: string, body: string) {
        let plainText = body;
        // Replace multiple spaces with a single space
        plainText = plainText.replace(/\s+/g, ' ');

        // Trim leading and trailing spaces
        plainText = plainText.trim();

        // Replace consecutive new lines with a single new line
        plainText = plainText.replace(/\n+/g, '\n');

        // Remove leading and trailing new lines
        plainText = plainText.replace(/^\n|\n$/g, '');

        // Replace HTML tags with appropriate plain text representation
        plainText = plainText.replace(/<\/?(?:p|div|h\d|ul|ol|li|br)>/gi, '\n');

        // Remove remaining HTML tags
        plainText = plainText.replace(/<[^>]+>/g, '');

        this.clipboard.copy(new DOMParser().parseFromString(title + ' ' + plainText, 'text/html').body.textContent);
        this.messageService.openMessage(MessageSavedComponent, $localize`Text copied to clipboard`);
    }

    addText() {
        this.addNewItem();
        setTimeout(() => {
            this.focusEditor();
        }, 500);
    }

    private addNewItem(item: ResourceItemData = {}): FormGroup {
        const group = this.createItemFormGroup(new ResourceItem(item));
        this.items.push(group);
        this.openItem();
        return group;
    }

    private openItem(index: number = -1) {
        this.opened = index !== -1 ? index : this.items.length - 1;
        if (this.prompt.value === '') {
            setTimeout(() => {
                const element =
                    this.element.nativeElement.getElementsByClassName('on-hover-mat-item')[
                        index === -1 && this.isMobile ? this.items.length - 1 : index
                    ];

                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
        }
    }

    public onFollowUp(isFirstTrigger?: boolean) {
        this.showPrompter.next(true);
        setTimeout(() => {
            if (isFirstTrigger) {
                document.getElementById('promptInput').scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            this.input?.nativeElement?.focus();
        }, 300);
    }

    focusEditor() {
        const editors = document
            .getElementsByClassName('basic-tinymce-editor-ele')
            [this.items.length - 1].getElementsByTagName('iframe');

        if (editors !== undefined && editors.length > 0) {
            const editor = editors[0];
            const body = editor.contentDocument.getElementsByTagName('body');
            if (body !== undefined && body.length > 0) {
                body[0].focus();
            }
        }
    }
    isAllSelected() {
        const numSelected = this.selectedIndices.length;
        const numItems = this.items.length;
        return numSelected === numItems;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.items.controls.forEach((control) => this.selection.select(this.items.controls.indexOf(control)));
        this.cdr.markForCheck();
    }

    private initResource() {
        this.buildSocket();
        if (this.resource.items_attached) {
            this.resource.items$.pipe(takeUntil(this.destroy)).subscribe((items) => {
                this.updateItems(items);
                /**
                 * processing items
                 */
                items
                    .filter((item) => item.isProcessing)
                    .forEach((item) => this.actionQueue.push(item.status, [item.id]));
                FormHelper.resetForm(this.resourceForm);
            });
        }
    }

    private onResourceCreatedOrUpdated(resource: Resource) {
        const created = !this.resource?.id && resource?.id;
        this._resource = resource;
        if (created) {
            this.buildSocket();
        }
        this.patchResourceUpdates(resource);
        // Make resource items prestine
        FormHelper.resetForm(this.items);
        if (resource.items && resource.items.length) {
            this.updateItems(resource.items);
        }
    }

    private patchResourceUpdates(resource: Resource) {
        this.resourceForm.patchValue({ id: resource.id });
        FormHelper.resetForm(this.resourceForm.get('id'));
        if (!(this.resourceForm.value.title && this.resourceForm.get('title').dirty)) {
            this.resourceForm.patchValue({ title: resource.title });
            FormHelper.resetForm(this.resourceForm.get('title'));
        }
        if (resource.media_id && !this.resourceForm.value.media_id) {
            this.resourceForm.patchValue({ media_id: resource.media_id });
            FormHelper.resetForm(this.resourceForm.get('media_id'));
        }
    }

    public cleanControl() {
        if (this.items) {
            this.resourceForm.removeControl('resource_items');
        }
    }

    public onSubmit(): void {
        this.loading.emit(true);
        this.checkSubmitValid();
    }

    checkSubmitValid(): Observable<boolean> {
        if (!this.resourceForm.valid) {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.loading.emit(false);
            return of(false);
        }
        if (this.publish && this.resourceForm.value.resource_items === '') {
            FormHelper.markForm(this.resourceForm);
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`You can't publish the material before adding the material text`
            );
            this.loading.emit(false);
            return of(false);
        }
        super.performResSubmit();
        return of(true);
    }

    public prepareSubmit(eager?: boolean): Observable<Resource> {
        return this.prepareData().pipe(
            switchMap((data: ResourceData) =>
                this.resource?.id
                    ? this.resourceService.update(data, true, eager ? { eager: 'items' } : {})
                    : this.resourceService.store(data, eager ? { eager: 'items' } : {})
            ),
            takeUntil(this.destroy),
            tap((resource) => this.onResourceCreatedOrUpdated(resource))
        );
    }

    protected prepareData(): Observable<ResourceData> {
        const data = new Resource(this.resourceForm.value).getData();
        if (this.items.dirty) {
            data.resource_items = this.prepareMaterialTextItem();
        } else {
            delete data.resource_items;
        }

        if (this.publish) {
            if ((data && data.published) || (this.resource && this.resource?.published)) {
                data.last_publish = moment().format('YYYY-MM-DD HH:mm:ss');
            } else {
                data.published = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        }

        if (!this.publish && data) {
            data.last_publish = null;
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.TextMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    protected prepareMaterialTextItem(): ResourceItemData[] {
        return this.items.controls
            .filter((ctl) => ctl.value.id || ctl.value.name || ctl.value.title)
            .map(this.mapFormItem);
    }
    private mapFormItem(item: AbstractControl): ResourceItemData {
        const itemData: ResourceItemData = {};
        if (item.value.id) {
            itemData.id = item.value.id;

            if (item.get('name').dirty) {
                itemData.name = item.value.name;
            }
            if (item.get('title').dirty) {
                itemData.title = item.value.title;
            }
        } else {
            itemData.item_type_value = 'text_material';
            if (item.get('name').value) {
                itemData.name = item.value.name;
            }
            if (item.get('title').value) {
                itemData.title = item.value.title;
            }

            if (item.get('language_id').value) {
                itemData.language_id = item.value.language_id;
            }

            if (item.get('parent_id').value) {
                itemData.parent_id = item.value.parent_id;
            }
        }
        if (item.get('text_validation').dirty) {
            itemData.text_validation = item.value.text_validation;
        }
        return itemData;
    }

    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`Material title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`Material language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`Material categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'items',
                title: $localize`Material text`,
                hasValidValue: this.items?.value !== '',
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`Material image`,
                hasValidValue: this.resourceForm.value.media_id,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`Material tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`Material Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this test that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`Material description`,
                info: $localize`A description of the test that will be visible befor you take the test.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this test.`,
            },
        ];
    }

    get items() {
        return this.resourceForm.get('resource_items') as FormArray;
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.items.markAsDirty();
        this.prepareSubmit(true).pipe(takeUntil(this.destroy)).subscribe();
    }

    private createItemFormGroup(item: ResourceItem): FormGroup {
        return this.fb.group({
            id: item.id,
            name: item.name,
            title: item.title,
            updated_at: item.updated_at,
            text_validation: item.text_validation || 'empty',
            language_id: item.language_id,
            parent_id: item.parent_id,
            total_tokens: item.total_tokens,
            status: item.status,
        });
    }
}
