import { IDataModel, Model } from '@klickdata/core/application';

export interface VTQData extends IDataModel {
    id?: number;
    difficulty_level?: number;
    question_count?: number;
    material_name?: string;
    material_type?: string;
    language_id?: number;
    user_id?: number;
    material_id?: number;
    create_course?: boolean;
}

export class VTQ extends Model<VTQData> {
    id: number;
    difficulty_level: number;
    question_count: number;
    material_name: string;
    material_type: string;
    language_id: number;
    user_id: number;
    material_id: number;
    create_course: boolean;
}
